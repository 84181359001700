<template>
  <div class="pt-24" data-cy="your-surveys">
    <loading :state="$store.getters.initiallyLoading(['surveys'])"/>
    <div class="your-surveys-table">
      <div class="flex justify-space-between align-center">
        <h4 class="mb-22 txt-40 txt-bold txt-font-din-medium">
          {{ $t('components.titles.yourSurveys') }}
        </h4>
        <a-button
          type="secondary"
          size="large"
          @click="goBack">
          {{ $t('components.description.goBackToSurvey') }}
        </a-button>
      </div>
      <div class="flex align-center">
        <span style="display: flex; cursor: pointer;" @click="showCreateSurvey">
          <embed class="mr-4" width="20px" height="20px" style="color: orange;" src="/nav-icons-survey-orange.svg">
          <span style="padding-left: 5px">{{ $t('components.description.createNewSurvey') }}</span>
        </span>
        <span
          class="ml-20"
          :disabled="!weightedAnalysisEnabled"
          :style="{
            display: 'flex',
            cursor: weightedAnalysisEnabled ? 'pointer' : undefined,
            color: weightedAnalysisEnabled ? undefined : 'lightgrey'
          }"
          @click="showDownloadWeightedAnalysis">
          <embed class="mr-4" width="30px" height="20px" :src="weightedAnalysisEnabled ? '/download-cloud-icon.svg' : '/download-cloud-icon-grey.svg'">
          <span style="padding-left: 5px">{{ $t('components.description.downloadWeightedAnalysis') }}</span>
        </span>
      </div>
      <div class="flex flex-wrap align-center mt-10">
        <a-button
          v-for="(filter, index) in filterInfo.createdAt"
          :key="filter.value"
          type="primary"
          shape="round"
          size="large"
          class="mr-10 mt-10 flex align-center"
          @click="removeFilter('createdAt', index)">
          {{ filter }}
          <a-icon type="close" theme="outlined"/>
        </a-button>
        <a-button
          v-for="(filter, index) in filterInfo.surveyStatus"
          :key="filter.value"
          type="primary"
          shape="round"
          size="large"
          class="mr-10 mt-10 flex align-center"
          @click="removeFilter('surveyStatus', index)">
          {{ filter }}
          <a-icon type="close" theme="outlined"/>
        </a-button>
      </div>
      <div class="mt-20">
        <a-table
        data-cy="surveys-table"
        :columns="columns"
        size="small"
        :data-source="surveysWithRole"
        row-key="id"
        :pagination="false"
        :row-class-name="(record, index) => record.currentUserRole.value ? `${record.currentUserRole.value}-row` : ''"
        :locale="{ emptyText: $t('components.description.noSurveysCreatedYet') }"
        @change="handleChange">
          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column, filters }">
            <div style="padding: 8px">
              <span style="font-size: 12px" class="txt-grey">{{ $t('components.titles.filterByValue') }}</span>
              <a-input
                ref="searchInput"
                :placeholder="$t('components.description.search')"
                :value="searchText[column.dataIndex]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="e => handleFilterSearchChange(column.dataIndex, e.target.value || undefined)">
                <template #prefix>
                  <a-icon type="search" theme="outlined" style="font-size: 18px"/>
                </template>
              </a-input>
              <div class="flex justify-end">
                <a-button
                  type="link"
                  size="small"
                  class="w90 txt-orange txt-bold"
                  @click="setSelectedKeys(filters.filter(filter => !searchText[column.dataIndex] || filter.value.includes(searchText[column.dataIndex])).map(filter => filter.value))">
                  {{ $t('components.description.selectAll') }}
                </a-button>
                <a-button
                  type="link"
                  size="small"
                  class="w90 txt-orange txt-bold"
                  @click="setSelectedKeys([])">
                  {{ $t('values.clear') }}
                </a-button>
              </div>
              <div
                v-for="filter of filters.filter(filter => !searchText[column.dataIndex] || filter.value.includes(searchText[column.dataIndex]))"
                :key="filter.value"
                class="m-10">
                <a-checkbox
                  :checked="selectedKeys && selectedKeys.includes(filter.value)"
                  @click="toggleFilterKey(setSelectedKeys, selectedKeys, filter)">
                  {{ filter.text }}
                </a-checkbox>
              </div>
              <a-divider style="margin-left: -8px; width: calc(100% + 16px);"/>
              <div class="flex justify-space-between mt-22">
                <a-button
                  type="primary"
                  ghost
                  size="small"
                  class="w80"
                  @click="clearSearch(column.dataIndex); clearFilters();">
                  {{ $t('values.cancel') }}
                </a-button>
                <a-button
                  type="primary"
                  size="small"
                  class="w80"
                  @click="clearSearch(column.dataIndex); confirm();">
                  {{ $t('values.ok') }}
                </a-button>
              </div>
            </div>
          </template>
          <template #surveyStatus="surveyStatus">
            <span>
              <a-tag
                style="padding: 5px"
                :color="getColourForStatus(surveyStatus.name)">
                <span style="display: flex;">
                  <a-icon v-if="surveyStatus.name==='Survey Complete'" type="check-circle" theme="filled" style="color: #66c300; font-size: 18px; padding-right: 5px;"/>
                  <span v-else-if="surveyStatus.progress != null" style="padding-right: 5px">{{ formatProgress(surveyStatus.progress, surveyStatus.name) }}</span>
                  <span>{{ translateSurveyStatus(surveyStatus.name) }}</span>
                </span>
              </a-tag>
            </span>
          </template>
          <template #viewAndEdit="surveyId, survey">
            <div v-if="surveyId !== currentSurveyId">
              <span style="display: flex; cursor: pointer; justify-content: center;" @click="leaveSurvey(surveyId, survey.projectId)">
                <embed v-if="getUserRoleIconOnSurvey(survey)" class="mr-4" width="20px" height="20px" :src="getUserRoleIconOnSurvey(survey)">
                <span style="padding-left: 5px">{{ $t('components.description.goToSurvey') }}</span>
              </span>
            </div>
            <div v-else class="selected-survey-go-to-survey-container" :class="{ 'selected-survey-go-to-survey-container-supervisor': survey.id === currentSurveyId && survey.currentUserRole.value === 'supervisor' }">
              <span>{{ $t('components.description.currentlyViewing') }}</span>
            </div>
          </template>
          <template #surveyName="name, survey">
            <div class="survey-name-container" :class="{ 'selected-survey-name-container': survey.id === currentSurveyId, 'selected-survey-name-container-supervisor': survey.id === currentSurveyId && survey.currentUserRole.value === 'supervisor' }">
              <span>{{ name }}</span>
              <span v-if="survey.currentUserRole.value !== 'manager' && survey.surveyManager">
                <br>
                <a-popover>
                  <template slot="content">
                    <p>{{ $t('components.labels.firstName') }}: {{ survey.surveyManager.firstName }}</p>
                    <p>{{ $t('components.labels.lastName') }}: {{ survey.surveyManager.lastName }}</p>
                    <p>Email: <a :href="`mailto:${survey.surveyManager.email}`">{{ survey.surveyManager.email }}</a></p>
                  </template>
                  <span class="managed-by-text">{{ $t('components.description.managedBy', { name: survey.surveyManager.firstName + ' ' + survey.surveyManager.lastName }) }}</span>
                </a-popover>
              </span>
            </div>
          </template>
          <template #role="currentUserRole">
            <span>{{ currentUserRole.label }}</span>
          </template>
          <template #more="survey">
            <a-dropdown :trigger="['click']" :disabled="survey.currentUserRole.value !== 'admin' && survey.currentUserRole.value !== 'manager'">
              <span class="more-column" :class="{ 'disabled-more-column': survey.currentUserRole.value !== 'admin' && survey.currentUserRole.value !== 'manager'}">
                <a-icon type="more"/>
              </span>
              <a-menu slot="overlay">
                <a-menu-item @click="startManaging(survey)">
                  <span>{{ $t('components.labels.manageSupervisors') }}</span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>
      </div>
    </div>
    <LeaveSurveyModal :visible="selectingSurveyId != null" :on-exit="onExit" :on-complete="onComplete"/>
    <ProjectCreationModal :visible="showCreateSurveyModal" :project="project" :projects="projects" :surveys="surveys" :create-project="false" :on-exit="finishCreateSurvey"/>
    <ManageSupervisorsModal :visible="managingSurveyVisible" :survey="managingSurvey" :on-exit="onExitManaging"/>
    <WeightedAnalyisModal :visible="weightedAnalysisVisible" :surveys="validatedSurveys" :on-exit="onExitWeightedAnalysis"/>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import { mapActions, mapState } from 'vuex';
import { setUserProject, setUserSurvey } from '@/util/util';
import project from '../../../mixins/project';
import Loading from '../../loading.vue';
import LeaveSurveyModal from './leave-survey-modal.vue';
import ProjectCreationModal from '../../project/project-creation-modal.vue';
import ManageSupervisorsModal from './manage-supervisors-modal/index.vue';
import WeightedAnalyisModal from './weighted-analysis-modal/index.vue';
import { requestData } from '../../../store/modules/request';
import { getUserRoleSystemOnSurvey, getUserRoleOnSurvey, getUserRoleIconOnSurvey } from '../../../util/roles';

const filterData = data => formatter => data?.map(item => ({
  text: formatter(item),
  value: formatter(item)
}));

export default {
  name: 'SurveyList',
  components: {
    Loading,
    LeaveSurveyModal,
    ProjectCreationModal,
    ManageSupervisorsModal,
    WeightedAnalyisModal
  },
  mixins: [project()],
  data() {
    return {
      selectingSurveyId: null,
      selectingProjectId: null,
      managingSurveyVisible: false,
      managingSurvey: null,
      weightedAnalysisVisible: false,
      filterInfo: {},
      showCreateSurveyModal: false,
      searchText: {}
    };
  },
  computed: {
    ...mapState({
      project: state => state.survey.project,
      currentSurveyId: state => state.survey.surveyId,
      pilot: state => state.survey.pilot
    }),
    ...requestData(['surveys', 'projects', 'currentUser']),
    surveysWithRole() {
      return (this.surveys || []).map(srvy => ({
        ...srvy,
        currentUserRole: this.getUserRoleOnSurvey(srvy),
        surveyManager: srvy?.userAssignments?.find(assignment => assignment?.roleSystem === 'srvy-mgr')
      }));
    },
    validatedSurveys() {
      if (this.surveys) return this.surveys.filter((survey) => survey.validation === 'Validated');
      return [];
    },
    weightedAnalysisEnabled() {
      if (!this.surveys) {
        return false;
      }
      // weighted analysis is enabled if there are at least two validated surveys that have the same sampling method, age group, and indicators,
      // so this check looks for one pair of surveys where all those values match
      return this.validatedSurveys.some((survey, index) => this.validatedSurveys.slice(index + 1).some((otherSurvey) =>
        survey.metadata.samplingMethod === otherSurvey.metadata.samplingMethod &&
            survey.metadata.includedAgeGroup === otherSurvey.metadata.includedAgeGroup &&
            survey.indicators.length === otherSurvey.indicators.length &&
            survey.indicators.every((indicator) => otherSurvey.indicators.some((otherIndicator) => indicator.id === otherIndicator.id))));
    },
    columns() {
      return [
        {
          title: this.$t('components.labels.surveyName'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'surveyName' },
          key: 'name',
          width: '30%'
        },
        {
          title: this.$t('components.labels.dateCreated'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          customRender: this.formatDate,
          scopedSlots: { filterDropdown: 'filterDropdown' },
          filteredValue: this.filterInfo.createdAt || null,
          filters: uniqBy(filterData(this.surveysWithRole)(srvy => this.formatDate(srvy.createdAt)), 'value'),
          onFilter: (value, record) => this.formatDate(record.createdAt) === value,
          width: '15%'
        },
        {
          title: this.$t('components.labels.status'),
          dataIndex: 'status',
          key: 'surveyStatus',
          scopedSlots: { customRender: 'surveyStatus', filterDropdown: 'filterDropdown' },
          filteredValue: this.filterInfo.surveyStatus || null,
          filters: uniqBy(filterData(this.surveysWithRole)(srvy => srvy.status.name), 'value'),
          onFilter: (value, record) => record.status.name === value,
          width: '20%'
        },
        {
          title: this.$t('components.labels.role'),
          key: 'role',
          dataIndex: 'currentUserRole',
          scopedSlots: { customRender: 'role', filterDropdown: 'filterDropdown' },
          filteredValue: this.filterInfo.role || null,
          filters: uniqBy(filterData(this.surveysWithRole)(srvy => srvy.currentUserRole?.label), 'value'),
          onFilter: (value, record) => record.currentUserRole?.label === value,
          width: '10%'
        },
        {
          dataIndex: 'id',
          key: 'viewEdit',
          scopedSlots: { customRender: 'viewAndEdit' }
        },
        {
          key: 'more',
          scopedSlots: { customRender: 'more' },
          width: 50
        }
      ];
    },
    isCurrentUserAdmin() {
      if (this.currentUser.verbs) {
        return this.currentUser.verbs.includes('project.delete');
      }
      return false;
    }
  },
  watch: {
    project: {
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  },
  methods: {
    getUserRoleSystemOnSurvey(srvy) {
      return getUserRoleSystemOnSurvey(srvy, this.isCurrentUserAdmin);
    },
    getUserRoleOnSurvey(srvy) {
      return getUserRoleOnSurvey.call(this, srvy, this.isCurrentUserAdmin);
    },
    getUserRoleIconOnSurvey(srvy) {
      return getUserRoleIconOnSurvey(srvy, this.isCurrentUserAdmin);
    },
    ...mapActions([
      'setLoading',
      'emptyStoredSurvey',
      'clearResults',
      'emptyStoredIndicators'
    ]),
    handleChange(pagination, filters) {
      this.filterInfo = filters;
    },
    handleFilterSearchChange(dataIndex, value) {
      this.$set(this.searchText, dataIndex, value);
    },
    removeFilter(filterGroup, index) {
      this.filterInfo[filterGroup].splice(index, 1);
    },
    toggleFilterKey(setSelectedKeys, selectedKeys, filter) {
      const index = selectedKeys?.indexOf(filter.value);
      if (index != null && index !== -1) {
        selectedKeys.splice(index, 1);
      } else {
        setSelectedKeys([...(selectedKeys || []), filter.value]);
      }
    },
    clearSearch(dataIndex) {
      this.$set(this.searchText, dataIndex, undefined);
    },
    async fetchData(created = false) {
      try {
        this.setLoading(true);
        if (this.isCurrentUserAdmin && this.project.id != null) {
          await this.$store.dispatch('get', [{
            key: 'surveys',
            url: `/projects/${this.project.id}/surveys`,
            extended: true
          }]);
        } else {
          await this.$store.dispatch('get', [{
            key: 'surveys',
            url: '/auth-surveys',
            extended: true
          }]);
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.setLoading(false);
        if (created === true) {
          this.showCreateSurveyModal = false;
          this.leaveSurvey(this.surveys?.[0]?.id, this.surveys?.[0]?.projectId);
        }
      }
    },
    setSurvey(surveyId, projectId) {
      localStorage.clear();
      sessionStorage.clear();
      setUserSurvey(this.currentUser.id, surveyId);
      setUserProject(this.currentUser.id, projectId);
      this.emptyStoredSurvey();
      this.clearResults();
      this.emptyStoredIndicators();
      this.routeToHome(surveyId);
    },
    homePath(surveyId) {
      if (this.surveys?.find(srvy => srvy.id === surveyId)?.status?.progress >= 0.25) return '/';
      const link = document.createElement('a');
      link.href = '/survey-planning/metadata';
      return link.host === window.location.host ? link.pathname : '/';
    },
    routeToHome(surveyId) {
      const query = Object.assign({}, this.$route.query);
      delete query.next;
      this.$router.push({
        path: this.homePath(surveyId),
        query
      });
    },
    getColourForStatus(surveyStatus) {
      switch (surveyStatus) {
        case 'Reporting':
          return undefined;
        case 'Survey Planning':
          return 'purple';
        case 'Pilot Survey':
          return 'volcano';
        case 'Data Collection':
          return 'orange';
        case 'Survey Complete':
          return 'green';
        case 'Retrospective Data':
          return 'red';
        default:
          return 'black';
      }
    },
    translateSurveyStatus(surveyStatus) {
      const translatedStatus = {
        Reporting: this.$t('components.labels.reporting').toLocaleUpperCase(),
        'Survey Planning': this.$t('components.labels.surveyPlanning').toLocaleUpperCase(),
        'Pilot Survey': this.$t('components.labels.pilotSurvey').toLocaleUpperCase(),
        'Data Collection': this.$t('components.labels.dataCollection').toLocaleUpperCase(),
        'Survey Complete': this.$t('components.labels.surveyComplete').toLocaleUpperCase(),
        'Retrospective Data': this.$t('components.labels.retrospectiveData').toLocaleUpperCase()
      };
      return translatedStatus[surveyStatus];
    },
    formatProgress(surveyProgress, surveyStatus) {
      if (surveyStatus === 'Retrospective Data') {
        return 'R';
      }
      if (surveyProgress == null) {
        return undefined;
      }
      return surveyProgress.toLocaleString(undefined, { style: 'percent' });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    leaveSurvey(surveyId, projectId) {
      this.selectingSurveyId = surveyId;
      this.selectingProjectId = projectId;
    },
    onExit() {
      this.selectingSurveyId = null;
      this.selectingProjectId = null;
    },
    onComplete() {
      this.setSurvey(this.selectingSurveyId, this.selectingProjectId);
    },
    showCreateSurvey() {
      this.showCreateSurveyModal = true;
    },
    finishCreateSurvey(created = false) {
      if (created === true) {
        this.fetchData(created);
      } else {
        this.showCreateSurveyModal = false;
      }
    },
    startManaging(survey) {
      this.managingSurvey = survey;
      this.managingSurveyVisible = true;
    },
    onExitManaging() {
      this.managingSurveyVisible = false;
    },
    showDownloadWeightedAnalysis() {
      if (this.weightedAnalysisEnabled) this.weightedAnalysisVisible = true;
    },
    onExitWeightedAnalysis() {
      this.weightedAnalysisVisible = false;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';

.your-surveys-table {
  .ant-table-tbody {
    background-color: #fff;
  }
  .ant-table-small .ant-table-content .ant-table-body {
    margin: 0;
  }
  .ant-table-thead {
    font-family: 'DINNextLTPro-Medium';
    font-weight: bold;
    background-color: #ffffff;
    border-bottom: 1px solid #8e908f7c;
  }
  .ant-table-row {
    font-family: 'DINNextLTPro-Medium';
    border-bottom: 1px solid #8e908f7c;
    background-color: #ffffff;
  }
  .ant-table-thead > tr > th .anticon-filter {
    right: unset;
  }
  .ant-table-thead > tr > th .anticon-filter > svg {
    top: 42%;
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  .survey-name-container {
    padding-left: 8px;
    height: 100%;
    width: 100%;
    font-family: 'DINNextLTPro-Medium';
  }
  .selected-survey-name-container {
    padding-left: 3px;
  }
  .selected-survey-go-to-survey-container {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-family: 'DINNextLTPro-Regular';
    letter-spacing: -0.25px;
    color: #8e908f;
    text-align: center;
  }
  .more-column {
    text-align: center;
    color: #757575;
    font-size: 20px;
    cursor: pointer;
  }
  .disabled-more-column {
    color: rgba(117, 117, 117, 0.3);
    cursor: default;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:has(.more-column) {
    text-align: center;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:has(.selected-survey-name-container) {
    border-left: solid 5px $color-survey-manager-primary;
    height: 100%;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr:has(.selected-survey-name-container) {
    background-color: $color-survey-manager-secondary;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr:has(.selected-survey-name-container-supervisor) {
    background-color: $color-survey-supervisor-secondary;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:has(.selected-survey-name-container-supervisor) {
    border-left: solid 5px $color-survey-supervisor-primary;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:has(.selected-survey-go-to-survey-container) {
    height: 100%;
    text-align: center;
  }
  .managed-by-text {
    font-size: 14px;
    font-style: italic;
    letter-spacing: -0.25px;
    color: rgba(54, 53, 52, 0.5);
    font-family: 'DINNextLTPro-Regular';
  }
  .supervisor-row:hover > td {
    background-color: $color-survey-supervisor-secondary !important;
  }
  .manager-row:hover > td {
    background-color: $color-survey-manager-secondary !important;
  }
}
.ant-popover-inner-content p {
  margin-bottom: 0.25rem;
}
</style>
