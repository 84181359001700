var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hide-required-mark":true}},[_c('span',[_c('h4',{staticClass:"mt-24 mb-22 txt-bold txt-font-din-medium"},[_vm._v(_vm._s(_vm.$t('components.titles.basicDetails')))]),_c('a-card',{attrs:{"bordered":true}},[_c('a-row',{staticClass:"mb-22",attrs:{"type":"flex","gutter":48}},[_c('a-col',{attrs:{"lg":11,"md":24,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.firstName'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'firstName',
                    {
                      rules: [
                        {
                          required: true,
                          message: '',
                        } ]
                  }]),expression:"[\n                    'firstName',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: '',\n                        },\n                      ]\n                  }]"}],attrs:{"placeholder":_vm.$t('components.labels.firstName'),"size":"large"}})],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.lastName'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'lastName',
                    {
                      rules: [
                        {
                          required: true,
                          message: '',
                        } ]
                  }]),expression:"[\n                    'lastName',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: '',\n                        },\n                      ]\n                  }]"}],attrs:{"placeholder":_vm.$t('components.labels.lastName'),"size":"large"}})],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.description.gender'))+" ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'gender',
                {
                  rules: [
                    {
                      required: false,
                      message: '',
                    } ],
                } ]),expression:"[\n                'gender',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('components.description.selectAGender')}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.genderArray),function(genderOption,index){return _c('a-select-option',{key:index,attrs:{"value":genderOption.value}},[_vm._v(" "+_vm._s(genderOption.label)+" ")])})],2)],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$tc('values.country', 1))+" ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'country',
                {
                  rules: [
                    {
                      required: false,
                      message: '',
                    } ],
                } ]),expression:"[\n                'country',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","get-popup-container":function (triggerNode) { return triggerNode.parentNode; },"filter-option":_vm.filterOption,"placeholder":_vm.$t('components.description.selectACountry')}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.countryArray),function(country,index){return _c('a-select-option',{key:index,attrs:{"value":country.name}},[_vm._v(" "+_vm._s(country.name)+" ")])})],2)],1)],1),_c('a-col',{attrs:{"lg":10,"md":24,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.description.capacity'))+" ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'capacity',
                {
                  rules: [
                    {
                      required: false,
                      message: '',
                    } ],
                } ]),expression:"[\n                'capacity',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('components.description.selectACapacity')}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.capacityArray),function(option,index){return _c('a-select-option',{key:index,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2)],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.description.affiliation'))+" ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'affiliation',
                {
                  rules: [
                    {
                      required: false,
                      message: '',
                    } ],
                } ]),expression:"[\n                'affiliation',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('components.description.selectAnAffiliation')}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.affiliationArray),function(option,index){return _c('a-select-option',{key:index,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2)],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('values.organization'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'organization',
                    {
                      rules: [
                        {
                          required: false,
                          message: '',
                        } ]
                  }]),expression:"[\n                    'organization',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '',\n                        },\n                      ]\n                  }]"}],attrs:{"placeholder":_vm.$t('values.organization'),"size":"large"}})],1)],1)],1),_c('a-button',{staticClass:"mt-10 mb-12 w170 flex align-center justify-center",attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('values.save'))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }