import clone from 'lodash/clone';
import { apiPaths, configForPossibleBackendRequest } from '../../util/request';

export default function(Vue) {
  const defaultState = {
    selectedIndicators: [],
    possibleIndicators: [],
    indicatorCallsBeingLoaded: 0,
    saving: false,
    odkBuildForm: null,
    error: null,
    additionalIndicatorBlob: undefined,
    additionalIndicatorIsFetching: false,
    additionalIndicatorAbortController: undefined,
    activeLanguages: [],
    defaultLanguages: [],
    questionnaireUploadAbortController: undefined,
    loadingActiveLanguages: false
  };

  return {
    state: {
      ...defaultState
    },
    mutations: {
      setAdditionalIndicatorIsFetching(state, status) {
        state.additionalIndicatorIsFetching = status;
      },
      setAdditionalIndicatorBlob(state, blob) {
        state.additionalIndicatorBlob = blob;
      },
      setAdditionalIndicatorAbortController(state, controller) {
        state.additionalIndicatorAbortController = controller;
      },
      setIndicators(state, indicators) {
        state.selectedIndicators = indicators;
      },
      setAllPossibleIndicators(state, indicators) {
        state.possibleIndicators = indicators;
      },
      setOdkBuildForm(state, odkBuild) {
        state.odkBuildForm = odkBuild;
      },
      setUploadQuestionnaireAbortController(state, abortController) {
        state.questionnaireUploadAbortController = abortController;
      },
      startLoading(state) {
        state.indicatorCallsBeingLoaded += 1;
      },
      stopLoading(state) {
        state.indicatorCallsBeingLoaded -= 1;
      },
      startSaving(state) {
        state.saving = true;
      },
      stopSaving(state) {
        state.saving = false;
      },
      setError(state, err) {
        state.error = err;
      },
      setDefaultLanguages(state, defaultLanguages) {
        state.defaultLanguages = defaultLanguages;
      },
      setActiveLanguages(state, activeLanguages) {
        state.activeLanguages = activeLanguages;
      },
      startLoadingActiveLanguages(state) {
        state.loadingActiveLanguages = true;
      },
      stopLoadingActiveLanguages(state) {
        state.loadingActiveLanguages = false;
      },
      resetIndicatorsState(state) {
        Object.keys(defaultState).forEach(key => {
          state[key] = clone(defaultState[key]);
        });
      }
    },
    getters: {
      additionalIndicatorBlob: (state) => state.additionalIndicatorBlob,
      additionalIndicatorIsFetching: (state) => state.additionalIndicatorIsFetching
    },
    actions: {
      async getAdditionalIndicatorReport({ commit, getters }, { surveyId, lang }) {
        commit('startLoading');
        commit('setAdditionalIndicatorIsFetching', true);

        const newController = new AbortController();
        commit('setAdditionalIndicatorAbortController', newController);
        const axiosConfig = {
          method: 'GET',
          url: `/survey/${surveyId}/additional-indicators`,
          responseType: 'blob',
          signal: newController.signal,
          params: {
            lang
          }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const { data } = await Vue.prototype.$http.request(apiConfig);
          const blob = new Blob([data], { type: data.type });
          commit('setAdditionalIndicatorBlob', blob);
        } catch (error) {
          commit('setError', error);
          commit('setAdditionalIndicatorIsFetching', false);
          return false;
        } finally {
          commit('stopLoading');
        }
        return true;
      },
      async cancelAdditionalIndicatorRequest({ getters, commit }) {
        if (getters.additionalIndicatorAbortController && !getters.additionalIndicatorAbortController.signal.aborted) {
          getters.additionalIndicatorAbortController.abort();
        }
        commit('setAdditionalIndicatorBlob', undefined);
        commit('setAdditionalIndicatorIsFetching', false);
      },
      async loadAllPossibleIndicators({ commit, getters }, { surveyId }) {
        commit('startLoading');

        const axiosConfig = {
          method: 'GET',
          url: apiPaths.surveyIndicators(surveyId)
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const response = await Vue.prototype.$http.request(apiConfig);

          commit('setAllPossibleIndicators', response.data);
          return response.data;
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopLoading');
        }
        return [];
      },
      async loadSelectedIndicators({ commit, getters }, { surveyId }) {
        commit('startLoading');

        const axiosConfig = {
          method: 'GET',
          url: apiPaths.surveySelectedIndicators(surveyId)
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setIndicators', response.data);
          return response.data;
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopLoading');
        }
        return [];
      },
      async saveSelectedIndicators({ commit, getters }, { surveyId, indicators }) {
        commit('startSaving');

        const axiosConfig = {
          method: 'POST',
          url: apiPaths.surveySelectedIndicators(surveyId),
          data: {
            indicatorIds: indicators
          }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setIndicators', response.data);
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopSaving');
        }
      },
      async loadSelectedIndicatorBuild(
        { commit, getters },
        { surveyId, languages, defaultLanguage }
      ) {
        commit('startLoading');
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.surveyBuiltForm(surveyId),
          params: {
            languages,
            default: defaultLanguage
          }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setOdkBuildForm', response.data);
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopLoading');
        }
      },
      async createOdkBuildFormByJson({ commit, getters }, { projectId, surveyId, odkBuildJson }) {
        commit('startLoading');
        const axiosConfig = {
          method: 'POST',
          url: apiPaths.formJson(projectId, surveyId),
          data: odkBuildJson,
          params: { formBuilder: true, publish: true }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          await Vue.prototype.$http.request(apiConfig);
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopLoading');
        }
      },
      async resetAllIndicators({ commit, getters, state }, surveyId) {
        const indicatorsToReset = state.selectedIndicators.filter(indicator => indicator.name === 'general_info_demo');
        commit('startSaving');

        const axiosConfig = {
          method: 'POST',
          url: apiPaths.surveySelectedIndicators(surveyId),
          data: { indicatorIds: indicatorsToReset.map(item => item.id) }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setIndicators', response.data);
          return response.data;
        } catch (error) {
          commit('setError', error);
        } finally {
          commit('stopSaving');
        }
        return [];
      },
      async getActiveLanguages({ commit, getters }, surveyId) {
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.surveyActiveLanguages(surveyId)
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          commit('startLoadingActiveLanguages');
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setActiveLanguages', response.data);
        } catch (error) {
          commit('setError', error);
          if (error.response && error.response.data && error.response.data.code === 409.13) {
            // survey is missing odkbuild, set active languages to empty
            commit('setActiveLanguages', []);
          }
        } finally {
          commit('stopLoadingActiveLanguages');
        }
      },
      async saveActiveLanguages({ commit, getters }, { surveyId, languages, partial }) {
        const axiosConfig = {
          method: 'POST',
          url: apiPaths.surveyActiveLanguages(surveyId),
          data: {
            languages,
            partial
          }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

        try {
          await Vue.prototype.$http.request(apiConfig);
          commit('setError', null);
        } catch (error) {
          commit('setError', error);
        }
      },
      resetActiveLanguages({ commit }) {
        commit('setActiveLanguages', []);
      },
      async loadExistingLanguages({ commit, getters }, surveyId) {
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.questionnaireDefaultLanguages(surveyId)
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setDefaultLanguages', response.data);
        } catch (error) {
          commit('setError', error);
        }
      },
      async uploadQuestionnaireTemplate({ commit, getters }, { surveyId, projectId, file }) {
        const abortController = new AbortController();
        const axiosConfig = {
          method: 'POST',
          url: apiPaths.uploadQuestionnaireTemplate(projectId, surveyId),
          data: file,
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          signal: abortController.signal
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
        commit('setUploadQuestionnaireAbortController', abortController);
        try {
          const response = await Vue.prototype.$http.request(apiConfig);
          commit('setUploadQuestionnaireAbortController', undefined);
          return response.status === 200;
        } catch (error) {
          commit('setError', error);
          commit('setUploadQuestionnaireAbortController', undefined);
          return false;
        }
      },
      cancelQuestionnaireTemplateUpload({ getters, commit }) {
        if (getters.questionnaireUploadAbortController && !getters.questionnaireUploadAbortController.signal.aborted) {
          getters.questionnaireUploadAbortController.abort();
          commit('setUploadQuestionnaireAbortController', undefined);
        }
      },
      async getQuestionnaireTemplate({ commit, getters }, surveyId) {
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.downloadQuestionnaireTemplate(surveyId),
          responseType: 'blob'
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
        try {
          const { data, headers } = await Vue.prototype.$http.request(apiConfig);
          const type = data.type || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const blob = new Blob([data], { type });
          return { blob, headers };
        } catch (error) {
          commit('setError', error);
          return false;
        }
      },
      emptyStoredIndicators({ commit }) {
        commit('resetIndicatorsState');
      }
    }
  };
}
