<template>
  <div class="filters">
    <div class="txt-grey txt-uppercase mr-20 txt-14 txt-font-din-medium">{{ $t('components.labels.filters') }}</div>
      <a-range-picker v-model="dateRange" class="mr-20" :allow-clear="true">
        <a-icon slot="suffixIcon" type="calendar"/>
      </a-range-picker>
      <a-select
        v-model="teamSelect"
        class="mr-20 w170 default-size"
        size="default"
        :placeholder="$t('components.description.filterByATeam')"
        :get-popup-container="(triggerNode) => triggerNode.parentNode">
        <a-select-option v-for="team in teams"
          :key="`${team.value}`"
          :value="team.value">
          {{ team.title }}
        </a-select-option>
      </a-select>
      <a-select
        v-model="clusterSelect"
        class="mr-20 w170 default-size"
        size="default"
        :placeholder="$t('components.description.filterByACluster')"
        :get-popup-container="(triggerNode) => triggerNode.parentNode">
        <a-select-option v-for="cluster in clusterList"
          :key="`${cluster.value}`"
          :value="cluster.value">
          {{ cluster.title }}
        </a-select-option>
      </a-select>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import uniq from 'lodash/uniq';
import enumerators from '../../../mixins/enumerators';

const moment = require('moment');

export default {
  name: 'ProjectDashboardFilters',
  mixins: [
    enumerators()
  ],
  props: {
    onFilter: {
      type: Function,
      default: () => []
    }
  },
  data() {
    return {
      teamFilter: '',
      clusterFilter: '',
      rangeFilter: [null, null]
    };
  },
  computed: {
    ...mapState({
      dashboardTeams: state => state.plausibility.dashboardTeams,
      dashboardClusters: state => state.plausibility.dashboardClusters,
      clusters: state => state.survey.clusters.filter(cluster => cluster.name)
    }),
    ...mapGetters(['dashboardToDate', 'dashboardFromDate', 'isRandomOrExhaustive']),
    dateRange: {
      get() {
        return this.rangeFilter;
      },
      set(newVal) {
        this.rangeFilter = newVal;
        this.onChange();
      }
    },
    teamSelect: {
      get() {
        return this.teamFilter;
      },
      set(newVal) {
        this.teamFilter = newVal;
        this.onChange();
      }
    },
    clusterSelect: {
      get() {
        return this.clusterFilter;
      },
      set(newVal) {
        this.clusterFilter = newVal;
        this.onChange();
      }
    },
    teams() {
      let teams = [];
      if (this.enumerators) {
        teams = uniq(
          this.enumerators
            .filter(enumerator => enumerator.teamId)
            .map(enumerator => enumerator.teamId)
        ).sort();
        teams = teams.map(teamId => ({ value: teamId, title: `${this.$tc('components.labels.team', 1)} ${teamId}` }));
      }
      return [{ title: this.$t('components.dropdown.allTeams'), value: '' }, ...(teams)];
    },
    clusterList() {
      let result = [];
      if (!this.isRandomOrExhaustive) {
        const clusterSorted = [...new Set(this.clusters.map(item => item.name))].sort((a, b) => {
          if (Number(a) && Number(b)) {
            return Number(a) - Number(b);
          }
          return a.charCodeAt(a.length - 1) - b.charCodeAt(b.length - 1);
        });
        result = clusterSorted.map(cluster => ({ value: cluster, title: `${this.$tc('values.cluster', 1)} ${cluster}` }));
      }
      return [{ title: this.$t('components.dropdown.allClusters'), value: '' }, ...result];
    }
  },
  mounted() {
    this.rangeFilter = [this.dashboardFromDate ? moment(this.dashboardFromDate) : null, this.dashboardToDate ? moment(this.dashboardToDate) : null];
    this.teamFilter = this.dashboardTeams || '';
    this.clusterFilter = this.dashboardClusters || '';
  },
  methods: {
    ...mapActions(['setDashboardFromDate', 'setDashboardToDate', 'setDashboardTeams', 'setDashboardClusters']),
    onChange() {
      this.setDashboardFromDate(this.rangeFilter[0] ? this.rangeFilter[0].startOf('date').toDate() : null);
      this.setDashboardToDate(this.rangeFilter[1] ? this.rangeFilter[1].endOf('date').toDate() : null);
      this.setDashboardTeams(this.teamFilter);
      this.setDashboardClusters(this.clusterFilter);
    }
  }
};
</script>

<style lang="scss" scoped>

.filters {
  padding-left: 40px;
  background: #eaeaeb;
  height: 80px;
  width: calc(100% + 60px);
  margin-left: -15px;
  margin-top: -30px;
  position: sticky;
  left: 0;
  top:0;
  right: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  z-index: 1001;
}
</style>
