import { mapGetters } from 'vuex';

export default () => ({
  computed: {
    ...mapGetters(['isSurveyActive'])
  },
  watch: {
    isSurveyActive: 'guardRoute'
  },
  methods: {
    guardRoute() {
      if (this.isSurveyActive !== null) {
        if (this.$route.meta.pilotGuard) {
          this.$router.replace('/');
        }
      }
    }
  }
});
