<template>
  <div v-if="groupSelected != null" class="pt-8 pb-24">
    <div>
      <div class="flex header-container">
        <h4 class="mt-24 mb-22 txt-40 txt-bold txt-font-din-medium">
          {{ groupSelected && groupSelected.title }}
        </h4>
        <div style="margin-left: auto;">
          <a-button
            style="width: 100px;"
            size="large"
            type="secondary"
            @click="exit">
            {{ $t('values.exit') }}
          </a-button>
          <a-button
            v-if="active !== 'results'"
            :disabled="disabledNext()"
            style="width: 100px;"
            class="ml-10"
            size="large"
            type="primary"
            @click="moveTabs">
            {{ $t('values.next') }}
          </a-button>
          <a-button
            v-if="active === 'results' &&
              groupSelected && groupSelected.standardizationStatus !== 'marked_as_finished'"
            class="ml-10"
            size="large"
            type="primary"
            :disabled="disabledFinish || isSupervisor"
            @click="exitStandardizationTest">
            {{ $t('components.description.finishStandardizationTest') }}
          </a-button>
        </div>
      </div>
      <a-tabs v-model="active" size="large">
        <a-tab-pane key="add-enumerators">
          <div slot="tab" class="txt-font-din-medium">{{ $t('components.titles.addEnumerators', { number: 1, symbol: '.' }) }}</div>
          <div class="flex">
          <div>
            <a-button
              type="link"
              class="txt-18 txt-black pl-0"
              :disabled="isSupervisor"
              @click="onManualAddButtonClick">
              <a-icon type="edit" theme="filled" class="txt-orange"/>
              {{ $t('components.titles.manuallyAddEnumerators') }}
            </a-button>
          </div>
        </div>
        <AddManualEnumeratorModal
          v-if="manualAddEnumeratorModalActive"
          :has-supervisor="groupSelected && groupSelected.enumerators.findIndex(item => item && item.supervisor) > -1"
          :project="project"
          :survey-id="surveyId"
          :group-id="groupSelected.id"
          :on-manual-enumerator="onManualEnumerator"
          :on-exit="() => manualAddEnumeratorModalActive = false"/>
          <GroupList :tem-bias="[]"
            :group-id="groupSelected && groupSelected.id"
            :enumerator-disabled="disabledFinish"
            class="pt-24"
            :enumerators="groupSelected && groupSelected.enumerators"
            :allow-delete="true"
            :on-empty="() => { active = 'add-enumerators' }"
            :show-standardization-outcome="false"/>
        </a-tab-pane>
        <a-tab-pane key="data" :disabled="groupSelected && groupSelected.enumerators.length === 0">
          <div slot="tab" class="txt-font-din-medium">{{ $t('components.titles.dataEntryNumberSymbol', { number: 2, symbol: '.' }) }}</div>
          <a-alert type="info" class="mb-16 data-entry-alert"
              :description="$t('components.notifications.deletingAllDataForEnumerator')"
              show-icon/>
          <StandardizationDataEntry v-if="!rerenderDataEntry"
            :group-id="groupSelected && groupSelected.id"
            :on-enumerator-count="onEnumeratorCount"
            :enumerators="groupSelected && groupSelected.enumerators"
            :on-data-entry-changes="onDataEntryChanges"/>
        </a-tab-pane>
        <a-tab-pane key="results" :disabled="disabledFinish || groupSelected && groupSelected.enumerators.length === 0">
          <div slot="tab" class="txt-font-din-medium">{{ $t('components.titles.resultsNumberSymbol', { number: 3, symbol: '.' }) }}</div>
          <GroupList
            class="pt-24"
            :enumerator-disabled="disabledFinish"
            :group-id="groupSelected && groupSelected.id"
            :enumerators="groupSelected && groupSelected.enumerators"
            :tem-bias="groupSelected && groupSelected.temBias ? groupSelected.temBias : []"
            :allow-delete="true"
            :on-empty="() => { active = 'add-enumerators' }"
            :show-standardization-outcome="true"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import project from '../../../../mixins/project';

export default {
  name: 'EnumeratorGroup',
  components: {
    StandardizationDataEntry: () => import('../StandardizationTest/standardization-data-entry.vue'),
    GroupList: () => import('./GroupList/index.vue'),
    AddManualEnumeratorModal: () => import('../AddManualEnumeratorModal/add-manual-enumerators-modal.vue')
  },
  mixins: [project()],
  props: {
    groupId: {
      type: String,
      required: true
    },
    groupSelectedRecord: {
      default: null,
      type: Object,
      required: false
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      manualAddEnumeratorModalActive: false,
      active: 'add-enumerators',
      rerenderDataEntry: false,
      reloadTemBias: false,
      disabledFinish: true,
      groupSelected: null
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      standardization: state => state.survey.standardization,
      standardizationGroupMap: state => state.standardizationGroups.standardizationGroupMap,
      project: state => state.survey.project,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    isTourRunning() {
      return (this.$tours.firstStepTour && this.$tours.firstStepTour.isRunning) ||
        (this.$tours.secondStepTour && this.$tours.secondStepTour.isRunning);
    }
  },
  watch: {
    surveyId: {
      handler(newSurveyId) {
        this.getStandardizationGroupsForSurvey({ surveyId: newSurveyId });
      }
    },
    standardizationGroupMap: {
      handler(newStandardizationGroupMap) {
        const groupId = parseInt(this.groupId, 0);
        const groupSelected = newStandardizationGroupMap[groupId];
        this.groupSelected = {
          ...groupSelected,
          key: groupSelected.id,
          numberOfEnumerators: groupSelected.enumerators?.length
        };
      }
    },
    active: {
      handler(newActive) {
        const route = { name: 'Group', params: { groupId: this.groupId.toString(), activeTab: newActive } };
        this.$router.replace(route);
      }
    },
    activeTab: {
      handler(newActive) {
        this.active = newActive;
      }
    },
    groupSelected: {
      async handler(newVal) {
        if (this.reloadTemBias && newVal.id) {
          await this.getTemBiasForGroup({ groupId: newVal.id });
          this.reloadTemBias = false;
        }
      }
    },
    standardization: {
      deep: true,
      async handler() {
        if (this.reloadTemBias && this.groupId) {
          await this.getTemBiasForGroup({ groupId: this.groupId });
          this.reloadTemBias = false;
        }
      }
    }
  },
  created() {
    this.reloadTemBias = true;
  },
  mounted() {
    if (!this.groupSelectedRecord) {
      if (!this.groupId) {
        this.exit();
      }
    } else {
      this.groupSelected = this.groupSelectedRecord;
    }

    this.active = this.activeTab;
    if (this.surveyId) this.getStandardizationGroupsForSurvey({ surveyId: this.surveyId });
  },
  methods: {
    ...mapActions([
      'getTemBiasForGroup',
      'endStandardizationGroupTest',
      'getStandardizationGroupsForSurvey'
    ]),
    async exitStandardizationTest() {
      await this.endStandardizationGroupTest({
        surveyId: this.surveyId,
        groupId: this.groupSelected.id
      });

      this.$router.push({ name: 'Enumerators' });
    },
    onDataEntryChanges() {
      this.reloadTemBias = true;
    },
    onEnumeratorCount(mapCount) {
      const hasTwoEnumerators = Object.keys(mapCount).length >= 2;
      let twoEntries = 0;
      Object.keys(mapCount).forEach(item => {
        if (mapCount[item] >= 2) {
          twoEntries += 1;
        }
      });
      const hasTwoEntries = twoEntries >= 2;
      this.disabledFinish = !hasTwoEnumerators || !hasTwoEntries;
    },
    onManualEnumerator(addNew) {
      if (!addNew) {
        this.manualAddEnumeratorModalActive = false;
      }
    },
    onManualAddButtonClick() {
      if (!this.isTourRunning) {
        this.manualAddEnumeratorModalActive = true;
      }
    },
    disabledNext() {
      const enabledEnumeratorTab = this.active === 'add-enumerators' && this.groupSelected && this.groupSelected.enumerators.length !== 0;
      const enabledDataTab = this.active === 'data' && !this.disabledFinish;
      return !enabledEnumeratorTab && !enabledDataTab;
    },
    moveTabs() {
      if (this.active === 'add-enumerators') {
        this.active = 'data';
      } else if (this.active === 'data') {
        this.active = 'results';
      }
    },
    exit() {
      this.$router.push({ name: 'Enumerators' });
    }
  }
};
</script>

<style lang="scss">
.title-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.header-container {
  align-items: center;
}
.data-entry-alert .ant-alert-description {
  font-family: 'DINNextLTPro-Regular';
  font-size: 16px;
}
</style>
