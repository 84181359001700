<template>
  <a-modal
    v-if="surveys"
    width="90%"
    class="weighted-analysis-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="visible"
    :closable="true"
    :mask-closable="false"
    @cancel="onClose">
    <h1 class="flex align-center justify-left txt-30 txt-bold txt-font-din-medium">
      {{ $t('components.description.downloadWeightedAnalysis') }}
    </h1>
    <a-row class="Tab" type="flex" justify="start">
      <a-col :class="['txt-18', (stage === WeightedAnalysisStage.SELECT) ? null : 'disabled-color']" :span="6">{{ $t('components.labels.selectSurveys', { number: 1}) }}</a-col>
      <a-col :class="['txt-18', (stage === WeightedAnalysisStage.ASSIGN) ? null : 'disabled-color']" :span="8">{{ $t('components.labels.assignPopulationFigures', { number: 2 }) }}</a-col>
      <a-col :class="['txt-18', (stage === WeightedAnalysisStage.SETTINGS) ? null : 'disabled-color']" :span="4">{{ $t('components.labels.settings', { number: 3 }) }}</a-col>
      <a-col :class="['txt-18', (stage === WeightedAnalysisStage.GENERATE) ? null : 'disabled-color']" :span="6">{{ $t('components.labels.generateReport', { number: 4 }) }}</a-col>
    </a-row>

    <span v-if="stage === WeightedAnalysisStage.SELECT">
      <a-tag
        style="display: flex; flex-direction: row; padding: 10px; margin: 20px 0px; width: 100%; color: black;"
        color="blue">
        <ul style="margin: 0px; padding-left: 30px;">
          <li v-html="$t('components.labels.onlyValidatedSurveys')"></li>
          <li v-html="$t('components.labels.allSelectedSurveysMustHaveTheSame')"></li>
        </ul>
      </a-tag>
      <div class="mt-20">
        <a-table
        class="weighted-analysis-table"
        :columns="columns"
        size="small"
        :data-source="sortedSurveys"
        row-key="id"
        :row-class-name="record => !record.enabled && 'disabled-row'"
        :pagination="false">
          <template #surveyName="name">
            <div :title="name">{{ name }}</div>
          </template>
          <template #indicators="indicators">
            <div :title="formatIndicators(indicators)">{{ formatIndicators(indicators) }}</div>
          </template>
          <template #selectSurvey="id">
            <a-checkbox v-model="selectedSurveys[id]"/>
          </template>
        </a-table>
      </div>
      <div class="mt-40 buttons-container">
        <a-button
          class="w170"
          type="primary"
          size="large"
          :disabled="filteredSurveys.length < 2"
          @click="next">
          {{ $t('values.next') }}
        </a-button>
      </div>
    </span>

    <span v-if="stage === WeightedAnalysisStage.ASSIGN">
      <a-tag
        style="display: flex; flex-direction: row; padding: 10px; margin: 20px 0px; width: 100%; color: black;"
        color="blue">
        <ul style="margin: 0px; padding-left: 30px;">
          <li>{{ $t('components.labels.allSelectedSurveysMustBeAssignedAPopulation') }}</li>
          <li>{{ $t('components.labels.populationFigureRefersTo') }}</li>
        </ul>
      </a-tag>
      <div class="mt-20">
        <a-table
        class="weighted-analysis-table"
        :columns="populationColumns"
        size="small"
        :data-source="filteredSurveys"
        row-key="id"
        :pagination="false">
          <template #surveyName="name">
            <div :title="name">{{ name }}</div>
          </template>
          <template #populationInput="id">
            <a-input-number v-model="surveyPopulations[id]" class="w120" :min="1" :precision="0" placeholder="0"/>
          </template>
        </a-table>
      </div>
      <div class="mt-40 buttons-container">
        <a-button
          class="w170 mr-10"
          type="secondary"
          size="large"
          @click="back">
          {{ $t('values.back') }}
        </a-button>
        <a-button
          class="w170"
          type="primary"
          size="large"
          :disabled="populationsNotFilled"
          @click="next">
          {{ $t('values.next') }}
        </a-button>
      </div>
    </span>

    <span v-if="stage === WeightedAnalysisStage.SETTINGS">
      <a-card class="mb-20">
        <div class="mb-20" style="font-weight: 600;">{{ $t('components.labels.selectSurveyToTakeSettingsFrom') }}</div>
        <a-select
          v-model="settingsSurveyId"
          class="w50"
          :placeholder="$t('components.description.selectSurvey')"
          :get-popup-container="(triggerNode) => triggerNode.parentNode">
            <a-icon slot="suffixIcon" type="caret-down"/>
            <a-select-option
              v-for="survey in filteredSurveys"
              :key="survey.id"
              :title="survey.name"
              :value="survey.id">
              {{ survey.name }}
            </a-select-option>
        </a-select>
        <a-tag
          style="display: flex; flex-direction: row; padding: 10px; margin: 20px 0px; width: 100%; color: black;"
          color="blue">
          <ul style="margin: 0px; padding-left: 30px;">
            <li v-html="$t('components.description.sourceOfSettingsForAllSurveys')"></li>
          </ul>
        </a-tag>
      </a-card>
      <a-card>
        <div class="mb-20" style="font-weight: 600;">{{ $t('components.labels.zScoreExclusions').toLocaleUpperCase() }}</div>
        <a-select
          v-model="zScoreExclusions"
          class="w50"
          :placeholder="$t('components.description.selectFlagExclusion')"
          :get-popup-container="(triggerNode) => triggerNode.parentNode">
            <a-icon slot="suffixIcon" type="caret-down"/>
            <a-select-option
              value="who">
              {{ $t('values.who') }}
            </a-select-option>
            <a-select-option
              value="smart">
              {{ $t('values.smart') }}
            </a-select-option>
        </a-select>
      </a-card>
      <div class="mt-40 buttons-container">
        <a-button
          class="w170 mr-10"
          type="secondary"
          size="large"
          @click="back">
          {{ $t('values.back') }}
        </a-button>
        <a-button
          class="w170"
          type="primary"
          size="large"
          :disabled="settingsSurveyId == null || zScoreExclusions == null"
          @click="next">
          {{ $t('values.next') }}
        </a-button>
      </div>
    </span>

    <span v-if="stage === WeightedAnalysisStage.GENERATE">
      <a-progress :percent="progressPercentage"
        :status="downloadIsReady ? undefined : 'active'"
        :show-info="false" stroke-color="#66c300"/>

      <p>
        {{ infoText }}
      </p>
      <div class="mt-40 buttons-container">
        <a-button
          class="w170 mr-10"
          type="secondary"
          size="large"
          :disabled="isFetching"
          @click="back">
          {{ $t('values.back') }}
        </a-button>
        <a-button
          class="w170"
          type="primary"
          size="large"
          :disabled="!this.weightedAnalysisBlob"
          @click="download">
          {{ $t('values.download') }}
        </a-button>
      </div>
    </span>
  </a-modal>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import axios from 'axios';
import { translateIndicator } from '@/util/indicators';
import { configForPossibleBackendRequest } from '../../../../util/request';

const WeightedAnalysisStage = {
  SELECT: 'select',
  ASSIGN: 'assign',
  SETTINGS: 'settings',
  GENERATE: 'generate',
  ERROR: 'error',
  SENDING: 'sending'
};

const MAX_LOADING_TIME_SECS = 60 * 10; // 10 minutes

export default {
  name: 'WeightedAnalysisModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    surveys: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      WeightedAnalysisStage,
      stage: WeightedAnalysisStage.SELECT,
      selectedSurveys: {},
      surveyPopulations: {},
      settingsSurveyId: undefined,
      zScoreExclusions: undefined,
      isFetching: false,
      requestTime: 0,
      requestTimeIntervalId: null,
      fileName: 'weighted-analysis',
      weightedAnalysisBlob: undefined,
      abortController: undefined,
      currentResourceId: undefined
    };
  },
  computed: {
    filteredSurveys() {
      return this.surveys.filter(srvy => this.selectedSurveys[srvy.id]);
    },
    sortedSurveys() {
      if (this.filteredSurveys.length === 0) return this.surveys.map(srvy => ({ ...srvy, enabled: true }));
      const { indicators, metadata: { samplingMethod, includedAgeGroup } } = this.filteredSurveys[0];
      const enabledSurveys = this.surveys.map(srvy => {
        if (
          srvy.metadata.samplingMethod === samplingMethod &&
          srvy.metadata.includedAgeGroup === includedAgeGroup &&
          srvy.indicators.length === indicators.length &&
          srvy.indicators.every((indicator) => indicators.some((otherIndicator) => indicator.id === otherIndicator.id))
        ) {
          return ({ ...srvy, enabled: true });
        }
        return ({ ...srvy, enabled: false });
      });
      return enabledSurveys.sort((a, b) => ((a.enabled === b.enabled) ? 0 : a.enabled ? -1 : 1));
    },
    populationsNotFilled() {
      return this.filteredSurveys.some(srvy => this.surveyPopulations[srvy.id] == null);
    },
    progressPercentage() {
      return this.weightedAnalysisBlob ? 100 : Math.min(
        this.requestTime / MAX_LOADING_TIME_SECS * 99, 99
      );
    },
    infoText() {
      return !this.isFetching && this.downloadIsReady
        ? this.$t('components.description.reportGenerationSuccessful')
        : this.$t('components.description.thisCouldTakeUpToTenMinutes');
    },
    downloadIsReady() {
      return !!this.weightedAnalysisBlob;
    },
    columns() {
      return [
        {
          dataIndex: 'id',
          key: 'selectSurvey',
          scopedSlots: { customRender: 'selectSurvey' },
          width: '5%'
        },
        {
          title: this.$t('components.labels.surveyName'),
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'surveyName' },
          width: '20%'
        },
        {
          title: this.$t('components.labels.dateCreated'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          customRender: this.formatDate,
          width: '20%'
        },
        {
          title: this.$t('components.titles.ageGroup').toLocaleUpperCase(),
          dataIndex: 'metadata.includedAgeGroup',
          key: 'ageGroup',
          width: '10%'
        },
        {
          title: this.$t('components.description.indicators').toLocaleUpperCase(),
          dataIndex: 'indicators',
          key: 'indicators',
          scopedSlots: { customRender: 'indicators' }
        },
        {
          title: this.$t('components.labels.samplingMethod'),
          dataIndex: 'metadata.samplingMethod',
          key: 'samplingMethod',
          width: '20%'
        }
      ];
    },
    populationColumns() {
      return [
        {
          title: this.$t('components.labels.surveyName'),
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'surveyName' },
          width: '30%'
        },
        {
          title: <div>
            <span>{this.$t('components.labels.recallPeriod').toLocaleUpperCase()}</span>
            <span>{this.$t('components.titles.mortality').toLocaleUpperCase()}</span>
          </div>,
          dataIndex: 'metadata.mortalityRecall',
          key: 'recallPeriod',
          width: '20%'
        },
        {
          title: <div>
            <span>{this.$t('components.labels.childWeightAdjustment').toLocaleUpperCase()}</span>
            <span>{this.$t('components.labels.clothingWeightSubtraction')}</span>
          </div>,
          dataIndex: 'clothingweight',
          key: 'clothingWeight',
          width: '30%'
        },
        {
          title: this.$t('components.labels.populationFigure').toLocaleUpperCase(),
          dataIndex: 'id',
          key: 'populationFigure',
          scopedSlots: { customRender: 'populationInput' }
        }
      ];
    }
  },
  watch: {
    filteredSurveys() {
      if (!this.selectedSurveys[this.settingsSurveyId]) {
        this.settingsSurveyId = undefined;
      }
    }
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    formatIndicators(indicators) {
      if (!indicators) return '';
      return indicators.map(indicator => translateIndicator.call(this, indicator.name)).join(', ');
    },
    next() {
      switch (this.stage) {
        case WeightedAnalysisStage.SELECT:
          this.stage = WeightedAnalysisStage.ASSIGN;
          break;
        case WeightedAnalysisStage.ASSIGN:
          this.stage = WeightedAnalysisStage.SETTINGS;
          break;
        case WeightedAnalysisStage.SETTINGS:
          this.startDownload();
          break;
        default:
          break;
      }
    },
    back() {
      switch (this.stage) {
        case WeightedAnalysisStage.ASSIGN:
          this.stage = WeightedAnalysisStage.SELECT;
          break;
        case WeightedAnalysisStage.SETTINGS:
          this.stage = WeightedAnalysisStage.ASSIGN;
          break;
        case WeightedAnalysisStage.GENERATE:
          this.stage = WeightedAnalysisStage.SETTINGS;
          break;
        default:
          break;
      }
    },
    deleteResource({ surveyIds, resourceId }) {
      if (resourceId) {
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;

        const axiosConfig = {
          method: 'DELETE',
          url: `/weighted-analysis/${resourceId}`,
          params: {
            surveyIds,
            force: true
          }
        };

        Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token)
        );
      }
      return false;
    },
    async retrieveResource({ surveyIds, resourceId }) {
      if (resourceId) {
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;

        this.abortController = new AbortController();
        const axiosConfig = {
          method: 'GET',
          url: `/weighted-analysis/${resourceId}`,
          params: {
            surveyIds
          },
          responseType: 'blob',
          signal: this.abortController.signal
        };

        try {
          const { data } = await Vue.prototype.$http.request(
            configForPossibleBackendRequest(axiosConfig, token)
          );
          this.weightedAnalysisBlob = new Blob([data], { type: data.type });
          this.isFetching = false;
          clearInterval(this.requestTimeIntervalId);
          this.deleteResource({ surveyIds, resourceId: this.currentResourceId });
          return { success: true };
        } catch (error) {
          if (axios.isCancel(error)) {
            // Request canceled
            return { success: false }; // so we don't show the error message.
          }
          if (error.response && error.response.status === 423) {
            return { success: false };
          }
          this.isFetching = false;
          return { success: false, error };
        }
      }
      return { success: false };
    },
    cancelDownload() {
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort();
      }
      clearInterval(this.requestTimeIntervalId);
      this.requestTime = 0;
      const surveyIds = this.filteredSurveys.map(srvy => srvy.id);
      this.deleteResource({ surveyIds, resourceId: this.currentResourceId });
      this.currentResourceId = null;
      this.isFetching = false;
      this.weightedAnalysisBlob = undefined;
    },
    async startPolling(resourceId) {
      this.requestTime = 0;
      this.requestTimeIntervalId = setInterval(() => {
        this.requestTime += 1;
        if (this.requestTime % 15 === 0) {
          this.retrieveResourceHandler(resourceId);
        }
      }, 1000);
    },
    async retrieveResourceHandler(resourceId) {
      const surveyIds = this.filteredSurveys.map(srvy => srvy.id);
      const { success, error } = await this.retrieveResource({ surveyIds, resourceId });
      if (error) {
        this.$message.error({
          content: this.$t('components.notifications.weightedAnalysisError'),
          duration: 5
        });
        this.onClose();
      }
      if (success) {
        clearInterval(this.reportRequestTimeIntervalId);
      }
    },
    async startDownload() {
      this.weightedAnalysisBlob = undefined;
      this.stage = WeightedAnalysisStage.GENERATE;
      this.isFetching = true;

      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const surveys = this.filteredSurveys.map(srvy => ({ surveyId: srvy.id, population: this.surveyPopulations[srvy.id] }));
      this.abortController = new AbortController();
      const axiosConfig = {
        method: 'POST',
        url: '/weighted-analysis',
        data: {
          surveys,
          settingsSurveyId: this.settingsSurveyId,
          zScoreExclusion: this.zScoreExclusions,
          lang: this.$i18n.locale
        },
        params: {
          debug: false
        },
        signal: this.abortController.signal
      };

      try {
        const { data } = await Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token)
        );
        this.currentResourceId = data.resourceid;
        this.startPolling(data.resourceid);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request canceled
        } else {
          this.$message.error({
            content: this.$t('components.notifications.weightedAnalysisError'),
            duration: 5
          });
          this.onClose();
        }
        this.isFetching = false;
      }
    },
    async download() {
      if (this.downloadIsReady) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.weightedAnalysisBlob);
        link.download = this.fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    onClose() {
      this.onExit();
      // reset all state on close
      this.cancelDownload();
      this.stage = WeightedAnalysisStage.SELECT;
      this.selectedSurveys = {};
      this.surveyPopulations = {};
      this.zScoreExclusions = undefined;
    }
  }
};
</script>

<style lang="scss">
.weighted-analysis-modal {
  .ant-modal-body {
    padding: 40px;
  }

  li {
    white-space: normal;
  }

  .weighted-analysis-table {
    .ant-table-small {
      border: none;
      table tr {
        height: 60px;
        vertical-align: middle;
      }
      table tr > th,td {
        font-weight: bold;
      }
      table tr > th div span div {
        display: flex;
        flex-direction: column;
      }
      table tr > th div span div span:last-child {
        font-weight: normal;
      }
      .ant-table-thead > tr > th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
      .ant-table-content .ant-table-row:last-child td {
        border-bottom: 1px solid #e8e8e8;
      }
      .ant-table-tbody > tr > td {
        word-break: break-all;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        // for some reason max-width is required to keep white-space: nowrap from breaking everything in the table,
        // setting it small doesn't actually restrict the width, but setting it large breaks styling
        max-width: 1px;
        white-space: nowrap;
      }
      .ant-table-tbody > tr > td > div {
        word-break: break-all;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .disabled-row {
        background-color: lightgrey;
        pointer-events: none;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: end;
  }
}

.ant-dropdown {
  z-index: 1052;
}
</style>
