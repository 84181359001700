<template>
  <a-modal
    width="620px"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="visible"
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
    class="adding-supervisor-modal"
    @cancel="onCancel">
    <h1 class="adding-a-supervisor-title">
      {{ $t('components.titles.addingASupervisor') }}
    </h1>
    <a-form :form="form" @submit="handleSubmit">
      <div class="input-container">
        <a-form-item class="form-item">
          <p class="input-title">
            {{ $t('components.labels.fullName') }}
          </p>
          <a-input
            v-decorator="[
              'fullName',
              { rules: [{ required: true, message: $t('components.labels.inputSupervisorFullName') }] },
            ]"
            data-cy="full-name-input"
            auto-focus
            size="large"
            class="input-field"
            :placeholder="$t('components.labels.fullNameHere')"/>
        </a-form-item>
      </div>
      <div class="input-container">
        <a-form-item>
          <p class="input-title">
            {{ $t('components.labels.email') }}
          </p>
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: $t('components.labels.notAValidEmailAddress'),
                  },
                  {
                    required: true,
                    message: $t('components.labels.inputSupervisorEmailAddress'),
                  },
                ],
              },
            ]"
            data-cy="email-input"
            type="email"
            class="input-field"
            size="large"
            :placeholder="$t('components.labels.email').toLowerCase()"/>
        </a-form-item>
        <a-form-item>
          <p class="input-title">
            {{ $t('components.labels.confirmEmail') }}
          </p>
          <a-input
            v-decorator="[
              'confirmEmail',
              {
                rules: [
                  {
                    type: 'email',
                    message: $t('components.labels.notAValidEmailAddress'),
                  },
                  {
                    required: true,
                    message: $t('components.labels.confirmSupervisorEmailAddress'),
                  },
                  {
                    validator: compareToFirstEmail,
                  },
                ],
              },
            ]"
            data-cy="confirm-email-input"
            type="email"
            class="input-field"
            size="large"
            :placeholder="$t('components.labels.repeatEmail')"/>
        </a-form-item>
      </div>
      <div class="mt-24 buttons-container">
        <a-button
          class="w200"
          type="primary"
          size="large"
          ghost
          @click="onCancel">
          {{ $t('values.cancel') }}
        </a-button>
        <a-form-item style="padding: 0;">
          <a-button
            data-cy="add-supervisor-button"
            class="w200 add-button"
            type="primary"
            html-type="submit"
            :disabled="isLoading || hasErrors(form.getFieldsError())"
            :loading="isLoading"
            size="large">
            {{ $t('values.add') }}
          </a-button>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>

import Vue from 'vue';
import { configForPossibleBackendRequest } from '@/util/request';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default {
  name: 'AddSupervisorModal',
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasErrors,
      form: this.$form.createForm(this, { name: 'add_supervisor' })
    };
  },
  methods: {
    fullNameError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched('fullName') && getFieldError('fullName');
    },
    emailError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched('email') && getFieldError('email');
    },
    confirmEmailError() {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched('confirmEmail') && getFieldError('confirmEmail');
    },
    onCancel() {
      this.form.resetFields();
      this.isLoading = false;
      this.onExit();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.isLoading = true;
          const data = {
            fullName: values.fullName,
            email: values.email
          };
          const baseConfig = { method: 'POST', url: `/surveys/${this.surveyId}/invitations`, data };
          const token = this.$store.getters.loggedIn
            ? this.$store.state.request.data.session.token
            : null;
          const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
          try {
            const response = await Vue.prototype.$http.request(axiosConfig);
            this.$message.success({
              content: this.$t('components.notifications.supervisorAddedSuccessfully'),
              duration: 5
            });
            this.onComplete(response.data);
            this.form.resetFields();
          } catch (error) {
            if (error.response.data.code === 404.1) {
              this.form.setFields({
                email: {
                  value: values.email,
                  errors: [new Error(this.$t('components.labels.supervisorWithThisEmailNotFound'))]
                }
              });
            } else if (error.response.data.code === 409.1) {
              this.form.setFields({
                email: {
                  value: values.email,
                  errors: [new Error(this.$t('components.labels.supervisorAlreadyAdded'))]
                }
              });
            } else {
              this.$message.error({
                content: this.$t('components.notifications.unexpectedErrorOnAddingSupervisor'),
                duration: 5
              });
            }
          } finally {
            this.isLoading = false;
          }
        } else {
          this.$message.error({
            content: this.$t('components.notifications.supervisorFixAllFieldsIssuesError'),
            duration: 5
          });
        }
      });
    },
    compareToFirstEmail(rule, value, callback) {
      const { form } = this;
      if (value && value !== form.getFieldValue('email')) {
        callback(this.$t('components.labels.confirmEmailDoesNotMatch'));
      } else {
        callback();
      }
    }
  }
};
</script>

<style lang="scss">
.adding-supervisor-modal {
  .ant-modal-body {
    padding: 40px;
  }

  .adding-a-supervisor-title {
    font-family: 'DINNextLTPro-Medium';
    font-size: 32px;
    line-height: 0.97;
    letter-spacing: -0.35px;
    text-align: left;
  }
  .input-container {
    margin-top: 32px;
    padding: 8px;
    border-radius: 1px;
    border: solid 1px #ccc;
  }
  .ant-form-item {
    margin: 0;
    padding: 16px;
  }
  .input-title {
    font-family: 'DINNextLTPro-Medium';
    font-size: 14px;
    letter-spacing: 0.35px;
    text-align: left;
    margin: 0;
    line-height: 17px;
  }
  .input-field {
    width: 50%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
  }
  .add-button {
    margin-left: 16px;
  }
}
</style>
