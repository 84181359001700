<template>
    <div class="form-creation mt-24 mb-40">
      <a-card v-if="!isSENS" :bordered="true" class="form-create">
        <embed width="70px" class="mt-36" src="/create-form-icon.svg">
        <div class="txt-20 txt-bold txt-font-din-medium mb-16 mt-20">{{ $t('components.titles.createANewForm') }}</div>
        <div class="txt-16">
          {{ $t('components.description.formBuilderCreateFromScratch') }}
        </div>
        <a-button class="mt-24 w200 min-width-fit-content" data-cy="questionnaire-create-new-form-option-button" :disabled="isSupervisor" type="primary" size="large" @click="setActive('create')">
          {{ $t('components.titles.createNewForm') }}
        </a-button>
      </a-card>
      <div
        v-if="!isSENS"
        class="txt-bold txt-font-din-medium txt-30 txt-grey ml-24 mr-24 txt-uppercase">
        {{ $t('values.or').toUpperCase() }}
      </div>
      <a-card :bordered="true" class="form-upload">
        <embed width="70px" class="mt-36" src="/download-upload-icon.svg">
        <div class="txt-20 txt-bold txt-font-din-medium mb-16 mt-20">{{ $t('components.titles.downloadUploadForm') }}</div>
        <div class="txt-16">
          {{ $t('components.description.UseExternalProgramToUploading') }}
        </div>
        <a-button data-cy="questionnaire-upload-form-option-button" :disabled="isSupervisor" class="mt-24 w200 min-width-fit-content" type="primary" size="large" @click="setActive('upload')">
          {{ $t('components.titles.uploadForm') }}
        </a-button>
        <div :class="['txt-14', 'divider', 'txt-italic', 'download-form', 'i18n.locale' === 'en' ? 'mb-24' : '']">
          <div class="download-form-details">
            <span class="txt-bold txt-font-din-medium">{{ $t('components.titles.importantColon') }}</span>
            {{ $t('components.description.uploadedFormsAdhereFormatting') }}
          </div>
        </div>
      </a-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'QuestionnaireFormActions',
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapGetters(['isSENS']),
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    setActive(type) {
      this.$emit('input', type);
    }
  }
};
</script>

<style lang="scss">

.form-creation {
  display: flex;
  align-items: center;
  text-align: center;

  .ant-card {
    width: 400px;
  }

  .ant-card-body {
    position: relative;
    height: 433px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .download-form {
    padding-top: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .download-form-details {
    margin-left: 30px;
    margin-right: 30px;
    color: rgba(54, 53, 52, 0.64);
  }

  @media (max-width: 1024px) {
    .ant-card-body embed {
      width: 50px;
    }

    .download-form-details {
      font-size: 10px;

      embed {
        width: 18px;
      }
    }

    .form-download-action {
      font-size: 11px;
    }

    .ant-card-body {
      padding: 15px;
    }
  }
}
</style>
