<template>
  <span data-cy="Technical">
    <h4 class="mt-40 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.technical') }}</h4>
    <a-card :bordered="true" class="mb-22">
      <a-form-item class="w635">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.background') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.background')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-textarea
          v-decorator="[
            'background',
            {
              rules: [
                {
                  required: false
                },
              ],
            },
          ]"
          :disabled="isSupervisor"
          :auto-size="{ minRows: 2, maxRows: 6 }"/>
      </a-form-item>
      <a-form-item :label="$t('components.titles.surveyType')" class="w270">
        <a-select v-decorator="[
            'surveyType',
            {
              rules: [
                {
                  required: true,
                  message: $t('components.description.selectSurveyType'),
                },
              ],
              getValueFromEvent: onSurveyTypeChange
            },
          ]"
          :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option :disabled="isSupervisor" value="Full Smart">
            {{ $t('components.dropdown.fullSmart') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="NNS">
            {{ $t('components.dropdown.nns') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Rapid Smart">
            {{ $t('components.dropdown.rapidSmart') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="SENS">
            {{ $t('components.dropdown.sens') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Other">
            {{ $t('components.dropdown.other') }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="w270">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.surveyDate') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.surveyDate')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <div class="flex">
          <a-form-item class="mr-24 w170">
            <a-select v-decorator="[
                'surveyMonth',
                {
                  initialValue: new Date().getMonth() + 1,
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.requireSurveyMonth'),
                    },
                  ],
                },
              ]" :disabled="isSupervisor" :get-popup-container="(triggerNode) => triggerNode.parentNode">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option v-for="(month, index) in months" :key="month" :value="index + 1">
                {{ month }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="w80">
            <a-select v-decorator="[
                'surveyYear',
                {
                  initialValue: new Date().getFullYear(),
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.requireSurveyYear'),
                    },
                  ],
                },
              ]" :disabled="isSupervisor" :get-popup-container="(triggerNode) => triggerNode.parentNode" class="w60">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </a-form-item>
      <a-form-item>
        <p>{{ $t('components.description.daysDataCollection') }}</p>
        <a-input-number v-decorator="[
            'collectionDays',
            {
              rules: [
                {
                  required: surveyType !== 'Other',
                  message: $t('components.description.enterDaysCollectData'),
                },
              ],
            },
          ]" :disabled="isSupervisor" :min="1" :max="999" @focus="setCollectionDaysOnFirstClick"/>
      </a-form-item>
      <a-form-item :label="$t('components.labels.seasonality')" class="w270">
        <a-select v-decorator="[
            'seasonality',
            {
              rules: [
                {
                  required: true,
                  message: $t('components.description.selectSeasonality'),
                },
              ],
            },
          ]" :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option :disabled="isSupervisor" value="Lean Season">
            {{ $t('components.dropdown.leanSeason') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Harvest Season">
            {{ $t('components.dropdown.harvestSeason') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Not applicable">
            {{ $t('components.dropdown.notApplicable') }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('components.labels.populationType')" class="w270">
        <a-select v-decorator="[
            'populationType',
            {
              rules: [
                {
                  required: true,
                  message: $t('components.description.selectPopulationType'),
                },
              ],
            },
          ]" :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option :disabled="isSupervisor" value="General population">
            {{ $t('components.dropdown.generalPopulation') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Host community">
            {{ $t('components.dropdown.hostCommunity') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Host community & IDPs">
            {{ $t('components.dropdown.hostCommunityIdp') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Host community & refugees">
            {{ $t('components.dropdown.hostCommunityRefugees') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="IDPs in community">
            {{ $t('components.dropdown.idpInCommunity') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="IDPs in settlements">
            {{ $t('components.dropdown.idpInSettlements') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Refugee in community">
            {{ $t('components.dropdown.refugeeInCommunity') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Refugee in settlements">
            {{ $t('components.dropdown.refugeeInSettlements') }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('components.labels.surveySetting')" class="w270">
        <a-select v-decorator="[
            'surveySetting',
            {
              rules: [
                {
                  required: true,
                  message: $t('components.description.selectSurveySetting'),
                },
              ],
            },
          ]" :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option :disabled="isSupervisor" value="Rural">
            {{ $t('components.dropdown.rural') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Urban">
            {{ $t('components.dropdown.urban') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Rural & Urban">
            {{ $t('components.dropdown.ruralUrban') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Other">
            {{ $t('components.dropdown.other') }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="w635">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.surveyGoal') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.surveyGoal')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-textarea v-decorator="[
            'surveyGoal',
            {
              rules: [
                {
                  required: false
                },
              ],
            },
          ]" :disabled="isSupervisor" :auto-size="{ minRows: 2, maxRows: 6 }"/>
      </a-form-item>
      <a-form-item class="w635">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.specificSurveyObjectives') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.surveyObjectives')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-textarea v-decorator="[
            'surveyObjective',
            {
              rules: [
                {
                  required: false
                },
              ],
            },
          ]" :disabled="isSupervisor" :auto-size="{ minRows: 2, maxRows: 6 }"/>
      </a-form-item>
      <a-form-item hidden :label="$t('components.labels.additionalQuestions')">
        <p>{{ $t('components.description.questionsInSurvey') }}</p>
          <a-checkbox-group v-decorator="[
            'additionalQuestions',
            {
              rules: [
                {
                  required: false
                },
              ],
            },
          ]" :disabled="isSupervisor">
            <a-row class="mb-8 w350">
              <a-col :span="12">
                <a-checkbox value="Wash">
                  Wash
                </a-checkbox>
              </a-col>
              <a-col :span="12">
                <a-checkbox value="IYCF">
                  {{ $t('components.dropdown.iycf') }}
                </a-checkbox>
              </a-col>
            </a-row>
            <a-row class="mb-8 w350">
              <a-col :span="12">
                <a-checkbox value="Food Security">
                  {{ $t('components.dropdown.foodSecurity') }}
                </a-checkbox>
              </a-col>
              <a-col :span="12">
                <a-checkbox value="Anemia">
                  {{ $t('components.dropdown.anemia') }}
                </a-checkbox>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-checkbox value="Health">
                  {{ $t('components.dropdown.health') }}
                </a-checkbox>
              </a-col>
              <a-col :span="12">
                <a-checkbox value="Mosquito Net Coverage ">
                  {{ $t('components.dropdown.mosquitoNetCoverage') }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
      </a-form-item>
    </a-card>
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  Modal
} from 'ant-design-vue';
import moment from 'moment';
import { months, generateYears } from '../../../../util/date-time';
import { apiPaths } from '../../../../util/request';
import { noop } from '../../../../util/util';
import request from '../../../../mixins/request';


export default {
  name: 'MetadataTechnical',
  mixins: [request()],
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    project: {
      type: Object,
      default: undefined
    },
    metadata: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      years: []
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    surveyType() {
      if (this.metadata) {
        return this.metadata.surveyType;
      }
      return null;
    },
    months() {
      let allMonths = months;
      if (this.$i18n.locale === 'fr') {
        allMonths = allMonths.map((_, index) => {
          const fr = moment().locale('fr');
          const month = fr.localeData().months(moment([2012, index]));
          return month.charAt(0).toUpperCase() + month.slice(1);
        });
      } else {
        moment.locale('en');
      }
      return allMonths;
    }
  },
  created() {
    this.years = generateYears();
  },
  methods: {
    ...mapActions([
      'setFormFormats',
      'setForm'
    ]),
    setCollectionDaysOnFirstClick(e) {
      if (e.target.value === '') {
        this.form.setFieldsValue({
          collectionDays: 1
        });
      }
    },
    onSurveyTypeChange(newSurveyType) {
      // We need to clear forms for when survey type changes so we prompt users to confirm
      const { forms } = this.$store.state.request.data;
      if (forms && forms.length > 0) {
        const oldSurveyType = this.form.getFieldValue('surveyType');
        const onOk = () => {
          this.form.setFieldsValue({ surveyType: newSurveyType });
          const projectId = this.$store.state.survey.project.id;
          const { surveyId } = this.$store.state.survey;
          window.localStorage.removeItem('surveyTypeWarning');
          window.localStorage.removeItem('surveyTypeWasSetByModal');
          this.setFormFormats({
            odkbuild: null,
            xml: null
          });
          this.setForm({
            language: undefined,
            type: null
          });
          const promises = forms
            .map(form => this.request({
              method: 'DELETE',
              url: apiPaths.form(projectId, form._data.xmlFormId)
            }));
          Promise.all(promises).then(() => {
            this.$store.dispatch('get', [{
              key: 'forms',
              url: apiPaths.surveyForms(projectId, surveyId),
              extended: true
            }]).catch(noop);
            this.$store.dispatch('get', [{
              key: 'projects',
              url: '/projects',
              extended: true
            }]).catch(noop);
          });
        };

        Modal.confirm({
          title: this.$t('components.notifications.confirmSurveyType'),
          okText: this.$t('values.yes'),
          cancelText: this.$t('values.no'),
          content: () => (this.$t('components.notifications.warningSurveyType')),
          onOk
        });
        return oldSurveyType;
      }
      window.localStorage.removeItem('surveyTypeWarning');
      window.localStorage.removeItem('surveyTypeWasSetByModal');
      return newSurveyType;
    }
  }
};
</script>

<style lang="scss">
</style>
