function translateIndicator(indicator) {
  const translateGroup = {
    anthropometry: this.$t('components.titles.anthropometry'),
    mortality: this.$t('components.titles.mortality'),
    general_info_demo: this.$t('components.titles.generalInfoDemo'),
    household_dietary_diversity_score: this.$t('components.titles.householdDietaryDiversityScore'),
    reduced_coping_strategy_index: this.$t('components.titles.reducedCopingStrategyIndex'),
    household_hunger_scale: this.$t('components.titles.householdHungerScale'),
    food_insecure_experience_scale: this.$t('components.titles.foodInsecureExperienceScale'),
    access_to_safe_water_for_drinking_cooking: this.$t('components.titles.accessToSafeWaterForDrinkingCooking'),
    household_water_treatment: this.$t('components.titles.householdWaterTreatment'),
    access_to_improved_sanitation: this.$t('components.titles.accessToImprovedSanitation'),
    access_to_handwashing: this.$t('components.titles.accessToHandwashing'),
    access_to_sufficient_quantity_water: this.$t('components.titles.accessToSufficientQuantityWater'),
    food_consumption_score: this.$t('components.titles.foodConsumptionScore'),
    mnps_supplementation_coverage: this.$t('components.titles.mnpsSupplementationCoverage'),
    bsfp_coverage: this.$t('components.titles.bsfpCoverage'),
    vitamin_a_supplementation_coverage: this.$t('components.titles.vitaminASupplementationCoverage'),
    deworming_coverage: this.$t('components.titles.dewormingCoverage'),
    measles_vaccination_coverage: this.$t('components.titles.measlesVaccinationCoverage'),
    episode_of_diarrhoea: this.$t('components.titles.episodeOfDiarrhoea'),
    child_anaemia: this.$t('components.titles.childAnaemia'),
    iycf: this.$t('components.titles.iycf'),
    episode_of_fever: this.$t('components.titles.episodeOfFever'),
    episode_of_ari: this.$t('components.titles.episodeOfAri'),
    women_anaemia: this.$t('components.titles.womenAnaemia'),
    women_health: this.$t('components.titles.womenHealth'),
    women_anthropometry: this.$t('components.titles.womenAnthropometry'),
    household: this.$tc('components.titles.household', 1),
    women: this.$t('components.titles.women'),
    child: this.$t('components.titles.child')
  };
  return translateGroup[indicator];
}

function translateGrouping(grouping) {
  const translateGroup = {
    'Food Security': this.$t('values.foodSecurity'),
    'Nutrition & Health': this.$t('components.labels.nutritionAndHealth'),
    WASH: (this.$t('values.wash')).toUpperCase()
  };
  return translateGroup[grouping];
}

function translatedFilterTypes(type) {
  let label = type;
  if (label === 'waz') {
    label = this.$t('components.description.waz');
  } else if (label === 'haz') {
    label = this.$t('components.description.haz');
  } else if (label === 'whz') {
    label = this.$t('components.description.whz');
  } else if (label === 'muac') {
    label = this.$t('values.muac');
  }
  return label;
}

export { translateIndicator, translateGrouping, translatedFilterTypes };

