var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"adding-supervisor-modal",attrs:{"width":"620px","dialog-style":{ display: 'none' },"footer":null,"visible":_vm.visible,"closable":false,"mask-closable":false,"keyboard":false},on:{"cancel":_vm.onCancel}},[_c('h1',{staticClass:"adding-a-supervisor-title"},[_vm._v(" "+_vm._s(_vm.$t('components.titles.addingASupervisor'))+" ")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"input-container"},[_c('a-form-item',{staticClass:"form-item"},[_c('p',{staticClass:"input-title"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.fullName'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fullName',
            { rules: [{ required: true, message: _vm.$t('components.labels.inputSupervisorFullName') }] } ]),expression:"[\n            'fullName',\n            { rules: [{ required: true, message: $t('components.labels.inputSupervisorFullName') }] },\n          ]"}],staticClass:"input-field",attrs:{"data-cy":"full-name-input","auto-focus":"","size":"large","placeholder":_vm.$t('components.labels.fullNameHere')}})],1)],1),_c('div',{staticClass:"input-container"},[_c('a-form-item',[_c('p',{staticClass:"input-title"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.email'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: _vm.$t('components.labels.notAValidEmailAddress'),
                },
                {
                  required: true,
                  message: _vm.$t('components.labels.inputSupervisorEmailAddress'),
                } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: $t('components.labels.notAValidEmailAddress'),\n                },\n                {\n                  required: true,\n                  message: $t('components.labels.inputSupervisorEmailAddress'),\n                },\n              ],\n            },\n          ]"}],staticClass:"input-field",attrs:{"data-cy":"email-input","type":"email","size":"large","placeholder":_vm.$t('components.labels.email').toLowerCase()}})],1),_c('a-form-item',[_c('p',{staticClass:"input-title"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.confirmEmail'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirmEmail',
            {
              rules: [
                {
                  type: 'email',
                  message: _vm.$t('components.labels.notAValidEmailAddress'),
                },
                {
                  required: true,
                  message: _vm.$t('components.labels.confirmSupervisorEmailAddress'),
                },
                {
                  validator: _vm.compareToFirstEmail,
                } ],
            } ]),expression:"[\n            'confirmEmail',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: $t('components.labels.notAValidEmailAddress'),\n                },\n                {\n                  required: true,\n                  message: $t('components.labels.confirmSupervisorEmailAddress'),\n                },\n                {\n                  validator: compareToFirstEmail,\n                },\n              ],\n            },\n          ]"}],staticClass:"input-field",attrs:{"data-cy":"confirm-email-input","type":"email","size":"large","placeholder":_vm.$t('components.labels.repeatEmail')}})],1)],1),_c('div',{staticClass:"mt-24 buttons-container"},[_c('a-button',{staticClass:"w200",attrs:{"type":"primary","size":"large","ghost":""},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('values.cancel'))+" ")]),_c('a-form-item',{staticStyle:{"padding":"0"}},[_c('a-button',{staticClass:"w200 add-button",attrs:{"data-cy":"add-supervisor-button","type":"primary","html-type":"submit","disabled":_vm.isLoading || _vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.isLoading,"size":"large"}},[_vm._v(" "+_vm._s(_vm.$t('values.add'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }