import Prompt from './prompt';

class Prompts {
  constructor({ data = {}, prompts = [], lastPrompt = {} }) {
    this.data = data;
    this._prompts = prompts;
    this.lastPrompt = lastPrompt;

    if (prompts.length === 0) {
      throw new Error('prompts param should not be empty.');
    }
    this.validatePrompts(this._prompts);
  }

  get ['prompts']() {
    return this.filterPrompts(this._prompts);
  }

  get ['defaultResult']() {
    return this.getDefaultValueForPrompt(this.prompts);
  }

  getDefaultValueForPrompt(prompts) {
    let result = {};

    for (const prompt of prompts) {
      if (this.isPrompt(prompt)) {
        result[prompt.key] = prompt.defaultValue;
      } else {
        result = { ...result, ...this.getDefaultValueForPrompt(prompt) };
      }
    }
    return result;
  }

  validatePrompts(prompts) {
    for (const prompt of prompts) {
      if (Array.isArray(prompt)) {
        this.validatePrompts(prompt);
      } else {
        this.validatePrompt(prompt);
      }
    }
  }

  validatePrompt(prompt) {
  }

  filterPrompts(prompts) {
    let filteredPrompts = [];

    if (Object.keys(this.data).length > 0) {
      for (const prompt of prompts) {
        if (this.isPrompt(prompt)) {
          if (Object.prototype.hasOwnProperty.call(prompt, 'visibleConditions')) {
            let include = true;
            for (const key in this.data) {
              if (Object.prototype.hasOwnProperty.call(prompt.visibleConditions, key)) {
                include = this.data[key] === prompt.visibleConditions[key];
              }
            }
            if (include || this.lastPrompt.key === prompt.key) {
              filteredPrompts.push(prompt);
            }
          } else {
            filteredPrompts.push(prompt);
          }
        } else {
          filteredPrompts.push(this.filterPrompts(prompt));
        }
      }
    } else {
      filteredPrompts = prompts;
    }

    return filteredPrompts;
  }

  isPrompt(obj) {
    return obj.prompt;
  }

  promptByKey(key, prompts) {
    let _prompts = prompts;

    if (_prompts === undefined) {
      _prompts = this.prompts;
    }

    for (const prompt of _prompts) {
      if (this.isPrompt(prompt)) {
        if (prompt.key === key) {
          return prompt;
        }
      }

      const match = this.promptByKey(key, prompt);

      if (match !== undefined) {
        return match;
      }
    }

    return null;
  }
}


export default Prompts;
