<template>
  <a-spin data-cy="Questionnaire-Spinner" :spinning="loading !== false || loadingActiveLanguages !== false || indicatorsLoading !== 0" size="large">
    <div v-if="currentProject" class="pt-24" data-cy="Questionnaire">
      <FormCreationAlert/>
      <FormUploadAlert/>

      <a-row class="mb-40 mt-4">
        <h1 class="txt-font-din-bold questionnaire-title">{{ $t('components.titles.questionnaire') }}</h1>
      </a-row>
      <h4 class="mt-40 mb-22 txt-bold txt-font-din-bold text-uppercase">{{ $t('components.titles.formCreation') }}</h4>
      <p v-if="!isSENS && !formType" class="txt-16">
        {{ $t('components.description.selectAnOptionToBeginCreating') }}
      </p>
      <QuestionnaireFormActions v-if="!formType" v-model="formType"/>
      <QuestionnaireFormCreate v-if="formType === 'create'"
        :survey="currentProject"
        :cancel="cancel"
        :on-form-upload-complete="onFormComplete"
        :language-index="language"
        @languageChange="setDefaultLangauge"/>
      <QuestionnaireFormUpload
        v-if="formType === 'upload'"
        :survey="currentProject"
        :cancel="cancel"/>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import QuestionnaireFormActions from './form-actions/form-actions.vue';
import project from '../../../mixins/project';
import isSurveyActive from '../../../mixins/isSurveyActive';

export default {
  name: 'Questionnaire',
  components: {
    QuestionnaireFormActions,
    FormUploadAlert: () => import('../../qr-regeneration-warning/form-upload-alert.vue'),
    FormCreationAlert: () => import('../../qr-regeneration-warning/form-creation.alert.vue'),
    QuestionnaireFormUpload: () => import('./form-actions/form-upload.vue'),
    QuestionnaireFormCreate: () => import('./form-actions/form-create.vue')
  },
  mixins: [project(), isSurveyActive()],
  computed: {
    ...mapGetters(['isSENS']),
    ...mapState({
      surveyId: state => state.survey.surveyId,
      hasOdkbuild: state => state.survey.hasOdkbuild,
      loading: state => state.survey.loading,
      indicatorsLoading: state => state.indicators.indicatorCallsBeingLoaded,
      loadingActiveLanguages: state => state.indicators.loadingActiveLanguages
    }),
    language: {
      set(language) {
        this.setForm({
          ...this.currentProject._data,
          language,
          type: this.formType
        });
      },
      get() {
        return this.$store.state.survey.form && this.$store.state.survey.form.language;
      }
    },
    formType: {
      set(type) {
        this.setForm({
          ...this.currentProject._data,
          language: this.language,
          type
        });
      },
      get() {
        return this.$store.state.survey.form && this.$store.state.survey.form.type;
      }
    }
  },
  watch: {
    surveyId(newVal, oldVal) {
      if (newVal !== oldVal && this.hasOdkbuild) {
        this.getActiveLanguages(this.surveyId);
      }
    },
    hasOdkbuild(newVal) {
      if (!newVal) {
        this.resetActiveLanguages();
      }
    }
  },
  mounted() {
    if (this.surveyId && this.hasOdkbuild) {
      this.getActiveLanguages(this.surveyId);
    } else {
      this.resetActiveLanguages();
    }
  },
  methods: {
    ...mapActions([
      'setForm',
      'ackFormUploadAlert',
      'ackFormBuilderAsChange',
      'getActiveLanguages',
      'resetActiveLanguages'
    ]),
    setDefaultLangauge(languageIndex) {
      this.language = languageIndex;
    },
    cancel() {
      this.setForm({
        language: this.language,
        type: null
      });
      this.ackFormUploadAlert();
      this.ackFormBuilderAsChange();
    },
    async onFormComplete() {
      this.getActiveLanguages(this.surveyId);
      await this.refreshProject();
    }
  }
};
</script>

<style lang="scss">
.survey-title .ant-input-lg {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 0px;
  height: 55px;
}
.questionnaire-title {
  letter-spacing: -0.7px;
  line-height: 1.2;
  font-size: 40px;

}
</style>
