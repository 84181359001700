<template>
  <span></span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingMessage',
  data() {
    return {
      key: 'loadingKey'
    };
  },
  computed: {
    ...mapState({
      saving: state => state.survey.saving,
      savingIndicators: state => state.indicators.saving
    })
  },
  watch: {
    saving(newValue) {
      if (newValue) {
        this.$message.loading({ content: this.$t('components.notifications.savingDot'), key: this.key, duration: 0 });
      } else if (newValue != null) {
        this.$message.success({ content: this.$t('components.notifications.saved'), key: this.key, duration: 1 });
      } else {
        this.$message.warning({ content: 'Something went wrong!', key: this.key, duration: 1 });
      }
    },
    savingIndicators(newValue) {
      if (newValue) {
        this.$message.loading({ content: 'Saving selected indicators', key: this.key });
      } else if (newValue != null) {
        this.$message.success({ content: 'Saved Indicators!', key: this.key, duration: 1 });
      } else {
        this.$message.warning({ content: 'Something went wrong!', key: this.key, duration: 1 });
      }
    }
  }
};
</script>

<style lang="scss">
</style>
