import Base from './base';

const props = [
  'id',
  'name',
  'abbreviation',
  'category',
  'subcategory',
  'displayName',
  'grouping',
  'core',
  'excludedIfSelected'
];

export default class GenericIndicator extends Base(props) {}
