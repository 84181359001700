<template>
  <div class="pt-24">
    <a-spin :spinning="loading !== false || !currentProject" size="large">
      <div v-if="!currentProject">
        <a-skeleton/>
      </div>
      <div v-else>
        <DashboardPilotSelection
          v-if="pilot === null"
          :project="currentProject"/>
        <DashboardTracking v-else :project="currentProject" :on-pilot-complete="onPilotComplete"/>
      </div>
      <ProjectCreationModal
        :visible="loading === false && projects !== null && !currentProject" :project="currentProject"
        :projects="projects"
        :create-project="true"
        :on-exit="onProjectCreationModalExit"/>
      <ProjectPilotCompleteModal v-if="pilotComplete" :on-exit="() => pilotComplete = false"/>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ProjectCreationModal from '../project-creation-modal.vue';
import DashboardPilotSelection from './pilot-selection.vue';
import DashboardTracking from './tracking.vue';
import project from '../../../mixins/project';

export default {
  name: 'ProjectDashboard',
  components: {
    ProjectCreationModal,
    DashboardPilotSelection,
    DashboardTracking,
    ProjectPilotCompleteModal: () => import('./project-pilot-complete-modal')
  },
  mixins: [project()],
  data() {
    return {
      pilotComplete: false
    };
  },
  computed: {
    ...mapGetters(['surveyCompletePercent']),
    ...mapState({
      pilot: state => state.survey.pilot,
      loading: state => state.survey.loading
    })
  },
  methods: {
    onPilotComplete() {
      this.pilotComplete = true;
    },
    onProjectCreationModalExit() {
      this.$router.push({ path: '/survey-planning/metadata' });
    }
  }
};
</script>

<style lang="scss">
</style>
