<template>
  <a-modal
    width="495px"
    class="project-onboarding-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="false"
    :visible="visible">
    <h1 class="flex align-center justify-center txt-40">
      {{ $t('components.titles.createASurvey') }}
    </h1>
    <p v-if="createProject" class="txt-16">
    {{ $t('components.description.theFirstThingWeNeedToDoIsBeginCreatingYourSurvey') }}
    <br><br>
    {{ $t('components.description.yourSurveyWillBeIdentifiedWithThisName') }}
    <br>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="$t('components.description.organizationAcronymLastNameOfSurveyManager')"></span>
    </p>
    <a-input
      v-model="surveyName"
      data-cy="survey-name-entry"
      :placeholder="$t('components.description.exampleNameAAH')"
      :required="true"
      size="large"
      @change="checkAvailability">
      <a-icon v-show="!isLoading && isNameAvailable != null && isNameAvailable === true"
        slot="suffix" type="check-circle" class="txt-green txt-24"/>
      <a-icon v-show="!isLoading && isNameAvailable != null && isNameAvailable === false"
        slot="suffix" type="stop" class="txt-red txt-24"/>
    </a-input>
    <a-alert
      v-if="error"
      class="mb-20"
      :message="$t('components.notifications.error')"
      :description="`${error}`"
      type="error"
      show-icon/>
    <div class="flex justify-space-between">
      <a-button
        v-if="onExit"
        class="mt-24 w200"
        type="primary"
        size="large"
        ghost
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w200"
        type="primary"
        size="large"
        :disabled="isLoading || createSurveyIsLoading || !surveyName || isNameAvailable === false || isNameAvailable === null"
        :loading="isLoading || createSurveyIsLoading"
        @click="createSurvey">
        {{ $t('components.description.createMySurvey') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import Project from '../../presenters/project';
import { noop } from '../../util/util';

// how long to wait before checking for names
const debounceDelay = 500;

export default {
  name: 'ProjectCreationModal',
  props: {
    project: {
      type: Object,
      required: false,
      default: undefined
    },
    projects: {
      type: Array,
      required: false,
      default: () => []
    },
    surveys: {
      type: Array,
      required: false,
      default: () => []
    },
    createProject: {
      type: Boolean,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    onExit: {
      type: Function,
      default: noop
    }
  },
  data() {
    return {
      surveyName: '',
      isNameAvailable: null,
      error: null,
      isLoading: false,
      createSurveyIsLoading: false
    };
  },
  methods: {
    ...mapActions(['createSurveyWithName', 'createProjectWithName', 'checkNameAvailabilityForSurveys']),
    async createSurvey() {
      if (this.surveyName === '') {
        return;
      }
      try {
        this.createSurveyIsLoading = true;
        if (this.createProject) {
          const response = await this.createProjectWithName({ name: this.surveyName });
          if (response.status === 200) {
            const createdProject = new Project(response.data);
            this.$store.commit('setData', {
              key: 'projects',
              value: [...this.projects, createdProject]
            });

            await this.createSurveyWithName(
              { projectId: createdProject._data.id, data: { name: this.surveyName } }
            );
          }
        } else {
          let surveyProjectId = this.project.id;
          if (!this.projects.some(project => project.id === surveyProjectId)) {
            surveyProjectId = this.projects[0].id;
          }
          await this.createSurveyWithName(
            { projectId: surveyProjectId, data: { name: this.surveyName } }
          );
        }
        this.onExit(true);
        this.surveyName = '';
        this.isNameAvailable = null;
      } catch (err) {
        this.error = err;
      } finally {
        this.createSurveyIsLoading = false;
      }
    },
    checkAvailability: debounce(function(event) {
      event.preventDefault();
      this.checkAvailabilityForName(event.target.value);
    }, debounceDelay),
    async checkAvailabilityForName(name) {
      this.isLoading = true;
      if (!name) {
        this.isNameAvailable = false;
        this.isLoading = false;
        return;
      }

      const foundSurvey = await this.checkNameAvailabilityForSurveys({
        name
      });
      if (foundSurvey === null) {
        this.isNameAvailable = null;
        return;
      }
      if (foundSurvey) {
        this.isNameAvailable = false;
      } else {
        this.isNameAvailable = true;
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.project-onboarding-modal {
  .ant-modal-body {
    padding: 40px;
  }

  img {
    height: 37px;
    margin-left: 7px;
    margin-bottom: 14px;
  }
}
</style>
