<template>
  <a-modal
    :closable="true"
    :visible="show"
    width="726px"
    footer=""
    @cancel="cancelDownload">
    <h1 data-cy="report-download-modal-title">{{ $t('components.titles.downloadSurveyReport') }}</h1>
    <div>
      <div v-if="stage === DownloadStage.CHOOSE">
        <p class="report-text">{{ $t('components.description.zScoreSelection') }}</p>
        <a-select v-model="selectedZScore" class="flag-picker" :placeholder="$t('components.labels.selectzScoreExclusionPlaceholder')"
           :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-select-option value="smart">{{ $t('components.dropdown.smartFlags') }}</a-select-option>
          <a-select-option value="who">{{ $t('components.dropdown.whoFlags') }}</a-select-option>
        </a-select>
      </div>

      <div v-if="stage === DownloadStage.DOWNLOAD_IN_PROGRESS">
        <a-progress :percent="progressPercentage"
           :status="downloadIsReady ? undefined : 'active'"
           :show-info="false" stroke-color="#66c300"/>

        <p class="report-text report-body">
          {{ infoText }}
        </p>
      </div>

      <div class="download-button-container">
        <a-button
            v-if="stage === DownloadStage.CHOOSE"
            :disabled="selectedZScore == undefined"
            class="mb-10 w170"
            type="primary"
            size="large" @click="startDownload">
            {{ $t('values.next') }}
        </a-button>

        <a-button
            v-if="stage !== DownloadStage.CHOOSE"
            class="mb-10 w170"
            type="primary"
            :disabled="!downloadIsReady"
            size="large"
            @click="download">
          {{ $t('values.download') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

const key = 'uploading-survey-report';
const MAX_LOADING_TIME_SECS = 60 * 5; // 5 minutes

export default {
  name: 'ReportDownloadModal',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    onExit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      DownloadStage: {
        CHOOSE: 'choose',
        DOWNLOAD_IN_PROGRESS: 'downloading'
      },
      stage: 'choose',
      selectedZScore: undefined,
      currentResourceId: null,
      reportFetching: false,
      reportRequestTime: 0,
      reportRequestTimeIntervalId: null,
      fileName: 'Survey Report.docx'
    };
  },
  computed: {
    ...mapGetters([
      'isFetching',
      'abortController',
      'reportBlob'
    ]),
    ...mapState({
      projectId: state => state.survey.project.id,
      surveyId: state => state.survey.surveyId,
      surveyError: state => state.survey.error
    }),
    progressPercentage() {
      return this.reportBlob ? 100 : Math.min(
        this.reportRequestTime / MAX_LOADING_TIME_SECS * 99, 99
      );
    },
    infoText() {
      return !this.isFetching && !!this.reportBlob
        ? this.$t('components.description.yourSurveyReportReady')
        : this.$t('components.description.thisCouldTakeUpToOneMinute');
    },
    downloadIsReady() {
      return !!this.reportBlob;
    }
  },
  methods: {
    ...mapActions([
      'triggerReportGeneration',
      'retrieveResource',
      'deleteResource'
    ]),
    cancelDownload() {
      this.stage = this.DownloadStage.CHOOSE;
      this.selectedZScore = undefined;
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort();
      }
      clearInterval(this.reportRequestTimeIntervalId);
      this.reportRequestTime = 0;
      this.deleteResource({ surveyId: this.surveyId, resourceId: this.currentResourceId });
      this.currentResourceId = null;
      this.onExit();
    },
    async retrieveResourceHandler(resourceId) {
      const { success, error } = await this.retrieveResource({ surveyId: this.surveyId, resourceId });
      if (error) {
        this.$message.error({
          content: this.$t('components.notifications.surveyReportError'),
          duration: 5
        });
        this.cancelDownload();
      }
      if (success) {
        clearInterval(this.reportRequestTimeIntervalId);
      }
    },
    async startPolling(resourceId) {
      this.reportRequestTime = 0;
      this.reportRequestTimeIntervalId = setInterval(() => {
        this.reportRequestTime += 1;
        if (this.reportRequestTime % 15 === 0) {
          this.retrieveResourceHandler(resourceId);
        }
      }, 1000);
    },
    async startDownload() {
      this.stage = this.DownloadStage.DOWNLOAD_IN_PROGRESS;

      const result = await this.triggerReportGeneration({
        projectId: this.projectId,
        surveyId: this.surveyId,
        selectedzScore: this.selectedZScore,
        lang: this.$i18n.locale
      });
      if (!result) {
        let content = this.$t('components.notifications.surveyReportError');
        if (this.surveyError && this.surveyError.response.data?.code === 409.11) {
          content = this.$t('components.notifications.notEnoughDataToGenerateReport');
        }
        this.$message.error({
          content,
          duration: 5
        });
        this.cancelDownload();
      } else {
        const { resourceid } = result;
        this.currentResourceId = resourceid;
        this.startPolling(resourceid);
      }
    },
    async download() {
      if (this.downloadIsReady) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.reportBlob);
        link.download = this.fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        this.deleteResource({ surveyId: this.surveyId, resourceId: this.currentResourceId });
      }
    }
  }
};
</script>


<style lang="scss">

.flag-picker {
  width: 270px;
  z-index: 999;
}

.download-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.report-text {
  font-family: 'DINNextLTPro-Regular';
  font-size: 16px;
  width: 100%;
  color: #363534;
  margin-top: 15px;
}

.report-body {
  margin-bottom: 50px;
}
</style>
