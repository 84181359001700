/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import axios from 'axios';

import {
  shapeMortalitySmartSubmissions,
  shapeMortalityManualSubmissions,
  shapeAnthropometryManualSubmissions,
  shapeAnthropometrySmartSubmissions
} from '../../util/normalization';
import { configForPossibleBackendRequest, queryString } from '../../util/request';

export default function(Vue) {
  const getPopulationStat = (state, key) => {
    const { stat = 0, stat_low = 0, stat_upp = 0 } = state
      .mortalityStats?.populationStats?.find(_stat => _stat.key === key) || {};
    return {
      value: stat ? stat.toFixed(2) : 0,
      lower: stat_low ? stat_low.toFixed(2) : 0,
      upper: stat_upp ? stat_upp.toFixed(2) : 0
    };
  };

  const defaultAnthroSettings = {
    ageFormat: 'months',
    autopopulation: ['survDate', 'team', 'hh', 'cluster', 'id'],
    clothingWeight: 0,
    muac_lwr: 115,
    muac_upr: 125,
    variableRanges: [
      {
        name: 'months',
        start: 6,
        end: 59.99,
        custom: false
      },
      {
        name: 'weight',
        start: 3,
        end: 31,
        custom: false,
        unit: 'kg'
      },
      {
        name: 'height',
        start: 54,
        end: 124,
        custom: false,
        unit: 'cm'
      },
      {
        name: 'muac',
        start: 55,
        end: 235,
        custom: false,
        unit: 'mm'
      }
    ],
    ageGroups: [
      {
        start: 6,
        end: 17,
        custom: false
      },
      {
        start: 18,
        end: 29,
        custom: false
      },
      {
        start: 30,
        end: 41,
        custom: false
      },
      {
        start: 42,
        end: 53,
        custom: false
      },
      {
        start: 54,
        end: 59,
        custom: false
      }
    ],
    restricted: [
      {
        name: 'months',
        queryKey: 'months',
        selected: true,
        start: 6.00,
        end: 59.99
      },
      {
        name: 'cm',
        queryKey: 'height',
        selected: false,
        start: 67,
        end: 110
      }
    ],
    zScoreExclusion: 'no_exclusions',
    zScoreValues: {
      smart: {
        whz: {
          start: -3,
          end: 3
        },
        haz: {
          start: -3,
          end: 3
        },
        waz: {
          start: -3,
          end: 3
        },
        bmiz: {
          start: -3, end: 3
        },
        muacz: {
          start: -3, end: 3
        }
      },
      who: {
        whz: {
          start: -5,
          end: 5
        },
        haz: {
          start: -6,
          end: 6
        },
        waz: {
          start: -6,
          end: 5
        },
        bmiz: {
          start: -5, end: 5
        },
        muacz: {
          start: -5, end: 5
        }
      }
    }
  };

  const defaultMortalitySettings = {
    autopopulation: ['survDate', 'cluster', 'team'],
    showCauseOfDeath: true,
    causeOfDeath: [
      {
        code: 1,
        label: 'Traumatic',
        custom: false
      },
      {
        code: 2,
        label: 'Non-traumatic',
        custom: false
      },
      {
        code: 99,
        label: 'Unknown',
        custom: false
      }
    ],
    showLocationOfDeath: false,
    locationOfDeath: [
      {
        code: 1,
        label: null,
        custom: false
      },
      {
        code: 2,
        label: null,
        custom: false
      },
      {
        code: 3,
        label: null,
        custom: false
      },
      {
        code: 99,
        label: null,
        custom: false
      }
    ],
    ageGroup: 'flexible',
    ageGroupFlexible: [
      { start: 0, end: 4 },
      { start: 5, end: 11 },
      { start: 12, end: 17 },
      { start: 18, end: 49 },
      { start: 50, end: 64 },
      { start: 65, end: 120 }
    ]
  };

  const defaultState = {
    // Settings
    anthroSettings: defaultAnthroSettings,
    defaultAnthroSettings,
    anthroSettingsLoaded: false,
    mortalitySettings: defaultMortalitySettings,
    defaultMortalitySettings,
    mortalitySettingsLoaded: false,

    anthroAnalysisChanged: false,
    mortalitySettingsChanged: false,

    // Meta
    fetchingData: false,
    error: undefined,

    // Data
    submissions: [],
    manualSubmissions: [],

    normalizedMortalityData: {},
    normalizedAnthroData: {},

    // Analysis
    prevalence: undefined,
    tables: undefined,
    anthroAnalysisSurveyInfo: undefined,

    // Plots
    anthroPlot: undefined,
    anthroPlotType: undefined,
    anthroAnalysisNotEnoughData: false,

    mortalityStats: undefined,
    mortaltyAnalysisNotEnoughData: false
  };
  return {
    state: { ...defaultState },
    mutations: {
      setAnthroSettings(state, settings) {
        state.anthroSettings = isEmpty(settings) ? defaultAnthroSettings : { ...settings };
      },
      setAnthroSettingsLoaded(state) {
        state.anthroSettingsLoaded = true;
      },
      setAnthroAnalysisChanged(state, isChanged) {
        state.anthroAnalysisChanged = isChanged;
      },
      setMortalitySettingsChanged(state, isChanged) {
        state.mortalitySettingsChanged = isChanged;
      },
      setMortalitySettings(state, settings) {
        state.mortalitySettings = isEmpty(settings) ? defaultMortalitySettings : { ...settings };
      },
      setMortalitySettingsLoaded(state) {
        state.mortalitySettingsLoaded = true;
      },
      setNormalizedMortalityData(state, data) {
        state.normalizedMortalityData = data;
      },
      setNormalizedAnthroData(state, data) {
        state.normalizedAnthroData = data;
      },
      setSubmissionData(state, data) {
        state.submissions = data;
      },
      setManualData(state, data) {
        state.manualSubmissions = data;
      },
      setPrevalence(state, data) {
        if (!data) {
          state.prevalence = undefined;
        } else {
          // Lowercase all the properties
          state.prevalence = Object.fromEntries(
            Object.entries(data).map(([k, v]) => [k.toLowerCase(), v])
          );
        }
      },
      setAnthroAnalysisSurveyInfo(state, surveyInfo) {
        state.anthroAnalysisSurveyInfo = surveyInfo;
      },
      setTables(state, data) {
        if (!data) {
          state.tables = undefined;
        } else {
          // Lowercase all the properties
          state.tables = Object.fromEntries(
            Object.entries(data).map(([k, v]) => [k.toLowerCase(), v])
          );
        }
      },
      setAnthroPlot(state, { plot, type }) {
        state.anthroPlot = plot;
        state.anthroPlotType = type;
      },
      setMortalityStats(state, stats) {
        state.mortalityStats = stats;
      },
      setFetching(state, status) {
        state.fetchingData = status;
      },
      setError(state, error) {
        state.error = error;
      },
      setAnthroAnalysisNotEnoughData(state, status) {
        state.anthroAnalysisNotEnoughData = status;
      },
      setMortalityAnalysisNotEnoughData(state, status) {
        state.mortaltyAnalysisNotEnoughData = status;
      },
      clearResults(state) {
        Object.keys(defaultState).forEach(key => {
          state[key] = clone(defaultState[key]);
        });
      }
    },
    getters: {
      normalizedMortalityData: (state) => state.normalizedMortalityData,
      normalizedAnthroData: (state) => state.normalizedAnthroData,
      showCauseOfDeath: (state) => {
        const { mortalitySettings } = state;
        const { showCauseOfDeath } = mortalitySettings;
        return showCauseOfDeath;
      },
      showLocationOfDeath: (state) => {
        const { mortalitySettings } = state;
        const { showLocationOfDeath } = mortalitySettings;
        return showLocationOfDeath;
      },
      // TODO: Remove unused data
      anthroData: (state) => {
        const shapedSmartSubmissions = shapeAnthropometrySmartSubmissions(state.submissions);
        const shapedManualSubmissions = shapeAnthropometryManualSubmissions(state.manualSubmissions);
        return [...shapedSmartSubmissions, ...shapedManualSubmissions];
      },
      // TODO: Remove unused data
      mortalityData: (state) => {
        const shapedSmartSubmissions = shapeMortalitySmartSubmissions(state.submissions);
        const shapedManualSubmissions = shapeMortalityManualSubmissions(state.manualSubmissions);
        return [...shapedSmartSubmissions, ...shapedManualSubmissions];
      },
      anthroSettings: (state) => {
        const { anthroSettings } = state;
        return anthroSettings;
      },
      anthroAnalysisChanged: (state) => {
        const { anthroAnalysisChanged } = state;
        return anthroAnalysisChanged === true;
      },
      mortalitySettingsChanged: (state) => {
        const { mortalitySettingsChanged } = state;
        return mortalitySettingsChanged === true;
      },
      ageFormat: (state) => {
        const { anthroSettings } = state;
        const { ageFormat = 'months' } = anthroSettings;
        return ageFormat;
      },
      clothingWeight: (state) => {
        const { anthroSettings } = state;
        const { clothingWeight = 0 } = anthroSettings;
        return clothingWeight;
      },
      autoPopulation: (state) => {
        const { anthroSettings } = state;
        const { autopopulation = [] } = anthroSettings;
        return autopopulation;
      },
      causeOfDeath: (state) => {
        const { mortalitySettings } = state;
        const { causeOfDeath = [] } = mortalitySettings;
        return causeOfDeath;
      },
      zScoreValues: (state) => {
        const { anthroSettings } = state;
        const { zScoreValues = [] } = anthroSettings;
        return zScoreValues;
      },
      mortalityAutoPopulation: (state) => {
        const { mortalitySettings } = state;
        const { autopopulation = [] } = mortalitySettings;
        return autopopulation;
      },
      variableRanges: (state) => {
        const { anthroSettings } = state;
        const { variableRanges = [] } = anthroSettings;
        return variableRanges;
      },
      restrictedAnalysis: (state) => {
        const { anthroSettings } = state;
        const { restricted = [] } = anthroSettings;
        return restricted;
      },
      // mortality related stats
      averageHouseholdSize: state => state.mortalityStats?.populationStats?.find(stat => stat.key === 'avg_hh_size')?.stat?.toFixed(2) || 0,
      childrenUnderFive: state => state.mortalityStats?.populationStats?.find(stat => stat.key === 'u5_rate')?.stat?.toFixed(2) || 0,
      householdChildrenUnderFive: state => state.mortalityStats?.sampleStats?.find(stat => stat.key === 'n_hh_u5')?.stat?.toFixed(0) || 0,
      householdChildrenUnderFivePercent: state => state.mortalityStats?.sampleStats?.find(stat => stat.key === 'p_hh_u5')?.stat?.toFixed(2) || 0,
      birthRate: (state) => getPopulationStat(state, 'birth_rate'),
      inMigrationRate: (state) => getPopulationStat(state, 'in_migration_rate'),
      outMigrationRate: (state) => getPopulationStat(state, 'out_migration_rate'),
      ageTally: (state) => state.mortalityStats?.ageTally || [],
      clusterTally: (state) => state.mortalityStats?.clusterTally || [],
      deathRates: (state) => state.mortalityStats?.deathRates || [],
      causeOfDeathStats: (state) => state.mortalityStats?.causeOfDeath || [],
      locationStats: (state) => state.mortalityStats?.location || []
    },
    actions: {
      async saveAnthropometrySettings({ getters, commit }, { projectId, surveyId, settings }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/settings/anthropometry`,
            data: settings
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            if (data && data.length && data.length > 0) {
              commit('setAnthroAnalysisChanged', true);
              commit('setAnthroSettings', data[0].config);
            }
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getAnthropometrySettings({ getters, commit }, { projectId, surveyId, params = {} }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'GET',
            url: `/projects/${projectId}/survey/${surveyId}/settings/anthropometry${queryString(params)}`
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            commit('setAnthroSettings', data.config);
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('setAnthroSettingsLoaded');
          }
        }
      },
      async saveMortalitySettings({ getters, commit }, { projectId, surveyId, settings }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/settings/mortality`, data: settings };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            if (data && data.length && data.length > 0) {
              commit('setMortalitySettingsChanged', true);
              commit('setMortalitySettings', data[0].config);
            }
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getMortalitySettings({ getters, commit }, { projectId, surveyId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}/settings/mortality` };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            commit('setMortalitySettings', data.config);
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('setMortalitySettingsLoaded');
          }
        }
      },
      async getAnthroSubmissionData({ getters, commit }, { projectId, surveyId }) {
        if (projectId) {
          const token = getters.loggedIn ? getters.token : null;
          const apiConfig = {
            method: 'GET', url: `/projects/${projectId}/child-anthro/${surveyId}`
          };

          try {
            const anthroData = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(apiConfig, token)
            );

            commit('setNormalizedAnthroData', anthroData.data);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getMortalitySubmissionData({ getters, commit }, { projectId, surveyId }) {
        if (projectId) {
          const token = getters.loggedIn ? getters.token : null;
          const apiConfig = {
            method: 'GET',
            url: `/projects/${projectId}/mortality/${surveyId}`,
            headers: { 'Content-Type': 'application/json' }
          };

          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(apiConfig, token)
            );

            commit('setNormalizedMortalityData', response.data);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getSurveySubmissionData({ getters, commit }, { projectId, surveyId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const submissionAxiosConfig = { method: 'GET', url: `/projects/${projectId}/submissions` };
          const manualAxiosConfig = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}/manual-submissions` };
          try {
            const [submissionResponse, manualSubmission] = await Promise.all([
              Vue.prototype.$http.request(
                configForPossibleBackendRequest(submissionAxiosConfig, token)
              ),
              Vue.prototype.$http.request(
                configForPossibleBackendRequest(manualAxiosConfig, token)
              )
            ]);
            commit('setSubmissionData', submissionResponse.data);
            commit('setManualData', manualSubmission.data);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getSurveyAnthroAnalysis({ getters, commit }, { projectId, surveyId, filters }) {
        commit('setFetching', true);
        commit('setAnthroAnalysisNotEnoughData', false);
        if (projectId && surveyId) {
          commit('setPrevalence', undefined);
          commit('setTables', undefined);
          commit('setAnthroAnalysisSurveyInfo', undefined);
          commit('setAnthroPlot', { plot: undefined, type: undefined });
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/anthro-analysis`, data: filters };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            commit('setPrevalence', data.prevalences);
            commit('setTables', data.tables);
            commit('setAnthroAnalysisSurveyInfo', data.surveyInfo);
            commit('setAnthroPlot', { plot: data.plot, type: data.distribution });
          } catch (error) {
            commit('setAnthroPlot', { plot: undefined, type: undefined });
            commit('setPrevalence', undefined);
            commit('setTables', undefined);
            commit('setAnthroAnalysisSurveyInfo', undefined);
            commit('setAnthroAnalysisNotEnoughData', true);
          } finally {
            commit('setFetching', false);
          }
        }
      },
      async getMortalityAnalysis({ getters, commit }, { projectId, surveyId }) {
        commit('setFetching', true);
        commit('setMortalityAnalysisNotEnoughData', false);
        if (projectId && surveyId) {
          commit('setMortalityStats', undefined);
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/mortality-analysis` };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            commit('setMortalityStats', data);
          } catch (error) {
            commit('setMortalityStats', undefined);
            commit('setMortalityAnalysisNotEnoughData', true);
          } finally {
            commit('setFetching', false);
          }
        }
      },
      async markAnthroAnalysisRequiresChange({ commit }) {
        commit('setAnthroAnalysisChanged', true);
      },
      async markAnthroAnalysisChanged({ commit }) {
        commit('setAnthroAnalysisChanged', false);
      },
      async markMortalitySettingsChanged({ commit }) {
        commit('setMortalitySettingsChanged', false);
      },
      clearResults({ commit }) {
        commit('clearResults');
      }
    }
  };
}
