<!--
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <nav>
    <div class="flex flex-column align-center justify-center pl-24" style="height: 64px;">
      <div class="flex logo-container">
        <img height="55px" src="/logo.svg" alt="Smart Platform">
        <span>V {{ version }}</span>
      </div>
      <span style="margin: -5px 0px 10px; font-size: 11px; font-weight: 600;">{{ $t('components.titles.createdByJanaDaher') }}</span>
    </div>
    <div class="flex align-center txt-grey ml-8">
      <a-icon class="txt-silver mr-4" type="right" style="height: 18px; width: 14px;"/>
      <a-breadcrumb>
        <a-icon slot="separator" class="txt-silver" type="right" style="height: 18px; width: 14px;"/>
        <a-breadcrumb-item v-for="route in transformedRoutes" :key="route.path">
          <router-link class="txt-font-din-medium txt-bold" :to="route.redirect || route.path" :disabled="true">
            {{ route.meta && route.meta.translateKey ? $t(`components.labels.${route.meta.translateKey}`) : route.name }}
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <AdminProjectSelector v-if="project" :current-user="currentUser" :project="project"/>
    </div>
    <div class="flex align-center justify-end">
      <a-tag v-if="surveyStatus" :color="getColourForStatus(surveyStatus)" class="mr-20 txt-bold txt-font-din-medium">
        {{ translateSurveyStatus }}
      </a-tag>
      <a-progress
        :percent="surveyCompletePercent"
        :success-percent="surveyCompletePercent"
        :stroke-width="12"
        class="mr-70">
        <template #format="percent">
          <span class="txt-black">{{ $t('components.labels.percentageComplete', { percentage: percent }) }}</span>
        </template>
      </a-progress>
      <a-popover
        :visible="resetSurveyPopoverVisible"
        :disabled="!$store.state.survey.metadata || !$store.state.survey.dirty || isSupervisor"
        placement="bottom"
        trigger="click"
        @visibleChange="resetSurveyPopoverVisibleChange">
        <template slot="content">
          <div class="reset-survey-popover-container">
            <a-icon type="exclamation-circle" theme="filled" style="color: red; fontSize: 20px; margin-right: 15px;"/>
            <div>
              <p>{{ $t('components.description.areYouSureYouWantToResetSurvey') }}</p>
              <ul>
                <li>{{ $t('components.labels.metadata') }}</li>
                <li>{{ $t('components.labels.questionnaire') }}</li>
                <li>{{ $t('components.labels.protocol') }}</li>
                <li>{{ $t('components.labels.localEventCalendar') }}</li>
                <li>{{ $t('components.labels.enumerators') }}</li>
                <li>{{ $t('components.description.allDataReceivedEnteredOnResults') }}</li>
              </ul>
              <p><span class="txt-font-din-medium" style="font-weight: bold; font-size: large; margin-top: 5px; margin-bottom: 5px;">{{ $t('components.description.stepCannotBeDone') }}</span></p>
              <p>{{ $t('components.description.ifYouWishToContinuePleaseWriteDownName') }}</p>
              <p>{{ $t('components.labels.theSurveyNameIs') }} <span class="txt-font-din-medium" style="font-weight: bold; font-size: large;">{{ (surveyName) || '' }}</span></p>
              <a-input v-model="resetSurveyConfirmText" onpaste="return false;" size="large"/>
              <div class="flex flex-row justify-end mt-10">
                <a-button
                  class="flex align-center justify-center mr-10"
                  type="primary"
                  size="large"
                  @click="resetSurveyPopoverVisibleChange(false)">
                  {{ $t('values.cancel') }}
                </a-button>
                <a-button
                  class="flex align-center justify-center"
                  type="secondary"
                  size="large"
                  :disabled="!surveyName || surveyName !== resetSurveyConfirmText"
                  @click="reset">
                  {{ $t('components.description.resetSurvey') }}
                </a-button>
              </div>
            </div>
          </div>
        </template>
        <a-button
          class="reset-survey-button ml-10"
          :disabled="!$store.state.survey.metadata || !$store.state.survey.dirty">
          {{ $t('components.description.resetSurvey') }}
        </a-button>
      </a-popover>
    </div>
    <div v-show="currentUser" class="flex align-center">
      <!-- Bring back when we do notification in app notifications -->
      <!-- <div class="notification-badge pl-24 pr-24">
        <a-badge dot>
          <a-icon type="bell"/>
        </a-badge>
      </div> -->
      <a-avatar :size="40" class="ml-24" icon="user" :src="currentUser.avatarUrl"/>
      <a-dropdown>
        <a class="ant-dropdown-link ml-10 mr-16 smart-username" @click="e => e.preventDefault()">
          {{ displayName }} <embed class="ml-22 mr-40" width="12px" src="/chevron-down.svg">
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <router-link id="navbar-change-survey-action" to="/surveys">
              {{ $t('components.dropdown.yourSurveys') }}
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link id="navbar-edit-profile-action" to="/account/edit">
              {{ $t('components.dropdown.editProfile') }}
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <a id="navbar-log-out-action" href="#" @click.prevent="logOut">
              {{ $t('components.dropdown.logOut') }}
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div v-if="loading" class="navbar-loading-container">
      <a-progress
        :stroke-color="{
          from: '#ecc182',
          to: '#e98300',
        }"
        :show-info="false"
        :percent="99.9"
        status="active"/>
    </div>
  </nav>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import request from '../mixins/request';
import { apiPaths } from '../util/request';
import { surveyStandardizationCSVKey } from '../util/project';
import { noop } from '../util/util';
import { requestData } from '../store/modules/request';
import { version } from '../../package.json';

export default {
  name: 'Navbar',
  components: {
    AdminProjectSelector: () => import('./admin-project-selector.vue')
  },
  mixins: [request()],
  data: () => ({
    transformedRoutes: [],
    resetSurveyConfirmText: '',
    resetSurveyPopoverVisible: false,
    version
  }),
  computed: {
    ...mapGetters([
      'surveyCompletePercent'
    ]),
    ...mapState({
      pilot: state => state.survey.pilot,
      loading: state => state.survey.loading,
      project: state => state.survey.project,
      surveyName: state => state.survey.name,
      surveyId: state => state.survey.surveyId,
      surveyStatus: state => state.survey.status.name,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    ...requestData(['session', 'currentUser', 'forms']),
    displayName() {
      if (this.currentUser) {
        const { lastName, firstName } = this.currentUser._data;
        if (lastName === '' || firstName === '') {
          return this.$t('components.labels.admin');
        }

        return `${lastName}, ${firstName && firstName.substr(0, 1)}.`;
      }

      return this.$t('components.labels.unknownUser');
    },
    translateSurveyStatus() {
      const translatedStatus = {
        Reporting: this.$t('components.labels.reporting').toLocaleUpperCase(),
        'Survey Planning': this.$t('components.labels.surveyPlanning').toLocaleUpperCase(),
        'Pilot Survey': this.$t('components.labels.pilotSurvey').toLocaleUpperCase(),
        'Data Collection': this.$t('components.labels.dataCollection').toLocaleUpperCase(),
        'Survey Complete': this.$t('components.labels.surveyComplete').toLocaleUpperCase(),
        'Retrospective Data': this.$t('components.labels.retrospectiveData').toLocaleUpperCase()
      };
      return translatedStatus[this.surveyStatus];
    }
  },
  mounted() {
    this.transformedRoutes = (this.$router.currentRoute && this.$router.currentRoute.matched) || [];
    const parentRoutes = {
      Dashboard: 'dashboard',
      'Survey Planning': 'surveyPlanning',
      Results: 'results',
      Report: 'report',
      Account: 'account',
      SurveyList: 'surveyList'
    };
    this.transformedRoutes = this.transformedRoutes.map((route) => {
      if (route?.name && Object.keys(parentRoutes).some((r) => (r === route.name))) {
        // eslint-disable-next-line
        route.meta.translateKey = parentRoutes[route.name];
      }
      return route;
    });
    this.$router.afterEach((to) => {
      this.transformedRoutes = to.matched;
      this.transformedRoutes = this.transformedRoutes.map((route) => {
        if (route?.name && Object.keys(parentRoutes).some((r) => (r === route.name))) {
          // eslint-disable-next-line
          route.meta.translateKey = parentRoutes[route.name];
        }
        return route;
      });
    });
  },
  methods: {
    ...mapActions([
      'emptyStoredSurvey',
      'clearResults',
      'resetTargetSurvey'
    ]),
    logOut() {
      this.delete(apiPaths.session(this.session.token)).catch(noop);
      this.$store.commit('clearData');
      this.clearResults();
      this.emptyStoredSurvey();
      this.$router.push('/login', () => {
        this.$alert().success(this.$t('components.notifications.youHaveLoggedOutSuccessfully'));
      });
    },
    resetSurveyPopoverVisibleChange(visible) {
      this.resetSurveyPopoverVisible = visible;
      if (!visible) {
        this.resetSurveyConfirmText = '';
      }
    },
    async reset() {
      const projectId = this.project.id;
      try {
        await this.resetTargetSurvey({ projectId, surveyId: this.surveyId });
        this.clearResults();
        this.emptyStoredSurvey();
        await this.$store.dispatch('get', [{
          key: 'projects',
          url: '/projects',
          extended: true
        }]);
        window.localStorage.removeItem('surveyTypeWarning');
        window.localStorage.removeItem('surveyTypeWasSetByModal');
        const csvKey = surveyStandardizationCSVKey(this.surveyId);
        window.localStorage.removeItem(csvKey);
        this.$alert().success(this.$t('components.notifications.youHaveResetSurvey'));
        this.resetSurveyPopoverVisible = false;
        this.resetSurveyConfirmText = '';
        this.$router.push('/survey-planning');
      } catch (error) {
        this.$alert().danger(error.message);
      }
    },
    getColourForStatus(surveyStatus) {
      switch (surveyStatus) {
        case 'Reporting':
          return undefined;
        case 'Survey Planning':
          return 'purple';
        case 'Pilot Survey':
          return 'volcano';
        case 'Data Collection':
          return 'orange';
        case 'Survey Complete':
          return 'green';
        case 'Retrospective Data':
          return 'red';
        default:
          return 'black';
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";

.reset-survey-popover-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  max-width: 350px;
  font-style: normal;
  font-family: 'DINNextLTPro-Regular';
}
nav {
  position: relative;
  margin-bottom: 0;
  height: 64px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.11);
  background-color: white;
  display: grid;
  grid-template-columns: auto 2fr 1.5fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  z-index: 1002;

  .ant-breadcrumb {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
  }


  .ant-breadcrumb-link a {
    vertical-align: text-bottom;
  }

  .ant-breadcrumb-separator {
    margin: 0 4px;
  }

  .smart-username {
    font-size: 15px;
  }

  .notification-badge {
    border-left: 1px solid #f4f4f5;
    border-right: 1px solid #f4f4f5;
    height: 100%;
    display: flex;
    align-items: center;

    .ant-badge {
      color: $color-link;
      font-size: 20px;
    }
  }

  .navbar-loading-container {
    width: 101%;
    height: 5px;
    position: absolute;
    left: -3px;
    right: 0px;
    top: -7px;
  }
  .logo-container {
    align-items: flex-start;
    justify-content: right;
  }
  .logo-container span {
    position: absolute;
    font-size: 0.7em;
    margin-top: 13px;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
