<template>
  <div id="supervisor-invitation-error" class="supervisor-invitation-error">
    <div class="logo-container">
      <img class="mb-10" height="55px" src="/logo.svg" alt="Smart Platform">
      <span>V {{ version }}</span>
    </div>
    <div class="body-container">
      <h1 class="title">{{ errorTitle }}</h1>
      <p class="regular-font">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import request from '@/mixins/request';
import { version } from '../../../../../package.json';

const POSSIBLE_ERROR_MESSAGES = ['invalidToken', 'invitationCancelled', 'invitationAlreadyAccepted', 'invitationExpired', 'unknownError'];

export default {
  name: 'SupervisorInvitationError',
  mixins: [request()],
  data: () => ({
    version
  }),
  computed: {
    errorMessage() {
      const { message } = this.$route.query;
      if (!POSSIBLE_ERROR_MESSAGES.includes(message)) {
        return this.$t('components.description.unknownErrorSupervisorInvitationError');
      }
      return this.$t(`components.description.${message}SupervisorInvitationError`);
    },
    errorTitle() {
      const { message } = this.$route.query;
      if (!POSSIBLE_ERROR_MESSAGES.includes(message)) {
        return this.$t('components.titles.unknownErrorSupervisorInvitationError');
      }
      return this.$t(`components.titles.${message}SupervisorInvitationError`);
    }
  }
};
</script>
<style lang="scss">
.supervisor-invitation-error {
  @media (max-width: 1024px) {
    padding-right: 0;
  }
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;

  .regular-font {
    font-family: 'DINNextLTPro-Regular';
    font-size: 16px;
  }

  .bold-font {
    font-family: 'DINNextLTPro-Bold';
    font-size: 16px;
  }

  .logo-container {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  .logo-container span {
    position: absolute;
    font-size: 0.7em;
    margin-top: 13px;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
  }

  .title {
    text-align: center;
    font-family: 'DINNextLTPro-Bold';
    font-size: 40px;
    margin-bottom: 32px;
  }

  .body-container {
    padding: 40px;
    border-top: 8px solid #f42828;
    background: #FFF;
    max-width: 650px;
  }
}
</style>
