import { configForPossibleBackendRequest } from '../../util/request';

export default function(Vue) {
  const defaultState = {
    showPlausibilityModal: false,
    loadingCalls: 0,
    savingCalls: 0,

    standardizationReport: undefined,
    temBias: [],

    lastTemBiasUpdated: new Date(),
    lastUpdated: new Date(),
    standardizationGroupMap: {},

    error: null,
    temBiasError: null
  };

  return {
    state: { ...defaultState },
    mutations: {
      setStandardizationGroups(state, items) {
        const standardizationGroupMap = {};
        items.forEach(group => {
          standardizationGroupMap[group.id] = group;
        });
        state.standardizationGroupMap = standardizationGroupMap;
        state.lastUpdated = new Date();
      },
      addStandardizationGroup(state, data) {
        state.standardizationGroupMap[data.id] = data;
        state.lastUpdated = new Date();
      },
      updateStandardizationGroupStatus(state, { groupId, data }) {
        const { standardizationStatus } = data;
        state.standardizationGroupMap[groupId].standardizationStatus = standardizationStatus;
        state.lastUpdated = new Date();
      },
      addEnumeratorToStandardizationGroup(state, { groupId, enumerator }) {
        state.standardizationGroupMap[groupId].enumerators.push(enumerator);
        state.lastUpdated = new Date();
      },
      updateEnumeratorForStandardizationGroup(state, { groupId, enumerator }) {
        const indexOfObject = state.standardizationGroupMap[groupId].enumerators
          .findIndex(item => item.id === enumerator.id);

        if (indexOfObject > -1) {
          const updatedValue = {
            ...state.standardizationGroupMap[groupId].enumerators[indexOfObject],
            ...enumerator
          };
          state.standardizationGroupMap[groupId].enumerators[indexOfObject] = updatedValue;
          state.lastUpdated = new Date();
        }
      },
      removeEnumeratorFromStandardizationGroup(state, { groupId, enumeratorId }) {
        const indexOfObject = state.standardizationGroupMap[groupId].enumerators
          .findIndex(item => item.id === enumeratorId);
        if (indexOfObject > -1) {
          state.standardizationGroupMap[groupId].enumerators.splice(indexOfObject, 1);
          state.lastUpdated = new Date();
          state.lastTemBiasUpdated = new Date();
        }
      },
      addTemBiasToGroup(state, { groupId, tembias }) {
        Vue.set(state.standardizationGroupMap[groupId], 'temBias', tembias);
        state.lastUpdated = new Date();
        state.lastTemBiasUpdated = new Date();
      },
      addTemBiasReportToGroup(state, { groupId, tembiasReport }) {
        state.standardizationGroupMap[groupId].tembiasReport = tembiasReport;
        state.lastUpdated = new Date();
        state.lastTemBiasUpdated = new Date();
      },
      removeStandardizationGroup(state, group) {
        delete state.standardizationGroupMap[group.id];
        state.lastUpdated = new Date();
        state.lastTemBiasUpdated = new Date();
      },
      setTemBiasForWholeSurvey(state, temBias) {
        state.temBias = temBias;
        state.lastTemBiasUpdated = new Date();
      },
      startLoading(state) {
        state.loadingCalls += 1;
      },
      stopLoading(state) {
        state.loadingCalls -= 1;
      },
      setStdSaving(state) {
        state.savingCalls += 1;
      },
      stopSaving(state) {
        state.savingCalls -= 1;
      },
      setError(state, err) {
        state.error = err;
      },
      setTemBiasError(state, err) {
        state.temBiasError = err;
      }
    },
    getters: {
      enumeratorsForGroupId: (state) => (groupId) =>
        ((groupId in state.standardizationGroupMap)
          ? state.standardizationGroupMap[groupId].enumerators
          : []
        ),
      allEnumerators: (state) => {
        const res = [];
        for (const [_, value] of Object.entries(state.standardizationGroupMap)) {
          res.push(...value.enumerators);
        }
        return res;
      },
      hasSupervisor: (state) => {
        const res = [];
        for (const [_, value] of Object.entries(state.standardizationGroupMap)) {
          const { enumerators } = value;
          for (const enumerator of enumerators) {
            if (enumerator.supervisor) {
              return true;
            }
          }
        }
        return false;
      },
      tembiasForGroupId: (state) => (groupId) =>
        (('temBias' in state.standardizationGroupMap)
          ? state.standardizationGroupMap[groupId].temBias
          : null
        )
    },
    actions: {
      async createStandardizationGroupsForSurvey({ getters, commit }, { surveyId, data }) {
        if (surveyId) {
          const baseConfig = {
            method: 'POST',
            url: `/surveys/${surveyId}/standardization-groups`,
            data
          };
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
          try {
            const { data: responseData } = await Vue.prototype.$http.request(axiosConfig);
            const { data: groupData } = responseData;

            commit('addStandardizationGroup', groupData);
            return groupData;
          } catch (error) {
            commit('setError', error);
          }
        }
        return null;
      },
      async getStandardizationGroupsForSurvey({ getters, commit }, { surveyId }) {
        commit('startLoading');
        if (surveyId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'GET',
            url: `/surveys/${surveyId}/standardization-groups`,
            params: {
              requestFullData: true
            }
          };
          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);
            const { items } = data;

            commit('setStandardizationGroups', items);
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopLoading');
          }
        }
      },
      async deleteTargetStandardizationGroupForSurvey({ getters, commit }, { surveyId, groupId }) {
        commit('setStdSaving');
        if (surveyId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'DELETE',
            url: `/surveys/${surveyId}/standardization-groups/${groupId}`
          };
          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

          try {
            const { data: responseData } = await Vue.prototype.$http.request(apiConfig);

            commit('removeStandardizationGroup', responseData);
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopSaving');
          }
        }
      },
      async startStandardizationGroup({ getters, commit }, { surveyId, groupId }) {
        commit('setStdSaving');
        if (surveyId && groupId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'PATCH',
            url: `/surveys/${surveyId}/standardization-groups/${groupId}`,
            data: {
              action: 'start'
            }
          };
          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);

            commit('updateStandardizationGroupStatus', { groupId, data });
            return data;
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopSaving');
          }
        }
        return null;
      },
      async endStandardizationGroupTest({ getters, commit }, { surveyId, groupId }) {
        commit('setStdSaving');
        if (surveyId && groupId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'PATCH',
            url: `/surveys/${surveyId}/standardization-groups/${groupId}`,
            data: {
              action: 'complete'
            }
          };
          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);

          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);

            commit('updateStandardizationGroupStatus', { groupId, data });
            return data;
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopSaving');
          }
        }
        return null;
      },
      async updateEnumeratorRanksForSurvey({ getters, commit }, { surveyId, data }) {
        commit('setStdSaving');
        if (surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'PATCH',
            url: `/surveys/${surveyId}/enumerator-assignment`,
            headers: {
              'Content-Type': 'application/json'
            },
            data
          };

          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            commit('stopSaving');
            const { data: responseData } = await Vue.prototype.$http.request(apiConfig);
            return responseData;
          } catch (error) {
            commit('stopSaving');
            commit('setError', error);
          }
        }
        return null;
      },
      async updateEnumeratorInGroup({ getters, commit }, { groupId, enumeratorId, data }) {
        commit('setStdSaving');
        if (enumeratorId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'PATCH',
            url: `/enumerators/${enumeratorId}`,
            headers: {
              'Content-Type': 'application/json'
            },
            query: {
              type: 'detail-update'
            },
            data
          };

          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            const { data: enumerator } = await Vue.prototype.$http.request(apiConfig);
            commit('updateEnumeratorForStandardizationGroup', { groupId, enumerator });
            commit('stopSaving');
            return enumerator;
          } catch (error) {
            commit('stopSaving');
            commit('setError', error);
          }
        }
        return null;
      },
      async addEnumeratorToGroup({ getters, commit }, { groupId, data }) {
        commit('setStdSaving');
        if (groupId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'POST',
            url: `/standardization-groups/${groupId}/enumerators`,
            headers: {
              'Content-Type': 'application/json'
            },
            data
          };

          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            const { data: enumerator } = await Vue.prototype.$http.request(apiConfig);

            commit('addEnumeratorToStandardizationGroup', { groupId, enumerator });
            return enumerator;
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopSaving');
          }
        }
        return null;
      },
      async removeEnumeratorFromGroup({ getters, commit }, { groupId, enumeratorId }) {
        commit('setStdSaving');
        if (groupId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'DELETE',
            url: `/standardization-groups/${groupId}/enumerators/${enumeratorId}`,
            headers: {
              'Content-Type': 'application/json'
            }
          };
          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);
            commit('removeEnumeratorFromStandardizationGroup', { groupId, enumeratorId });
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopSaving');
          }
        }
      },
      async getTemBiasForGroup({ getters, commit }, { groupId }) {
        commit('startLoading');
        if (groupId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'GET',
            url: `/standardization-groups/${groupId}/tembias`,
            params: {
              requestReport: false
            }
          };

          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);
            const { tembias } = data;
            commit('addTemBiasToGroup', { groupId, tembias });
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopLoading');
          }
        }
      },
      async getTemBiasReportForGroup({ getters, commit }, { groupId, lang }) {
        commit('startLoading');
        if (groupId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'GET',
            url: `/standardization-groups/${groupId}/tembias`,
            params: {
              lang,
              requestReport: true
            }
          };

          const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
          try {
            const { data } = await Vue.prototype.$http.request(apiConfig);
            const { report } = data;
            commit('addTemBiasReportToGroup', { groupId, data: report });
            return report;
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('stopLoading');
          }
        }
        return null;
      },
      async getTemBiasForWholeSurvey({ getters, commit }, { projectId, surveyId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}/standardization/tembias` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setTemBiasForWholeSurvey', response.data?.tembias);
          } catch (error) {
            commit('setTemBiasError', error);
          }
        }
      }
    }
  };
}
