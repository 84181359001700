import Prompt from '../prompts/prompt';

export const resultValidationprompts = [
  [
    // TODO: Remove use of this Prompt component if possible - it is not setup well.
    new Prompt({
      key: 'validated',
      // title is nowhere referenced
      title: 'validate_results_title',
      prompt: 'result_validated_request_prompt',
      type: Array,
      options: [
        'Validated',
        'Validated with Caution',
        'Not Validated'
      ],
      defaultValue: 'Validated',
      visibleConditions: {
      }
    }),
    new Prompt({
      key: 'not_validated_reason',
      title: 'Validate Results',
      prompt: 'result_not_validated_explanation_prompt',
      type: String,
      defaultValue: '',
      visibleConditions: {
        validated: 'Not Validated'
      }
    })
  ],
  new Prompt({
    key: 'shareRawData',
    title: 'Please consider sharing raw data',
    prompt: 'raw_data_prompt',
    type: Boolean,
    defaultValue: false,
    visibleConditions: {
      validated: true,
      shareRawData: false
    }
  })
];

export const reportValidationprompts = [
  [
    new Prompt({
      key: 'validated',
      title: 'Validate Survey Report',
      prompt: 'report_validated_request_prompt',
      type: Array,
      promptConfirmText: 'Validate',
      options: [
        'Validated',
        'Validated with Comments',
        'Not Validated'
      ],
      defaultValue: 'Validated',
      visibleConditions: {
      }
    })
  ],
  new Prompt({
    key: 'shareRawData',
    title: 'Please consider sharing raw data',
    prompt: 'raw_data_prompt',
    type: Boolean,
    defaultValue: false,
    visibleConditions: {
      validated: true,
      shareRawData: false
    }
  })
];
