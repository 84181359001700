<template>
  <div>
    <h4 class="mt-24 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.avatar') }}</h4>
    <a-card :bordered="true" class="avatar-panel">
      <a-avatar :size="96" class="ml-32 mb-24" icon="user" :src="currentUser.avatarUrl"/>
      <a-upload
          name="file"
          :show-upload-list="false"
          accept=".jpg,.jpeg,.png"
          :custom-request="({file}) => uploadAvatarImage(file)">
        <a-button
            :disabled="saving"
            type="primary"
            size="large"
            class="mt-10 mb-12 w170 flex align-center justify-center">
          {{ $t('components.description.changeAvatar') }}
        </a-button>
      </a-upload>
    </a-card>
  </div>
</template>

<script>

import Vue from 'vue';
import request from '../../../../mixins/request';
import { apiPaths, configForPossibleBackendRequest } from '../../../../util/request';
import { requestData } from '../../../../store/modules/request';

export default {
  name: 'UserEditAvatar',
  components: { },
  mixins: [request()],
  data() {
    return {
      saving: false
    };
  },
  computed: requestData(['currentUser', 'user']),
  methods: {
    async uploadAvatarToStorage(file) {
      const formData = new FormData();

      formData.append('file', file);

      const baseConfig = {
        params: { saveExtension: true },
        data: formData,
        method: 'POST',
        url: process.env.VUE_APP_AVATAR_UPLOAD_URL
      };
      const axiosConfig = configForPossibleBackendRequest(baseConfig);

      try {
        return await Vue.prototype.$http.request(axiosConfig);
      } catch (error) {
        this.error = error.response.data.message;
        this.$message.error({ content: error.response.data.message, duration: 5 });
      }
      return { data: null };
    },
    async uploadAvatarImage(file) {
      this.saving = true;
      const messageKey = 'avatar';
      this.$message.loading({
        content: this.$t('components.notifications.uploadingAvatarImage'),
        key: messageKey,
        duration: 20
      });

      const { data: { url } } = await this.uploadAvatarToStorage(file);

      const user = { ...this.currentUser };
      user.avatarUrl = url;

      const response = await this.request({
        method: 'PATCH',
        url: apiPaths.user(this.user.id),
        data: user
      });

      await this.fetchUser();

      this.$message.success({
        content: this.$t('components.notifications.avatarImageUpdated'),
        key: messageKey,
        duration: 20
      });

      this.saving = false;
    },
    fetchUser() {
      return this.$store.dispatch('get', [{
        key: 'user',
        url: apiPaths.user(this.currentUser.id),
        success: ({ currentUser }) => {
          this.$store.commit('setData', {
            key: 'currentUser',
            value: currentUser.with(this.user.object)
          });
        }
      }]);
    }
  }
};
</script>

<style lang="scss">
.avatar-panel {
  width: 235px;
}
</style>
