/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/*
Copyright 2017 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
*/

import converter from 'number-to-words';
import { isFinite, toNumber } from 'lodash';
import { userProjectKey, userSurveyKey, language } from './project';

export const noop = () => {};

export const uniqueSequence = () => {
  let id = 0;
  return () => {
    id += 1;
    return id;
  };
};

export const STATIC_COLOR_LIST = [
  '#FF4B58',
  '#61D6A7',
  '#FF58E4',
  '#6D6A1B',
  '#74BEF5',
  '#D74EA8',
  '#4F3AD4',
  '#CFD226',
  '#C09BF4',
  '#1A136C',
  '#FFB638',
  '#189994',
  '#C66C02',
  '#1377FF',
  '#772255',
  '#508F0A',
  '#9D9D9D',
  '#F57B01',
  '#B79D65',
  '#9BC325'
];

export const getRandomColor = (n, opacity = 0.4) => `${STATIC_COLOR_LIST[(n - 1) % STATIC_COLOR_LIST.length]}${(Math.round(opacity * 255)).toString(16)}`;

export const parsePropertyToInt = (property) => {
  try {
    return parseInt(property, 0);
  } catch (err) {
    return 0;
  }
};

export const padHandsontableData = (data, minimumRowCount, rowColumnCount) => {
  const rowCountToAdd = minimumRowCount - data.length;
  const rowsToAdd = new Array(rowCountToAdd).fill(new Array(rowColumnCount).fill(undefined));
  return [...data, ...rowsToAdd];
};

export const convertStringToFloat = (value) => {
  try {
    if (value != null) {
      if (value !== '') {
        return parseFloat(value);
      }
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const convertStringToInt = (value) => {
  try {
    if (value) {
      if (value !== '') {
        return parseInt(value, 0);
      }
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const isEmptyOrNull = (value) => value === '' || value === null || value === undefined;

export const buildArrayOfValues = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i += 1) {
    arr.push(i);
  }
  return arr;
};

export const getUserProject = (userId) => convertStringToInt(localStorage.getItem(userProjectKey(userId)));
export const setUserProject = (userId, projectId) => localStorage.setItem(userProjectKey(userId), projectId);
export const getUserSurvey = (userId) => {
  const session = convertStringToInt(sessionStorage.getItem(userSurveyKey(userId)));
  if (!session) {
    const local = convertStringToInt(localStorage.getItem(userSurveyKey(userId)));
    // first time a tab is opened, copy local Storage userSurveyKey into session Storage
    if (local) {
      sessionStorage.setItem(userSurveyKey(userId), local);
    }
    return local;
  }
  return session;
};
export const setUserSurvey = (userId, surveyId) => {
  sessionStorage.setItem(userSurveyKey(userId), surveyId);
  localStorage.setItem(userSurveyKey(userId), surveyId);
};

export const setLocale = (locale) => {
  localStorage.setItem(language, locale);
};

export const getLocale = () => {
  const locale = localStorage.getItem(language);
  if (!locale) {
    setLocale('en');
    return 'en';
  }
  return locale;
};

export const convertStringToNumeric = (value) => {
  if (typeof value === 'string') {
    if (isFinite(toNumber(value))) {
      return toNumber(value);
    }
  }
  return value;
};
