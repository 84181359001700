<template>
  <div class="alert-container">
    <a-alert
      v-for="(item, i) in activeAlerts" :key="i"
      class="sticky-alert"
      :description="item.message"
      type="info"
      closable
      :after-close="item.onClose"
      show-icon/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import enumerators from '../../mixins/enumerators';

export default {
  name: 'MetadatAlert',
  mixins: [enumerators()],
  data() {
    return {
      activeAlerts: []
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata
    }),
    gpsMessage() {
      return this.$t('components.notifications.gpsSelectionChanged');
    }
  },
  watch: {
    metadata: {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue || this.enumerators.length === 0) {
          return;
        }

        if (newValue.allowGPS !== oldValue.allowGPS && this.getAlertIndex('allowGps') === -1) {
          this.activeAlerts.unshift(
            {
              id: 'allowGps',
              message: this.gpsMessage,
              onClose: this.closeGps
            }
          );
        }
      }
    }
  },
  methods: {
    getAlertIndex(id) {
      const index = this.activeAlerts.findIndex(item => item.id === id);
      return index;
    },
    closeGps() {
      const index = this.getAlertIndex('allowGps');
      if (index > -1) {
        this.activeAlerts.splice(index);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.sticky-alert {
  margin-top: 10px;
  align-content: center;
  min-width: 70%;
  width: auto;
  max-width: 100%;
  margin-right: 24pt;
}

.alert-container {
  position: fixed;
  z-index: 9999;
  width: auto;
}
</style>
