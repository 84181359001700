<template>
  <div>
    <!-- TODO: Fix DashboardFilters to intake possible clusters/team when applicable -->
    <DashboardFilters v-model="filters"/>
    <h1 class="mt-40 mb-20 txt-40 txt-bold txt-font-din-medium word-break-all overflow-wrap-break">
      {{ $t('components.titles.trackingSurveyName', { surveyName }) }}
    </h1>
    <a-card :bordered="true" class="mb-20">
      <div class="txt-20 txt-bold txt-black txt-font-din-medium mb-10">
        {{ $t('components.titles.plausibilityReports') }}
        <a-tooltip
          :title="$t('components.toolTips.downloadYourPlausibilityReportsHere')"
          class="ml-8">
          <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
      </div>
      <div class="flex align-center">
        <a-button
          type="link"
          class="txt-16 txt-black txt-font-din-medium mr-10 flex align-center pl-0 pr-0"
          target="blank"
          @click="onPlausibilityRequest(true)">
          <embed width="20px" src="/icon-report.svg" class="mr-10">
          {{ $t('components.description.requestMortalityPlausibilityReport') }}
        </a-button>
        <PlausibilityReportLoadingModal
          :show="showMortalityModal"
          :is-fetching="mortalityPlausibilityIsFetching"
          :on-exit="closePlausibilityModal"
          :file-blob="mortalityPlausibilityBlob"
          file-name="mortality_plausibility.docx"
          :request-time="plausibilityRequestTime"/>

        <a-button
          type="link"
          class="txt-16 txt-black txt-font-din-medium mr-10 flex align-center pl-0 pr-0"
          target="blank"
          @click="onPlausibilityRequest(false)">
          <embed width="20px" src="/icon-report.svg" class="mr-10">
          {{ $t('components.description.requestAnthropometryPlausibilityReport') }}
        </a-button>
        <PlausibilityReportLoadingModal
          :show="showAnthroModal"
          :is-fetching="anthroPlausibilityIsFetching"
          :on-exit="closePlausibilityModal"
          :file-blob="anthroPlausibilityBlob"
          file-name="anthropometry_plausibility.docx"
          :request-time="plausibilityRequestTime"/>
      </div>
    </a-card>
    <a-card :bordered="true" class="ant-card-no-padding mb-20">
      <div class="team-activity-container">
        <div class="pt-20 pl-20 pb-20 pr-20">
          <div class="txt-20 txt-bold txt-black txt-font-din-medium mb-10">
            {{ $t('components.titles.teamActivity') }}
            <a-tooltip
              :title="$t('components.toolTips.useClusterAssignmentBelowToAssignEachTeamToClusters')"
              class="ml-8">
              <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
            </a-tooltip>
          </div>
          <TeamClusters v-if="clusterAssignments" :cluster-assignments="clusterAssignments" :cluster-team-locations="clusterTeamLocations" @refetch="fetchData"/>
        </div>
        <div>
          <ClusterMap
            v-if="clusterAssignments || clusterTeamLocations"
            :cluster-team-locations="clusterTeamLocations"
            :clusters="clusters"
            :cluster-assignments="clusterAssignments"
            :initial-lat="metadata.latitude"
            :initial-lng="metadata.longitude"/>
        </div>
      </div>
    </a-card>
    <div class="two-cards">
      <DataQualityAnalysis :entries="dataQualitySnapshotResultData" :is-loading="dashboardDataQualityIsLoading"/>
      <a-card :bordered="true">
        <div class="txt-20 txt-bold txt-black txt-font-din-medium mb-10">
          {{ $t('components.titles.averageTimePerHH') }}
          <a-tooltip
            :title="$t('components.toolTips.onceQuestionnairesAreReceivedFromSmartCollect')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </div>
        <div class="w100 h100">
          <div v-if="dashboardAnalysisDataIsLoading" class="loading-container">
            <a-spin/>
          </div>
          <a-empty
            v-else-if="averageTimePerHouseholdData.length === 0"
            class="mt-70"
            :description="$t('components.description.noDataWithinFilters')"/>
          <AverageHousehold
            v-else
            :entries="averageTimePerHouseholdData"/>
        </div>
      </a-card>
    </div>
    <a-card :bordered="true" class="mt-20 mb-20">
      <div class="txt-20 txt-bold txt-black txt-font-din-medium mb-10">
        {{ $t('components.titles.sampleSizeTracking') }}
      </div>
      <div class="sample-size-tracking-container">
          <div class="flex align-center">
            <div class="mr-8">
              <embed width="60px" src="/icon-ruler.svg">
            </div>
            <div>
              <a-spin v-if="dashboardAnalysisDataIsLoading"/>
              <div v-else class="txt-40 txt-bold txt-black txt-font-din-medium lh-1">
                {{ childrenMeasuredData != null ? childrenMeasuredData.toFixed(2) : '' }}%
              </div>
              <div>{{ $t('components.description.childrenMeasured') }}</div>
            </div>
          </div>
          <div class="flex align-center">
            <div class="mr-8">
              <embed width="60px" src="/icon-nodes.svg">
            </div>
            <div>
              <a-spin v-if="dashboardAnalysisDataIsLoading"/>
              <div v-else class="txt-40 txt-bold txt-black txt-font-din-medium lh-1">
                {{ clusterCompletedData != null ? clusterCompletedData.toFixed(2) : '' }}%
              </div>
              <div>{{ $t('components.description.clustersReached') }}</div>
            </div>
          </div>
          <div class="txt-grey txt-16 flex justify-center align-center">
            <a-icon type="warning" class="mr-8 txt-20 warning-icon"/>
            <div class="w450">
              {{ $t('components.description.allReserveClustersShouldBeSurveyed') }}
            </div>
          </div>
      </div>
    </a-card>
    <h3 class="txt-20 txt-black txt-bold txt-font-din-medium mb-20">{{ $t('components.titles.householdAnalysis') }}</h3>
    <HouseholdAnalysis class="mb-40" :is-loading="dashboardAnalysisDataIsLoading" :household-entries="householdAnalysisData"/>
    <a-popconfirm
      v-if="pilot"
      :title="$t('components.notifications.areYouSureYouWantToDeleteAllPilotData')"
      :ok-text="$t('components.description.yesSure')"
      :cancel-text="$t('values.no')"
      :disabled="isSupervisor"
      @confirm="deletePilot">
      <a-button
        class="mt-10 w170 mb-20 min-width-fit-content"
        type="danger"
        size="large"
        :disabled="isSupervisor">
        {{ $t('components.description.deletePilot') }}
      </a-button>
    </a-popconfirm>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { flatten, isNil } from 'lodash';
import TeamClusters from './team-clusters.vue';
import ClusterMap from './cluster-map.vue';
import DashboardFilters from './dashboard-filters.vue';

const isEmptyChange = (oldValue, newValue) => ((isNil(oldValue) || oldValue === '') && (isNil(newValue) || newValue === ''));

export default {
  name: 'ProjectDashboardTracking',
  components: {
    TeamClusters,
    ClusterMap,
    DashboardFilters,
    DataQualityAnalysis: () => import('./data-quality'),
    AverageHousehold: () => import('./average-household'),
    HouseholdAnalysis: () => import('./household-analysis'),
    PlausibilityReportLoadingModal: () => import('../../results/PlausibilityModal/plausibility-report-loading-modal.vue')
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    onPilotComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      filters: undefined,

      showMortalityModal: false,
      showAnthroModal: false,
      plausibilityRequestTime: 0,
      plausibilityRequestTimeIntervalId: null,

      name: '',
      dataQuality: {}
    };
  },
  computed: {
    ...mapState({
      surveyName: state => state.survey.name,
      submissions: state => state.results.submissions,
      metadata: state => state.survey.metadata,
      pilot: state => state.survey.pilot,
      clusterAssignments: state => state.survey.clusterAssignments,
      clusters: state => state.survey.clusters.filter(cluster => cluster.name),
      projectId: state => state.survey.project.id,
      surveyId: state => state.survey.surveyId,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup',

      dashboardAnalysisDataIsLoading: state => state.dashboardAnalysis.dashboardAnalysisDataIsLoading,
      dashboardDataQualityIsLoading: state => state.dashboardAnalysis.dashboardDataQualityIsLoading,
      averageTimePerHouseholdData: state => state.dashboardAnalysis.averageTimePerHousehold,
      householdAnalysisData: state => state.dashboardAnalysis.householdAnalysisData,
      childrenMeasuredData: state => state.dashboardAnalysis.childrenMeasured,
      clusterCompletedData: state => state.dashboardAnalysis.clusterCompleted,
      dataQualitySnapshotResultData: state => state.dashboardAnalysis.dataQualitySnapshotResultData,
      dashboardFromDate: state => state.plausibility.dashboardFromDate,
      dashboardToDate: state => state.plausibility.dashboardToDate,
      dashboardTeams: state => state.plausibility.dashboardTeams,
      dashboardClusters: state => state.plausibility.dashboardClusters
    }),
    ...mapGetters([
      'anthroPlausibilityBlob',
      'anthroPlausibilityIsFetching',
      'mortalityPlausibilityBlob',
      'mortalityPlausibilityIsFetching'
    ]),
    clusterTeamLocations() {
      return flatten((this.householdAnalysisData && this.householdAnalysisData.map((item) => item.locations.map((location) => ({ ...location, cluster: item.cluster })))) || []);
    }
  },
  watch: {
    dashboardToDate(oldValue, newValue) {
      if (!isEmptyChange(oldValue, newValue)) {
        this.fetchData();
      }
    },
    dashboardTeams(oldValue, newValue) {
      if (!isEmptyChange(oldValue, newValue)) {
        this.fetchData();
      }
    },
    dashboardClusters(oldValue, newValue) {
      if (!isEmptyChange(oldValue, newValue)) {
        this.fetchData();
      }
    },
    surveyId(oldValue, newValue) {
      if (!isEmptyChange(oldValue, newValue)) {
        this.fetchData();
      }
    }
  },
  mounted() {
  },
  created() {
    this.fetchStandardization();
  },
  methods: {
    ...mapActions([
      'getStandardization',
      'setPilot',
      'getSurveyAnthroPlausibility',
      'cancelSurveyAnthroPlausibilityRequest',
      'getSurveyMortalityPlausibility',
      'cancelSurveyMoralityPlausibilityRequest',
      'loadDashboardAnalysis',
      'loadDataQualityAnalysis'
    ]),
    async fetchStandardization() {
      if (this.projectId && this.surveyId) {
        await this.getStandardization({
          surveyId: this.surveyId,
          projectId: this.projectId
        });
        this.fetchData(true);
      }
    },
    fetchData(silentFetch) {
      if (this.projectId && this.surveyId) {
        this.loadDashboardAnalysis({
          surveyId: this.surveyId,
          projectId: this.projectId,
          fromDate: this.dashboardFromDate,
          toDate: this.dashboardToDate,
          teams: this.dashboardTeams,
          cluster: this.dashboardClusters,
          silentFetch
        });
        this.loadDataQualityAnalysis({
          surveyId: this.surveyId,
          projectId: this.projectId,
          fromDate: this.dashboardFromDate,
          toDate: this.dashboardToDate,
          teams: this.dashboardTeams,
          cluster: this.dashboardClusters,
          silentFetch
        });
      }
    },
    async onPlausibilityRequest(isMortality) {
      this.plausibilityRequestTime = 0;
      this.plausibilityRequestTimeIntervalId = setInterval(() => {
        this.plausibilityRequestTime += 1;
      }, 1000);
      if (isMortality) {
        this.showMortalityModal = true;

        const successful = await this.getSurveyMortalityPlausibility({
          projectId: this.projectId,
          surveyId: this.surveyId,
          onOriginalData: true,
          lang: this.$i18n.locale
        });

        if (!successful) {
          this.$message.error({
            content: this.$t('components.notifications.errorMortalityPlausibility'),
            duration: 5
          });
          this.showMortalityModal = false;
        }
      } else {
        this.showAnthroModal = true;

        const successful = await this.getSurveyAnthroPlausibility({
          projectId: this.projectId,
          surveyId: this.surveyId,
          onOriginalData: true,
          lang: this.$i18n.locale
        });

        if (!successful) {
          this.$message.error({
            content: this.$t('components.notifications.errorAnthropometryPlausibility'),
            duration: 5
          });
          this.showAnthroModal = false;
        }
      }

      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
    },
    closePlausibilityModal() {
      this.plausibilityRequestTime = 0;
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }

      if (this.showMortalityModal) {
        this.showMortalityModal = false;
        this.cancelSurveyMoralityPlausibilityRequest();
      } else {
        this.showAnthroModal = false;
        this.cancelSurveyAnthroPlausibilityRequest();
      }
    },
    deletePilot() {
      this.$alert().success(this.$t('components.notifications.yourPilotHasBeenDeleted'));
      this.setPilot({ pilot: false });
      this.onPilotComplete();
    }
  }
};
</script>

<style lang="scss">
.loading-container {
  text-align: center;
  padding-top: 30px;
  min-height: 300px;
}
.team-activity-container {
  display: grid;
  min-height: 410px;
  grid-template-columns: 35% 65%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}

.two-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas:
    ". .";
}

.data-quality-table {
  td, th {
    padding: 10px;
  }

  tr {
    border-bottom: 1px solid #e6e6e7;
    &:last-child {
      border-bottom: none;
    }
  }
}

.sample-size-tracking-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 20% 20% 60%;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas:
    ". . .";

  .warning-icon {
    margin-top: 10px;
    align-self: flex-start;
  }
}
</style>
