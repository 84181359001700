import sumBy from 'lodash/sumBy';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { parsePropertyToInt } from './util';

export const CLUSTER_NOT_ACCESSIBLE_STATUS = 'Not Accessible';
export const CLUSTER_COMPLETE_STATUS = 'Complete';
export const CLUSTER_PARTICALLY_COMPLETE_STATUS = 'Partially Complete';

// eslint-disable-next-line import/prefer-default-export
export const getSubmissionData = (cluster, submissions, numberOfHouseholdsPerCluster) => {
  if (submissions) {
    const status = submissions.length === numberOfHouseholdsPerCluster
      ? CLUSTER_COMPLETE_STATUS : CLUSTER_PARTICALLY_COMPLETE_STATUS;
    return {
      ...cluster,
      status,
      consented: sumBy(submissions, submission => {
        const consent = get(submission, 'selected[0].consent_group[0].consent[0]', 0);
        return consent === '1' ? 1 : 0;
      }),
      refused: sumBy(submissions, submission => parsePropertyToInt(get(submission, 'hhrefused[0]', 0))),
      absent: sumBy(submissions, submission => parsePropertyToInt(get(submission, 'hhabsent[0]', 0))),
      childrenMeasured: sumBy(submissions, submission => parsePropertyToInt(get(submission, 'total_measured[0]', 0))),
      childrenEligible: sumBy(submissions, submission => parsePropertyToInt(get(submission, 'total_eligible[0]', 0)))
    };
  }
  return {
    ...cluster,
    status: CLUSTER_NOT_ACCESSIBLE_STATUS,
    consented: 0,
    refused: 0,
    absent: 0,
    childrenMeasured: 0,
    childrenEligible: 0
  };
};

// Checks for duplicate unitNames and sums it
export const filterClustersByDuplicates = clusters => {
  const grouped = sortBy(groupBy(clusters, 'unitName'), group => clusters.indexOf(group[0]));
  return Object.keys(grouped).map(key => {
    const item = uniqBy(grouped[key], 'population');
    const cumulativePop = sumBy(item, 'population');
    const row = item[0];
    const joined = grouped[key].map(cluster => cluster.name).filter(cluster => cluster).join(', ');
    const location = row.location === '' ? undefined : row.location;
    return [row.unitName, cumulativePop || 0, joined, location];
  });
};

// Convert the geoUnit data into a form usable by the table
export const geoUnitsToTableData = geoUnits => geoUnits.map(geoUnit =>
  [geoUnit.unitName, geoUnit.population || 0, geoUnit.clusters, geoUnit.location, geoUnit.id]);

export const getRCNumber = (cluster, clusters, prefix = ' ') => {
  if (cluster && clusters) {
    const RCClusters = clusters.filter((item) => item.unitName === cluster.unitName && item.name === cluster.name);
    if (RCClusters.length > 1) {
      const indexNumber = RCClusters.findIndex((item) => item.id === cluster.id);
      if (indexNumber > -1) {
        return `${prefix}${indexNumber + 1}`;
      }
    }
  }
  return '';
};
