<template>
  <a-modal
    width="420px"
    :dialog-style="{ display: 'none', top: '20%', left: '20%' }"
    :footer="null"
    :visible="visible"
    :closable="false"
    :keyboard="false"
    class="delete-supervisor-modal"
    @cancel="onExit">
    <div class="ml-4" style="display: flex; flex-direction: row; align-items: start;">
      <a-icon type="exclamation-circle" theme="filled" style="color: red; fontSize: 20px; margin-right: 15px;"/>
      <div style="display: flex; flex-direction: column;">
        <span style="white-space:pre-wrap">{{ $t('components.description.deleteSupervisor') }}</span>
        <div class="mt-24 buttons-container">
          <a-button
            class="w80"
            type="primary"
            size="large"
            @click="onExit">
            {{ $t('values.cancel') }}
          </a-button>
          <a-button
            class="w170 ml-16"
            type="primary"
            size="large"
            ghost
            @click="onComplete">
            {{ $t('values.remove') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

export default {
  name: 'DeleteSupervisorModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss">
.delete-supervisor-modal {
  .ant-modal-body {
    padding: 40px;
  }
  .buttons-container {
    display: flex;
    justify-content: end;
  }
}
</style>
