<template>
  <a-modal
    :closable="true"
    :visible="show"
    width="726px"
    footer=""
    @cancel="closeModalWithConfirm">
    <h1 data-cy="survey-report-upload-modal-title">{{ $t('components.titles.uploadSurveyReport') }}</h1>
    <a-row class="Tab" type="flex" justify="start">
      <a-col :class="['txt-18', (stage === UploadStage.CHOOSE || stage === UploadStage.UPLOAD) ? null : 'disabled-color']" :span="8">{{ $t('components.labels.uploadSurveyReport', { number: 1 }) }}</a-col>
      <a-col :class="['txt-18', (stage === UploadStage.SELECT_Z_SCORE) ? null : 'disabled-color']" :span="12">{{ $t('components.labels.selectzScoreExclusion', { number: 2 }) }}</a-col>
    </a-row>
    <hr>
    <div class="Container">
      <div v-if="stage === UploadStage.CHOOSE">
        <p class="txt-14">{{ $t('components.description.pleaseSelectAFileYouWantToUpload') }}</p>
        <div class="upload">
          <a-upload
            :file-list="fileList"
            accept=".doc, .docx,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            :before-upload="selectUploadfile">
            <img src="/upload-data.svg" width="80px">
            <p class="upload-container-text">
              {{ $t('components.description.uploadFileDoc') }}
            </p>
          </a-upload>
        </div>
      </div>
      <div v-if="stage === UploadStage.UPLOAD">
        <p class="txt-14">{{ $t('components.description.pleaseSelectAFileYouWantToUpload') }}</p>
        <div class="Rectangle">
          <p style="margin-right: auto;">{{ uploadFile.name }}</p>
          <p style="margin-right: 50px">{{ uploadFile.size }}</p>
          <a @click="resetStages"><img src="/trash-bin.svg"></a>
        </div>
      </div>
      <div v-if="stage === UploadStage.SELECT_Z_SCORE" style="height: 100%;">
        <div>
          <p class="txt-14"></p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="txt-14" v-html="$t('components.description.zScoreExclusionInstruction')"></p>
          <a-select v-model="selectedZScore" class="flag-picker" :placeholder="$t('components.labels.selectzScoreExclusionPlaceholder')"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
            <a-select-option value="smart">{{ $t('components.dropdown.smartFlags') }}</a-select-option>
            <a-select-option value="who">{{ $t('components.dropdown.whoFlags') }}</a-select-option>
          </a-select>

          <div class="confirm-checkbox">
            <a-checkbox v-model="confirmedSelection"
            :disabled="selectedZScore == null || isUploading">
              <span class="checkbox-span">{{ $t('components.description.iConfirmzScoreExclusion') }}</span>
            </a-checkbox>

            <a-spin v-if="isUploading" class="mt-20" size="large"/>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <a-button v-if="stage === UploadStage.CHOOSE" key="submit-process" style="margin-top: 15px" disabled class="TabButton" type="primary" @click="goToZScoreSelection">
        Continue
      </a-button>
      <a-button v-if="stage === UploadStage.UPLOAD" key="submit-process" class="TabButton" type="primary" @click="goToZScoreSelection">
        {{ $t('values.continue') }}
      </a-button>
      <a-button
          v-if="stage === UploadStage.SELECT_Z_SCORE"
          key="submit-process"
          :disabled="selectedZScore == undefined || isUploading || !confirmedSelection"
          class="TabButton"
          type="primary" @click="uploadSelectedFile">
        {{ $t('values.continue') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>

import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import project from '../../mixins/project';
import reportValidationPrompts from '../../smartplus/components/report/reportValidationPrompts';
import { isValidDocFile } from '../../util/files';

const key = 'uploading-survey-report';

export default {
  name: 'ReportUploadModal',
  mixins: [project()],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    onExit: {
      type: Function,
      required: true
    },
    onUploadComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      UploadStage: {
        CHOOSE: 'choose',
        UPLOAD: 'upload',
        SELECT_Z_SCORE: 'selectZScore'
      },
      stage: 'choose',
      selectedZScore: undefined,

      fileList: [],
      isUploading: false,
      confirmedSelection: false,
      uploadFile: {
        name: '',
        size: '',
        file: undefined
      }
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      projectId: state => state.survey.project.id
    })
  },
  methods: {
    ...mapActions([
      'uploadSurveyReportToStorage',
      'createSurveyReport',
      'setSurveyReports'
    ]),
    resetStages() {
      this.stage = this.UploadStage.CHOOSE;
      this.confirmedSelection = false;
      this.selectedZScore = undefined;
    },
    closeModalWithConfirm() {
      this.resetStages();
      this.onExit();
    },
    goToZScoreSelection() {
      this.stage = this.UploadStage.SELECT_Z_SCORE;
    },
    uploadSelectedFile() {
      this.uploadReportToStorage(this.uploadFile.file);
    },
    async uploadReportToStorage(file) {
      this.isUploading = true;
      const { data } = await this.uploadSurveyReportToStorage({ file });

      if (data && data.url !== undefined) {
        const addedSurveyReport = await this.createSurveyReport({
          url: data.url,
          size: file.size,
          filename: file.name,
          zScoreExclusion: this.selectedZScore,
          surveyId: this.surveyId,
          projectId: this.projectId
        });
        if (addedSurveyReport) {
          await this.setSurveyReports({ surveyReports: [addedSurveyReport] });
        }
        this.isUploading = false;
        this.resetStages();
        this.onUploadComplete();
      }
    },
    selectUploadfile(file) {
      this.stage = this.UploadStage.UPLOAD;
      this.uploadFile.file = file;
      this.uploadFile.name = file.name;
      const fileSize = file.size;
      const fileSizeKB = Math.round(fileSize * 0.01) / 10;
      const fileSizeMB = Math.round(fileSizeKB * 0.01) / 10;
      const fileSizeGB = Math.round(fileSizeMB * 0.01) / 10;
      if (fileSizeGB > 1)
        this.uploadFile.size = `${fileSizeGB}GB`;
      else if (fileSizeMB > 1)
        this.uploadFile.size = `${fileSizeMB}MB`;
      else
        this.uploadFile.size = `${fileSizeKB}KB`;
    }
  }
};
</script>


<style lang="scss">
.TabButton {
  width: 195px;
  height: 48px;
  padding: 9px 4px 9px 13px;
  border-radius: 1px;
  margin-left: auto;
}
.TabButton-disabled {
  width: 195px;
  height: 48px;
  padding: 9px 4px 9px 13px;
  border-radius: 1px;
  margin-left: auto;
  opacity: 0.5;
  pointer-events: none;
}
.disabled-color {
  color: #cccccc;
}
.enable-color {
  color: #000000;
}
.Tab {
  font-size: 17px;
  font-weight: bold;
}
.Container {
  height: 260px;
}
.Rectangle {
  height: 76px;
  margin: 20px 0 20px 1px;
  padding: 25px;
  font-size: 16px;
  background-color: #f4f4f5;
  display: flex;
}
.upload-container-text {
  width: 519px;
  height: 39px;
  margin: 20px 0 0;
  padding: 0 50px 9px 38px;
  opacity: 0.23;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: center;
  color: #363534;
}
.upload {
  width: 100%;
  margin: 20px 1px 25px 2px;
  padding: 47px 52px 47px 73px;
  border-radius: 3px;
  border: dashed 2px #bebebe;
  text-align: center;
  justify-content: flex-end;
}
.confirm-checkbox {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}
.checkbox-span {
  font-weight: normal;
}
</style>
