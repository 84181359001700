<template>
  <div class="pt-24" data-cy="Protocol">
    <a-spin :spinning="loading !== false" size="large">
      <div>
        <h4 class="mb-22 txt-40 txt-bold txt-font-din-medium">
          {{ $t('components.titles.protocol') }}
        </h4>
        <div class="flex justify-space-between">
        <div class="w50 txt-18">
          {{ $t('components.description.spaceToDownloadSurveyProtocol') }}
          </div>
          <a-card>
            <div class="flex flex-column">
              <div class="txt-16 mb-10">
                {{ $t('components.description.pleaseClickValidateProtocol') }}
              </div>
              <a-button
                class="w170 min-width-fit-content"
                type="primary"
                size="large"
                icon="check"
                :disabled="isProtocolValidated || protocols.length === 0 || isSupervisor"
                data-cy="validate-protocol-btn"
                @click="validate">
                {{ $t('components.description.validateProtocol') }}
              </a-button>
            </div>
          </a-card>
        </div>
        <div class="flex justify-space-between align-center mt-40">
          <div class="flex align-center">
            <a-button
              type="link"
              class="txt-18 txt-black txt-font-din-medium mr-20 flex align-center"
              :disabled="loadingIndicators || surveyLoading || !metadata"
              :loading="generatingProtocol"
              data-cy="protocol-download-button"
              @click="download">
              <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
              {{ $t('components.description.downloadProtocol') }}
            </a-button>
            <a-upload
              name="file"
              :show-upload-list="false"
              accept=".doc,
              .docx,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              :custom-request="({file}) => uploadReport(file)">
              <a-button
                :disabled="saving || isSupervisor"
                data-cy="protocol-upload-button"
                type="link"
                class="txt-18 txt-black txt-font-din-medium flex align-center">
                <embed v-if="isSupervisor" width="30px" src="/Upload.svg" class="mr-10">
                <embed v-else width="30px" src="/Upload-orange.svg" class="mr-10">
              {{ $t('components.description.uploadProtocol') }}
              </a-button>
            </a-upload>
          </div>
        </div>
        <a-card :bordered="true" class="ant-card-no-padding w100 mt-20 mb-20">
          <div class="protocol-container">
            <div class="protocol-container-rows txt-uppercase txt-bold txt-font-din-medium">
              <div>{{ $t('components.labels.name') }}</div>
              <div>{{ $t('components.labels.lastModified') }}</div>
              <div>{{ $t('components.labels.size') }}</div>
              <div></div>
            </div>
            <div
              v-for="(protocol, index) in protocols"
              :key="protocol.id"
              class="protocol-container-rows">
              <div class="flex align-center">
                {{ protocol.name }}
                <a-tag
                  v-if="index === 0 && protocol.validated && protocol.validated !== 'Not Validated'"
                  color="green" class="ml-8">
                  <a-icon type="check-circle" class="mr-4"/>
                  {{ $t(`components.labels.${protocol.validated.toLowerCase().replaceAll(' ', '_')}`) }}
                </a-tag>
                <a-tag v-if="protocol.validated === 'Not Validated'" color="red" class="ml-8">
                  <a-icon type="close-circle" class="mr-4"/>
                  {{ $t(`components.labels.${protocol.validated.toLowerCase().replaceAll(' ', '_')}`) }}
                </a-tag>
              </div>
              <div class="flex align-center">
                {{ dateToHumanReadable(protocol.createdAt) }}
              </div>
              <div>{{ protocol.size }}</div>
              <div>
                <a-button
                  type="link"
                  class="txt-18 txt-black txt-font-din-medium flex align-center"
                  :href="protocol.url">
                  <embed width="30px" src="/download-cloud-icon-grey.svg">
                </a-button>
              </div>
            </div>
            <div v-if="protocols.length === 0" class="protocol-empty-row">
              {{ $t('components.description.haveNotUploadedProtocol') }}
            </div>
          </div>
          <div v-if="protocols.length === 0"
            class="flex justify-center align-center flex-column mb-20 mt-40">
            <div class="txt-24 txt-black txt-font-din-medium mb-20">
              {{ $t('components.description.surveyProtocolIsReady') }}
            </div>
            <a-button
              class="mb-40"
              type="primary"
              size="large"
              icon="cloud-download"
              @click="download">
              {{ $t('components.description.downloadProtocol') }}
            </a-button>
          </div>
        </a-card>
      </div>
    </a-spin>
    <ValidationModal
      v-if="showValidationModal"
      :on-exit="onModalExit"
      :on-complete="onModalComplete"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import project from '../../../mixins/project';
import { generateProtocol } from '../../../util/documents';
import { configForPossibleBackendRequest } from '../../../util/request';
import dateToHumanReadable from '../dateToHumanReadable';

const key = 'uploading-protocol';
export default {
  name: 'Protocol',
  components: {
    ValidationModal: () => import('./validate-modal.vue')
  },
  mixins: [project()],
  data() {
    return {
      key: 'protocolLoading',
      saving: false,
      showValidationModal: false,
      generatingProtocol: false
    };
  },
  computed: {
    ...mapGetters(['surveyOrganization', 'isProtocolValidated']),
    ...mapState({
      metadata: state => state.survey.metadata,
      project: state => state.survey.project,
      survey: state => state.survey,
      surveyId: state => state.survey.surveyId,
      calendar: state => state.survey.calendar,
      protocols: state => state.survey.protocols,
      geoUnits: state => state.survey.geoUnits,
      surveyLoading: state => state.survey.loading,
      selectedIndicators: state => state.indicators.selectedIndicators,
      loadingIndicators: state => state.indicators.indicatorCallsBeingLoaded > 0,
      status: state => state.survey.status,
      loading: state => state.survey.loading,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    loadingIndicators(newValue, old) {
      if (newValue && newValue !== old) {
        this.$message.loading({ content: this.$t('components.notifications.loadingIndicators'), key: this.key });
      }

      if (!newValue && newValue !== old && !this.surveyLoading) {
        this.$message.success({ content: this.$t('components.notifications.loaded'), key: this.key, duration: 1 });
      }
    },
    surveyLoading(newValue, old) {
      if (newValue && newValue !== old) {
        this.$message.loading({ content: this.$t('components.notifications.loadingSurvey'), key: this.key });
      }
    },
    surveyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSelectedIndicators({
          surveyId: newVal
        });
        this.checkFieldsFilled();
      }
    },
    metadata: {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          this.getProtocols();
        }
      }
    }
  },
  created() {
    if (this.surveyId) {
      this.loadSelectedIndicators({
        surveyId: this.surveyId
      });
      this.checkFieldsFilled();
    }
  },
  methods: {
    ...mapActions(['getProtocols', 'loadSelectedIndicators']),
    dateToHumanReadable,
    translateSurveyType(surveyType) {
      let translate = this.$t('components.dropdown.other');
      if (surveyType.match(/rapid/i)) {
        translate = this.$t('components.dropdown.rapidSmart');
      } else if (surveyType.match(/full/i)) {
        translate = this.$t('components.dropdown.fullSmart');
      } else if (surveyType.match(/nns/i)) {
        translate = this.$t('components.dropdown.nns');
      } else if (surveyType.match(/sens/i)) {
        translate = this.$t('components.dropdown.sens');
      }
      return translate;
    },
    translateStageSampling(stageSampling) {
      let translate = this.$t('components.dropdown.simpleRandom');
      if (stageSampling.match(/simple/i)) {
        translate = this.$t('components.dropdown.simpleRandom');
      } else if (stageSampling.match(/systematic/i)) {
        translate = this.$t('components.dropdown.systematicRandom');
      }
      return translate;
    },
    translateSeasonality(seasonality) {
      let translate = this.$t('components.dropdown.notApplicable');
      if (seasonality.match(/lean/i)) {
        translate = this.$t('components.dropdown.leanSeason');
      } else if (seasonality.match(/harvest/i)) {
        translate = this.$t('components.dropdown.harvestSeason');
      }
      return translate;
    },
    translatePopulationType(populationType) {
      let translate = this.$t('components.dropdown.generalPopulation');
      if (populationType.match(/host/i)) {
        translate = this.$t('components.dropdown.hostCommunity');
      } else if (populationType.match(/refugee in community/i)) {
        translate = this.$t('components.dropdown.refugeeInCommunity');
      } else if (populationType.match(/refugee in settlements/i)) {
        translate = this.$t('components.dropdown.refugeeInSettlements');
      } else if (populationType.match(/idps in community/i)) {
        translate = this.$t('components.dropdown.idpInCommunity');
      } else if (populationType.match(/idps in settlements/i)) {
        translate = this.$t('components.dropdown.idpInSettlements');
      } else if (populationType.match(/host community & refugees/i)) {
        translate = this.$t('components.dropdown.hostCommunityRefugees');
      } else if (populationType.match(/host community & IDPs/i)) {
        translate = this.$t('components.dropdown.hostCommunityIdp');
      }
      return translate;
    },
    translateSurveySetting(surveySetting) {
      let translate = this.$t('components.dropdown.other');
      if (surveySetting.match(/rural/i)) {
        translate = this.$t('components.dropdown.rural');
      } else if (surveySetting.match(/urban/i)) {
        translate = this.$t('components.dropdown.urban');
      }
      return translate;
    },
    translateSurveyEquipment(surveyEquipment) {
      let translate = this.$t('components.dropdown.manualMeasurementTools');
      if (surveyEquipment.match(/manual/i)) {
        translate = this.$t('components.dropdown.manualMeasurementTools');
      } else if (surveyEquipment.match(/mobile/i)) {
        translate = this.$t('components.dropdown.mobileAppFor3DMeasurements');
      }
      return translate;
    },
    async download() {
      this.generatingProtocol = true;
      const metadata = {
        ...this.metadata,
        secondStageSampling: this.translateStageSampling(this.metadata.secondStageSampling ? this.metadata.secondStageSampling : ''),
        surveyType: this.translateSurveyType(this.metadata.surveyType ? this.metadata.surveyType : ''),
        seasonality: this.translateSeasonality(this.metadata.seasonality ? this.metadata.seasonality : ''),
        populationType: this.translatePopulationType(this.metadata.populationType ? this.metadata.populationType : ''),
        surveySetting: this.translateSurveySetting(this.metadata.surveySetting ? this.metadata.surveySetting : ''),
        surveyEquipment: this.translateSurveyEquipment(this.metadata.surveyEquipment ? this.metadata.surveyEquipment : '')
      };
      try {
        await generateProtocol(
          metadata,
          this.survey,
          this.calendar,
          this.surveyOrganization,
          this.geoUnits,
          this.selectedIndicators
        );

        this.generatingProtocol = false;
      } catch (error) {
        this.generatingProtocol = false;
        this.error = error.message;
        this.$message.error({ content: error.message, key, duration: 5 });
      }
    },
    async uploadReport(file) {
      this.saving = true;
      this.$message.loading({ content: this.$t('components.notifications.uploadingProtocol'), key, duration: 20 });
      const formData = new FormData();
      formData.append('file', file);

      const baseConfig = {
        params: {
          saveExtension: true,
          friendlyName: file.name
        },
        method: 'POST',
        url: process.env.VUE_APP_PROTOCOL_UPLOAD_URL,
        data: formData
      };

      const axiosConfig = configForPossibleBackendRequest(baseConfig);
      try {
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data) {
          const size = (file.size / 1024).toFixed(2);
          await this.upload(data.url, `${size} KB`, file.name);
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$message.error({ content: error.response.data.message, key, duration: 5 });
        this.saving = false;
      }
      return false;
    },
    async upload(url, size, name) {
      try {
        const { surveyId } = this.metadata;
        const baseConfig = { method: 'POST', url: `/projects/${this.project.id}/survey/${surveyId}/save-protocol`, data: { url, name, size } };
        const token = this.$store.getters.token ? this.$store.getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data) {
          this.getProtocols();
          this.$message.success({ content: this.$t('components.notifications.successfullyUploadedProtocol'), key, duration: 2 });
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$message.error({ content: error.response.data.message, key, duration: 5 });
      } finally {
        this.saving = false;
      }
    },
    validate() {
      this.showValidationModal = true;
    },
    onModalExit() {
      this.showValidationModal = false;
    },
    async onModalComplete(reason) {
      const { surveyId } = this.metadata;
      const protocol = this.protocols[0];
      const payload = {
        protocolId: protocol.id,
        reason
      };
      const baseConfig = {
        method: 'POST',
        url: `/projects/${this.project.id}/survey/${surveyId}/validate-protocol`,
        data: payload
      };
      const token = this.$store.getters.token ? this.$store.getters.token : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
      try {
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data) {
          this.getProtocols();
          this.showValidationModal = false;
        }
      } catch (error) {
        this.$message.error({ content: this.$t('components.notifications.errorValidatingProtocol'), key, duration: 5 });
      }
    },
    checkFieldsFilled() {
      if (
        !this.status.metadataComplete ||
        !this.surveyOrganization ||
        !this.status.clustersComplete ||
        !this.status.questionnaireComplete
      ) {
        this.$notification.error({
          message: 'Error',
          duration: 0,
          description:
          this.$t('components.description.fieldsWithinPreviousSurveyPlanning')
        });
      }
    }
  }
};
</script>

<style lang="scss">

.protocol-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 60px;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}

.protocol-container-rows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 10%;
  grid-template-rows: 60px;
  gap: 0px 0px;
  grid-template-areas:
    ". . . .";
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #8e908f7c;
}

.protocol-empty-row {
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #8e908f7c;
}

.info-button {
  background: #3897ff;
  color: white;
}
</style>
