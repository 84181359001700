var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"data-cy":"Technical"}},[_c('h4',{staticClass:"mt-40 mb-22 txt-bold txt-font-din-medium"},[_vm._v(_vm._s(_vm.$t('components.titles.technical')))]),_c('a-card',{staticClass:"mb-22",attrs:{"bordered":true}},[_c('a-form-item',{staticClass:"w635"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.background'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.background')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'background',
          {
            rules: [
              {
                required: false
              } ],
          } ]),expression:"[\n          'background',\n          {\n            rules: [\n              {\n                required: false\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor,"auto-size":{ minRows: 2, maxRows: 6 }}})],1),_c('a-form-item',{staticClass:"w270",attrs:{"label":_vm.$t('components.titles.surveyType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surveyType',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('components.description.selectSurveyType'),
              } ],
            getValueFromEvent: _vm.onSurveyTypeChange
          } ]),expression:"[\n          'surveyType',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('components.description.selectSurveyType'),\n              },\n            ],\n            getValueFromEvent: onSurveyTypeChange\n          },\n        ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Full Smart"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.fullSmart'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"NNS"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.nns'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Rapid Smart"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.rapidSmart'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"SENS"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.sens'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Other"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.other'))+" ")])],1)],1),_c('a-form-item',{staticClass:"w270"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.surveyDate'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.surveyDate')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('div',{staticClass:"flex"},[_c('a-form-item',{staticClass:"mr-24 w170"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'surveyMonth',
              {
                initialValue: new Date().getMonth() + 1,
                rules: [
                  {
                    required: true,
                    message: _vm.$t('components.description.requireSurveyMonth'),
                  } ],
              } ]),expression:"[\n              'surveyMonth',\n              {\n                initialValue: new Date().getMonth() + 1,\n                rules: [\n                  {\n                    required: true,\n                    message: $t('components.description.requireSurveyMonth'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isSupervisor,"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.months),function(month,index){return _c('a-select-option',{key:month,attrs:{"value":index + 1}},[_vm._v(" "+_vm._s(month)+" ")])})],2)],1),_c('a-form-item',{staticClass:"w80"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'surveyYear',
              {
                initialValue: new Date().getFullYear(),
                rules: [
                  {
                    required: true,
                    message: _vm.$t('components.description.requireSurveyYear'),
                  } ],
              } ]),expression:"[\n              'surveyYear',\n              {\n                initialValue: new Date().getFullYear(),\n                rules: [\n                  {\n                    required: true,\n                    message: $t('components.description.requireSurveyYear'),\n                  },\n                ],\n              },\n            ]"}],staticClass:"w60",attrs:{"disabled":_vm.isSupervisor,"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.years),function(year){return _c('a-select-option',{key:year,attrs:{"value":year}},[_vm._v(" "+_vm._s(year)+" ")])})],2)],1)],1)]),_c('a-form-item',[_c('p',[_vm._v(_vm._s(_vm.$t('components.description.daysDataCollection')))]),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'collectionDays',
          {
            rules: [
              {
                required: _vm.surveyType !== 'Other',
                message: _vm.$t('components.description.enterDaysCollectData'),
              } ],
          } ]),expression:"[\n          'collectionDays',\n          {\n            rules: [\n              {\n                required: surveyType !== 'Other',\n                message: $t('components.description.enterDaysCollectData'),\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor,"min":1,"max":999},on:{"focus":_vm.setCollectionDaysOnFirstClick}})],1),_c('a-form-item',{staticClass:"w270",attrs:{"label":_vm.$t('components.labels.seasonality')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'seasonality',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('components.description.selectSeasonality'),
              } ],
          } ]),expression:"[\n          'seasonality',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('components.description.selectSeasonality'),\n              },\n            ],\n          },\n        ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Lean Season"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.leanSeason'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Harvest Season"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.harvestSeason'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Not applicable"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.notApplicable'))+" ")])],1)],1),_c('a-form-item',{staticClass:"w270",attrs:{"label":_vm.$t('components.labels.populationType')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'populationType',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('components.description.selectPopulationType'),
              } ],
          } ]),expression:"[\n          'populationType',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('components.description.selectPopulationType'),\n              },\n            ],\n          },\n        ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"General population"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.generalPopulation'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Host community"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.hostCommunity'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Host community & IDPs"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.hostCommunityIdp'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Host community & refugees"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.hostCommunityRefugees'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"IDPs in community"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.idpInCommunity'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"IDPs in settlements"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.idpInSettlements'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Refugee in community"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.refugeeInCommunity'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Refugee in settlements"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.refugeeInSettlements'))+" ")])],1)],1),_c('a-form-item',{staticClass:"w270",attrs:{"label":_vm.$t('components.labels.surveySetting')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surveySetting',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('components.description.selectSurveySetting'),
              } ],
          } ]),expression:"[\n          'surveySetting',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('components.description.selectSurveySetting'),\n              },\n            ],\n          },\n        ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Rural"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.rural'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Urban"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.urban'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Rural & Urban"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.ruralUrban'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Other"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.other'))+" ")])],1)],1),_c('a-form-item',{staticClass:"w635"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.surveyGoal'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.surveyGoal')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surveyGoal',
          {
            rules: [
              {
                required: false
              } ],
          } ]),expression:"[\n          'surveyGoal',\n          {\n            rules: [\n              {\n                required: false\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor,"auto-size":{ minRows: 2, maxRows: 6 }}})],1),_c('a-form-item',{staticClass:"w635"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.specificSurveyObjectives'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.surveyObjectives')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surveyObjective',
          {
            rules: [
              {
                required: false
              } ],
          } ]),expression:"[\n          'surveyObjective',\n          {\n            rules: [\n              {\n                required: false\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor,"auto-size":{ minRows: 2, maxRows: 6 }}})],1),_c('a-form-item',{attrs:{"hidden":"","label":_vm.$t('components.labels.additionalQuestions')}},[_c('p',[_vm._v(_vm._s(_vm.$t('components.description.questionsInSurvey')))]),_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'additionalQuestions',
          {
            rules: [
              {
                required: false
              } ],
          } ]),expression:"[\n          'additionalQuestions',\n          {\n            rules: [\n              {\n                required: false\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor}},[_c('a-row',{staticClass:"mb-8 w350"},[_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"Wash"}},[_vm._v(" Wash ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"IYCF"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.iycf'))+" ")])],1)],1),_c('a-row',{staticClass:"mb-8 w350"},[_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"Food Security"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.foodSecurity'))+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"Anemia"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.anemia'))+" ")])],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"Health"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.health'))+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-checkbox',{attrs:{"value":"Mosquito Net Coverage "}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.mosquitoNetCoverage'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }