import { mapGetters } from 'vuex';

export default () => ({
  computed: {
    ...mapGetters(['isSurveyActive']),
    disabled() {
      const { disabledIfSurveyActive } = this.$route.meta;
      return this.isSurveyActive && disabledIfSurveyActive;
    }
  },
  watch: {
  },
  methods: {
  }
});
