<template>
  <div class="mortality-results">
    <div class="mortality-graph-container">
      <a-card :bordered="true" class="ant-card-no-padding">
        <div class="txt-20 txt-font-din-medium txt-black txt-bold mt-10 mb-10 ml-20">
          {{ $t('components.titles.populationPyramid') }}
        </div>
        <div class="w100 mb-30">
          <div v-if="fetchingData" class="w100 pb-40 flex justify-center align-center mb-30 ">
            <a-spin size="large"/>
          </div>
          <PopulationPyramidChart
            v-else-if="!mortaltyAnalysisNotEnoughData && mortalityStats && mortalityStats.plot"
            :plot="mortalityStats.plot"/>
          <div
            v-else-if="mortaltyAnalysisNotEnoughData"
            class="w100 pb-40 flex justify-center align-center">
            <a-empty :description="$t('components.notifications.notEnoughDataToGenerateReport')"/>
          </div>
        </div>
      </a-card>
      <a-card :bordered="true">
        <div class="txt-12 txt-font-din-medium txt-black txt-bold txt-uppercase">
          {{ $t('components.labels.averageHouseholdSize') }}
        </div>
        <div class="txt-45 font-din-medium txt-black txt-bold mb-20">
          {{ averageHouseholdSize }}
        </div>
        <div class="txt-12 txt-font-din-medium mt-30 txt-black txt-bold txt-uppercase">
          {{ $t('components.labels.childrenUnderFive') }}
        </div>
        <div class="txt-45 font-din-medium txt-black txt-bold mb-20">{{ childrenUnderFive }}%</div>

        <div class="txt-12 txt-font-din-medium mt-30 txt-black txt-bold txt-uppercase">
          {{ $t('components.labels.householdsWithChildrenUnderFive') }}
        </div>
        <div class="txt-45 font-din-medium txt-black txt-bold mb-20">
          {{ householdChildrenUnderFive }}
          <span class="txt-20">({{ householdChildrenUnderFivePercent }}%)</span>
        </div>
        <a-divider/>
        <div class="rate-summary txt-black">
          <div class="txt-bold">{{ $t('components.labels.birthRate') }}</div>
          <div>{{ birthRate.value }}<span v-if="samplingMethod !== 'Exhaustive'"> ({{ birthRate.lower }} - {{ birthRate.upper }} <span v-if="samplingMethod !== 'Exhaustive'" style="padding-left:8px;"> {{ $t('values.ci', { number: '95%' }) }}</span>)</span></div>
          <div class="txt-bold">{{ $t('components.labels.inMigrationRateJoined') }}</div>
          <div>
            {{ inMigrationRate.value }}<span v-if="samplingMethod !== 'Exhaustive'"> ({{ inMigrationRate.lower }} - {{ inMigrationRate.upper }} <span v-if="samplingMethod !== 'Exhaustive'" style="padding-left:8px;"> {{ $t('values.ci', { number: '95%' }) }}</span>)</span>
          </div>
          <div class="txt-bold">{{ $t('components.labels.outMigrationRateLeft') }}</div>
          <div>
            {{ outMigrationRate.value }}<span v-if="samplingMethod !== 'Exhaustive'"> ({{ outMigrationRate.lower }} - {{ outMigrationRate.upper }} <span v-if="samplingMethod !== 'Exhaustive'" style="padding-left:8px;"> {{ $t('values.ci', { number: '95%' }) }}</span>)</span>
          </div>
        </div>
        <div class="txt-grey txt-italic mt-20">{{ $t('components.labels.unitRatesPerTenThousandPerDay') }}</div>
      </a-card>
    </div>
    <div class="card-container mt-34 mb-34">
      <a-tabs type="card">
        <a-tab-pane key="1" :tab="$t('components.labels.ageGroups')">
          <GroupTable :max-height="375" :rows="ageTallyData" :columns="ageColumns"/>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('components.labels.clustersNumber', { clusters: clusterTally.length })">
          <GroupTable v-if="samplingMethod === 'Cluster'" :max-height="375" :rows="clusterTallyData" :columns="clusterColumns"/>
          <div v-else :rows="clusterTallyData">{{ $t('components.description.noData') }}</div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="death-container">
      <a-card :bordered="true">
        <div class="txt-20 txt-font-din-medium txt-black txt-bold">
          {{ $t('components.titles.deathRatesAndDesignEffects') }}
        </div>
        <DeathRateTable :data="deathRates"/>
        <div class="txt-grey txt-italic mt-20">
          {{ $t('components.description.crudeDeathRateCalculation') }}
        </div>
      </a-card>
      <div>
        <a-card :bordered="true" class="mb-32 pb-32">
          <div class="txt-20 txt-font-din-medium txt-black txt-bold">{{ $t('components.titles.causeOfDeath') }}</div>
            <div
              v-for="(item, index) in causeOfDeathStats"
              :key="index"
              class="death-summary txt-black mt-20 w250">
                <div class="txt-bold">{{ item.code != null ? `${item.code}.` : `${item.code}` }}</div>
                <div class="txt-bold">{{ translateCauseOfDeath(item.label) }}</div>
                <div>{{ item.percent }}%</div>
            </div>
        </a-card>
        <a-card :bordered="true" class="pb-22">
          <div class="txt-20 txt-font-din-medium txt-black txt-bold">{{ $t('components.titles.locationOfDeath') }}</div>
          <div
            v-for="(item, index) in locationStats"
            :key="index"
            class="death-summary txt-black mt-20 w250">
              <div class="txt-bold">{{ item.code != null ? `${item.code}.` : '' }}</div>
              <div class="txt-bold">{{ item.label != null ? `${translateLocationOfDeath(item.label)}` : '' }}</div>
              <div>{{ item.percent }}%</div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MortalityResults',
  components: {
    GroupTable: () => import('./group-table.vue'),
    DeathRateTable: () => import('./death-rate-table.vue'),
    PopulationPyramidChart: () => import('./population-pyramid-chart.vue')
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      mortalityStats: state => state.results.mortalityStats,
      fetchingData: state => state.results.fetchingData,
      mortaltyAnalysisNotEnoughData: state => state.results.mortaltyAnalysisNotEnoughData,
      samplingMethod: state => state.survey?.metadata?.samplingMethod
    }),
    ...mapGetters([
      'mortalityData',
      'averageHouseholdSize',
      'childrenUnderFive',
      'householdChildrenUnderFive',
      'householdChildrenUnderFivePercent',
      'birthRate',
      'inMigrationRate',
      'outMigrationRate',
      'ageTally',
      'clusterTally',
      'deathRates',
      'causeOfDeathStats',
      'locationStats'
    ]),
    ageTallyData() {
      return this.ageTally.map((row, index) => ({
        key: index + 1,
        years: row.age,
        pop: row.n,
        male: row.male,
        female: row.female,
        joined: row.joined,
        left: row.left,
        births: row.births,
        deaths: row.deaths
      }));
    },
    clusterTallyData() {
      return this.clusterTally.map((row, index) => ({
        key: index + 1,
        cluster: row.cluster,
        pop: row.n,
        male: row.male,
        female: row.female,
        joined: row.joined,
        left: row.left,
        births: row.births,
        deaths: row.deaths
      }));
    },
    ageColumns() {
      const ageColumns = [
        {
          title: this.$tc('components.labels.year', 2),
          dataIndex: 'years',
          key: 'years'
        },
        {
          title: this.$t('components.labels.pop'),
          dataIndex: 'pop',
          key: 'pop',
          sorter: (a, b) => a.pop - b.pop
        },
        {
          title: this.$tc('values.male', 1),
          dataIndex: 'male',
          key: 'male',
          sorter: (a, b) => a.male - b.male
        },
        {
          title: this.$tc('values.female', 1),
          dataIndex: 'female',
          key: 'female',
          sorter: (a, b) => a.female - b.female
        },
        {
          title: this.$t('components.titles.joined'),
          dataIndex: 'joined',
          key: 'joined',
          sorter: (a, b) => a.joined - b.joined
        },
        {
          title: this.$t('components.titles.left'),
          dataIndex: 'left',
          key: 'left',
          sorter: (a, b) => a.left - b.left
        },
        {
          title: this.$tc('components.labels.birth', 2),
          dataIndex: 'births',
          key: 'births',
          sorter: (a, b) => a.births - b.births
        },
        {
          title: this.$tc('components.labels.death', 2),
          dataIndex: 'deaths',
          key: 'deaths',
          sorter: (a, b) => a.deaths - b.deaths
        }
      ];
      return ageColumns;
    },
    clusterColumns() {
      const clusterColumns = [
        {
          title: this.$tc('components.titles.cluster', 1),
          dataIndex: 'cluster',
          key: 'cluster'
        },
        {
          title: this.$t('components.labels.pop'),
          dataIndex: 'pop',
          key: 'pop',
          sorter: (a, b) => a.pop - b.pop
        },
        {
          title: this.$tc('values.male', 1),
          dataIndex: 'male',
          key: 'male',
          sorter: (a, b) => a.male - b.male
        },
        {
          title: this.$tc('values.female', 1),
          dataIndex: 'female',
          key: 'female',
          sorter: (a, b) => a.female - b.female
        },
        {
          title: this.$t('components.titles.joined'),
          dataIndex: 'joined',
          key: 'joined',
          sorter: (a, b) => a.joined - b.joined
        },
        {
          title: this.$t('components.titles.left'),
          dataIndex: 'left',
          key: 'left',
          sorter: (a, b) => a.left - b.left
        },
        {
          title: this.$tc('components.labels.birth', 2),
          dataIndex: 'births',
          key: 'births',
          sorter: (a, b) => a.births - b.births
        },
        {
          title: this.$tc('components.labels.death', 2),
          dataIndex: 'deaths',
          key: 'deaths',
          sorter: (a, b) => a.deaths - b.deaths
        }
      ];
      return clusterColumns;
    }
  },
  methods: {
    translateCauseOfDeath(label) {
      if ((/non-traumatic/i).test(label)) {
        return this.$t('components.labels.nonTraumatic');
      }
      if ((/traumatic/i).test(label)) {
        return this.$t('components.labels.traumatic');
      }
      if ((/unknown/i).test(label)) {
        return this.$t('components.labels.unknown');
      }
      return label;
    },
    translateLocationOfDeath(label) {
      if (label === 'In current location') {
        return this.$t('components.labels.inCurrentLocation');
      }
      if (label === 'During Migration') {
        return this.$t('components.labels.duringMigration');
      }
      if (label === 'In place of last residence') {
        return this.$t('components.labels.inPlaceOfLastResidence');
      }
      if (label === 'other') {
        return this.$t('components.labels.other');
      }
      return label;
    }
  }
};
</script>

<style lang="scss">
.mortality-graph-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}

.mortality-results {

  .ant-tabs-tab {
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    color: #363534;
    margin-right: 12px !important;
  }

  .card-container {
    background: #f5f5f5;
    overflow: hidden;
  }

  .card-container > .ant-tabs-card > .ant-tabs-content {
    min-height: 120px;
    margin-top: -16px;
  }

  .ant-card-body {
    height: 100%;
  }

  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    color: #363534;
    font-size: 18px;
    font-family: 'DINNextLTPro-Medium';
  }

}

.death-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  margin-bottom: 30px;
}

.rate-summary {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  white-space: nowrap;
}

.death-summary {
  display: grid;
  grid-template-columns: 0.3fr 2fr 1fr;
  grid-row-gap: 0px;
  div {
    border-bottom: 1px solid #e6e6e7;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
