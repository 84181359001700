import get from 'lodash/get';
import moment from 'moment';

export const shapeMortalityManualSubmissions = (submissions) =>
  submissions
    .filter(submission => submission.type === 'mortality')
    .map(submission => [
      submission.id,
      submission.survDate,
      submission.cluster,
      submission.team,
      submission.hh,
      submission.sex,
      submission.age,
      submission.joined,
      submission.left,
      submission.born,
      submission.died,
      submission.cause,
      submission.location,
      submission.source
    ]);

export const shapeMortalitySmartSubmissions = (submissions) =>
  submissions
    .filter(submission => submission.selected &&
      submission.selected.length > 0)
    .map(submission => {
      // eslint-disable-next-line camelcase
      const { household_selection1, selected } = submission;
      const { CLUSTER, TEAM, HH } = household_selection1[0];
      const cluster = get(CLUSTER, '[0]', '');
      const team = get(TEAM, '[0]', '');
      const hh = get(HH, '[0]', '');
      const survDate = moment(submission.end[0]).format('YYYY-M-D');
      const deathListings = get(selected[0], 'listing_death', []);
      const death = deathListings.map(row => {
        const person = get(row, 'listing_death1[0]', undefined);
        const died = get(row, 'death[0]', undefined) === '1' ? 'y' : undefined;
        if (person) {
          return [
            undefined,
            survDate,
            cluster,
            team,
            hh,
            get(person, 'sex_death[0]', undefined),
            get(person, 'age_years_death[0]', undefined),
            get(person, 'joined_death[0]', undefined) === '1' ? 'y' : undefined,
            undefined,
            get(person, 'birth_death[0]', undefined) === '1' ? 'y' : undefined,
            died,
            get(person, 'cause[0]', undefined),
            get(person, 'location[0]', undefined),
            'Smart Collect'
          ];
        }
        return [];
      });
      const departListings = get(selected[0], 'listing_depart', []);
      const depart = departListings.map(row => {
        const person = get(row, 'listing_depart1[0]', undefined);
        const departed = get(row, 'depart[0]', undefined) === '1' ? 'y' : undefined;
        if (person) {
          return [
            undefined,
            survDate,
            cluster,
            team,
            hh,
            get(person, 'sex_left[0]', undefined),
            get(person, 'age_years_left[0]', undefined),
            get(person, 'joined_left[0]', undefined) === '1' ? 'y' : undefined,
            departed,
            get(person, 'birth_left[0]', undefined) === '1' ? 'y' : undefined,
            undefined,
            undefined,
            undefined,
            'Smart Collect'
          ];
        }
        return [];
      });

      return [...death, ...depart];
    })
    .flat();

export const shapeAnthropometryManualSubmissions = (submissions) =>
  submissions
    .filter(submission => submission.type === 'anthropometry')
    .map(submission => [
      submission.id,
      submission.survDate,
      submission.cluster,
      submission.team,
      submission.hh,
      submission.sex,
      submission.birthDate,
      submission.months,
      submission.weight,
      submission.height,
      submission.edema,
      submission.muac,
      '',
      '',
      '',
      submission.measure,
      submission.clothes,
      submission.strata,
      submission.wtfactor,
      submission.source
    ]);

export const shapeAnthropometrySmartSubmissions = (submissions) =>
  submissions
    .filter(submission => submission.selected &&
      submission.selected.length > 0 &&
      submission.selected[0].child)
    .map(submission => {
      // eslint-disable-next-line camelcase
      const { household_selection1, selected } = submission;
      const { CLUSTER, TEAM, HH } = household_selection1[0];
      const cluster = get(CLUSTER, '[0]', '');
      const team = get(TEAM, '[0]', '');
      const hh = get(HH, '[0]', '');
      const survDate = moment(submission.end[0]).format('YYYY-M-D');
      return selected[0].child.map(child => {
        const sex = get(child, 'CHSEX[0]', '');
        const months = get(child, 'first_measure[0].MONTHS[0]', '');
        const birthDateKnown = get(child, 'first_measure[0].BIRTHDAT[0]', undefined);
        const birthDate = birthDateKnown ? moment(birthDateKnown).format('YYYY-M-D') : '';
        const weight = get(child, 'first_measure[0].child_under_five[0].WEIGHT[0]', '');
        const height = get(child, 'first_measure[0].child_under_five[0].HEIGHT[0]', '');
        const edema = get(child, 'first_measure[0].child_under_five[0].EDEMA[0]', '');
        const clothes = get(child, 'first_measure[0].child_under_five[0].CLOTHES[0]', '');
        const measure = get(child, 'first_measure[0].child_under_five[0].MEASURE[0]', '');
        const muac = get(child, 'first_measure[0].child_under_five[0].MUAC[0]', '');
        return [
          '',
          survDate,
          cluster,
          team,
          hh,
          sex,
          birthDate,
          months,
          weight,
          height,
          edema,
          muac,
          '',
          '',
          '',
          measure,
          clothes,
          '',
          '',
          'Smart Collect'
        ];
      });
    }).flat();
