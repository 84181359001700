<template>
  <a-form layout="vertical" :form="form" :hide-required-mark="true">
    <h4 class="mt-24 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.changePassword') }}</h4>
    <a-card :bordered="true">
      <a-form-item>
          <span slot="label" class="flex align-center">
            {{ $t('components.labels.oldPassword') }}
          </span>
        <a-input
            v-decorator="[
                  'oldPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('components.description.pleaseEnterYourCurrentPassword'),
                      },
                    ]
                }]"
            type="password"
            :placeholder="$t('components.labels.oldPassword')"
            size="large"
            class=""/>
      </a-form-item>
      <a-form-item>
          <span slot="label" class="flex align-center">
            {{ $t('components.labels.newPassword') }}
          </span>
        <a-input
            v-decorator="[
                  'newPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('components.description.pleaseEnterYourNewPassword'),
                      },
                    ]
                }]"
            type="password"
            :placeholder="$t('components.labels.newPassword')"
            size="large"
            class=""/>
      </a-form-item>
      <a-form-item>
          <span slot="label" class="flex align-center">
            {{ $t('components.labels.confirmPassword') }}
          </span>
        <a-input
            v-decorator="[
                  'confirm',
                  {
                    rules: [
                      {
                        validator: newPasswordMismatched,
                        trigger: 'change',
                        required: true,
                        message: $t('components.description.passwordsDoNotMatch'),
                      },
                    ]
                }]"
            type="password"
            :placeholder="$t('components.labels.confirmPassword')"
            size="large"
            class=""/>
      </a-form-item>
      <a-button
          class="mt-10 mb-12 w170 flex align-center justify-center min-width-fit-content"
          type="primary"
          size="large"
          @click="submit">
        {{ $t('components.description.changePassword') }}
      </a-button>
    </a-card>
  </a-form>
</template>

<script>

import request from '../../../../mixins/request';
import { apiPaths } from '../../../../util/request';
import { requestData } from '../../../../store/modules/request';

export default {
  name: 'UserEditPassword',
  components: { },
  mixins: [request()],
  data() {
    return {
    };
  },
  computed: requestData(['currentUser', 'user']),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'user_change_password'
    });
  },
  methods: {
    async newPasswordMismatched() {
      const newPassword = this.form.fieldsStore.fields.newPassword.value;
      const confirm = this.form.fieldsStore.fields.confirm.value;

      if (newPassword !== confirm) {
        return Promise.reject(
          new Error(this.$t('components.notifications.pleaseCheckPasswordsMatch'))
        );
      }
      return Promise.resolve();
    },
    async submit() {
      return this.form.validateFields(async (err, values) => {
        if (!err) {
          const data = { old: values.oldPassword, new: values.newPassword };
          await this.put(apiPaths.password(this.user.id), data);
          this.$alert().success(this.$t('components.notifications.successPasswordsHaveBeenUpdated'));
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
