<template>
  <div>
    <h1 class="mt-20 txt-40 txt-bold txt-font-din-medium word-break-all overflow-wrap-break">
      {{ $t('components.titles.trackingSurveyName', { surveyName }) }}
    </h1>
    <p v-if="surveyCompletePercent >= 25" class="txt-18 mt-20">
      {{ $t('components.description.congratulationsYouHaveCompletedSurveyAndEnumerator') }}
    </p>
    <p v-else>
      {{ $t('components.description.pleaseCompleteSurveyPlanning') }}
    </p>
    <div>
      <a-button
        class="mt-10 mr-20 min-width-fit-content"
        type="primary"
        size="large"
        :disabled="!isMetadataComplete || isSupervisor"
        @click="beginPilot">
        {{ $t('components.description.beginSurveyPilot') }}
      </a-button>
      <a-button
        class="mt-10 w-170 min-width-fit-content"
        type="primary"
        ghost
        size="large"
        :disabled="!isMetadataComplete || isSupervisor"
        @click="skipPilot">
        {{ $t('components.description.skipSurveyPilot') }}
      </a-button>
    </div>
    <SkipPilotJustification v-if="justification" :on-exit="onExit" :on-complete="onComplete"/>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'ProjectDashboardPilotSelection',
  components: {
    SkipPilotJustification: () => import('./skip-pilot-justification.vue')
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      justification: false
    };
  },
  computed: {
    ...mapGetters([
      'surveyCompletePercent',
      'isMetadataComplete'
    ]),
    ...mapState({
      surveyName: state => state.survey.name,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    ...mapActions(['setPilot']),
    beginPilot() {
      this.setPilot({ pilot: true });
    },
    skipPilot() {
      this.justification = true;
    },
    onExit() {
      this.justification = false;
    },
    onComplete(skipJustification) {
      this.setPilot({
        pilot: false,
        skipJustification
      });
    }
  }
};
</script>

<style lang="scss">
</style>
