<template>
  <div>
    <p v-if="isSupervisor" class="metadata-survey-title">{{ name }}</p>
    <a-input v-else v-model.trim="name" size="large" class="ant-naked-input survey-title" @blur="saveName">
      <i slot="addonAfter" class="icon-pencil" style="fontSize: 20px; color: #c6c7c6"></i>
    </a-input>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MetadataSurveyName',
  data() {
    return {
      name: ''
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      survey: state => state.survey,
      surveyName: state => state.survey.name,
      project: state => state.survey.project,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    surveyName(newValue) {
      this.name = newValue;
    }
  },
  mounted() {
    this.name = this.surveyName;
  },
  methods: {
    ...mapActions(['updateSurveyName']),
    saveName() {
      if (this.isSupervisor) {
        return;
      }
      if (!this.name) {
        this.name = this.surveyName;
        this.$alert().danger(this.$t('components.notifications.errorEmptySurveyName'));
      } else if (this.project && this.project.id && this.surveyId) {
        this.updateSurveyName({
          surveyId: this.surveyId,
          projectId: this.project.id,
          name: this.name
        }).then(() => this.$message.success(this.$t('components.notifications.surveyNameUpdated')));
      }
    }
  }
};
</script>

<style lang="scss">
.survey-title .ant-input-lg {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 0px;
  height: 55px;
}
.metadata-survey-title {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 0px;
  white-space: nowrap;
  margin-bottom: 0px;
}
</style>
