<template>
  <div class="team-clusters">
    <div class="team-cluster-header">
      <div class="w25 txt-14 txt-grey">{{ $tc('components.labels.team', 1) }}</div>
      <div class="w75 txt-14 txt-grey">{{ $t('components.labels.clusterAssignment') }}</div>
    </div>
    <div v-for="team in teams" :key="team" class="team-cluster-row">
      <div class="w25 flex align-center">
        <div class="team-circle" :style="{ backgroundColor: getRandomColor(team, 1) }"></div>
        {{ $tc('components.labels.team', 1) }} {{ team }}
      </div>
      <div class="w75 pb-8 pt-8">
        <a-select
          v-model="teamClusters[team]"
          :search="false"
          class="w100"
          mode="multiple"
          :disabled="(pilot === true) || isSupervisor"
          :filter-option="filterOption"
          :placeholder="$t('components.description.assignClusters')"
          :get-popup-container="(triggerNode) => triggerNode.parentNode"
          @select="updateValue($event, team, 'add')"
          @deselect="updateValue($event, team, 'remove')">
          <a-select-option
            v-for="cluster in clusters"
            :key="cluster.id"
            :value="cluster.id"
            :label="cluster.name">
            <div :class="clusterIsOutOfRange(team, cluster) ? 'out-of-range-cluster' : ''">
              <a-tooltip
                v-if="clusterIsOutOfRange(team, cluster)"
                :title="$t('components.toolTips.teamGPSLocationIsMoreThanFiveKM')">
                <span>{{ cluster.name === 'RC' ? `${cluster.name}-${cluster.unitName}${getRCNumber(cluster, clusters)}` : cluster.name }}</span>
              </a-tooltip>
              <span v-else>
                {{ cluster.name === 'RC' ? `${cluster.name}-${cluster.unitName}${getRCNumber(cluster, clusters)}` : cluster.name }}
              </span>
              <div class="pull-right cluster-information txt-grey txt-11 mt-4">
                {{ getTeamsAssignedToCluster(cluster.id) }}
              </div>
            </div>
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>

<script>
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import { configForPossibleBackendRequest } from '../../../util/request';
import { getRandomColor } from '../../../util/util';
import { getRCNumber } from '../../../util/clusters';
import { haversineDistance } from '../../../util/location';
import enumeratorsMixins from '../../../mixins/enumerators';

// how long to wait before updating team-cluster assignments in store
const debounceDelay = 5000;

export default {
  name: 'ProjectDashboardTeamCluster',
  mixins: [enumeratorsMixins()],
  props: {
    clusterAssignments: {
      type: Array,
      required: true
    },
    clusterTeamLocations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      teamClusters: {}
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      dashboardTeams: state => state.plausibility.dashboardTeams,
      pilot: state => state.survey.pilot,
      dashboardClusters: state => state.plausibility.dashboardClusters,
      geoUnits: state => state.survey.geoUnits,
      clusters: state => [...state.survey.clusters.filter(cluster => cluster.name && cluster.name !== 'RC').sort((a, b) => a.name - b.name), ...state.survey.clusters.filter(cluster => cluster.name && cluster.name === 'RC').sort((a, b) => a.name - b.name)],
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    teams() {
      let selectedClusterId = null;
      if (this.dashboardClusters) {
        const selectedCluster = this.clusters.filter(item => item.name === this.dashboardClusters)[0];
        selectedClusterId = selectedCluster ? selectedCluster.id : null;
      }
      if (this.dashboardTeams) {
        return [this.dashboardTeams];
      }
      if (this.enumerators) {
        return uniq(
          this.enumerators
            .map(enumerator => enumerator.teamId)
            .filter(item => item && (!selectedClusterId || this.teamClusters[item].includes(selectedClusterId))) // filter out null or 0s and not filtered clusters
        ).sort();
      }
      return [];
    }
  },
  watch: {
    clusterAssignments() {
      this.setTeamClusters();
    },
    clusterTeamLocations() {
      this.setTeamClusters();
    }
  },
  mounted() {
    if (this.clusterAssignments?.length) {
      this.setTeamClusters();
    }
  },
  methods: {
    ...mapActions(['setClusterAssignments',
      'setDashboardAnalysisDataIsLoading',
      'setDashboardDataQualityIsLoading']),
    getRandomColor,
    getRCNumber,
    getTeamsAssignedToCluster(clusterId) {
      const clustersAssignees = Object
        .keys(this.teamClusters)
        .filter(teamId => this.teamClusters[teamId].includes(clusterId));

      if (clustersAssignees.length > 0) {
        const output = clustersAssignees.join(', ');
        const singular = clustersAssignees.length === 1;
        return `Assigned to team${singular ? '' : 's'} ${output}`;
      }

      return '';
    },
    clusterIsOutOfRange(teamId, cluster) {
      if (!teamId || isEmpty(cluster) || !this.clusterTeamLocations || this.clusterTeamLocations.length < 1) {
        return false;
      }
      const geoUnit = (this.geoUnits || []).find((item) => item.id === cluster.geoUnitId);
      // eslint-disable-next-line eqeqeq
      const teamClusterLocation = this.clusterTeamLocations.find((item) => item.cluster == cluster.name && item.team == teamId);
      if (!isEmpty(teamClusterLocation) && geoUnit) {
        const lat1 = get(teamClusterLocation, 'gps.latitude', null);
        const lng1 = get(teamClusterLocation, 'gps.longitude', null);
        const [lat2, lng2] = get(geoUnit, ['location'], '').split(',').map((item) => item.trim());
        if (lat1 != null && lng1 != null && lat2 != null && lng2 != null && lat2 !== '' && lng2 !== '') {
          const distance = haversineDistance(lat1, lng1, lat2, lng2);
          if (distance >= 5) {
            return true;
          }
        }
      }
      return false;
    },
    initializeLoadings() {
      this.setDashboardAnalysisDataIsLoading(true);
      this.setDashboardDataQualityIsLoading(true);
    },
    async updateValue(clusterId, teamId, action) {
      // action indicates whether the cluster was added or removed from the team
      const assignment = {
        clusterId,
        teamId,
        action
      };
      this.initializeLoadings();
      const { data } = await this.saveTeamClusters(assignment);
      this.setAssignments(data);
    },
    setAssignments: debounce(async function(data) {
      await this.setClusterAssignments(data);
      this.$emit('refetch');
    }, debounceDelay),
    filterOption(input, option) {
      return (
        option.componentOptions.propsData?.label?.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    saveTeamClusters(assignments) {
      const projectId = this.$store.state.survey.project.id;
      const baseConfig = { method: 'POST', url: `/projects/${projectId}/survey/${this.surveyId}/assign-cluster`, data: assignments };
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
      return Vue.prototype.$http.request(axiosConfig);
    },
    setTeamClusters() {
      const teamClusters = {};
      this.clusterAssignments.forEach(row => {
        const teamId = row.teamId.toString();
        const previous = teamClusters[teamId] || [];
        teamClusters[teamId] = uniq([...previous, row.clusterId]);
      });
      this.teamClusters = { ...teamClusters };
    }
  }
};
</script>

<style lang="scss">
.team-clusters {
  .ant-select-selection__choice__content .out-of-range-cluster {
    color: red;
  }
  .team-cluster-header {
    display: flex;
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  .team-cluster-row {
    min-height: 70px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e7;
  }

  .team-circle {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }

  .ant-select-selection {
    height: auto !important;
  }

  .ant-select-selection__choice__content .cluster-information {
    display: none;
  }

}
</style>
