export default function() {
  const defaultState = {
    formUploadChanged: false,
    formBuilderChanged: false
  };

  return {
    state: { ...defaultState },
    mutations: {
      setFormUploadChanged(state) {
        state.formUploadChanged = true;
      },
      ackFormUploadChanged(state) {
        state.formUploadChanged = false;
      },
      setFormBuilderChanged(state) {
        state.formBuilderChanged = true;
      },
      ackFormBuilderChanged(state) {
        state.formBuilderChanged = false;
      }
    },
    actions: {
      async setFormUploadAsChanged({ commit }) {
        commit('setFormUploadChanged');
      },
      async ackFormUploadAlert({ commit }) {
        commit('ackFormUploadChanged');
      },
      async setFormBuilderAsChanged({ commit }) {
        commit('setFormBuilderChanged');
      },
      async ackFormBuilderAsChange({ commit }) {
        commit('ackFormBuilderChanged');
      }
    }
  };
}
