<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';
import { apiPaths } from '../../util/request';
import { requestData } from '../../store/modules/request';
import request from '../../mixins/request';

export default {
  name: 'AccountLogout',
  mixins: [request()],
  computed: {
    ...requestData(['session', 'currentUser', 'forms'])
  },
  mounted() {
    this.delete(apiPaths.session(this.session.token));
    this.$store.commit('clearData');
    this.emptyStoredSurvey();
    this.clearResults();
    this.$router.push('/login');
  },
  methods: {
    ...mapActions([
      'emptyStoredSurvey',
      'clearResults'
    ])
  }
};
</script>

<style scoped>
</style>
