/*
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
// TODO: Optimize imports
import {
  Button,
  message,
  notification,
  Alert,
  Avatar,
  BackTop,
  Badge,
  Breadcrumb,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  List,
  Menu,
  Progress,
  Radio,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Tabs,
  Table,
  Tag,
  Timeline,
  Tooltip,
  Drawer,
  Popover,
  Popconfirm,
  Modal,
  Dropdown,
  Upload,
  Result
} from 'ant-design-vue';

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueI18n);
// pluck only the ones we use once we know which com
Vue.use(Button);
Vue.use(message);
Vue.use(Alert);
Vue.use(Avatar);
Vue.use(BackTop);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(ConfigProvider);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(List);
Vue.use(Menu);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(DatePicker);
Vue.use(Row);
Vue.use(Drawer);
Vue.use(Select);
Vue.use(Skeleton);
Vue.use(Spin);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Modal);
Vue.use(Dropdown);
Vue.use(notification);
Vue.use(Popconfirm);
Vue.use(Upload);
Vue.use(Result);

Vue.prototype.$message = message;
Vue.prototype.$notification = notification;

