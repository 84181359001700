<!--
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->


<template>
  <div id="sign-up-container" class="row">
    <div class="col-xl-lg-md-sm-xs-12 sign-up-panel">
      <div class="heading">
        <img src="/logo.svg">
        <h3>{{ $t('values.registration') }}</h3>
        <router-link id="login-link" :to="loginLocation"
          :disabled="disabled">
          {{ $t('components.titles.backToLogin') }}
        </router-link>
      </div>

      <div class="form">
        <div class="panel-body">
          <form @submit.prevent="submit">
            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.firstName') }}</legend>
                <input
                  ref="firstName"
                  v-model.trim="form.firstName"
                  data-cy="firstName"
                  type="text"
                  class="form-control"
                  :placeholder="$t('components.titles.firstNamePlacehoder')"
                  required
                  autocomplete="off">
            </fieldset>
            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.lastName') }}</legend>
                <input
                  ref="lastName"
                  v-model.trim="form.lastName"
                  data-cy="lastName"
                  type="text"
                  class="form-control"
                  :placeholder="$t('components.titles.lastNamePlacehoder')"
                  required
                  autocomplete="off">
            </fieldset>

            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.emailAddress') }}</legend>
              <input
                ref="email"
                v-model.trim="form.email"
                data-cy="email"
                type="email"
                class="form-control shadow-none"
                :placeholder="$t('components.titles.emailPlaceholder')"
                required
                autocomplete="off">
              <div>
                <ul>
                  <li class="warning">{{ $t('components.notifications.afterSignUpNotAbleToChangeEmail') }}</li>
                </ul>
              </div>
            </fieldset>

            <fieldset class="legend-border hidden-xl hidden-lg hidden-md hidden-sm">
              <legend class="legend-border">{{ $t('components.titles.gender') }}</legend>
              <div class="w100">
                <a-select
                  :placeholder="$t('components.titles.selectGender')"
                  :value="selectedGender"
                  :get-popup-container="(triggerNode) => triggerNode.parentNode"
                  @change="setGender">
                  <a-icon slot="suffixIcon" type="caret-down"/>
                    <a-select-option v-for="(genderOption, index) in genderArray"
                    :key="index"
                    :value="index">
                      {{ genderOption.label }}
                    </a-select-option>
                </a-select>
              </div>
            </fieldset>

            <fieldset class="legend-border hidden-xl hidden-lg hidden-md hidden-sm">
                <legend class="legend-border">{{ $t('components.titles.capacity') }}</legend>
                <div class="w100">
                  <a-select
                    :placeholder="$t('components.titles.selectCapacity')"
                    :value="selectedCapacity"
                    :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    @change="setCapacity">
                    <a-icon slot="suffixIcon" type="caret-down"/>
                      <a-select-option v-for="(capacity, index) in capacityArray"
                        :key="index"
                        :value="index">
                        {{ capacity.label }}
                      </a-select-option>
                  </a-select>
                </div>
            </fieldset>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 left-cell hidden-xs">
                <fieldset class="legend-border">
                  <legend class="legend-border">{{ $t('components.titles.gender') }}</legend>
                  <div class="w100">
                    <a-select
                      :placeholder="$t('components.titles.selectGender')"
                      :value="selectedGender"
                      :get-popup-container="(triggerNode) => triggerNode.parentNode"
                      @change="setGender">
                      <a-icon slot="suffixIcon" type="caret-down"/>
                        <a-select-option v-for="(genderOption, index) in genderArray"
                          :key="index"
                          :value="index">
                          {{ genderOption.label }}
                        </a-select-option>
                    </a-select>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 right-cell hidden-xs">
                  <fieldset class="legend-border">
                    <legend class="legend-border">{{ $t('components.titles.capacity') }}</legend>
                    <div class="w100">
                      <a-select
                        :placeholder="$t('components.titles.selectCapacity')"
                        :value="selectedCapacity"
                        :get-popup-container="(triggerNode) => triggerNode.parentNode"
                        @change="setCapacity">
                        <a-icon slot="suffixIcon" type="caret-down"/>
                          <a-select-option v-for="(capacity, index) in capacityArray"
                            :key="index"
                            :value="index">
                            {{ capacity.label }}
                          </a-select-option>
                      </a-select>
                    </div>
                </fieldset>
                </div>
            </div>

                <fieldset class="legend-border">
                  <legend class="legend-border">{{ $t('components.titles.affiliation') }}</legend>
                  <div class="w100">
                    <a-select
                      :placeholder="$t('components.titles.selectAffiliation')"
                      :value="selectedAffiliation"
                      :get-popup-container="(triggerNode) => triggerNode.parentNode"
                      @change="setAffiliation">
                      <a-icon slot="suffixIcon" type="caret-down"/>
                        <a-select-option
                          v-for="(affiliation, index) in affiliationArray"
                          :key="index"
                          :value="index">
                          {{ affiliation.label }}
                        </a-select-option>
                    </a-select>
                  </div>
                </fieldset>

                <fieldset class="legend-border">
                  <legend class="legend-border">{{ $t('components.titles.organization').toUpperCase() }}</legend>
                  <input
                      ref="organization"
                      v-model.trim="form.organization"
                      data-cy="organization"
                      type="text"
                      class="form-control"
                      :placeholder="$t('components.titles.organizationPlaceholder')"
                      autocomplete="off">
                </fieldset>
            <fieldset class="legend-border">
              <legend class="legend-border">{{ $tc('components.titles.country', 1).toUpperCase() }}</legend>
                <div class="w100">
                  <a-select
                    show-search
                    :placeholder="$t('components.titles.countryPlaceholder')"
                    :value="selectedCountry"
                    :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    @change="setCountry">
                    <a-icon slot="suffixIcon" type="caret-down"/>
                      <a-select-option v-for="(country, index) in countryArray"
                      :key="index"
                      :value="country.name">
                        {{ country.name }}
                      </a-select-option>
                  </a-select>
                </div>
            </fieldset>

            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.password') }}</legend>
              <div class="input-group">
                <input
                  v-model="form.password"
                  data-cy="password"
                  :type="passwordFieldType"
                  class="form-control"
                  :placeholder="$t('components.titles.passwordPlaceholder')"
                  autocomplete="off"
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}">
                  <span id="basic-addon1"
                    class="input-group-addon eye-styling"
                    data-cy="see-password-button"
                    @click="togglePasswordFieldType">
                    <embed src="/eye.svg" :class="showPassword">
                    <embed src="/eye-blocked.svg" :class="hidePassword">
                  </span>
              </div>
              <div>
                <ul>
                    <li :class="containsLetter">{{ $t('components.description.mustContainAtLeastOneLetter') }}</li>
                    <li :class="containsCapital">{{ $t('components.description.mustContainAtLeastOneUppercase') }}</li>
                    <li :class="containsLowercase">{{ $t('components.description.mustContainAtLeastOneLowercase') }}</li>
                    <li :class="containsNumber">{{ $t('components.description.mustContainAtLeastOneNumber') }}</li>
                    <li :class="containsSpecialCharacter">
                      {{ $t('components.description.mustContainSpecialCharacter') }}
                    </li>
                    <li :class="longEnough">{{ $t('components.description.mustBeAtLeast8Characters') }}</li>
                  </ul>
              </div>
            </fieldset>

            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.confirmPassword') }}</legend>
              <div class="input-group">
                <input
                  v-model="form.confirmPassword"
                  data-cy="confirmPassword"
                  :type="passwordFieldType"
                  class="form-control"
                  :placeholder="$t('components.titles.confirmPasswordPlaceholder')"
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  autocomplete="off">
                  <span id="basic-addon1"
                    class="input-group-addon eye-styling"
                    data-cy="see-password-button"
                    @click="togglePasswordFieldType">
                    <embed src="/eye.svg" :class="showPassword">
                    <embed src="/eye-blocked.svg" :class="hidePassword">
                  </span>
              </div>
              <div class="error" :hidden="passwordFieldsMatch">{{ $t('components.description.passwordFieldsMatch') }}</div>
            </fieldset>

            <div class="footer">
              <button
                id="sign-up-button"
                :disabled="disabled"
                type="submit" class="btn btn-primary orange">
                {{ $t('components.titles.signUp') }} <spinner :state="disabled"/>
              </button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Spinner from '../spinner.vue';
import request from '../../mixins/request';
import { noop } from '../../util/util';
import countryArray from '../../util/countries';
import { capitalizeStringArray } from '../../smartplus/stringUtilities';

export default {
  name: 'SignUp',
  components: { Spinner },
  mixins: [request()],

  data() {
    return {
      cleanMessages: {
        409.3: this.$t('components.notifications.thisAccountAlreadyExists')
      },
      disabled: false,
      passwordFieldType: 'password',
      passwordVisibilityIconPath: '/eye-blocked.svg',
      showPassword: 'hide',
      hidePassword: 'show',
      rememberMe: false,
      countryArray,
      genderIndex: undefined,
      capacityIndex: undefined,
      countryIndex: undefined,
      affiliationIndex: undefined,
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        gender: '',
        capacity: '',
        affiliation: '',
        organization: '',
        country: ''
      }
    };
  },
  computed: {

    /**
     * ensure the passwords are in sync
     */
    formValid() {
      return this.containsNumber === 'success' &&
        this.containsLetter === 'success' &&
        this.containsSpecialCharacter === 'success' &&
        this.containsCapital === 'success' &&
        this.containsLowercase === 'success' &&
        this.longEnough === 'success' &&
        this.passwordFieldsMatch &&
        this.form.capacity &&
        this.form.capacity !== '' &&
        this.form.gender &&
        this.form.gender !== '' &&
        this.form.affiliation &&
        this.form.affiliation !== '' &&
        this.form.organization &&
        this.form.organization !== '' &&
        this.form.country &&
        this.form.country !== '';
    },
    genderArray() {
      const genderArray = [
        { value: 'Male', label: this.$tc('values.male', 1) },
        { value: 'Female', label: this.$tc('values.female', 1) },
        { value: 'Prefer not to say', label: this.$t('components.description.preferNotToSay') }
      ];
      return genderArray;
    },
    affiliationArray() {
      const affiliationArray = [
        { value: 'International NGO', label: this.$t('components.dropdown.internationalNGO') },
        { value: 'UN agency', label: this.$t('components.dropdown.unAgency') },
        { value: 'Government', label: this.$t('components.dropdown.government') },
        { value: 'Academia', label: this.$t('components.dropdown.academia') },
        { value: 'local NGO', label: this.$t('components.dropdown.localNGO') },
        { value: 'independent consultant', label: this.$t('components.dropdown.independentConsultant') },
        { value: 'other', label: this.$t('components.dropdown.other') }
      ];
      return affiliationArray;
    },
    capacityArray() {
      const capacityArray = [
        { value: 'National staff', label: this.$t('components.dropdown.nationalStaff') },
        { value: 'Expatriate staff', label: this.$t('components.dropdown.expatriateStaff') }
      ];
      return capacityArray;
    },
    containsCapital() {
      return this.form.password.match(/[A-Z]/g) ? 'success' : 'error';
    },
    containsLowercase() {
      return this.form.password.match(/[a-z]/g) ? 'success' : 'error';
    },
    containsNumber() {
      return this.form.password.match(/[0-9]/g) ? 'success' : 'error';
    },
    containsLetter() {
      return this.form.password.match(/[a-zA-Z]/g) ? 'success' : 'error';
    },
    containsSpecialCharacter() {
      // eslint-disable-next-line no-useless-escape
      return this.form.password.match(/[\_\*\-?]/g) ? 'success' : 'error';
    },
    longEnough() {
      return this.form.password.length >= 8 ? 'success' : 'error';
    },
    passwordFieldsMatch() {
      return this.form.password === this.form.confirmPassword;
    },
    selectedGender() {
      if (this.genderIndex || this.genderIndex === 0) {
        return this.genderArray[this.genderIndex].label;
      }
      return undefined;
    },
    selectedCapacity() {
      if (this.capacityIndex || this.capacityIndex === 0) {
        return this.capacityArray[this.capacityIndex].label;
      }
      return undefined;
    },
    selectedAffiliation() {
      if (this.affiliationIndex || this.affiliationIndex === 0) {
        return this.affiliationArray[this.affiliationIndex].label;
      }
      return undefined;
    },
    selectedCountry() {
      if (this.countryIndex) {
        return this.countryIndex;
      }
      return undefined;
    },
    resetPasswordLocation() {
      return {
        path: '/reset-password',
        query: Object.assign({}, this.$route.query)
      };
    },
    loginLocation() {
      return {
        path: '/login',
        query: Object.assign({}, this.$route.query)
      };
    }
  },
  mounted() {
    this.$refs.email.focus();
  },
  beforeRouteLeave(to, from, next) {
    if (this.disabled) {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    setCountry(index) {
      this.countryIndex = index;
    },
    setCapacity(index) {
      this.capacityIndex = index;
    },
    setGender(index) {
      this.genderIndex = index;
    },
    setAffiliation(index) {
      this.affiliationIndex = index;
    },

    /** change the eye */
    togglePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.passwordVisibilityIconPath = this.passwordVisibilityIconPath === '/eye.svg' ? 'eye-blocked.svg' : '/eye.svg';
      this.showPassword = this.showPassword === 'show' ? 'hide' : 'show';
      this.hidePassword = this.hidePassword === 'hide' ? 'show' : 'hide';
    },

    submit() {
      //capture the drop-down list values
      this.form.capacity = this.capacityArray[this.capacityIndex].value;
      this.form.affiliation = this.affiliationArray[this.affiliationIndex].value;
      this.form.country = this.countryIndex;
      this.form.gender = this.genderArray[this.genderIndex].value;
      //don't submit if the passwords aren't valid & identical
      if (!this.formValid) {
        const missing = Object.keys(this.form).filter(key => !this.form[key]);
        const missingFields = capitalizeStringArray(missing).join(', ');
        this.$alert().danger(this.$t('components.notifications.filloutAllrequiredField', {
          missingFields
        }));
        return;
      }
      this.disabled = true;
      setTimeout(() => {
        this.$store.commit('hideAlert');
      }, 5000);

      this.request({
        method: 'POST',
        url: '/users/register',
        data: this.form,
        problemToAlert: (data) => this.cleanMessages[`${data.code}`] || data.message
      })
        .then(({ data }) => {
          if (data.id >= 1) {
            this.$gtag.event('register');
            setTimeout(() => {
              this.$router.push('/login', () => {
                this.$alert().success(this.$t('components.notifications.registrationSuccessful'));
              });
            }, 250);
          }
        })
        .finally(() => {
          this.disabled = false;
        })
        .then(() => {
          this.routeToNext();
        })
        .catch(noop);
    }
  }
};
</script>


<style scoped>

.sign-up-panel {
  font-family: 'DINNextLTPro-Regular';
  padding: 30px 30px;
  color: #363534;
  background-color:#fff;
}
.heading {
  text-align: left;
  margin-bottom : 1em;
}
.header h4 {
  font-family : 'DINNextLTPro-Regular';
  color : #363534;
}
.heading img {
  padding-bottom: 2.5em;
}
.heading p {
  font-size: 1.25em;
  color: #9a9a99;
}
legend {
  padding : 0 1em 0 1em;
}
input {
  border: none;
}
.panel-body {
  padding : 0 0 0 0;
}
.panel-footer {
  background-color: #fff;
}
#sign-up-container {
  margin-top: 1.75em;
  margin-bottom : 1.75em;
  box-shadow: 0 0 1em #333;
  background-color:#fff;
  box-sizing: border-box;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 840px;
  font-size:1em;
}
button.orange {
  background-color: #e98301 !important;
  font-size: 1em;
  font-family: 'SourceSansPro-SemiBold';
  border: none;
}

fieldset.legend-border {
  border: 1px solid #ddd !important;
  padding: 0 1em 1em 1em !important;
  margin: 0 0 1em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.legend-border {
  font-size: 0.75em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  border-bottom: none;
}

span.icon-eye {
  font-size: 20px;
}

embed {
  pointer-events: none;
  height : 20px;
  width : 20px;
  padding : 0 0 0 0;
}

.row {
  margin-left:0;
  margin-right:0;
  padding: 0 0 0 0;
}

#forgot-password-col {
  text-align: right;
  padding-right:0;
}

#sign-up-button {
  font-family: 'DINNextLTPro-Regular';
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  height : 3.5em;
  width : 50%;
}
.eye-styling {
  background-color:white;
  border-color:white;
}
.footer p {
  font-family : 'DINNextLTPro-Medium';
  margin-bottom : 0;
}

#login-link:hover {
  color :#e98301;
}
#login-link:active {
  color :#d3b58e;
}
#login-link:link {
  color :#e98301;
}
#login-link:visited {
  color :#e98301;
}

.left-cell {
  padding: 0 5px 0 0;
}
.right-cell {
  padding: 0 0 0 5px;
}

input[type=text] {
  border : 0;
  box-shadow : none;
}
input[type=password] {
  border : 0;
  box-shadow : none;
}
input[type=email] {
  border : 0;
  box-shadow : none;
}
ul {
  background-color : #efefef;
}
.error {
  color : #f00;
  background-color : #efefef;
}
.warning {
  color : rgb(255, 119, 0);
  background-color : #efefef;
}
.success {
  display: none;
}

</style>
