<template>
  <span data-cy="Logistics">
    <h4 class="mt-40 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.logistics').toUpperCase() }}</h4>
    <a-card :bordered="true" class="mb-22">
      <a-form-item>
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.numberOfTeams') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.selectNumberOfTeams')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-input-number v-decorator="[
            'numberOfTeams',
            {
              rules: [
                {
                  required: surveyType !== 'Other',
                  message: $t('components.description.enterHowManyTeams'),
                },
              ],
            },
          ]" :disabled="isSupervisor" :min="1" :max="100"/>
      </a-form-item>
      <a-form-item class="w270">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.surveyEquipment') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.surveyEquipment')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-select v-decorator="[
            'surveyEquipment',
            {
              initialValue: 'Manual Measurement Tools',
              rules: [
                {
                  required: surveyType !== 'Other',
                  message: $t('components.description.selectEquipment')
                },
              ],
            },
          ]" :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option :disabled="isSupervisor" value="Manual Measurement Tools">
            {{ $t('components.dropdown.manualMeasurementTools') }}
          </a-select-option>
          <a-select-option :disabled="isSupervisor" value="Mobile app for 3D measurements">
            {{ $t('components.dropdown.mobileAppFor3DMeasurements') }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="w270">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.gpsData') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.gpsData')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-radio-group v-decorator="[
          'allowGPS',
          {
            initialValue: true,
            rules: [
              {
                required: true,
                message: $t('components.description.selectEquipment'),
              },
            ],
          },
        ]" :disabled="isSupervisor" name="radioGroup">
          <a-radio :value="true">
            {{ $t('values.yes') }}
          </a-radio>
          <a-radio :value="false">
            {{ $t('values.no') }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-card>
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MetadataLogistics',
  props: {
    metadata: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    surveyType() {
      if (this.metadata) {
        return this.metadata.surveyType;
      }

      return null;
    }
  }
};
</script>

<style lang="scss">
</style>
