import axios from 'axios';
import { configForPossibleBackendRequest, queryString } from '../../util/request';

// TODO: Move to ENv
const URL = process.env.VUE_APP_REPORT_UPLOAD_URL;

export default function(Vue) {
  const defaultState = {
    showPlausibilityModal: false,
    requestTime: 0,
    requestIntervalId: null,

    isFetching: false,

    error: null,
    reportBlob: undefined,
    abortController: undefined
  };

  return {
    state: { ...defaultState },
    mutations: {
      setFetching(state) {
        state.isFetching = true;
      },
      setFetchingDone(state) {
        state.isFetching = false;
      },
      setReportBlob(state, blob) {
        state.reportBlob = blob;
      },
      setAbortController(state, controller) {
        state.abortController = controller;
      },
      setError(state, err) {
        state.error = err;
      }
    },
    getters: {
      isFetching: (state) => state.isFetching,
      abortController: (state) => state.abortController,
      reportBlob: (state) => state.reportBlob
    },
    actions: {
      async deleteResource({ getters }, { surveyId, resourceId }) {
        if (resourceId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'DELETE',
            url: `/surveys/${surveyId}/rena-resource/${resourceId}`,
            params: {
              force: true
            }
          };

          try {
            await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            return true;
          } catch (error) {
            return false;
          }
        }
        return false;
      },
      async retrieveResource({ commit, getters }, { surveyId, resourceId }) {
        if (resourceId) {
          const token = getters.loggedIn ? getters.token : null;

          const axiosConfig = {
            method: 'GET',
            url: `/surveys/${surveyId}/rena-resource/${resourceId}`,
            responseType: 'blob'
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            const blob = new Blob([data], { type: data.type });
            commit('setReportBlob', blob);
            commit('setFetchingDone');
            return { success: true };
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
              return { success: false }; // so we don't show the error message.
            }
            if (error.response && error.response.status === 423) {
              return { success: false };
            }
            commit('setError', error);
            commit('setFetchingDone');
            return { success: false, error };
          }
        }
        return { success: false };
      },
      async triggerReportGeneration({ commit, getters }, { projectId, surveyId, selectedzScore, lang }) {
        commit('setReportBlob', undefined);
        if (projectId && surveyId && selectedzScore !== null) {
          const token = getters.loggedIn ? getters.token : null;
          commit('setFetching');

          const axiosConfig = {
            method: 'GET',
            url: `/projects/${projectId}/survey/${surveyId}/generate-survey-report/${selectedzScore}`,
            params: {
              lang
            }
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            return data;
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
            }
            commit('setError', error);
            commit('setFetchingDone');
          }
        }
        return false;
      },
      async getReportForSurvey({ getters, commit }, { projectId, surveyId, selectedzScore }) {
        commit('setReportBlob', undefined);
        commit('setFetching');
        if (projectId && surveyId && selectedzScore !== null) {
          const token = getters.loggedIn ? getters.token : null;

          const newController = new AbortController();
          commit('setAbortController', newController);

          const axiosConfig = {
            method: 'GET',
            url: `/projects/${projectId}/survey/${surveyId}/survey-report/${selectedzScore}`,
            responseType: 'blob',
            signal: newController.signal
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            const blob = new Blob([data], { type: data.type });
            commit('setReportBlob', blob);
            return true;
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
              return true; // so we don't show the error message.
            }
            commit('setError', error);
          } finally {
            commit('setFetchingDone');
            commit('setCancelTokenSource', undefined);
          }
        }
        return false;
      },
      async uploadSurveyReportToStorage({ commit }, { file }) {
        const formData = new FormData();
        formData.append('file', file);

        const baseConfig = {
          params: {
            saveExtension: true,
            friendlyName: file.name
          },
          data: formData,
          method: 'POST',
          url: URL
        };
        const axiosConfig = configForPossibleBackendRequest(baseConfig);

        try {
          const response = Vue.prototype.$http.request(axiosConfig);
          return response;
        } catch (error) {
          commit('setError', error);
        }
        return { data: {} };
      },
      async createSurveyReport({ getters, commit }, { projectId, surveyId, url, size, filename, validated = null, params, zScoreExclusion }) {
        const sizeStr = `${(size / 1024).toFixed(2)} KB`;
        const baseConfig = {
          method: 'POST',
          url: `/projects/${projectId}/survey/${surveyId}/survey-report${queryString(params)}`,
          data: { url, name: filename, size: sizeStr, validated, zScoreExclusion }
        };

        const token = getters.loggedIn ? getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

        try {
          const { data } = await Vue.prototype.$http.request(axiosConfig);
          return data;
        } catch (error) {
          commit('setError', error);
        }

        return null;
      }
    }
  };
}
