import { apiPaths } from './request';

// eslint-disable-next-line import/prefer-default-export
export const grantFormAccessToEnumeratorsForProject = async (
  form, project, enumerators, RequestMixinContext
) => {
  if (form) {
    const payload = {
      name: project.name,
      archived: null,
      forms: [
        {
          xmlFormId: form.xmlFormId,
          name: form.name,
          state: form.state,
          assignments: enumerators.map(enumerator => ({ actorId: enumerator.id, roleId: 2 }))
        }
      ]
    };
    await RequestMixinContext.put(apiPaths.project(project.id), payload);
  }
};

export const enumeratorColumns = (i18n = { $t: (() => {}) }) => ([
  { 'Weight 1': i18n.$t('components.labels.weightNumber', { number: 1 }) },
  { 'Weight 2': i18n.$t('components.labels.weightNumber', { number: 2 }) },
  { 'Height 1': i18n.$t('components.labels.heightNumber', { number: 1 }) },
  { 'Height 2': i18n.$t('components.labels.heightNumber', { number: 2 }) },
  { 'MUAC 1': i18n.$t('components.labels.muacNumber', { number: 1 }) },
  { 'MUAC 2': i18n.$t('components.labels.muacNumber', { number: 2 }) }
]);

export const enumeratorColumnsDataFields = enumeratorColumns().map(metric => Object.keys(metric)[0].toLowerCase().replace(' ', ''));
