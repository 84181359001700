<template>
  <span>
    <span v-if="isResendAllowed()" style="display: flex; cursor: pointer;" @click="resendInviteWrapper(invitationId)">
      <embed class="mr-4" src="/icon-mail.svg">
      <span style="padding-left: 5px">{{ $t('components.description.resendInvite') }}</span>
    </span>
    <span v-else style="display: flex; cursor: pointer;">
      <span style="color: grey">{{ $tc('components.description.resendInXMinutes', minutesUntilResend) }}</span>
    </span>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CountdownTimer',
  props: {
    resendInvite: {
      type: Function,
      required: true
    },
    resendCooldown: {
      type: String,
      required: true
    },
    invitationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      minutesUntilResend: 30,
      timerID: null
    };
  },
  watch: {
    minutesUntilResend: {
      handler(value) {
        if (this.timerID) {
          clearTimeout(this.timerID);
        }
        if (value > 0) {
          this.timerID = setTimeout(() => {
            this.minutesUntilResend -= 1;
          }, 60 * 1000);
        }
      }
    }
  },
  mounted() {
    this.minutesUntilResend = moment(this.resendCooldown).add(1, 'm').diff(moment(), 'm');
  },
  methods: {
    isResendAllowed() {
      return this.minutesUntilResend <= 0;
    },
    async resendInviteWrapper(invitationId) {
      await this.resendInvite(invitationId);
      this.minutesUntilResend = 30;
    }
  }
};
</script>

<style lang="scss">
</style>
