import { configForPossibleBackendRequest, requestAlertMessage } from '../../util/request';

export default function(Vue) {
  return {
    state: {
      token: null,
      csrf: null,
      expiresAt: null,
      createdAt: null
    },
    mutations: {
    },
    actions: {
      async login({ state, commit }, { email, password, rememberMe }) {
        const baseConfig = {
          data: { email, password, rememberMe },
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          url: '/sessions'
        };
        const axiosConfig = configForPossibleBackendRequest(baseConfig);
        try {
          const result = await Vue.prototype.$http.request(axiosConfig);
          for (const key of Object.keys(result.data)) {
            state[key] = result.data[key];
          }

          await this.dispatch('get', [{
            key: 'currentUser',
            url: '/users/current',
            headers: { Authorization: `Bearer ${state.token}` },
            extended: true,
            success: async () => {
              await commit('setData', { key: 'session', value: state });
            }
          }]);
          return result.data;
        } catch (error) {
          const message = requestAlertMessage(error, (data) => (data.code === 401.2
            ? 'Incorrect email address and/or password.'
            : data.message));
          commit('setAlert', { type: 'danger', message });
          return {};
        }
      }
    }
  };
}
