<!--
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <div ref="app" class="app" :class="[isLoggedOut ? 'isLoggedOut' : '']">
    <!-- Do not show the navbar until the first time a navigation is confirmed.
    The user's session may change during that time, affecting how the navbar is
    rendered. -->
    <LoadingMessage/>
    <alert id="app-alert"/>
    <navbar v-if="firstNavigationConfirmed && !isLoggedOut"/>
    <aside v-show="!isLoggedOut">
      <a-button v-show="!isLoggedOut" type="link" class="handle-trigger" @click="visible = true">
        <a-icon
          type="menu-unfold"
          :style="{ fontSize: '25px', color: 'white' }"/>
      </a-button>
      <main-menu v-show="!isLoggedOut" class="side-bar-fixed"/>
    </aside>
    <main ref="main" class="container-fluid">
      <a-drawer
        placement="left"
        :visible="visible"
        :closable="true"
        @close="visible = false">
        <main-menu v-show="!isLoggedOut"/>
      </a-drawer>
      <a-config-provider :get-popup-container="getPopupContainer">
        <router-view/>
      </a-config-provider>
      <a-back-top :target="() => $refs.main"/>
    </main>
  </div>
</template>

<script>
import Alert from './alert.vue';
import Navbar from './navbar.vue';
import MainMenu from './main-menu.vue';
import LoadingMessage from './loading-message.vue';
import pilotRouteGuard from '../mixins/pilotRouteGuard';

export default {
  name: 'App',
  components: { Alert, Navbar, MainMenu, LoadingMessage },
  mixins: [pilotRouteGuard()],
  data() {
    return {
      visible: false
    };
  },
  computed: {
    // Vue seems to trigger the initial navigation before creating App. If the
    // initial navigation is synchronous, Vue seems to confirm the navigation
    // before creating App. However, if the initial navigation is asynchronous,
    // Vue seems to create App before waiting to confirm the navigation.
    firstNavigationConfirmed() {
      return this.$store.state.router.navigations.first.confirmed;
    },
    /** this method is used to enable/disable the  dashboard view
     * add a component name to the array below to hide the dashboard when visiting that component
    */
    isLoggedOut() {
      if (this.$route.name) {
        return ['SignUp', 'AccountLogin', 'AccountClaim', 'AccountActivate', 'AccountResetPassword', 'SupervisorInvitationError', 'SupervisorInvitationSuccess', 'PrivacyPolicy'].indexOf(this.$route.name) >= 0;
      }

      return true;
    }
  },
  mounted() {
    // The `disabled` class on an <a> element does not prevent keyboard
    // navigation.
    $(this.$refs.app).on('click', 'a.disabled', (event) => {
      event.preventDefault();
    });
  },
  methods: {
    getPopupContainer() {
      return document.querySelector('main');
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/variables';

.app {
  display: grid;
  grid-template-columns: 235px 1fr 1fr 1fr;
  grid-template-rows: 64px 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Nav Nav Nav Nav"
    "Aside Main Main Main"
    "Aside Main Main Main";
  height: 100vh;
  overflow: hidden;

  &.isLoggedOut {
    grid-template-rows: 0 1fr 0;
  }
}

nav {
  grid-area: Nav;
}

aside {
  z-index: 1;
  grid-area: Aside;
}

main {
  grid-area: Main;
  width: 100%;
  overflow: scroll;
  position: relative;
}

.handle-trigger {
  cursor: pointer;
  margin-top: 10px;
  width: 35px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #363534;
  display: none;

  &:hover {
    color: #ccc;
    background: #363534;
  }
}

@media (max-width: 1024px) {
  .app {
    grid-template-columns: 0 1fr 1fr 1fr;
  }

  aside {
    z-index: 1002;
  }

  .handle-trigger {
    display: flex;
  }

  .side-bar-fixed {
    display: none;
  }
}

.ant-drawer {
  z-index: 1003;
}

.ant-drawer-body {
  padding: 0;
  height: 100%;
  width: 100%;

  .sidebar {
    width: 100%;
    padding-top: 30px;
  }
}

#app-alert {
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position : fixed;
  width: 60%;
  margin-left : 20%;
  text-align: center;
  top: 34px;

  // 1 greater than the Bootstrap maximum
  z-index: 1061;

  &.alert-success {
    border-color: $color-success;
  }

  &.alert-info {
    border-color: $color-info;
  }

  &.alert-danger {
    border-color: $color-danger;
  }
}

body.modal-open #app-alert {
  display: none;
}
</style>
