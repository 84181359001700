<!--
Copyright 2017 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Created by</th>
        <th>Last Modified</th>
        <th>Last Submission</th>
      </tr>
    </thead>
    <tbody>
      <form-row v-for="form of forms" :key="form.xmlFormId" :form="form"/>
    </tbody>
  </table>
</template>

<script>
import FormRow from './row.vue';
import { requestData } from '../../store/modules/request';

export default {
  name: 'FormTable',
  components: { FormRow },
  computed: requestData(['forms'])
};
</script>
