<template>
    <div class="pt-24">
      <div>
        <h4 class="mb-22 txt-40 txt-bold txt-font-din-medium">
          {{ $t('components.titles.editAccount') }}
        </h4>
        <div v-if="user != undefined">
          <a-row class="mb-22" type="flex" :gutter="48">
            <a-col class="" :lg="24">
              <user-edit-basic-details/>
            </a-col>
            <a-col class="" :lg="11">
              <user-edit-password v-if="user != null && user.id === currentUser.id"/>
            </a-col>
            <a-col class="" :lg="11">
              <user-edit-avatar v-if="user != null && user.id === currentUser.id"/>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
</template>

<script>

import UserEditBasicDetails from './edit/basic-details.vue';
import UserEditPassword from './edit/password.vue';
import UserEditAvatar from './edit/avatar.vue';
import validateData from '../../../mixins/validate-data';
import { apiPaths } from '../../../util/request';
import { requestData } from '../../../store/modules/request';

export default {
  name: 'UserEdit',
  components: {
    UserEditAvatar,
    UserEditBasicDetails,
    UserEditPassword
  },
  mixins: [validateData()],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: requestData(['user', 'currentUser']),
  watch: {
    $route: 'fetchData'
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('get', [{
        key: 'user',
        url: apiPaths.user(this.id),
        success: ({ currentUser }) => {
          if (this.user.id !== currentUser.id) return;
          this.$store.commit('setData', {
            key: 'currentUser',
            value: currentUser.with(this.user.object)
          });
        }
      }]);
    }
  }
};

</script>
