// eslint-disable-next-line import/prefer-default-export
export const isValidDocFile = (file) => {
  const validTypes = [
    'doc',
    'docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const { type } = file;
  const isDocExtension = file.name.indexOf('doc') > 0;

  if (file.type) {
    return validTypes.includes(type) && isDocExtension;
  }

  return isDocExtension;
};
