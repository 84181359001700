export const label = {
  'srvy-mgr': 'manager',
  'srvy-sup': 'supervisor',
  admin: 'admin'
};

export function getUserRoleSystemOnSurvey(survey, isAdmin) {
  const currentUserAssignment = survey?.userAssignments?.find(assignment => assignment.isCurrentUser);
  if (currentUserAssignment) {
    return currentUserAssignment.roleSystem;
  }
  return isAdmin ? 'admin' : null;
}

export const getRoleLabelOnSurvey = (roleSystem) => {
  if (!roleSystem) return null;
  return { system: roleSystem, value: label[roleSystem], label: this.$t(`components.labels.${label[roleSystem]}`) };
};

export function getUserRoleOnSurvey(survey, isAdmin) {
  const currentUserRoleSystem = getUserRoleSystemOnSurvey(survey, isAdmin);
  if (currentUserRoleSystem) {
    return { system: currentUserRoleSystem, value: label[currentUserRoleSystem], label: this.$t(`components.labels.${label[currentUserRoleSystem]}`) };
  }
  return null;
}

export const getRoleIconOnSurvey = (roleSystem) => {
  if (!roleSystem) return null;
  const icon = {
    'srvy-mgr': '/pencil-circle.svg',
    'srvy-sup': '/supervisor-eye-icon.svg',
    admin: '/pencil-circle.svg'
  };
  return icon[roleSystem];
};

export function getUserRoleIconOnSurvey(survey, isAdmin) {
  const currentUserRoleSystem = getUserRoleSystemOnSurvey(survey, isAdmin);
  if (currentUserRoleSystem) {
    return getRoleIconOnSurvey(currentUserRoleSystem);
  }
  return null;
}
