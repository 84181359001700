// Copyright 2020 SMART Methodology
// Copyright 2019 ODK Central Developers
// See the NOTICE file at the top-level directory of this distribution and at
// https://github.com/getodk/central-frontend/blob/master/NOTICE.

// NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
// APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
// IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

// This file is part of ODK Central. It is subject to the license terms in
// the LICENSE file found in the top-level directory of this distribution and at
// https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
// including this file, may be copied, modified, propagated, or distributed
// except according to the terms contained in the LICENSE file.

import { DateTime } from 'luxon';
import moment from 'moment';

const MONTH_TO_AGE_RATIO = 30.4375;
export const ago = (duration) => DateTime.local().minus(duration);

const formatDatePart = (dateTime) => {
  const now = DateTime.local();
  if (now.hasSame(dateTime, 'day')) return 'Today';
  if (now.minus({ days: 1 }).hasSame(dateTime, 'day')) return 'Yesterday';
  for (let i = 2; i <= 5; i += 1)
    if (now.minus({ days: i }).hasSame(dateTime, 'day'))
      return dateTime.toFormat('cccc');
  return dateTime.toFormat('y/MM/dd');
};
export const formatDate = (isoString, blankString = '') => {
  if (isoString == null) return blankString;
  const dateTime = DateTime.fromISO(isoString);
  if (!dateTime.isValid) throw new Error(dateTime.invalidReason);
  const datePart = formatDatePart(dateTime);
  const timePart = dateTime.toFormat('HH:mm');
  return `${datePart} ${timePart}`;
};

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const generateYears = (ahead = 30) => {
  const currentYear = new Date().getFullYear();
  return new Array(ahead).fill(0)
    .map((value, index) => (currentYear + index));
};

// https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
export const xlSerialToJsDate = (xlSerial) => {
  // milliseconds since 1899-31-12T00:00:00Z, corresponds to xl serial 0.
  const xlSerialOffset = -2209075200000;

  let elapsedDays;
  // each serial up to 60 corresponds to a valid calendar date.
  // serial 60 is 1900-02-29. This date does not exist on the calendar.
  // we choose to interpret serial 60 (as well as 61) both as 1900-03-01
  // so, if the serial is 61 or over, we have to subtract 1.
  if (xlSerial < 61) {
    elapsedDays = xlSerial;
  } else {
    elapsedDays = xlSerial - 1;
  }

  // javascript dates ignore leap seconds
  // each day corresponds to a fixed number of milliseconds:
  // 24 hrs * 60 mins * 60 s * 1000 ms
  const millisPerDay = 86400000;

  const jsTimestamp = xlSerialOffset + elapsedDays * millisPerDay;
  return new Date(jsTimestamp);
};

export const validDateFormat = (date, formats) => moment(date, formats, true).isValid();

export const isAfterDate = (date, afterDate) => moment(date).isAfter(afterDate);

export const dateFormats = [
  'YYYY-M-D', 'YYYY/M/D', 'YYYY M D', 'YYYYMD', 'YYYY.M.D',
  'YYYY-M-D HH:mm', 'YYYY/M/D HH:mm', 'YYYY M D HH:mm', 'YYYYMD HH:mm', 'YYYY.M.D HH:mm',
  'M-D-YY', 'M/D/YY', 'M D YY', 'MDYY', 'M.D.YY',
  'MM/DD/YYYY', 'MM/DD/YYYY',
  'DD/M/YYYY', 'DD/MM/YYYY',
  'DD-MM-YYYY', 'D-M-YY', 'D/M/YY', 'D M YY', 'DMYY', 'D.M.YY',
  'M-D-YYYY', 'M/D/YYYY', 'M D YYYY', 'MDYYYY', 'M.D.YYYY'
];

// new Date() creates a date in our CURRENT TIMEZONE while Excel serial is in UTC.
export const formatSmartDates = (date, format = 'YYYY-M-D') => {
  if (!date || date === '') return '';

  if (typeof date === 'number') {
    return (date ? moment.utc(xlSerialToJsDate(date)).format(format) : '');
  }

  if (!date) {
    return '';
  }

  for (const curFormat of dateFormats) {
    const curDate = moment(date, curFormat, true);
    if (curDate.isValid()) {
      return curDate.format(format);
    }
  }
  return 'Invalid Date';
};

export const calculateNewAge = (survDate, birthDate, format = 'YYYY-M-D') => {
  if (!birthDate || birthDate === '') return '';
  if (!survDate || !birthDate || survDate === '' || birthDate === '') return null;

  const end = moment(survDate, format);
  const start = moment(birthDate, format);
  if (end && start) {
    const monthCalculation = end.diff(start, 'days') / MONTH_TO_AGE_RATIO;
    return monthCalculation.toFixed(2);
  }
  return null;
};
