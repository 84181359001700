export const RADIUS_OF_THE_EARTH = 6371.071; // Radius of the Earth in kilometers

export const haversineDistance = (latitude1, longitude1, latitude2, longitude2) => {
  const rlat1 = latitude1 * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = latitude2 * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (longitude2 - longitude1) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    RADIUS_OF_THE_EARTH *
    Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
  return d;
};
