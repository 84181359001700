import axios from 'axios';
import { configForPossibleBackendRequest } from '../../util/request';

export default function(Vue) {
  const defaultState = {
    showPlausibilityModal: false,
    plausibilityRequestTime: 0,
    plausibilityRequestTimeIntervalId: null,

    anthroPlausibilityIsFetching: false,
    mortalityPlausibilityIsFetching: false,
    anthroPlausibilityBlob: undefined,
    anthroPlausibilityAbortController: undefined,
    mortalityPlausibilityBlob: undefined,
    mortalityPlausibilityAbortController: undefined,
    dashboardTeams: null,
    dashboardClusters: null,
    dashboardFromDate: null,
    dashboardToDate: null,
    error: undefined
  };

  return {
    state: { ...defaultState },
    mutations: {
      setAnthroPlausibilityIsFetching(state, status) {
        state.anthroPlausibilityIsFetching = status;
      },
      setAnthroPlausibilityBlob(state, blob) {
        state.anthroPlausibilityBlob = blob;
      },
      setAnthroPlausibilityAbortController(state, controller) {
        state.anthroPlausibilityAbortController = controller;
      },
      setMortalityPlausibilityIsFetching(state, status) {
        state.mortalityPlausibilityIsFetching = status;
      },
      setMortalityPlausibilityBlob(state, blob) {
        state.mortalityPlausibilityBlob = blob;
      },
      setMortalityPlausibilityAbortController(state, controller) {
        state.mortalityPlausibilityAbortController = controller;
      },
      setDashboardTeams(state, teams) {
        state.dashboardTeams = teams;
      },
      setDashboardClusters(state, clusters) {
        state.dashboardClusters = clusters;
      },
      setDashboardFromDate(state, fromDate) {
        state.dashboardFromDate = fromDate;
      },
      setDashboardToDate(state, toDate) {
        state.dashboardToDate = toDate;
      },
      setError(state, error) {
        state.error = error;
      }
    },
    getters: {
      anthroPlausibilityBlob: (state) => state.anthroPlausibilityBlob,
      anthroPlausibilityAbortController: (state) => state.anthroPlausibilityAbortController,
      mortalityPlausibilityBlob: (state) => state.mortalityPlausibilityBlob,
      mortalityPlausibilityAbortController: (state) => state.mortalityPlausibilityAbortController,
      anthroPlausibilityIsFetching: (state) => state.anthroPlausibilityIsFetching,
      mortalityPlausibilityIsFetching: (state) => state.mortalityPlausibilityIsFetching,
      dashboardTeams: (state) => state.dashboardTeams,
      dashboardClusters: (state) => state.dashboardClusters,
      dashboardFromDate: (state) => state.dashboardFromDate,
      dashboardToDate: (state) => state.dashboardToDate
    },
    actions: {
      async getSurveyAnthroPlausibility({ getters, commit, state }, { projectId, surveyId, onOriginalData = false, lang }) {
        const filters = {
          group: 'all',
          type: 'whz',
          exclusion: 'no_exclusions',
          distribution: 'gaussian',
          params: {
            exclusions: 'no_exclusions'
          }
        };
        commit('setAnthroPlausibilityBlob', undefined);
        commit('setAnthroPlausibilityIsFetching', true);
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const newController = new AbortController();
          commit('setAnthroPlausibilityAbortController', newController);

          const axiosConfig = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/anthro-plausibility`,
            data: filters,
            params: {
              lang,
              onOriginalData,
              ...(onOriginalData && state.dashboardFromDate && {
                from: state.dashboardFromDate
              }),
              ...(onOriginalData && state.dashboardToDate && {
                to: state.dashboardToDate
              }),
              ...(onOriginalData && state.dashboardTeams && {
                team: state.dashboardTeams
              }),
              ...(onOriginalData && state.dashboardClusters && {
                cluster: state.dashboardClusters
              })
            },
            responseType: 'blob',
            signal: newController.signal
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            const blob = new Blob([data], { type: data.type });
            commit('setAnthroPlausibilityBlob', blob);
            return true;
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
              return true; // so we don't show the error message.
            }
            const err = await error.response.data.text();
            commit('setError', JSON.parse(err));
          } finally {
            commit('setAnthroPlausibilityIsFetching', false);
          }
        }
        return false;
      },
      async cancelSurveyAnthroPlausibilityRequest({ getters, commit }) {
        if (getters.anthroPlausibilityAbortController && !getters.anthroPlausibilityAbortController.signal.aborted) {
          getters.anthroPlausibilityAbortController.abort();
        }
        commit('setAnthroPlausibilityBlob', undefined);
      },
      async getSurveyMortalityPlausibility({ getters, commit, state }, { projectId, surveyId, onOriginalData = false, lang }) {
        commit('setMortalityPlausibilityBlob', undefined);
        commit('setMortalityPlausibilityIsFetching', true);
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const newController = new AbortController();
          commit('setMortalityPlausibilityAbortController', newController);

          const axiosConfig = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/mortality-plausibility`,
            responseType: 'blob',
            params: {
              lang,
              onOriginalData,
              ...(onOriginalData && state.dashboardFromDate && {
                from: state.dashboardFromDate
              }),
              ...(onOriginalData && state.dashboardToDate && {
                to: state.dashboardToDate
              }),
              ...(onOriginalData && state.dashboardTeams && {
                team: state.dashboardTeams
              }),
              ...(onOriginalData && state.dashboardClusters && {
                cluster: state.dashboardClusters
              })
            },
            signal: newController.signal
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            const blob = new Blob([data], { type: data.type });
            commit('setMortalityPlausibilityBlob', blob);
            return true;
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
              return true; // so we don't show the error message.
            }
            commit('setError', error);
          } finally {
            commit('setMortalityPlausibilityIsFetching', false);
          }
        }
        return false;
      },
      async cancelSurveyMoralityPlausibilityRequest({ getters, commit }) {
        if (getters.mortalityPlausibilityAbortController && !getters.mortalityPlausibilityAbortController.signal.aborted) {
          getters.mortalityPlausibilityAbortController.abort();
        }
        commit('setMortalityPlausibilityBlob', undefined);
      },
      setDashboardTeams({ commit }, teams) {
        commit('setDashboardTeams', teams);
      },
      setDashboardClusters({ commit }, clusters) {
        commit('setDashboardClusters', clusters);
      },
      setDashboardFromDate({ commit }, date) {
        commit('setDashboardFromDate', date);
      },
      setDashboardToDate({ commit }, date) {
        commit('setDashboardToDate', date);
      }
    }
  };
}
