import axios from 'axios';
import { apiPaths, configForPossibleBackendRequest } from '../../util/request';

export default function(Vue) {
  const defaultState = {
    averageTimePerHousehold: [],
    householdAnalysisData: [],
    childrenMeasured: 0,
    clusterCompleted: 0,
    dashboardAnalysisCallsBeingLoaded: 0,
    dataQualitySnapshotResultData: {},
    dashboardAnalysisDataIsLoading: true,
    dashboardDataQualityIsLoading: true,
    dashboardAnalysisRequestAbortController: null,
    dashboardDataQualityRequestAbortController: null,
    error: null
  };

  return {
    state: {
      ...defaultState
    },
    mutations: {
      setAverageTimePerHousehold(state, averageTimePerHousehold) {
        state.averageTimePerHousehold = averageTimePerHousehold;
      },
      setHouseholdAnalysisData(state, householdAnalysisData) {
        state.householdAnalysisData = householdAnalysisData;
      },
      setChildrenMeasured(state, childrenMeasured) {
        state.childrenMeasured = childrenMeasured;
      },
      setClusterCompleted(state, clusterCompleted) {
        state.clusterCompleted = clusterCompleted;
      },
      setDataQualitySnapshotResultData(state, data) {
        state.dataQualitySnapshotResultData = data;
      },
      startLoading(state) {
        state.dashboardAnalysisCallsBeingLoaded += 1;
      },
      stopLoading(state) {
        state.dashboardAnalysisCallsBeingLoaded -= 1;
      },
      setError(state, err) {
        state.error = err;
      },
      setDashboardAnalysisDataIsLoading(state, isLoading) {
        state.dashboardAnalysisDataIsLoading = isLoading;
      },
      setDashboardDataQualityIsLoading(state, isLoading) {
        state.dashboardDataQualityIsLoading = isLoading;
      },
      setDashboardAnalysisRequestAbortController(state, controller) {
        state.dashboardAnalysisRequestAbortController = controller;
      },
      setDashboardDataQualityRequestAbortController(state, controller) {
        state.dashboardDataQualityRequestAbortController = controller;
      }
    },
    actions: {
      async loadDashboardAnalysis({ commit, getters, state }, { surveyId, projectId, fromDate = null, toDate = null, teams = null, clusters = null, silentFetch = false }) {
        commit('startLoading');
        const token = getters.loggedIn ? getters.token : null;
        const dashboardFromDate = fromDate || getters.dashboardFromDate;
        const dashboardToDate = toDate || getters.dashboardToDate;
        const dashboardTeams = teams || getters.dashboardTeams;
        const dashboardClusters = clusters || getters.dashboardClusters;
        if (state.dashboardAnalysisRequestAbortController?.signal && !state.dashboardAnalysisRequestAbortController?.signal?.aborted) {
          state.dashboardAnalysisRequestAbortController.abort();
        }
        const newController = new AbortController();
        commit('setDashboardAnalysisRequestAbortController', newController);
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.dashboardAnalysis(projectId, surveyId),
          params: {
            ...(dashboardFromDate && {
              from: dashboardFromDate
            }),
            ...(dashboardToDate && {
              to: dashboardToDate
            }),
            ...(dashboardTeams && {
              teams: dashboardTeams
            }),
            ...(dashboardClusters && {
              clusters: dashboardClusters
            })
          }
        };

        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
        if (!silentFetch) {
          commit('setDashboardAnalysisDataIsLoading', true);
        }
        try {
          const response = await Vue.prototype.$http.request({ ...apiConfig, signal: newController.signal });
          const { averageTimePerHousehold, householdAnalysisData, childrenMeasured, clusterReached } = response.data;

          commit('setAverageTimePerHousehold', averageTimePerHousehold);
          commit('setHouseholdAnalysisData', householdAnalysisData);
          commit('setChildrenMeasured', childrenMeasured);
          commit('setClusterCompleted', clusterReached);
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit('setError', error);
            commit('setAverageTimePerHousehold', []);
            commit('setHouseholdAnalysisData', []);
            commit('setChildrenMeasured', 0);
            commit('setClusterCompleted', 0);
          }
        } finally {
          commit('stopLoading');
          commit('setDashboardAnalysisDataIsLoading', false);
        }
      },
      async loadDataQualityAnalysis({ commit, getters, state }, { surveyId, projectId, fromDate = null, toDate = null, teams = null, clusters = null, silentFetch = false }) {
        commit('startLoading');
        const dashboardFromDate = fromDate || getters.dashboardFromDate;
        const dashboardToDate = toDate || getters.dashboardToDate;
        const dashboardTeams = teams || getters.dashboardTeams;
        const dashboardClusters = clusters || getters.dashboardClusters;
        if (state.dashboardDataQualityRequestAbortController?.signal && !state.dashboardDataQualityRequestAbortController?.signal?.aborted) {
          state.dashboardDataQualityRequestAbortController.abort();
        }
        const newController = new AbortController();
        commit('setDashboardDataQualityRequestAbortController', newController);
        const axiosConfig = {
          method: 'GET',
          url: apiPaths.dataQualityAnalyis(projectId, surveyId),
          params: {
            ...(dashboardFromDate && {
              from: dashboardFromDate
            }),
            ...(dashboardToDate && {
              to: dashboardToDate
            }),
            ...(dashboardTeams && {
              teams: dashboardTeams
            }),
            ...(dashboardClusters && {
              clusters: dashboardClusters
            })
          }
        };
        const token = getters.loggedIn ? getters.token : null;
        const apiConfig = configForPossibleBackendRequest(axiosConfig, token);
        if (!silentFetch) {
          commit('setDashboardDataQualityIsLoading', true);
        }
        try {
          const response = await Vue.prototype.$http.request({ ...apiConfig, signal: newController.signal });
          const result = response.data;

          commit('setDataQualitySnapshotResultData', result);
        } catch (error) {
          if (!axios.isCancel(error)) {
            commit('setDataQualitySnapshotResultData', {});
            commit('setError', error);
          }
        } finally {
          commit('stopLoading');
          commit('setDashboardDataQualityIsLoading', false);
        }
      },
      setDashboardAnalysisDataIsLoading({ commit }, isLoading) {
        commit('setDashboardAnalysisDataIsLoading', isLoading);
      },
      setDashboardDataQualityIsLoading({ commit }, isLoading) {
        commit('setDashboardDataQualityIsLoading', isLoading);
      }
    }
  };
}
