// Copyright 2020 SMART Methodology
// Copyright 2019 ODK Central Developers
// See the NOTICE file at the top-level directory of this distribution and at
// https://github.com/getodk/central-frontend/blob/master/NOTICE.

// NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
// APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
// IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

// This file is part of ODK Central. It is subject to the license terms in
// the LICENSE file found in the top-level directory of this distribution and at
// https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
// including this file, may be copied, modified, propagated, or distributed
// except according to the terms contained in the LICENSE file.

import Vuex from 'vuex';
import Vue from 'vue';
import alert from './modules/alert';
import modal from './modules/modal';
import request from './modules/request';
import router from './modules/router';
import survey from './modules/survey';
import results from './modules/results';
import plausibility from './modules/plausibility';
import session from './modules/session';
import indicators from './modules/indicators';
import dashboardAnalysis from './modules/dashboard-analysis';
import standardizationGroups from './modules/standardization-groups';
import surveyForms from './modules/survey-forms';
import surveyReport from './modules/survey-report';

export function createStore(vuex, vue) {
  let _vue;

  if (vue !== undefined) {
    _vue = vue;
  } else {
    _vue = Vue;
  }

  return new vuex.Store({
    modules: {
      alert,
      modal,
      request: request(_vue),
      results: results(_vue),
      router,
      session: session(_vue),
      plausibility: plausibility(_vue),
      survey: survey(_vue),
      indicators: indicators(_vue),
      dashboardAnalysis: dashboardAnalysis(_vue),
      standardizationGroups: standardizationGroups(_vue),
      surveyForms: surveyForms(_vue),
      surveyReport: surveyReport(_vue)
    }
  });
}

export default createStore(Vuex);
