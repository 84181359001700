<template>
  <div class="pt-12 pb-24">
    <a-spin :spinning="loading !== false" size="large">
      <div v-if="!groupSelected">
        <h4 class="mt-24 mb-22 txt-40 txt-bold txt-font-din-medium">
          {{ $t('components.titles.enumerators') }} ({{ enumerators.length }})
        </h4>
        <div class="mb-20 flex">
          <div v-if="!standardizationComplete" class="flex">
            <div class="step-current"><p>1</p></div>
            <p class="text-current-step">{{ $t('components.titles.standardizationTest') }}</p>
          </div>
          <div v-else class="flex pointer" @click="backToGroups">
            <div class="step-completed txt-bold"><img src="tick.svg"></div>
            <p class="text-not-selected-step">{{ $t('components.titles.standardizationTest') }}</p>
          </div>
          <div v-if="!standardizationComplete" class="flex ml-20">
            <div class="step-not-selected txt-bold"><p>2</p></div>
            <p class="text-not-selected-step">{{ $t('components.titles.assignTeamsRoles') }}</p>
          </div>
          <div v-else class="flex ml-20">
            <div class="step-current txt-bold"><p>2</p></div>
            <p class="text-current-step">{{ $t('components.titles.assignTeamsRoles') }}</p>
          </div>
        </div>
        <div class="flex justify-space-between align-center">
          <AddStandardizationGroup v-if="!standardizationComplete" class="mb-22"/>
          <div class="finish-button-containers">
            <a-button
              v-if="!standardizationComplete"
              :disabled="!someGroupFinished"
              class="ml-20 w170"
              type="primary"
              size="large"
              data-cy="proceed-button-stdization"
              @click="proceedToTeams">
              {{ $t('values.proceed') }}
            </a-button>
            <a-button
              v-else
              class="ml-20 w170"
              :disabled="isSupervisor"
              type="primary"
              size="large"
              @click="finish">
              {{ $t('values.finish') }}
            </a-button>
          </div>
        </div>
        <StandardizationGroup
          v-if="!standardizationComplete"
          :current="current" :steps="steps"/>
        <EnumeratorTeams
          v-else
          :key="lastUpdated.toString()" :enumerators="enumerators"/>
      </div>
    </a-spin>
    <FinishSurveyCreationModal v-if="finishModalActive" :on-exit="onFinishExit"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import project from '../../../mixins/project';
import enumerators from '../../../mixins/enumerators';

export default {
  name: 'Enumerators',
  components: {
    AddStandardizationGroup: () => import('./StandardizationTest/add-standardization-group.vue'),
    StandardizationGroup: () => import('./StandardizationTest/standardization-group-list.vue'),
    EnumeratorTeams: () => import('./EnumeratorTeams/enumerator-teams.vue'),
    FinishSurveyCreationModal: () => import('./FinishSurveyCreationModal/finish-survey-creation-modal.vue')
  },
  mixins: [project(), enumerators()],
  data() {
    return {
      current: 0,
      groupSelected: null,
      finishModalActive: false
    };
  },
  computed: {
    ...mapState({
      project: state => state.survey.project,
      surveyId: state => state.survey.surveyId,
      standardizationComplete: state => state.survey.standardizationComplete,
      standardizationReport: state => state.standardizationGroups.standardizationReport,
      lastUpdated: state => state.standardizationGroups.lastUpdated,
      standardizationGroupMap: state => state.standardizationGroups.standardizationGroupMap,
      projectId: state => state.survey.project.id,
      loading: state => state.survey.loading,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    ...mapGetters(['enumeratorsForGroupId']),
    someGroupFinished() {
      return Object.values(this.standardizationGroupMap).some(group =>
        group?.standardizationStatus === 'marked_as_finished');
    },
    steps() {
      return [
        {
          title: this.$t('components.titles.standardizationTest')
        },
        {
          title: this.$t('components.titles.assignTeamsRoles')
        }
      ];
    }
  },
  watch: {
    surveyId: {
      handler() {
        if (this.surveyId) {
          this.getStandardizationGroupsForSurvey({ surveyId: this.surveyId });
        }
        this.fetchPageData();
      }
    }
  },
  mounted() {
    if (this.surveyId) {
      this.getStandardizationGroupsForSurvey({ surveyId: this.surveyId });
    }
    this.fetchPageData();
  },
  methods: {
    ...mapActions(['getStandardization', 'setStandardizationComplete', 'finishStandardization', 'getStandardizationGroupsForSurvey']),
    fetchPageData() {
      if (this.project && this.project.id && this.surveyId) {
        this.getStandardization({
          projectId: this.project.id,
          surveyId: this.surveyId
        });
      }
    },
    proceedToTeams() {
      this.setStandardizationComplete({ status: true, shouldSaveToDB: !this.isSupervisor });
    },
    backToGroups() {
      this.setStandardizationComplete({ status: false, shouldSaveToDB: !this.isSupervisor });
    },
    finish() {
      this.finishModalActive = true;
      this.finishStandardization({
        projectId: this.projectId,
        surveyId: this.surveyId
      });
    },
    onFinishExit() {
      this.finishModalActive = false;
    }
  }
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';

.text-current-step {
  color: $color-accent-primary;
  font-size: 1.2em;
  padding: 11px 9px;
}
.text-not-selected-step {
  color: #363534;
  font-size: 1.2em;
  padding: 11px 9px;
}
.step-current {
  width: 42px;
  height: 42px;
  flex-grow: 0;
  text-align: center;
  background-color: #e98300;
  border-radius: 50%;
  color: white;
  font-size: 1.2em;
  padding-top: 11px;
}
.step-not-selected {
  width: 42px;
  height: 42px;
  flex-grow: 0;
  text-align: center;
  border-radius: 50%;
  padding-top: 11px;
  color: #363534;
  border: solid 1px #363534;
}
.step-completed {
  width: 42px;
  height: 42px;
  flex-grow: 0;
  text-align: center;
  border-radius: 50%;
  padding-top: 11px;
  background-color: #363534;
}
.pointer {
  cursor: pointer;
}
.finish-button-containers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
