<template>
  <a-spin :spinning="loading !== false" size="large" class="pt-24" :class="{ 'disabled' : disabled }">
    <MetaDataAlerts/>
    <a-row class="w350 mb-40 mt-4">
      <SurveyName/>
    </a-row>
    <a-form layout="vertical" :form="form" :hide-required-mark="true">
      <MetadataAdministrative :form="form"/>
      <MetadataTechnicals :form="form" :project="currentProject" :metadata="metadata"/>
      <MetadataGeographicInformation ref="geographicArea" v-model="geographicArea" @update-geographic-information="updateGeographicInformation"/>
      <MetadataLogistics :metadata="metadata"/>
    </a-form>
    <a-button
        class="mt-10 mb-24 w170 flex align-center justify-center"
        type="primary"
        size="large"
        @click="goNext">
      {{ $t('values.next') }}
      <a-icon type="right" class="txt-10"/>
    </a-button>
  </a-spin>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import project from '../../../mixins/project';
import request from '../../../mixins/request';
import SurveyName from './survey-name/index.vue';
import MetadataAdministrative from './administrative/administrative.vue';
import MetadataLogistics from './logistics/logistics.vue';
import MetadataTechnicals from './technical/technical.vue';
import MetadataGeographicInformation from './geographic-information/geographic-information.vue';
import isSurveyActive from '../../../mixins/isSurveyActive';
import MetaDataAlerts from '../../qr-regeneration-warning/metadata.alerts.vue';

export default {
  name: 'Metadata',
  components: {
    SurveyName,
    MetadataAdministrative,
    MetadataLogistics,
    MetadataTechnicals,
    MetaDataAlerts,
    MetadataGeographicInformation
  },
  mixins: [project(), request(), isSurveyActive()],
  data() {
    return {
      isStateSynced: false,
      geographicArea: undefined,
      geographicInformation: undefined,
      form: this.$form.createForm(this, {
        name: 'create_metadata',
        // eslint-disable-next-line func-names
        onValuesChange: debounce((props, values) => {
          this.setDirty();
          if (this.metadata && this.isStateSynced) {
            const geographicArea = {
              ...this.geographicArea,
              geographicCountry: (this.geographicArea &&
                this.geographicArea.geographicCountry) || this.metadata.geographicCountry
            };
            this.setMetadata({
              ...this.metadata,
              ...values,
              ...this.geographicArea ? geographicArea : {},
              geographicInformation: this.geographicInformation
            });
          } else if (!this.isStateSynced) {
            const geographicArea = {
              ...this.geographicArea,
              geographicCountry: (this.geographicArea &&
                this.geographicArea.geographicCountry) || this.metadata.geographicCountry
            };
            this.isStateSynced = true;
          } else {
            const surveyEquipment = values.surveyEquipment || 'Manual Measurement Tools';
            this.setMetadata({
              surveyEquipment,
              ...values
            });
          }
        }, 500)
      })
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      loading: state => state.survey.loading,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    metadata: {
      deep: true,
      handler() {
        if (this.isStateSynced) {
          return;
        }
        if (!this.metadata) {
          return;
        }
        const fields = pick(this.metadata, Object.keys(this.form.domFields));
        const share = this.metadata?.share?.toString() || 'true';
        this.form.setFieldsValue({
          ...fields,
          share
        });
        this.form.validateFields();
      }
    },
    currentProject: {
      deep: true,
      handler() {
        if (this.currentProject) {
          this.name = this.currentProject._data.name;
        }
      }
    },
    geographicArea: {
      deep: true,
      handler() {
        if (!this.isStateSynced) {
          return;
        }
        if (this.metadata.latitude !== this.geographicArea?.latitude ||
          this.metadata.longitude !== this.geographicArea?.longitude ||
          this.metadata.geographicAreaName !== this.geographicArea.geographicAreaName) {
          this.setMetadata({
            ...this.metadata,
            ...this.geographicArea ? this.geographicArea : {},
            geographicInformation: this.geographicInformation
          });
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'setMetadata',
      'setDirty'
    ]),
    getAlertIndex(id) {
      const index = this.activeAlerts.findIndex(item => item.id === id);
      return index;
    },
    updateGeographicInformation(geographicInformation) {
      this.geographicInformation = geographicInformation;
    },
    goNext() {
      if (this.isSupervisor) {
        this.$router.push('/survey-planning/cluster-sampling');
        return;
      }
      this.form.validateFields(async (err, values) => {
        if (!this.geographicArea) {
          this.$refs.geographicArea.setError(true);
          return;
        }

        if (!err) {
          this.setMetadata({
            ...this.metadata,
            ...values,
            ...this.geographicArea ? this.geographicArea : {},
            geographicInformation: this.geographicInformation
          });
          this.$router.push('/survey-planning/cluster-sampling');
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
