<template>
  <a-modal
    width="495px"
    class="project-onboarding-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="visible"
    :closable="true"
    @cancel="onExit">
    <h1 class="flex align-center justify-center txt-30 txt-bold txt-font-din-medium">
      {{ $t('components.titles.leavingThisSurvey') }}
    </h1>
    <p class="txt-16">
      {{ $t('components.description.youAreAboutToChangeSurveys') }}
    </p>
    <div class="mt-24 flex justify-space-between">
      <a-button
        class="w200"
        type="primary"
        size="large"
        ghost
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="w200"
        type="primary"
        size="large"
        @click="onComplete">
        {{ $t('values.continue') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'LeaveSurveyModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss">
</style>
