<!--
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->


<template>
  <div class="center-div login-div-container">
    <a-row id="account-login-container" type="flex">
      <div class="hidden-xs img-panel col-lg-6 col-md-6">
        <img class="side-pic hidden-xs" src="/login-banner.png">
      </div>
      <a-col class="login-panel" flex="auto">
        <img class="login-logo" src="logo.svg">
        <div class="font-med welcome">
          {{ $t('components.titles.welcomeToSmartPlus') }}
        </div>

        <div class="font-reg continue">
          {{ $t('components.titles.loginToContinue') }}
        </div>

        <div class="form">
          <div class="panel-body">
            <form @submit.prevent="submit">
              <fieldset>
                <div class="input-border email-border">
                  <div class="input-label email font-med min-width-fit-content">
                    {{ $t('components.titles.emailAddress') }}
                  </div>
                  <input
                      ref="email"
                      v-model.trim="email"
                      type="email"
                      class="form-control info-input"
                      :placeholder="$t('components.titles.enterEmailAddress')"
                      required
                      autocomplete="off">
                </div>
              </fieldset>

              <fieldset>
                <div class="input-border password-border">
                  <div class="input-label password font-med min-width-fit-content">
                    {{ $t('components.titles.password') }}
                  </div>
                  <div class="input-group">
                    <input
                        ref="password"
                        v-model="password"
                        data-cy="password"
                        :type="passwordFieldType"
                        class="form-control info-input"
                        :placeholder="$t('components.titles.enterPassword')"
                        required
                        autocomplete="current-password">
                    <span
                        id="basic-addon1"
                        class="input-group-addon eye-styling"
                        data-cy="see-password-button"
                        @click="togglePasswordFieldType">
                      <a-icon type="eye" :style="{ fontSize: '16px', color: '#b2b3b2' }" :class="showPassword"/>
                      <a-icon type="eye-invisible" :style="{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.45)' }" :class="hidePassword"/>
                  </span>
                  </div>
                </div>
              </fieldset>
              <div class="row">
                <div id="remember-me-col" class="col-xs-6">
                  <a-checkbox id="checkbox" @change="onCheckChange">
                    <span class="remember">{{ $t('components.titles.rememberMe') }}</span>
                  </a-checkbox>
                </div>

                <div id="forgot-password-col" class="col-xs-6">
                  <router-link id="forgot-password" :to="resetPasswordLocation"
                               :disabled="disabled">
                    {{ $t('components.titles.forgotPassword') }}
                  </router-link>
                </div>
              </div>

              <div class="footer-login">
                <div>
                  <button class="login-button btn btn-primary orange" type="submit" :disabled="disabled">
                    {{ $t('components.labels.login') }}
                    <spinner :state="disabled"/>
                  </button>
                </div>
                <div class="sign-up">
                  {{ $t('components.titles.dontHaveAnAccount') }}
                  <router-link id="register-link" :to="registerLocation"
                               :disabled="disabled">
                  {{ $t('components.titles.signUp') }}
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { requestData } from '@/store/modules/request';
import Spinner from '../spinner.vue';
import request from '../../mixins/request';
import { setUserSurvey } from '../../util/util';

export default {
  name: 'AccountLogin',
  components: { Spinner },
  mixins: [request()],
  data() {
    return {
      disabled: false,
      email: '',
      password: '',
      passwordFieldType: 'password',
      passwordVisibilityIconPath: '/eye-blocked.svg',
      showPassword: 'hide',
      hidePassword: 'show',
      rememberMe: false
    };
  },
  computed: {
    ...mapGetters([
      'surveyCompletePercent'
    ]),
    ...requestData(['currentUser']),
    resetPasswordLocation() {
      return {
        path: '/reset-password',
        query: Object.assign({}, this.$route.query)
      };
    },
    registerLocation() {
      return {
        path: '/sign-up',
        query: Object.assign({}, this.$route.query)
      };
    }
  },
  mounted() {
    let { email } = this.$route.query;
    if (email != null) {
      email = decodeURIComponent(email);
      this.email = email;
      this.$refs.password.focus();
    } else {
      this.$refs.email.focus();
    }
    //dismiss toast message if one is visible
    this.dismissToastMessage(5000);
  },
  beforeRouteLeave(to, from, next) {
    if (this.disabled) {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    /**
     * dismiss the toast message
     * @params {number} delayInMilliseconds wait this many milliseconds before dismissing
     * the toast message. The message will be dismissed immediately if this number isn't given
     */
    dismissToastMessage(delayInMilliseconds) {
      if (delayInMilliseconds >= 1) {
        setTimeout(() => {
          this.$store.commit('hideAlert');
        }, delayInMilliseconds);
      } else {
        this.$store.commit('hideAlert');
      }
    },
    onCheckChange() {
      this.rememberMe = !this.rememberMe;
    },
    togglePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.passwordVisibilityIconPath = this.passwordVisibilityIconPath === '/eye.svg' ? 'eye-blocked.svg' : '/eye.svg';
      this.showPassword = this.showPassword === 'show' ? 'hide' : 'show';
      this.hidePassword = this.hidePassword === 'hide' ? 'show' : 'hide';
    },
    nextPath() {
      let { next } = this.$route.query;
      const { selectedSurveyId } = this.$route.query;
      const { id } = this.currentUser;
      if (next == null) {
        if (this.surveyCompletePercent >= 25) return '/';
        next = '/survey-planning/metadata';
      }
      if (selectedSurveyId !== null) {
        setUserSurvey(id, selectedSurveyId);
        next = '/surveys';
      }
      const link = document.createElement('a');
      link.href = next;
      return link.host === window.location.host ? link.pathname : '/';
    },
    routeToNext() {
      const query = Object.assign({}, this.$route.query);
      delete query.next;
      delete query.selectedSurveyId;
      delete query.email;
      this.$router.push({
        path: this.nextPath(),
        query
      });
    },
    submit() {
      this.disabled = true;

      return this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      })
        .finally(() => {
          this.$gtag.event('login');
          this.disabled = false;
        })
        .then(async () => {
          this.routeToNext();
        })
        .catch(() => {
          //dismiss the error toast message
          this.dismissToastMessage(5000);
        });
    }
  }
};
</script>

<style scoped>
#remember-me-label {
  padding-left: 1em;
}

.img-panel {
  padding: 0 0 0 0;
  height: 100%;
  max-width: 42rem;
}

.login-panel {
  font-family: 'DINNextLTPro-Regular';
  padding: 42px 59px;
  color: #363534;
  background-color: #fff;
  height: 100%;
}

.heading {
  text-align: left;
}

.header h4 {
  font-family: 'DINNextLTPro-Regular';
  color: #363534;
}

.heading img {
  min-width: 120px;
  width: 60%;
  padding-bottom: 2.5em;
}

.heading p {
  font-size: 1.25em;
  color: #9a9a99;
}

.legend-border input {
  border: none;
}

.panel-body {
  padding: 0 0 0 0;
}

.panel-footer {
  background-color: #fff;
}

.login-div-container {
  overflow-y: scroll;
}

#account-login-container {
  margin-top: 4.8rem;
  box-shadow: 0 0 1em #333;
  background-color: #fff;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 861px;
  height: 644px;
  font-size: 1em;
  flex-flow: inherit;
}

fieldset.legend-border {
  border: 1px solid #ddd !important;
  padding: 0 1em 1em 1em !important;
  margin: 0 0 1em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.legend-border {
  font-size: 0.75em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  border-bottom: none;
}

embed {
  pointer-events: none;
  height: 20px;
  width: 20px;
  padding: 0 0 0 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
  padding: 0 0 0 0;
}

.login-logo {
  margin-bottom: 58px;
}

.welcome {
  color: #363534;
  font-size: 2.6rem;
  line-height: 2.6rem;
  margin-bottom: 0.9rem;
  font-family: 'DINNextLTPro-Medium';
}

.continue {
  color: #9a9a99;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-bottom: 4.1rem;
}

.input-border {
  width: 100%;
  height: 4.8rem;
  padding: 0.8rem 0;
  border-radius: 1px;
  border: solid 1px #ccc;
}

.email-border {
  margin-bottom: 2.1rem;
}

.password-border {
  margin-bottom: 1.6rem;
}

.side-pic {
  height: 100%;
  width: 100%;
}

.input-label {
  font-size: 1rem;
  margin-top: -1.6rem;
  margin-left: 1.1rem;
  background-color: white;
  font-family: 'DINNextLTPro-Medium';
}

.email {
  width: 7.4rem;
}

.password {
  width: 5.2rem;
}

.info-input, div.input-border.password-border > span > input {
  border: 0;
  box-shadow: none;
  font-size: 1.6rem;
}

.login-button {
  width: 17rem;
  height: 4.8rem;
  font-family: 'DINNextLTPro-Regular';
  background-color: #e98301;
  font-size: 1em;
}

.sign-up {
  margin-top: 5.8rem;
}

#remember-me-col {
  margin-top: 0.2rem;
  padding-left: 0;
  display: inline-block;
  white-space: nowrap;
}

.remember {
  font-family: 'DINNextLTPro-Regular';
  font-weight: bold;
  color: #363534;
}

#forgot-password-col {
  text-align: right;
  padding-right: 0;
  color: #8e908f;
  margin-bottom: 3.5rem;
  justify-content: flex-end;
  display: flex;
  white-space: nowrap;
}

.eye-styling {
  background-color: white;
  border-color: white;
}

.footer-login {
  font-family: 'DINNextLTPro-Medium';
  margin-bottom: 0;
}

#register-link:hover {
  color: #e98301;
}

#register-link:active {
  color: #d3b58e;
}

#register-link:link {
  color: #e98301;
}

#register-link:visited {
  color: #e98301;
}

#register-link {
  font-family: DINNextLTPro-Medium;
}

.side-pic {
  height: 100%;
  width: 100%;
}

.center-div {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
