<template>
  <div class="results-container">
    <div class="graph">
      <div class="graph-container">
        <a-card :bordered="true" class="h100">
          <div class="txt-20 txt-font-din-medium txt-black">{{ title }}</div>
          <Plotly v-if="!loading && plot && filters" :plot="plot" :type="filters.distribution" :filters="filters"/>
          <div
            v-else-if="anthroAnalysisNotEnoughData"
            class="h100 w100 flex justify-center align-center">
            <a-empty :description="$t('components.notifications.notEnoughDataToGenerateReport')"/>
          </div>
          <div v-else class="mt-30 w100 h100 flex justify-center align-center">
            <a-spin size="large"/>
          </div>
        </a-card>
      </div>
      <div class="graph-sidebar" :class="{ disabled: loading }">
        <ResultFilters v-model="filters" :survey-info="anthroAnalysisSurveyInfo"/>
      </div>
    </div>
    <div>
      <div v-if="!showResults" class="results">
        <a-card :bordered="true">
           <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="txt-20 txt-font-din-medium txt-black" v-html="$t('components.titles.mainSurveyResults', {
            type: filters && translatedFilter(filters.type)
          })">
          </div>
          <PrevelanceWHZ v-if="filters && filters.type === 'whz'" :type="filters.type" :survey-info="anthroAnalysisSurveyInfo" :prevalence="prevalence"/>
          <PrevelanceWAZ v-if="filters && filters.type === 'waz'" :type="filters.type" :survey-info="anthroAnalysisSurveyInfo" :prevalence="prevalence"/>
          <PrevelanceHAZ v-if="filters && filters.type === 'haz'" :type="filters.type" :survey-info="anthroAnalysisSurveyInfo" :prevalence="prevalence"/>
          <PrevelanceMUAC v-if="filters && filters.type === 'muac'" :type="filters.type" :survey-info="anthroAnalysisSurveyInfo" :prevalence="prevalence"/>
        </a-card>
      </div>
      <div v-if="showResults" class="results">
        <a-card :bordered="true">
          <div class="txt-20 txt-font-din-medium txt-black">
            {{ resultsTitle }}
            <a-tooltip
              v-if="filters && filters.group == 'cluster' && filters.distribution != 'groups'"
              class="ml-8">
              <div slot="title">
                {{ resultsByClusterText.title }}
                <br><br>
                {{ resultsByClusterText.index }}
                <br> <br>
                {{ resultsByClusterText.idLessThan1 }}
                <br> <br>
                {{ resultsByClusterText.idGreaterThan1 }}
              </div>
              <a-icon type="info-circle" class="pt-0 info-icon"/>
            </a-tooltip>
          </div>
          <ResultsSection
            :type="filters.type"
            :group="filters.group"
            :distribution="filters.distribution"
            :is-age-muac="isAgeMuac"
            :muac-lwr="muacLwr"
            :muac-upr="muacUpr"
            :tables="tables"
            :plot="plot"/>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { translatedFilterTypes } from '@/util/indicators';

export default {
  name: 'AnthropometryResults',
  components: {
    Plotly: () => import('./plotly.vue'),
    ResultFilters: () => import('./result-filters'),
    PrevelanceWHZ: () => import('./prevalences/whz.vue'),
    PrevelanceWAZ: () => import('./prevalences/waz.vue'),
    PrevelanceHAZ: () => import('./prevalences/haz.vue'),
    PrevelanceMUAC: () => import('./prevalences/muac.vue'),
    ResultsSection: () => import('./results/index.vue')
  },
  data() {
    return {
      filters: undefined
    };
  },
  computed: {
    ...mapGetters(['anthroAnalysisChanged']),
    ...mapState({
      surveyId: state => state.survey.surveyId,
      projectId: state => state.survey.project.id,
      prevalence: state => state.results.prevalence,
      loading: state => state.results.fetchingData,
      tables: state => state.results.tables,
      plot: state => state.results.anthroPlot,
      anthroAnalysisNotEnoughData: state => state.results.anthroAnalysisNotEnoughData,
      anthroAnalysisSurveyInfo: state => state.results.anthroAnalysisSurveyInfo
    }),
    resultsByClusterText() {
      return {
        title: this.$t('components.titles.howToReadThisDistributionTable'),
        index: this.$t('components.description.indexOfDispersion'),
        idLessThan1: this.$t('components.description.idLessThanOneAndPGreaterThanNineFive'),
        idGreaterThan1: this.$t('components.description.idHigherThanOneAndPLessThanZeroFive')
      };
    },
    title() {
      const titles = {
        all: {
          whz: {
            gaussian: this.$t('components.titles.weightForHeightMeanZScore', {}),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.whz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.whz').toUpperCase() })
          },
          muac: {
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('values.muac').toUpperCase() })
          },
          waz: {
            gaussian: this.$t('components.titles.weightForAgeMeanZScore', {}),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.waz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.waz').toUpperCase() })
          },
          haz: {
            gaussian: this.$t('components.titles.heightForAgeMeanZScore', {}),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.haz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.haz').toUpperCase() })
          },
          muac_age: {},
          bmi_age: {}
        },
        sex: {
          whz: {
            gaussian: this.$t('components.titles.weightForHeightMeanZScore', {
              value: this.$t('components.titles.boysOrGirls')
            }),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.whz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.whz').toUpperCase() })
          },
          muac: {
            cumulative: this.$t('components.titles.cumulativeDistributionFor', {
              value: this.$t('values.muac').toUpperCase()
            })
          },
          waz: {
            gaussian: this.$t('components.titles.weightForAgeMeanZScore', {
              value: this.$t('components.titles.boysOrGirls')
            }),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.waz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.waz').toUpperCase() })
          },
          haz: {
            gaussian: this.$t('components.titles.heightForAgeMeanZScore', {
              value: this.$t('components.titles.boysOrGirls')
            }),
            cumulative: this.$t('components.titles.cumulativeDistributionFor', { value: this.$t('components.description.haz').toUpperCase() }),
            probability: this.$t('components.titles.probabilityPlotFor', { value: this.$t('components.description.haz').toUpperCase() })
          },
          muac_age: {},
          bmi_age: {}
        },
        cluster: {
          whz: {
            groups: this.$t('components.titles.weightForHeightMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            }),
            dist_whz_2: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_whz_3: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_edema: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_gam: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_sam: this.$t('components.titles.distributionOfCasesInCluster')
          },
          muac: {
            groups: this.$t('components.titles.percentageOfMalnutritionToMuac')
          },
          waz: {
            groups: this.$t('components.titles.weightForAgeMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            }),
            dist_waz_2: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_waz_3: this.$t('components.titles.distributionOfCasesInCluster')
          },
          haz: {
            groups: this.$t('components.titles.heightForAgeMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            }),
            dist_haz_2: this.$t('components.titles.distributionOfCasesInCluster'),
            dist_haz_3: this.$t('components.titles.distributionOfCasesInCluster')
          },
          muac_age: {},
          bmi_age: {}
        },
        months: {
          whz: {
            groups: this.$t('components.titles.weightForHeightMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            })
          },
          muac: {
            groups: this.$t('components.titles.percentageOfMalnutritionToMuac')
          },
          waz: {
            groups: this.$t('components.titles.weightForAgeMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            })
          },
          haz: {
            groups: this.$t('components.titles.heightForAgeMeanZScore', {
              symbol: '±',
              value: this.$t('values.sd')
            })
          },
          muac_age: {},
          bmi_age: {}
        }
      };
      try {
        const { distribution, type, group } = this.filters;
        return titles[group][type][distribution];
      } catch (err) {
        return '';
      }
    },
    isAgeMuac() {
      if (this.filters) {
        const { type, group } = this.filters;
        return type === 'muac' && group === 'months';
      }
      return false;
    },
    resultsTitle() {
      try {
        if (this.isAgeMuac) {
          return this.$t('components.titles.muacEvaluationForAgeGroups');
        }
        const { group } = this.filters;
        return group === 'cluster' ? this.$t('components.titles.resultsByCluster') : this.$t('components.titles.resultsByAgeGroup');
      } catch (err) {
        return '';
      }
    },
    showResults() {
      try {
        const { group } = this.filters;
        return group === 'cluster' || group === 'months';
      } catch (err) {
        return false;
      }
    },
    muacLwr() {
      if (this.anthroAnalysisSurveyInfo && this.anthroAnalysisSurveyInfo.settings && this.anthroAnalysisSurveyInfo.settings.muac_lwr) {
        return this.anthroAnalysisSurveyInfo.settings.muac_lwr;
      }
      return '';
    },
    muacUpr() {
      if (this.anthroAnalysisSurveyInfo && this.anthroAnalysisSurveyInfo.settings && this.anthroAnalysisSurveyInfo.settings.muac_upr) {
        return this.anthroAnalysisSurveyInfo.settings.muac_upr;
      }
      return '';
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onDataChange();
      }
    },
    anthroAnalysisChanged() {
      if (this.anthroAnalysisChanged) {
        this.onDataChange();
        this.markAnthroAnalysisChanged();
      }
    }
  },
  methods: {
    ...mapActions(['getSurveyAnthroAnalysis', 'markAnthroAnalysisChanged']),
    onDataChange() {
      if (this.filters) {
        this.getSurveyAnthroAnalysis({
          projectId: this.projectId,
          surveyId: this.surveyId,
          filters: this.filters
        });
      }
    },
    translatedFilter(type) {
      return translatedFilterTypes.call(this, type);
    }
  }
};
</script>

<style lang="scss">
.results-container {
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding-bottom: 30px;
}

.graph {
  display: flex;
 }

.info-icon {
  color: #c6c7c6;
  font-size: 20px
}

.ant-tooltip-inner {
  width: 385px;
}

.graph-container {
  width: 70%;
  padding-bottom: 15px;
  .ant-card-body {
    height: 100%;
  }
}

.graph-sidebar {
  width: 30%;
  margin-left: 30px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .ant-card .ant-card-body {
    padding: 20px !important
  }

  .ant-radio-wrapper {
    font-family: 'DINNextLTPro-Regular';
  }
}

.graph-seperator {
  border: 1px dashed #d8d8d8;
  margin-top: 10px;
  margin-bottom: 10px;
}

.results {
}

.ant-table-thead > tr {
  vertical-align: top;
}
</style>
