<template>
  <div v-show="surveyValidation" data-cy="survey-report-main-container" class="pt-24">
    <a-alert
      v-if="!isSurveyResultsValidated"
      class="mb-40 report-alert"
      type="info">
      <div slot="description" class="flex justify-space-between align-center">
        <div class="report-description">
          <div class="report-text">
            <div class="report-title">{{ $t('components.description.pleaseValidateSurveyResult') }}</div>
            <div>
              {{ $t('components.description.downloadSurveyReportDescription') }}
            </div>
          </div>

          <a-button
            data-cy="survey-report-validate-results-button"
            class="info-button"
            size="large"
            icon="check"
            :disabled="isSupervisor"
            @click="() => showResultValidationModal = true">
            {{ $t('components.description.validateResults') }}
          </a-button>
        </div>
      </div>
    </a-alert>
    <a-alert
        v-if="!shareRawData && isSurveyResultsValidated"
        class="mb-40"
        :message="$t('components.description.shareRawDataRequest')"
        type="info">
      <div slot="description" class="flex justify-space-between align-center">
        <div>
          {{ $t('components.description.shareRawDataDescription') }}
        </div>
        <a-button
            class="info-button"
            size="large"
            icon="check"
            :disabled="isSupervisor"
            @click="optInShareData">
          {{ $t('components.description.shareRawDataInfo') }}
        </a-button>
      </div>
    </a-alert>
    <DownloadModal
      :show="showDownloadModal"
      :on-exit="closeDownloadModal"/>
    <UploadModal
      :show="showUploadModal"
      :on-exit="closeUploadModal"
      :on-upload-complete="onUploadComplete"/>
    <div :class="{ disabledBlock: surveyValidation === '_unset_' }">
      <h4 class="mb-22 txt-40 txt-bold txt-font-din-medium">
        {{ $t('components.titles.surveyReport') }}
      </h4>
      <div class="flex justify-space-between">
        <div class="w50 txt-18">
          {{ $t('components.description.surveyReportDownloadInstructions') }}
        </div>
        <a-button
          class="w170"
          type="primary"
          size="large"
          icon="check"
          data-cy="survey-report-validate-report-button"
          :disabled="disableValidateReportButton || isSupervisor"
          @click="validateReport">
          {{ $t('components.description.validateReportButton') }}
        </a-button>
      </div>
      <div class="flex justify-space-between align-center mt-40">
        <div class="flex align-center">
          <a-button
            data-cy="survey-report-download-draft-survey-report"
              type="link"
              class="txt-18 txt-black txt-font-din-medium mr-20 flex align-center"
              @click="openDownloadModal">
            <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
            {{ $t('components.description.downloadDraftSurveyReport') }}
          </a-button>
          <a-button
            data-cy="survey-report-upload-survey-report"
            type="link"
            class="txt-18 txt-black txt-font-din-medium flex align-center"
            :disabled="isSupervisor"
            @click="openUploadModal">
            <embed width="30px" src="/Upload-orange.svg" class="mr-10" :class="{ 'disabled': isSupervisor }">
              {{ $t('components.description.uploadSurveyReport') }}
          </a-button>
        </div>
      </div>
      <a-card :bordered="true" class="ant-card-no-padding w100 mt-20 mb-20">
        <div class="report-container">
          <div class="report-container-rows txt-uppercase txt-bold txt-font-din-medium">
            <div>{{ $t('components.labels.name') }}</div>
            <div>{{ $t('components.labels.zScoreExclusion') }}</div>
            <div>{{ $t('components.labels.lastModified') }}</div>
            <div>{{ $t('components.labels.size') }}</div>
            <div></div>
          </div>
          <div
              v-for="report of getLatestSurveyReport()"
              :key="report.id"
              :class="['report-container-rows', !report.url ? 'light-gray': '']">
            <div class="flex align-center">
              {{ report.name }}
              <a-tag v-if="report.validated === 'Not Validated'" color="red" class="ml-4">
                <a-icon type="close-circle" class="mr-4"/>
                {{ $t('components.labels.not_validated') }}
              </a-tag>
              <a-tag
                  v-if="report.generated"
                  color="green" class="ml-4">
                <a-icon type="check-circle" class="mr-4"/>
                {{ $t('components.labels.originalReport') }}
              </a-tag>
              <a-tag
                  v-if="!report.url"
                  color="green" class="ml-4">
                <a-icon type="check-circle" class="mr-4"/>
                {{ $t('components.labels.originalReport') }}
              </a-tag>
              <a-tag
                  v-if="report.validated === 'Validated' || report.validated === 'Validated with Comments'"
                  color="green" class="ml-4">
                <a-icon type="check-circle" class="mr-4"/>
                {{ $t(`components.labels.${report.validated.toLowerCase().replaceAll(' ', '_')}`) }}
              </a-tag>
            </div>
            <div class="flex align-center">
              {{ report.zScoreExclusion }}
            </div>
            <div class="flex align-center">
              <a-spin v-if="!report.createdAt"/>
              {{ report.createdAt ? dateToHumanReadable(report.createdAt) : '' }}
            </div>
            <div>
              <a-spin v-if="!report.size"/>
              {{ report.size }}
            </div>
            <div>
              <a-button
                  v-if="report.url"
                  type="link"
                  class="txt-18 txt-black txt-font-din-medium flex align-center"
                  @click="download(report)">
                <embed width="30px" src="/download-cloud-icon.svg">
              </a-button>
            </div>
          </div>
        </div>
        <div
          v-if="!latestSurveyReport"
          class="flex justify-center align-center flex-column mb-20 mt-40">
          <div class="txt-24 txt-black txt-font-din-medium mb-20">
            {{ $t('components.description.yourSurveyReportReady') }}
          </div>
          <a-button
            class="mb-40"
            type="primary"
            size="large"
            icon="cloud-download"
            @click="openDownloadModal">
            {{ $t('components.description.downloadSurveyReport') }}
          </a-button>
        </div>
      </a-card>
    </div>
    <ModalWizard
      v-if="showResultValidationModal"
      :on-complete="onResultModalComplete"
      :on-exit="onResultModalExit"
      :title="$t('components.titles.validateResults')"
      :prompt-opts="{ shareRawData }"
      :prompts="prompts"/>

    <ModalWizard
      v-if="showReportValidationModal"
      :on-complete="onReportModalComplete"
      :on-exit="onReportModalExit"
      :title="$t('components.description.validateSurveyReports')"
      :prompt-opts="{ shareRawData }"
      :prompts="reportValidationprompts"/>

    <ResultConfirmationModal
      :active="resultConfirmation"
      :on-complete="() => onModalComplete(results)"
      :on-exit="() => resultConfirmation = false"/>
</div>
</template>

<script>

import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { saveAs } from 'file-saver';
import dateToHumanReadable from '../survey/dateToHumanReadable';
import ModalWizard from '../../smartplus/components/modalWizard/index.vue';
import project from '../../mixins/project';
import { reportValidationprompts, resultValidationprompts } from '../../smartplus/components/report/reportValidationPrompts';

import DownloadModal from './report-download-modal.vue';
import UploadModal from './report-upload-modal.vue';

const key = 'uploading-survey-report';

export default {
  name: 'Report',
  components: {
    ModalWizard,
    DownloadModal,
    UploadModal,
    ResultConfirmationModal: () => import('./result-confirmation-modal.vue')
  },
  mixins: [project()],
  data() {
    return {
      flag: 'smart',
      prompts: resultValidationprompts,
      reportValidationprompts,
      saving: false,

      showUploadModal: false,
      showDownloadModal: false,

      showResultValidationModal: false,
      showReportValidationModal: false,
      resultConfirmation: false,
      results: [],
      requestInterval: undefined
    };
  },
  computed: {
    ...mapGetters(['surveyOrganization', 'isSurveyReportValidated', 'isSurveyResultsValidated']),
    ...mapState({
      surveyValidation: state => state.survey.validation,
      surveyLoading: state => state.survey.loading,
      surveyId: state => state.survey.surveyId,
      surveyName: state => state.survey.name,
      metadata: state => state.survey.metadata,
      project: state => state.survey.project,
      surveyReports: state => state.survey.surveyReports,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    shareRawData() {
      if (this.metadata) {
        return this.metadata.share;
      }
      return true;
    },
    latestSurveyReport() {
      return this.getLatestSurveyReport()[0];
    },
    disableValidateReportButton() {
      return !this.isSurveyResultsValidated ||
        !this.latestSurveyReport ||
        this.latestSurveyReport.validated != null ||
        !this.latestSurveyReport.url;
    }
  },
  destroyed() {
    clearInterval(this.requestInterval);
  },
  methods: {
    ...mapGetters(['surveyReportsByCreatedAt']),
    ...mapActions([
      'setMetadata',
      'getSurveyReports',
      'updateSurveyReport',
      'setValidation'
    ]),
    download({ url, name }) {
      return saveAs(url, `${name}.docx`);
    },
    getLatestSurveyReport() {
      const translatedZScore = {
        smart: this.$t('components.titles.smart'),
        who: this.$t('components.titles.who')
      };
      const reports = this.surveyReportsByCreatedAt();
      const latestReport = reports.find(report => !report.generated);
      if (latestReport) {
        const zScoreExclusion = translatedZScore[latestReport.zScoreExclusion];
        return [{ ...latestReport, zScoreExclusion }];
      }
      return [];
    },
    dateToHumanReadable,
    validateReport() {
      this.showReportValidationModal = true;
    },
    onReportModalExit() {
      this.showReportValidationModal = false;
    },
    onResultModalExit() {
      this.showResultValidationModal = false;
    },
    async onReportModalComplete(results) {
      if (this.metadata.share !== results.shareRawData) {
        this.setMetadata({ ...this.metadata, share: results.shareRawData });
      }

      try {
        const report = this.surveyReportsByCreatedAt()[0];
        const surveyData = await this.updateSurveyReport({ ...report, validated: results.validated })
          .catch(this.displayReportError);
        if (surveyData) {
          this.getSurveyReports();
          report.validated = results.validated;
        }
      } catch (err) {
        this.$message.error({ content: 'An error occured validating report', key, duration: 5 });
      } finally {
        this.showReportValidationModal = false;
      }
    },
    openDownloadModal() {
      this.showDownloadModal = true;
    },
    closeDownloadModal() {
      this.showDownloadModal = false;
    },
    openUploadModal() {
      this.showUploadModal = true;
    },
    closeUploadModal() {
      this.showUploadModal = false;
    },
    onUploadComplete() {
      this.showUploadModal = false;
      this.getSurveyReports();
    },
    optInShareData() {
      this.setMetadata({ ...this.metadata, share: true });
    },
    async onResultModalComplete(results) {
      this.showResultValidationModal = false;
      if (results.validated === 'Validated' || results.validated === 'Validated with Caution') {
        this.resultConfirmation = true;
        this.results = results;
      }
    },
    async onModalComplete(results) {
      if (this.metadata.share !== results.shareRawData) {
        this.setMetadata({ ...this.metadata, share: results.shareRawData });
      }

      if (results.validated === 'Not Validated') {
        this.setValidation({ validation: results.validated, reasonForNotValidated: results.not_validated_reason })
          .catch(this.displayError);
      } else {
        this.setValidation({ validation: results.validated })
          .catch(this.displayError);
      }

      this.showResultValidationModal = false;
      this.resultConfirmation = false;
    },
    displayError(err) {
      this.$notification.error({
        message: this.$t('values.error'),
        duration: 0,
        description: this.$t('components.notifications.yourResultsAreMissingDataForIndicators'),
        btn: h => h(
          'a-button',
          {
            props: {
              type: 'danger',
              size: 'small'
            },
            on: {
              click: () => this.$notification.error({
                message: this.$t('components.notifications.error'),
                description: err.response.data.message
              })
            }
          },
          'Details',
        )
      });
    },
    displayReportError(err) {
      this.$notification.error({
        message: this.$t('values.error'),
        duration: 0,
        description: this.$t('components.notifications.yourReportCannotBeValidated'),
        btn: h => h(
          'a-button',
          {
            props: {
              type: 'danger',
              size: 'small'
            },
            on: {
              click: () => this.$notification.error({
                message: this.$t('components.notifications.error'),
                description: err.response.data.message
              })
            }
          },
          'Details',
        )
      });
    }
  }
};
</script>

<style lang="scss">
.flag-button {
  color: #8e908f;
  border: 1px solid #8e908f;

  &.flagActive {
    color: #e98300;
    border: 1px solid #e98300;
  }
}

.report-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.report-text {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-right: 35px;
}

.report-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.report-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 60px;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}

.report-container-rows {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 10%;
  grid-template-rows: 60px;
  gap: 0px 0px;
  grid-template-areas:
    ". . . .";
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #8e908f7c;
}

.light-gray {
  background: lightgray;
}

.info-button {
  background: #3897ff;
  color: white;
}
</style>
