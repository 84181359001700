<template>
  <div class="relative">
    <div v-if="meetsLocalEventRequirements && calendar" class="calendar-upload-actions">
      <a-button
        type="primary"
        shape="circle"
        icon="download"
        class="mr-10"
        :href="calendar.url"
        target="_blank"/>
      <a-popconfirm
        :title="$t('components.notifications.sureDeleteCalendar')"
        :ok-text="$t('values.yes')"
        :cancel-text="$t('values.no')"
        :disabled="isSupervisor"
        @confirm="removeCalendar">
        <a-button
          type="danger"
          shape="circle"
          icon="delete"
          :disabled="isSupervisor"
          :loading="deleting"/>
      </a-popconfirm>
    </div>
    <a-upload
      v-if="meetsLocalEventRequirements"
      :file-list="fileList"
      list-type="picture"
      name="file"
      accept=".doc,
      .docx,application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      class="w100 calendar-upload-container"
      :remove="handleRemove"
      :before-upload="beforeUpload">
      <a-button
        data-cy="calendar-upload"
        :disabled="isSupervisor"
        class="calendar-upload-button w100 flex txt-24 flex-column
        txt-gray justify-center align-center txt-font-din-medium">
        <embed width="140px" src="/calendar-upload.svg" class="mb-24">
        <div v-if="!calendar" class="opacity-25 txt-18 txt-font-din-medium">
          {{ $t('components.description.uploadEventLocalCalendar') }}
        </div>
        <div v-if="calendar" data-cy="replace-calendar-desc" data class="opacity-25 txt-uppercase txt-18 txt-font-din-medium">
          {{ `${$t('components.description.replaceCalendar')} ` }}<span data-cy="uploaded-calendar-name" class="txt-orange">{{ calendar.name }}</span>
        </div>
      </a-button>
    </a-upload>
    <div v-if="!meetsLocalEventRequirements" class="mt-30 calendar-upload-requirements">
      <a-result
        data-cy="go-to-metadata-desc"
        :title="$t('components.notifications.completeMetadataPage')">
        <template #extra>
          <a-button type="primary">
            <router-link to="/survey-planning/metadata">
              {{ $t('components.description.goToMetadata') }}
            </router-link>
          </a-button>
        </template>
      </a-result>
    </div>
    <a-popconfirm
      :title="$t('components.notifications.confirmUploadingLocalCalendarTemplate')"
      :ok-text="$t('values.confirm')"
      :cancel-text="$t('values.no')"
      :disabled="!fileList.length > 0 || isSupervisor"
      @confirm="upload">
      <a-button
        ref="uploadButton"
        type="primary"
        size="large"
        :loading="saving"
        :disabled="!fileList.length > 0 || isSupervisor"
        class="mt-20 mb-20">
        {{ $t('components.description.uploadToDatabase') }}
      </a-button>
    </a-popconfirm>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { configForPossibleBackendRequest } from '../../../../util/request';
import { isValidDocFile } from '../../../../util/files';

export default {
  name: 'CalendarUpload',
  data() {
    return {
      fileList: [],
      saving: false,
      deleting: false
    };
  },
  computed: {
    ...mapGetters([
      'meetsLocalEventRequirements'
    ]),
    ...mapState({
      metadata: state => state.survey.metadata,
      project: state => state.survey.project,
      calendar: state => state.survey.calendar,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    ...mapActions(['getCalendar']),
    beforeUpload(file) {
      this.fileList = [file];
      this.$refs.uploadButton.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false;
    },
    handleRemove() {
      this.fileList = [];
    },
    async uploadFile() {
      const file = this.fileList[0];
      if (isValidDocFile(file)) {
        const formData = new FormData();
        formData.append('file', this.fileList[0]);
        const baseConfig = {
          method: 'POST',
          url: process.env.VUE_APP_CALENDER_UPLOAD_URL,
          params: {
            saveExtension: true
          },
          data: formData
        };
        const token = this.$store.getters.token ? this.$store.getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

        try {
          const { data } = await Vue.prototype.$http.request(axiosConfig);
          if (data) return data.url;
        } catch (error) {
          this.error = error.response.data.message;
        }
        return null;
      }

      this.$alert().danger(this.$t('components.notifications.typeLocalEventCalendar'));

      return null;
    },
    async upload() {
      try {
        this.saving = true;
        const {
          surveyMonth,
          surveyYear,
          geographicAreaName,
          geographicCountry,
          surveyId,
          includedAgeGroup,
          country
        } = this.metadata;
        const surveyCountry = geographicCountry || country;
        const ageGroup = includedAgeGroup || '6-59';
        const name = `${ageGroup}_${surveyCountry}_${geographicAreaName}_${surveyYear}_${surveyMonth}`
          .replaceAll(' ', '_')
          .replaceAll(',', '');
        const url = await this.uploadFile();
        if (url) {
          const baseConfig = { method: 'POST', url: `/projects/${this.project.id}/survey/${surveyId}/save-calendar`, data: { url, name, country: surveyCountry, type: ageGroup } };
          const token = this.$store.getters.token ? this.$store.getters.token : null;

          const { data } = await Vue.prototype.$http.request(
            configForPossibleBackendRequest(baseConfig, token)
          );
          if (data) {
            this.getCalendar();
            this.fileList = [];
            this.$alert().success(this.$t('components.notifications.successfullySavedToDatabase'));
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$alert().danger(this.$t('components.notifications.errorSavingCalendar'));
      } finally {
        this.saving = false;
      }
    },
    async removeCalendar() {
      this.deleting = true;
      try {
        const { surveyId } = this.metadata;
        const baseConfig = { method: 'DELETE', url: `/projects/${this.project.id}/survey/${surveyId}/delete-calendar` };
        const token = this.$store.getters.token ? this.$store.getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data.success) {
          this.getCalendar();
          this.$alert().success(this.$t('components.notifications.calendarSuccessfullyDeleted'));
        }
      } catch (err) {
        this.$alert().danger(err.message);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style lang="scss">
.calendar-upload-requirements {
  background: white;
  border: 1px dashed #ccc;
  margin-top: 10px;
}
.calendar-upload-button {
  height: 400px;
  border-radius: 5px;
  border-style: dashed;
}
.calendar-upload-container .ant-upload {
  width: 100%;
  max-height: 400px;
  min-height: 400px;
}
.ant-upload-list-item {
  background: white;
}

.ant-upload-list-item-info > span {
  display: flex;
  align-items: center;

  i.anticon-picture {
    display: none;
  }

  .ant-upload-list-item-name {
    padding: 0;
  }

  i.anticon-delete {
    font-size: 20px;
    color: #f42828;
    padding-right: 20px;
  }
}
.calendar-upload-actions {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}
</style>
