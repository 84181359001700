/*
Copyright 2020 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
*/
import qrcode from 'qrcode-generator';
import pako from 'pako/lib/deflate';
import { API_URL } from './request';

// Generates a QR code for ODK Collect, returning the HTML for an <img> element.
export default (url, { errorCorrectionLevel, cellSize }, enumeratorDetails, metadata) => {
  const code = qrcode(0, errorCorrectionLevel);
  // Collect requires the JSON to have `general` and `admin` properties, even if
  // their values are empty objects.

  let perDayTeam = 0;
  if (metadata && metadata.samplingMethod === 'Cluster') {
    perDayTeam = metadata?.householdDayTeam || 0;
  } else {
    perDayTeam = metadata?.householdPerDayTeam || 0;
  }

  const enumerator = enumeratorDetails
    ? {
      id: enumeratorDetails.id,
      firstName: enumeratorDetails.firstName,
      supervisor: enumeratorDetails.supervisor,
      token: enumeratorDetails.token
    } : {};
  const settings = {
    general: {
      host_url: `${API_URL || window.location.origin}/v1/`,
      server_url: `${API_URL || window.location.origin}${url}`,
      householdPerDayTeam: perDayTeam,
      ...enumerator
    },
    admin: {}
  };

  const deflated = pako.deflate(JSON.stringify(settings), { to: 'string' });
  code.addData(btoa(deflated));
  code.make();
  return code.createImgTag(cellSize, 20);
};
