<template>
  <div id="supervisor-invitation-success" class="supervisor-invitation-success">
    <div class="logo-container">
      <img class="mb-10" height="55px" src="/logo.svg" alt="Smart Platform">
      <span>V {{ version }}</span>
    </div>
    <div class="body-container">
      <h1 class="title">{{ $t('components.titles.supervisorInvitationSuccess') }}</h1>
      <p class="regular-font mb-32">{{ $t('components.description.supervisorInvitationSuccessBody1') }}</p>
      <a-alert
        v-show="isLoggedIn && !currentUserIdMatchesInvitedUserId"
        type="error"
        :closable="false"
        show-icon
        class="mb-32">
        <template #description>
          {{ $t('components.description.supervisorInvitationSuccessDifferentAccountBody') }}
        </template>
        <template #icon>
          <a-icon type="info-circle" theme="filled"/>
        </template>
      </a-alert>
      <p class="regular-font mb-32">
        {{ $t('components.description.supervisorInvitationSuccessBody2') }}
      </p>
      <p class="regular-font mb-4">
        {{ $t('components.labels.invitationSurveyName') }}
      </p>
      <p class="bold-font mb-12">
        {{ surveyName }}
      </p>
      <p class="regular-font mb-4">
        {{ $t('components.labels.invitationAccount') }}
      </p>
      <p class="bold-font mb-32">
        {{ email }}
      </p>

      <button class="login-button btn btn-primary orange" type="submit" @click="loginClicked">
        {{ isLoggedIn ? $t('components.labels.loginToThisAccountInstead') : $t('components.labels.loginToYourAccount') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { requestData } from '@/store/modules/request';
import request from '@/mixins/request';
import { setUserSurvey } from '@/util/util';
import { apiPaths } from '@/util/request';
import { version } from '../../../../../package.json';

export default {
  name: 'SupervisorInvitationSuccess',
  mixins: [request()],
  data: () => ({
    version
  }),
  computed: {
    ...requestData(['session', 'currentUser']),
    surveyId() {
      return this.$route.query.surveyId;
    },
    surveyName() {
      return this.$route.query.surveyName;
    },
    email() {
      return this.$route.query.email;
    },
    currentUserIdMatchesInvitedUserId() {
      return this.currentUser?.id != null && this.$route.query.userId != null && +this.currentUser?.id === +this.$route.query.userId;
    },
    isLoggedIn() {
      return this.currentUser && this.currentUser.id !== null;
    },
    loginLocation() {
      return {
        path: '/login',
        query: {
          email: this.email,
          selectedSurveyId: this.surveyId
        }
      };
    }
  },
  mounted() {
    if (this.isLoggedIn && this.currentUserIdMatchesInvitedUserId) {
      setUserSurvey(this.currentUser.id, this.surveyId);
      this.emptyStoredSurvey();
      this.clearResults();
      this.$message.success({
        content: this.$t('components.notifications.invitationAcceptedSuccessfully'),
        key: 'invitation-success',
        duration: 10
      });
      this.$router.replace({ path: '/surveys' });
    }
  },
  methods: {
    ...mapActions([
      'emptyStoredSurvey',
      'clearResults'
    ]),
    logoutAndRedirectToLogin() {
      this.delete(apiPaths.session(this.session.token));
      this.$store.commit('clearData');
      this.emptyStoredSurvey();
      this.clearResults();
      this.$router.replace(this.loginLocation);
    },
    loginClicked() {
      if (!this.isLoggedIn) {
        this.$router.replace(this.loginLocation);
      } else {
        this.logoutAndRedirectToLogin();
      }
    }
  }
};
</script>
<style lang="scss">
.supervisor-invitation-success {
  @media (max-width: 1024px) {
    padding-right: 0;
  }
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;

  .regular-font {
    font-family: 'DINNextLTPro-Regular';
    font-size: 16px;
  }

  .bold-font {
    font-family: 'DINNextLTPro-Bold';
    font-size: 16px;
  }

  .logo-container {
    display: flex;
    align-items: flex-start;
    justify-content: right;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  .logo-container span {
    position: absolute;
    font-size: 0.7em;
    margin-top: 13px;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
  }

  .title {
    text-align: center;
    font-family: 'DINNextLTPro-Bold';
    font-size: 40px;
    margin-bottom: 32px;
  }

  .body-container {
    padding: 40px;
    border-top: 8px solid #66C300;
    background: #FFF;
    max-width: 650px;
  }


  .login-button {
    width: 100%;
    height: 4.8rem;
    font-family: 'DINNextLTPro-Regular';
    background-color: #e98301;
    font-size: 1em;
  }
}
</style>
