<template>
  <span data-cy="administrative">
    <CreateOrganization v-if="!isSupervisor" v-model="active" :on-complete="onComplete"/>
    <h4 class="mt-24 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.administrative') }}</h4>
    <a-card :bordered="true">
      <a-row class="mb-20" type="flex" align="middle" :gutter="20">
        <a-col :lg="11" :md="24" :sm="24" class="select-organization">
          <a-form-item class="metadata-organization-container">
            <span slot="label" class="flex align-center">
              {{ $t('components.labels.organization') }}&nbsp;&nbsp;
              <a-tooltip
                :title="$t('components.toolTips.organization')"
                class="ml-8">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
              </a-tooltip>
            </span>
            <a-select v-decorator="[
              'organizationId',
              {
                rules: [
                  {
                    required: true,
                    message: $t('components.description.selectOrg'),
                  },
                ],
              },
            ]"
            show-search
            :placeholder="$t('components.description.searchOrg')"
            :get-popup-container="(triggerNode) => triggerNode.parentNode"
            :filter-option="filterOption"
            @change="onOrganizationChange">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option
                v-for="org in organizations"
                :key="org.id"
                :disabled="isSupervisor"
                :title="org.name"
                :value="org.id">
                {{ org.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="7" :md="24" :sm="24">
          <a-form-item class="metadata-country-container">
              <span slot="label" class="flex align-center">
                {{ $tc('components.labels.country', 1) }}
                <a-tooltip
                  :title="$t('components.toolTips.country')"
                  class="ml-8">
                  <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
                </a-tooltip>
              </span>
              <a-select
                v-decorator="[
                  'country',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('components.description.selectCountry'),
                      },
                    ]
                }]"
                show-search
                :placeholder="$t('components.description.searchCountry')"
                :get-popup-container="(triggerNode) => triggerNode.parentNode">
                <a-icon slot="suffixIcon" type="caret-down"/>
                  <a-select-option v-for="(country, index) in countryArray"
                    :key="index"
                    :disabled="isSupervisor"
                    :value="country.name">
                      {{ country.name }}
                  </a-select-option>
              </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="24" :sm="24">
          <div class="flex align-center mt-24">
            <a-button type="link"
              :disabled="isSupervisor"
              class="new-organization-button txt-orange txt-semi-bold
                txt-font-din-medium letter-spacing flex align-center"
              @click="active = true">
              <a-icon
                type="plus-circle"
                theme="filled"
                :disabled="isSupervisor"
                class="mr-4"
                :class="{'txt-orange': !isSupervisor}"
                style="font-size: 20px;"/>
              {{ $t('components.description.addOrg') }}
            </a-button>
          </div>
        </a-col>
      </a-row>
      <!-- Bring back when we add user permisisons and users -->
      <!-- <a-form-item :label="$t('components.labels.userAccess')" class="w270">
        <div class="flex align-center">
          <a-tooltip :title="$t('components.toolTips.edit')">
            <embed class="mr-4" width="20px" height="20px" src="/pencil-circle.svg">
          </a-tooltip>
          <a href=""
            class="txt-orange ml-4 txt-semi-bold txt-font-din-medium letter-spacing">
            {{ $t('components.description.manageUser') }}
          </a>
        </div>
      </a-form-item> -->
      <a-form-item class="w270" data-cy="share-data">
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.shareData') }}&nbsp;&nbsp;
          <a-tooltip class="ml-8">
            <div slot="title">
              {{ $t('components.toolTips.shareDataYes') }}
              <br><br>
              {{ $t('components.toolTips.shareDataNo') }}
              </div>
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-select
          v-decorator="[
            'share',
            {
              initialValue: 'true',
            }
          ]"
          :get-popup-container="(triggerNode) => triggerNode.parentNode">
          <a-icon slot="suffixIcon" type="caret-down"/>
            <a-select-option value="true" :disabled="isSupervisor">
              {{ $t("values.yes") }}
            </a-select-option>
            <a-select-option value="false" :disabled="isSupervisor">
              {{ $t("values.no") }}
            </a-select-option>
        </a-select>
      </a-form-item>
    </a-card>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import countryArray from '../../../../util/countries';

export default {
  name: 'MetadataAdministrative',
  components: {
    CreateOrganization: () => import('../create-organization/create-organization')
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      countryArray,
      active: false,
      organization: undefined
    };
  },
  computed: {
    ...mapState({
      organizations: state => state.survey.organizations,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  updated() {
    if (this.form) {
      const { organizationId } = this.form.getFieldsValue();
      this.organization = organizationId;
    }
  },
  methods: {
    onComplete(organizationId, values) {
      if (organizationId) {
        this.form.setFieldsValue({
          organizationId,
          country: values.country
        });
      } else {
        const government = this.organizations.find(organization => organization.name === 'Government');
        this.organization = government.id;
        this.form.setFieldsValue({
          organizationId: government.id,
          country: values.country
        });
      }
    },
    onOrganizationChange(value) {
      if (!this.isSupervisor) {
        this.organization = value;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style lang="scss">
.metadata-country-container {
  height: 7rem;
}

.metadata-organization-container {
  height: 7rem;
}

.organization-select {
  padding-right: 4rem;
}

.ant-select-selection-selected-value {
  padding-right: 4rem;
}

.new-organization-button {
  text-wrap: wrap;
  width: 100%;
}
</style>
