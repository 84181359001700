<template>
  <div class="center-div privacy-div-container">
    <a-row id="privacy-policy-container" type="flex">
      <a-col class="privacy-panel" flex="auto">
        <img class="privacy-logo" src="logo.svg">
        <div class="font-med title">
          Privacy Notice: SMART Initiative SMART+ data infrastructure.
        </div>

        <div class="font-reg policy-body">
          The SMART Initiative is a global effort hosted by Action Against Hunger Canada. The SMART Initiative was
          founded as a global inter-agency effort to fight hunger around the world by establishing shared systems,
          standardized tools, resources, reliable data and thought leadership for those working in the nutrition
          information space.
        </div>
        <div class="font-reg policy-body">
          The SMART Initiative makes This Application available to governmental and non-governmental organizations
          (“Survey Managers”) who utilize This Application to conduct surveys in order to collect data within their
          communities relevant to the detection of malnutrition. This Application collects Personal Data of individual
          Users of This Application who act as authorized employees/agents of Survey Managers (the “Data Collectors”),
          and of individuals and their family members who provide information in response to surveys administered by
          the Survey Managers.
        </div>
        <div class="font-reg policy-body">
          The SMART Initiative hosted by Action Against Hunger respects your privacy and is committed to protecting it
          through our compliance with the practices described in this Notice. This Notice describes our practices for
          collecting, using, maintaining, protecting, sharing, and disclosing the Personal Data collected via This
          Application, regardless of the country where the Data Subject is located.
        </div>
        <div class="font-reg policy-body">
          Please read this Notice carefully to understand our policies and practices for processing and storing Personal
          Data. By engaging with This Application, you accept and consent to the practices described in this Notice.
        </div>
        <div class="font-reg policy-body">
          This document can be printed for reference by using the print command in the settings of any browser.
        </div>

        <div class="font-reg policy-body">
          <div class="bold">Host</div>
          <div>Action Against Hunger (ACF)/ Action Contre la Faim (ACF) Canada</div>
          <div>500 – 720 Bathurst Street</div>
          <div>Toronto, ON, M5S 2R4Tel: +1 (416) 6441016</div>
        </div>

        <div class="font-reg policy-body">
          <span class="bold">Host contact email:</span> info@smartmethodology.org
        </div>

        <div class="font-med title space-above">
          Definitions
        </div>

        <div class="font-reg policy-body">
          <div class="bold">Personal Data/Information (or Data)</div>
          Any information that directly, indirectly, or in connection with other information — including a personal
          identification number — allows for the identification or identifiability of a natural person.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Usage Data</div>
          Information collected automatically through this Application (or third-party services employed in this
          Application), which can include: the IP addresses or domain names of the computers utilized by the Users who
          use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
          utilized to submit the request to the server, the size of the file received in response, the numerical code
          indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features
          of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time
          spent on each page within the Application) and the details about the path followed within the Application with
          special reference to the sequence of pages visited, and other parameters about the device operating system
          and/or the User's IT environment.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">User</div>
          The Survey Manager, as well as the individual Data Collector using This Application to collect Data.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Data Subject</div>
          The natural person to whom the Personal Data refers.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Data Processor</div>
          Any natural or legal person, public authority, agency or other body which processes Personal Data on behalf of
          the Data Owner, as described in this privacy policy including the Data Host, and any sub-processor engaged by
          the Data Host.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Data Owner</div>
          The natural or legal person, governmental authority, agency or other body which, alone or jointly with others,
          determines the purposes and means of the processing of Personal Data. Within the SMART Initiative, the
          Survey Managers are the Data Owners of Personal Data collected via This Application and authorize the Data
          Host to process and utilize Data collected via the App to provide aggregated and/or de-identified Data for the
          purposes identified herein.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Data Host</div>
          Action Against Hunger, acting as Data Processor authorized to act on behalf of the Data Owner to collect and
          process Data as part of the SMART Initiative.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">This Application</div>
          The means by which the Personal Data of the User and Data Subjects from whom the User obtains Personal
          Data is collected and processed.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Service</div>
          The service provided by This Application as described above, and in any relative terms (if available), and within
          This Application.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Cookie</div>
          Cookies are Trackers consisting of small sets of data stored in the User's browser.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Tracker</div>
          Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and
          fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the
          User’s device.
        </div>

        <hr>

        <div class="font-med title space-above">
          Legal information
        </div>
        <div class="font-reg policy-body">
          This Privacy Notice has been prepared based on provisions of multiple applicable data privacy laws. This
          privacy policy relates solely to This Application, if not stated otherwise within this document.
        </div>

        <div class="font-med title space-above">
          Types of Data collected
        </div>
        <div class="font-reg policy-body">
          The types of Personal Data that This Application collects include:
        </div>
        <ol class="font-reg policy-body">
          <li>
            <p>Data of individual Data Collector Users:</p>
            <ul>
              <li>
                First name
              </li>
              <li>
                Permissions: contacts, Camera, Microphone and Approximate location
              </li>
            </ul>
          </li>
          <li>
            <p>Data inputted by Data Collector Users About Other Data Subjects:</p>
            <ul>
              <li>
                First name
              </li>
              <li>
                GPS coordinates (if applicable)
              </li>
              <li>
                Height
              </li>
              <li>
                Weight
              </li>
              <li>
                Age
              </li>
              <li>
                Sex
              </li>
              <li>
                Mid Upper Arm Circumference (MUAC)
              </li>
              <li>
                Infant and young child feeding practices (if applicable)
              </li>
              <li>
                Vaccination and supplementation status (if applicable)
              </li>
              <li>
                Health seeking behaviors (if applicable)
              </li>
              <li>
                Hemoglobin level (if applicable)
              </li>
              <li>
                Water Hygiene and Sanitation (if applicable)
              </li>
              <li>
                Food Security (if applicable)
              </li>
            </ul>
          </li>
          <li>
            <p>Data Collected Automatically when using This Application:</p>
            <ul>
              <li>
                Usage Data
              </li>
              <li>
                other files that record interaction with This Application (System logs) or use other Personal Data (such
                as the IP Address).
              </li>
            </ul>
          </li>
        </ol>

        <div class="font-reg policy-body">
          Some of the Personal Data collecting using This Application may be defined as sensitive data under applicable
          laws, and will be treated accordingly as regards the Legal Basis of Processing, Data Subject Rights, and other
          relevant requirements of applicable laws.
        </div>
        <div class="font-reg policy-body">
          Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when
          using This Application. Users are responsible for any third-party Personal Data obtained, published or shared
          through This Application and confirm that they have the third party's consent to provide the Data to the
          Owner.
        </div>
        <div class="font-reg policy-body">
          Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data
          may make it impossible for this Application to provide its Service. In cases where This Application specifically
          states that some Data is not mandatory, Users are free not to communicate this Data without consequences to
          the availability or the functioning of the Service.
        </div>
        <div class="font-reg policy-body">
          Any use of Cookies – or of other tracking tools – by This Application or by the owners of third-party services
          used by This Application serves the purpose of providing the Service required by the User, in addition to any
          other purposes described in the present document.
        </div>

        <div class="font-med title space-above">
          Mode and place of processing the Data
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Methods of processing</div>
          The Data Host takes appropriate security measures to prevent unauthorized access, disclosure, modification, or
          unauthorized destruction of the Data.
        </div>
        <div class="font-reg policy-body">
          The Data processing is carried out using computers and/or IT enabled tools, following organizational
          procedures and modes strictly related to the purposes indicated. In addition to the Data Host, in some cases,
          the Data may be accessible to certain types of persons in charge, involved with the operation of This
          Application (administration, legal, system administration) or external parties (such as third-party technical
          service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if
          necessary, as Sub-Processors by the Host. The updated list of these parties may be requested from the Data
          Host at any time.
        </div>
        <div class="font-reg policy-body">
          The Data Host processes and stores all data securely in the Microsoft Azure Cloud in the US using services that
          are managed by Microsoft. This means that the Personal Data collected by This Application may be transferred
          to, or accessed from, countries other than the country in which Users and other Data Subjects are located.
          Microsoft has security and privacy safeguards in place to protect information it holds on our behalf.
          <a href="https://www.microsoft.com/en-us/trustcenter">Read more about Microsoft’s privacy and security practices</a>
          (external link).
        </div>

        <div class="font-med title space-above">
          Legal basis of processing
        </div>
        <div class="font-reg policy-body">
          The Data Host processes Personal Data relating to Data Subjects on behalf of the Data Owner if one of the
          following applies:
          <ul>
            <li>
              Data Subjects have given their consent (whether express or implied, as required by applicable laws) for
          their Data to be processed as described herein;
            </li>
            <li>
              provision of the Data is necessary for the performance of an agreement with the User and/or Data Subject
          for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to which the Owner is subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public interest or in the exercise of official
          authority vested in the Data Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate interests pursued by the Data Owner or by a
          third party.
            </li>
          </ul>
          In any case, and upon request, the Data Owner will clarify the specific legal basis that applies to the processing,
          and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
          requirement necessary to enter into a contract.
        </div>

        <div class="font-med title space-above">
          Place
        </div>
        <div class="font-reg policy-body">
          The Data is processed at the Data Host's operating offices and in any other places where the parties involved in
          the processing are located, and by the Data Host’s third-party service providers (sub-processors), including the
          Microsoft Azure Cloud in the US.
        </div>
        <div class="font-reg policy-body">
          Depending on the User's location, data transfers may involve transferring the User's or other Data Subject’s
          Data to a country other than their own. To find out more about the place(s) of processing of such transferred
          Data, Users or other Data Subjects can contact the Data Host.
        </div>
        <div class="font-reg policy-body">
          Users and other Data Subjects also are entitled to learn about the legal basis of Data transfers to a country
          other than their country of residence or to any international organization governed by public international law
          or set up by two or more countries, such as the UN, and about the security measures taken by the Data Host to
          safeguard their Data.
        </div>
        <div class="font-reg policy-body">
          If any such transfer takes place, Users or other Data Subjects can find out more by inquire with the Host using
          the information provided in the contact section.
        </div>

        <div class="font-med title space-above">
          Retention time
        </div>
        <div class="font-reg policy-body">
          Personal Data shall be processed and stored for as long as required by the purpose for which it has been
          collected. More specifically,
          <ul>
            <li>
              Personal Data collected for purposes related to the performance of a contract or agreement between the
Data Host and the Data Owner shall be retained until such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of legitimate interests shall be retained as long as needed to
fulfill such purposes. Users may find specific information regarding the legitimate interests within the
relevant sections of this document or by contacting the Data Host.
            </li>
          </ul>
        </div>
        <div class="font-reg policy-body">
          The Data Host may be allowed to retain Personal Data for a longer period whenever the Data Owner and/or
          Data Subject has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the
          Data Host may be obliged to retain Personal Data for a longer period whenever required to do so for the
          performance of a legal obligation or upon order of an authority.
        </div>
        <div class="font-reg policy-body">
          Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to
          erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the
          retention period.
        </div>

        <div class="font-med title space-above">
          The purposes of processing
        </div>
        <div class="font-reg policy-body">
          The Data concerning the User and other Data Subjects is collected for the following purposes:
        </div>
        <div class="font-reg policy-body">
          <div>(1) Users</div>
          <div>To allow the Data Host to:</div>
          <ul>
            <li>
              register the User to use This Application;
            </li>
            <li>
              authenticate the User;
            </li>
            <li>
              receive device permissions for Personal Data access;
            </li>
            <li>
              conduct analytics regarding use of This Application;
            </li>
            <li>
              comply with its legal obligations;
            </li>
            <li>
              respond to enforcement requests;
            </li>
            <li>
              protect its rights and interests (or those of its Users or third parties);
            </li>
            <li>
              detect any malicious or fraudulent activity.
            </li>
          </ul>
        </div>
        <div class="font-reg policy-body">
          <div>(2) Other Data Subjects</div>
          <div>To allow the Data Owner to:</div>
          <ul>
            <li>
              <div>
                collect and obtain demographic and body measurement Data on children and other populations in
                specific geographic locations, to authorize the Data Host on behalf of the Smart Initiative to analyse
                and de-identify the Data, and make data available on an aggregated and/or de-identified individual
                level basis to governmental and non-governmental bodies for malnutrition research and prevention;
              </div>
              <div>
                To allow the Data Owner and Data Host on its behalf to:
                <ul>
                  <li>
                    verify the accuracy of the data collected by Data Collector Users;
                  </li>
                  <li>
                    conduct analytics regarding usage of this Application;
                  </li>
                  <li>
                    comply with legal obligations;
                  </li>
                  <li>
                    respond to enforcement requests;
                  </li>
                  <li>
                    protect lawful rights and interests (or those of its Users or third parties);
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="font-med title space-above">
          Device permissions for Personal Data access
        </div>
        <div class="font-reg policy-body">
          Depending on the User's specific device, This Application may request certain permissions that allow it to
          access the User's device Data as described below.
        </div>
        <div class="font-reg policy-body">
          By default, these permissions must be granted by the User before the respective information can be accessed.
        </div>
        <div class="font-reg policy-body">
          Once the permission has been given, it can be revoked by the User at any time. In order to revoke these
          permissions, Users may refer to the device settings or contact the Data Host for support at the contact details
          provided in the present document.
        </div>
        <div class="font-reg policy-body">
          The exact procedure for controlling app permissions may be dependent on the User's device and software.
        </div>
        <div class="font-reg policy-body">
          Please note that the revoking of such permissions might impact the proper functioning of This Application.
        </div>
        <div class="font-reg policy-body-spaced">
          <div>
            If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed
            to, modified or removed) by This Application.
          </div>
          <div class="bold">
            Approximate location permission (continuous)
          </div>
          <div>
            Used for accessing the User's approximate device location. This Application may collect, use, and share User
            location Data in order to provide location-based services.
          </div>
          <div class="bold">
            Camera permission
          </div>
          <div>
            Used for accessing the camera or capturing images and video from the device.
          </div>
          <div class="bold">
            Contacts permission
          </div>
          <div>
            Used for accessing contacts and profiles on the User's device, including the changing of entries.
          </div>
          <div class="bold">
            Microphone permission
          </div>
          <div>
            Allows accessing and recording microphone audio from the User's device.
          </div>
        </div>

        <div class="font-med title space-above">
          The rights of Data Subjects
        </div>
        <div class="font-reg policy-body">
          Depending on their location and the requirements of applicable laws, Data Subjects may exercise certain rights
          regarding their Data processed by the Host on behalf of the Owner.
        </div>
        <div class="font-reg policy-body">
          In particular, Data Subjects may have the right to do the following:
          <ul>
            <li>
              <b>Withdraw their consent.</b> the right to withdraw consent where Data Subjects have previously given their
              consent to the processing of their Personal Data.
            </li>
            <li>
              <b>Object to processing of their Data.</b> the right to object to the processing of their Data if the processing is
              carried out on a legal basis other than consent. Further details will be provided by the Host and/or Owner
              on request.
            </li>
            <li>
              <b>Access their Data.</b> the right to learn if Data is being processed by the Owner, obtain disclosure regarding
              certain aspects of the processing, and obtain a copy of the Data undergoing processing.
            </li>
            <li>
              <b>Verify and seek rectification.</b> the right to verify the accuracy of their Data and ask for it to be updated or
              corrected.
            </li>
            <li>
              <b>Restrict the processing of their Data.</b> the right, under certain circumstances, to restrict the processing of
              their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
            </li>
            <li>
              <b>Have their Personal Data deleted or otherwise removed.</b> the right, under certain circumstances, to
              obtain the erasure of their Data from the Owner and/or the Host or other Data Processors.
            </li>
            <li>
              <b>Receive their Data and have it transferred to another controller.</b> the right to receive their Data in a
              structured, commonly used and machine readable format and, if technically feasible, to have it
              transmitted to another controller without any hindrance. This provision is applicable provided that the
              Data is processed by automated means and that the processing is based on the Data Subject’s consent, on
              a contract which the Data Subject is part of or on pre-contractual obligations thereof.
            </li>
            <li>
              <b>Lodge a complaint.</b> the right to bring a claim before their competent data protection authority.
            </li>
          </ul>
        </div>

        <div class="font-reg policy-body">
          <div class="bold">How to exercise these rights</div>
          Any requests to exercise Data Subject rights can be directed to the Data Host through the contact details
          provided in this document and will be provided by the Data Host to the Data Owner . These requests can be
          made to the Data Host free of charge and will be addressed by the Data Owner in accordance with applicable
          law. It may be necessary for the Data Host to ask for Personal Data necessary for the Data Host and/or Data
          Owner to verify the identity of the Data Subject, prior to responding.
        </div>

        <div class="font-med title space-above">
          Transfer and Disclosures of Personal Data to Third Parties
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Employees, Agents and Service Providers to Data Host</div>
          Personal Data of Data Subjects will be accessible to the Data Host’s Employees, Agents and Third Party Service
          providers (sub Data-Processors) in order to verify the accuracy of the Data, analyze the Data for research
          purposes, and de-identify and/or aggregate Data collected by Data Collector Users by removing personal
          identifiers such as name or location, and/or producing individual level or aggregated reports to be made
          available to third parties.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Data Owner and Third Party (Governments, Researchers and Others)</div>
          The Data Host obtains the Data on behalf of the Data Owners, to whom the Personal Data will be made
          available for the purposes outlined in this Privacy Notice. Aggregated data derived from the Personal Data will
          be made available to third parties, including governments, non-governmental organizations, researchers and
          others, without special permission. The Data Host, when authorized by the Data Owner, will make available
          Individual level data of Data Subjects (excluding Users), with personal identifiers including name and GPS
          location removed, to researchers upon submission and approval of an application for the Data and signature of
          a data sharing agreement.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Lawful Request from Authorities</div>
          The User declares to be aware that the Data Host and/or Data Owner may be required to reveal Personal Data
          upon request of public authorities. If the Data Subject’s data is transferred to another jurisdiction, their
          Personal Data may be accessible to law enforcement or other authorities within that jurisdiction.
        </div>
        <div class="font-reg policy-body">
          <div class="bold">Legal action</div>
          Personal Data may be used for legal purposes by the Data Host and/or Data Owner in Court or in the stages
          leading to possible legal action arising from improper use of this Application or the related Services.
        </div>

        <div class="font-med title space-above">
          Information not contained in this policy
        </div>
        <div class="font-reg policy-body">
          In addition to the information contained in this Privacy Notice, the Data Host and/or This Application may
          provide the User with additional and contextual information concerning particular Services or the collection
          and processing of Personal Data upon request.
        </div>
        <div class="font-reg policy-body">
          More details concerning the collection or processing of Personal Data may be requested from the Data Host at
          any time. Please see the contact information at the beginning of this document.
        </div>

        <div class="font-med title space-above">
          Changes to this privacy policy
        </div>
        <div class="font-reg policy-body">
          The Data Host reserves the right to make changes to this Privacy Notice at any time by notifying its Users on
          this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice
          to Users via any contact information available to the Data Host. It is strongly recommended to check this page
          often, referring to the date of the last modification listed at the bottom.
        </div>
        <div class="font-reg policy-body">
          Should the changes affect processing activities performed on the basis of consent, the Data Host shall collect
          new consent where required by applicable law.
        </div>
      </a-col>
    </a-row>
  </div>
</template>


<script>export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>
.img-panel {
  padding: 0 0 0 0;
  height: 100%;
  max-width: 42rem;
}

.privacy-panel {
  font-family: 'DINNextLTPro-Regular';
  padding: 42px 59px;
  color: #363534;
  background-color: #fff;
  height: 100%;
}

.panel-body {
  padding: 0 0 0 0;
}

.privacy-div-container {
  overflow-y: scroll;
}

#privacy-policy-container {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  box-shadow: 0 0 1em #333;
  background-color: #fff;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 861px;
  font-size: 1em;
  flex-flow: inherit;
}

.row {
  margin-left: 0;
  margin-right: 0;
  padding: 0 0 0 0;
}

.privacy-logo {
  margin-bottom: 58px;
}

.title {
  color: #363534;
  font-size: 2.6rem;
  line-height: 2.6rem;
  margin-bottom: 0.9rem;
  font-family: 'DINNextLTPro-Medium';
}

.policy-body {
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-bottom: 1.8rem;
}

.policy-body-spaced {
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 1.8rem;
}

.bold {
  font-weight: bold;
}

.space-above {
  padding-top: 1.0rem;
}

.center-div {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
}

ol > li {
   margin-bottom: 2.0rem;
}
</style>
