<template>
  <a-form layout="vertical" :form="form" :hide-required-mark="true">
    <span>
      <h4 class="mt-24 mb-22 txt-bold txt-font-din-medium">{{ $t('components.titles.basicDetails') }}</h4>
      <a-card :bordered="true">
        <a-row class="mb-22" type="flex" :gutter="48">
          <a-col class="" :lg="11" :md="24" :sm="24">
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('components.labels.firstName') }}
              </span>
              <a-input
                  v-decorator="[
                      'firstName',
                      {
                        rules: [
                          {
                            required: true,
                            message: '',
                          },
                        ]
                    }]"
                  :placeholder="$t('components.labels.firstName')"
                  size="large"
                  class=""/>
            </a-form-item>
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('components.labels.lastName') }}
              </span>
              <a-input
                  v-decorator="[
                      'lastName',
                      {
                        rules: [
                          {
                            required: true,
                            message: '',
                          },
                        ]
                    }]"
                  :placeholder="$t('components.labels.lastName')"
                  class=""
                  size="large"/>
            </a-form-item>
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('components.description.gender') }}
              </span>
              <a-select v-decorator="[
                  'gender',
                  {
                    rules: [
                      {
                        required: false,
                        message: '',
                      },
                    ],
                  },
                ]"
                :placeholder="$t('components.description.selectAGender')">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option
                    v-for="(genderOption, index) in genderArray"
                    :key="index"
                    :value="genderOption.value">
                  {{ genderOption.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $tc('values.country', 1) }}
              </span>
              <a-select v-decorator="[
                  'country',
                  {
                    rules: [
                      {
                        required: false,
                        message: '',
                      },
                    ],
                  },
                ]"
                        show-search
                        :get-popup-container="(triggerNode) => triggerNode.parentNode"
                        :filter-option="filterOption"
                        :placeholder="$t('components.description.selectACountry')">
                <!--              :get-popup-container="(triggerNode) => triggerNode.parentNode"-->
                <!--              @change="onOrganizationChange"-->
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option v-for="(country, index) in countryArray"
                                 :key="index"
                                 :value="country.name">
                  {{ country.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col class="" :lg="10" :md="24" :sm="24">
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('components.description.capacity') }}
              </span>
              <a-select v-decorator="[
                  'capacity',
                  {
                    rules: [
                      {
                        required: false,
                        message: '',
                      },
                    ],
                  },
                ]"
                        :placeholder="$t('components.description.selectACapacity')">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option
                    v-for="(option, index) in capacityArray"
                    :key="index"
                    :value="option.value">
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('components.description.affiliation') }}
              </span>
              <a-select v-decorator="[
                  'affiliation',
                  {
                    rules: [
                      {
                        required: false,
                        message: '',
                      },
                    ],
                  },
                ]"
                        :placeholder="$t('components.description.selectAnAffiliation')">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option
                    v-for="(option, index) in affiliationArray"
                    :key="index"
                    :value="option.value">
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <span slot="label" class="flex align-center">
                {{ $t('values.organization') }}
              </span>
              <a-input
                  v-decorator="[
                      'organization',
                      {
                        rules: [
                          {
                            required: false,
                            message: '',
                          },
                        ]
                    }]"
                  :placeholder="$t('values.organization')"
                  class=""
                  size="large"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-button
          class="mt-10 mb-12 w170 flex align-center justify-center"
          type="primary"
          size="large"
          @click="submit">
          {{ $t('values.save') }}
        </a-button>
      </a-card>
    </span>
  </a-form>
</template>

<script>

import { Input } from 'ant-design-vue';
import { pick } from 'ramda';
import request from '../../../../mixins/request';
import { apiPaths } from '../../../../util/request';
import { requestData } from '../../../../store/modules/request';
import countryArray from '../../../../util/countries';


export default {
  name: 'UserEditBasicDetails',
  components: {
  },
  mixins: [request()],
  props: {
  },
  data() {
    return {
      countryArray
    };
  },
  computed: {
    ...requestData(['currentUser', 'user']),
    affiliationArray() {
      const affiliationArray = [
        { value: 'iNGO', label: this.$t('components.dropdown.ingo') },
        { value: 'UN agency', label: this.$t('components.dropdown.unAgency') },
        { value: 'Government', label: this.$t('components.dropdown.government') },
        { value: 'Academia', label: this.$t('components.dropdown.academia') },
        { value: 'local NGO', label: this.$t('components.dropdown.localNGO') },
        { value: 'independent consultant', label: this.$t('components.dropdown.independentConsultant') },
        { value: 'other', label: this.$t('components.dropdown.other') }
      ];
      return affiliationArray;
    },
    capacityArray() {
      const capacityArray = [
        { value: 'National staff', label: this.$t('components.dropdown.nationalStaff') },
        { value: 'Expatriate staff', label: this.$t('components.dropdown.expatriateStaff') }
      ];
      return capacityArray;
    },
    genderArray() {
      const genderArray = [
        { value: 'Male', label: this.$tc('values.male', 1) },
        { value: 'Female', label: this.$tc('values.female', 1) },
        { value: 'Prefer not to say', label: this.$t('components.description.preferNotToSay') }
      ];
      return genderArray;
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'user_basic_details' });
  },
  mounted() {
    this.form.setFieldsValue(
      pick(Object.keys(this.form.formItems),
        this.user.object)
    );
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async submit() {
      return this.form.validateFields(async (err, values) => {
        const messageKey = 'user-basic-details';
        this.$message.loading({ content: this.$t('components.notifications.savingDot'), key: messageKey, duration: 20 });

        if (!err) {
          const response = await this.request({
            method: 'PATCH',
            url: apiPaths.user(this.user.id),
            data: values
          });

          this.$store.commit('setData', {
            key: 'user',
            value: this.user.with(response.data)
          });

          if (this.user.id === this.currentUser.id) {
            this.$store.commit('setData', {
              key: 'currentUser',
              value: this.currentUser.with(response.data)
            });
          }

          this.$message.success({ content: this.$t('components.notifications.userDetailsSaved'), key: messageKey, duration: 20 });
        }
      });
    }
  }
};
</script>


<style lang="scss">
.ant-select-selection {
  height: 40px;
}
</style>
