<template>
  <div class="pt-24">
    <h4 class="mb-22 txt-40 txt-black txt-bold txt-font-din-medium">
      {{ $t('components.titles.anthropometry') }}
    </h4>
    <div class="alert-container">
      <a-alert
        v-for="(item) in activeAlerts" :key="item.id"
        class="sticky-alert"
        :description="item.message"
        type="info"
        closable
        :after-close="item.onClose"
        show-icon/>
    </div>
    <a-tabs :default-active-key="activeTab" size="large" @change="onTabChange">
      <a-tab-pane key="results">
        <div slot="tab" data-cy="results-anthropometry-results-tab" class="txt-black txt-font-din-medium">{{ $t('components.titles.results').toUpperCase() }}</div>
        <AnthroResults v-if="surveyId && projectId"/>
        <a-card v-else :bordered="true" class="flex justify-center align-center">
          <a-spin/>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="dataentry" :disabled="!surveyId">
        <div slot="tab" data-cy="results-anthropometry-data-entry-tab" class="txt-black txt-font-din-medium">{{ $t('components.titles.dataEntry').toUpperCase() }}</div>
        <AnthroNormalizedDataEntry
          :disable-clusters="isRandom || isExhaustive"
          :on-data="onData"
          :on-reload-anthro-data="onReloadAnthroData"
          :on-entry-change="onEntryChange"/>
      </a-tab-pane>
      <a-tab-pane key="settings" :disabled="!surveyId || !settingsLoaded">
        <div slot="tab" data-cy="results-anthropometry-settings-tab" class="txt-black txt-font-din-medium">{{ $t('components.titles.settings').toUpperCase() }}</div>
        <AnthroSettings @activeAlerts="activeAlertsEvent"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import project from '../../../mixins/project';
import AnthroResults from './Results/results.vue';

export default {
  name: 'Anthropometry',
  components: {
    AnthroResults,
    AnthroNormalizedDataEntry: () => import('./NormalizedDataEntry/data-entry'),
    AnthroSettings: () => import('./Settings/settings')
  },
  mixins: [project()],
  props: {
    activeTab: {
      type: String,
      default: 'results'
    }
  },
  data() {
    return {
      changed: false,
      activeAlerts: []
    };
  },
  computed: {
    ...mapGetters(['isRandom', 'isExhaustive']),
    ...mapState({
      surveyId: state => state.survey.surveyId,
      projectId: state => state.survey.project.id,
      settingsLoaded: state => state.results.anthroSettingsLoaded
    })
  },
  watch: {
    surveyId: {
      deep: true,
      handler() {
        if (this.surveyId) {
          this.onData();
        }
      }
    }
  },
  mounted() {
    if (this.surveyId) {
      this.onData();
    }
  },
  methods: {
    ...mapActions([
      'getSurveySubmissionData',
      'getAnthroSubmissionData',
      'getAnthropometrySettings',
      'markAnthroAnalysisRequiresChange'
    ]),
    activeAlertsEvent($event) {
      this.activeAlerts = $event;
    },
    onTabChange(newActive) {
      if (this.changed) {
        this.markAnthroAnalysisRequiresChange();
        this.changed = false;
      }
      const route = { name: 'Anthropometry', params: { activeTab: newActive } };
      this.$router.replace(route);
    },
    onEntryChange() {
      this.changed = true;
    },
    onReloadAnthroData() {
      this.getAnthroSubmissionData({
        projectId: this.projectId,
        surveyId: this.surveyId
      });
    },
    onData() {
      this.getAnthroSubmissionData({
        projectId: this.projectId,
        surveyId: this.surveyId
      });
      this.getAnthropometrySettings({ surveyId: this.surveyId, projectId: this.projectId });
    }
  }
};
</script>

<style lang="scss">
.sticky-alert {
  margin-top: 10px;
  align-content: center;
  min-width: 70%;
  width: auto;
  max-width: 100%;
  margin-right: 24pt;
}

.alert-container {
  top: 0;
  position: sticky;
  z-index: 9999;
  width: auto;
}
</style>
