// the base module
import Handsontable from 'handsontable/base';

// cell type modules
import {
  registerCellType,
  AutocompleteCellType,
  CheckboxCellType,
  DateCellType,
  DropdownCellType,
  HandsontableCellType,
  NumericCellType,
  TextCellType,
  TimeCellType
} from 'handsontable/cellTypes';

// renderer modules
import {
  registerRenderer,
  baseRenderer,
  checkboxRenderer,
  autocompleteRenderer,
  htmlRenderer,
  numericRenderer,
  textRenderer
} from 'handsontable/renderers';

// editor modules
import {
  registerEditor,
  AutocompleteEditor,
  CheckboxEditor,
  BaseEditor,
  DateEditor,
  DropdownEditor,
  HandsontableEditor,
  NumericEditor,
  SelectEditor,
  TextEditor
} from 'handsontable/editors';

// validator modules
/*import {
  autocompleteValidator,
  dateValidator,
  numericValidator,
  timeValidator
} from 'handsontable/validators';*/

// plugin modules
import {
/*
  Autofill,
  ColumnSummary,
  Comments,
  CustomBorders,
  DragToScroll,
  Formulas,
  ManualColumnFreeze,
  ManualColumnMove,
  ManualColumnResize,
  ManualRowMove,
  ManualRowResize,
  MergeCells,
  MultiColumnSorting,
  MultipleSelectionHandles,
  NestedRows,
  PersistentState,
  Search,
  TouchScroll,
  TrimRows,*/
  UndoRedo,
  registerPlugin,
  AutoColumnSize,
  AutoRowSize,
  HiddenColumns,
  BasePlugin,
  ExportFile,
  BindRowsWithHeaders,
  CollapsibleColumns,
  ColumnSorting,
  CopyPaste,
  DropdownMenu,
  Filters,
  NestedHeaders,
  ContextMenu,
  HiddenRows
} from 'handsontable/plugins';

// translation modules
import {
  registerLanguageDictionary,
  enUS,
  frFR
} from 'handsontable/i18n';

// registering functions that let you quickly register all modules at once
import {
  registerAllValidators
} from 'handsontable/registry';

// register all cell types at once
registerCellType(AutocompleteCellType);
registerCellType(DateCellType);
registerCellType(DropdownCellType);
registerCellType(HandsontableCellType);
registerCellType(NumericCellType);
registerCellType(TextCellType);
registerCellType(TimeCellType);
registerCellType(CheckboxCellType);

// register all renderers at once
registerRenderer(baseRenderer);
registerRenderer(autocompleteRenderer);
registerRenderer(htmlRenderer);
registerRenderer(numericRenderer);
registerRenderer(textRenderer);
registerRenderer(checkboxRenderer);

// register all editors at once
registerEditor(AutocompleteEditor);
registerEditor(BaseEditor);
registerEditor(DateEditor);
registerEditor(DropdownEditor);
registerEditor(HandsontableEditor);
registerEditor(NumericEditor);
registerEditor(SelectEditor);
registerEditor(TextEditor);
registerEditor(CheckboxEditor);

// register all validators at once
registerAllValidators();

// register all plugins at once
registerPlugin(HiddenColumns);
registerPlugin(CopyPaste);
registerPlugin(HiddenRows);
registerPlugin(Filters);
registerPlugin(DropdownMenu);
registerPlugin(AutoColumnSize);
registerPlugin(AutoRowSize);
registerPlugin(BasePlugin);
registerPlugin(ExportFile);
registerPlugin(BindRowsWithHeaders);
registerPlugin(CollapsibleColumns);
registerPlugin(ColumnSorting);
registerPlugin(NestedHeaders);
registerPlugin(UndoRedo);
registerPlugin(ContextMenu);

// register individual translations
registerLanguageDictionary(enUS);
registerLanguageDictionary(frFR);

