<template>
  <div class="pt-24" :class="{ 'disabled' : disabled }">
    <a-alert
      v-if="metadata && metadata.surveyType && surveyTypeWasSetByModal && !hideSurveyTypeWarning"
      class="mb-20"
      :description="$t('components.notifications.selectedSurveyType', { surveyType: translateSurveyType(metadata.surveyType)})"
      type="info"
      closable
      :after-close="setLocalhideSurveyTypeWarning"
      show-icon/>
    <ClusterSamplingAlerts/>
    <SurveyTypeModal v-if="loading === false && metadata && metadata.surveyType == null && !isSupervisor" :metadata="metadata" :on-complete="onSurveyTypeComplete"/>
    <MetadataSampling
      v-if="metadata"
      v-model="sampling"
      :metadata="metadata"
      :on-save="save"/>
    <a-skeleton v-if="!sampling || !size || !metadata" active class="w100 h100"/>
    <MetadataSamplingSize
      v-if="sampling && metadata" v-model="size" :sampling="sampling" :on-save="save"/>
    <MetadataClusterSelection
      v-if="sampling && size && metadata"
      v-model="cluster"
      :metadata="metadata"
      :on-save="save"
      :sampling="sampling"
      :size="size"/>
    <div v-if="metadata" class="flex">
      <a-button
        class="mt-10 mb-24 w170 mr-8 flex align-center justify-center"
        type="primary"
        size="large"
        @click="goBack">
        <a-icon type="left" class="txt-10"/>
        {{ $t('values.back') }}
      </a-button>
      <a-button
        class="mt-10 mb-24 w170 flex align-center justify-center"
        type="primary"
        size="large"
        @click="goNext">
        {{ $t('values.next') }}
        <a-icon type="right" class="txt-10"/>
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import project from '../../../mixins/project';
import isSurveyActive from '../../../mixins/isSurveyActive';

export default {
  name: 'ClusterSampling',
  components: {
    MetadataSampling: () => import('./sampling/sampling'),
    MetadataSamplingSize: () => import('./sampling-size/sampling-size'),
    MetadataClusterSelection: () => import('./cluster-selection/cluster-selection'),
    ClusterSamplingAlerts: () => import('../../qr-regeneration-warning/cluster-sampling-alerts.vue'),
    SurveyTypeModal: () => import('./survey-type-modal/survey-type-modal.vue')
  },
  mixins: [project(), isSurveyActive()],
  data() {
    return {
      sampling: undefined,
      size: undefined,
      cluster: undefined,
      surveyTypeWasSetByModal: false
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      loading: state => state.survey.loading,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    hideSurveyTypeWarning() {
      return !!window.localStorage.getItem('surveyTypeWarning');
    }
  },
  mounted() {
    this.surveyTypeWasSetByModal = !!window.localStorage.getItem('surveyTypeWasSetByModal');
  },
  methods: {
    ...mapActions([
      'setMetadata'
    ]),
    save: debounce(function() {
      const values = {
        ...this.metadata,
        ...this.sampling,
        ...this.size,
        ...this.cluster
      };
      this.setMetadata(values);
    }, 500),
    goBack() {
      this.$router.push('/survey-planning/metadata');
    },
    goNext() {
      if (!this.isSupervisor) {
        this.save();
      }
      this.$router.push('/survey-planning/questionnaire');
    },
    setLocalhideSurveyTypeWarning() {
      window.localStorage.setItem('surveyTypeWarning', true);
    },
    onSurveyTypeComplete() {
      this.surveyTypeWasSetByModal = true;
    },
    translateSurveyType(surveyType) {
      let translate = this.$t('components.dropdown.other');
      if (surveyType.match(/rapid/i)) {
        translate = this.$t('components.dropdown.rapidSmart');
      } else if (surveyType.match(/full/i)) {
        translate = this.$t('components.dropdown.fullSmart');
      } else if (surveyType.match(/nns/i)) {
        translate = this.$t('components.dropdown.nns');
      } else if (surveyType.match(/sens/i)) {
        translate = this.$t('components.dropdown.sens');
      }
      return translate;
    }
  }
};
</script>

<style lang="scss">
</style>
