var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"data-cy":"Logistics"}},[_c('h4',{staticClass:"mt-40 mb-22 txt-bold txt-font-din-medium"},[_vm._v(_vm._s(_vm.$t('components.titles.logistics').toUpperCase()))]),_c('a-card',{staticClass:"mb-22",attrs:{"bordered":true}},[_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.numberOfTeams'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.selectNumberOfTeams')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'numberOfTeams',
          {
            rules: [
              {
                required: _vm.surveyType !== 'Other',
                message: _vm.$t('components.description.enterHowManyTeams'),
              } ],
          } ]),expression:"[\n          'numberOfTeams',\n          {\n            rules: [\n              {\n                required: surveyType !== 'Other',\n                message: $t('components.description.enterHowManyTeams'),\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isSupervisor,"min":1,"max":100}})],1),_c('a-form-item',{staticClass:"w270"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.surveyEquipment'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.surveyEquipment')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'surveyEquipment',
          {
            initialValue: 'Manual Measurement Tools',
            rules: [
              {
                required: _vm.surveyType !== 'Other',
                message: _vm.$t('components.description.selectEquipment')
              } ],
          } ]),expression:"[\n          'surveyEquipment',\n          {\n            initialValue: 'Manual Measurement Tools',\n            rules: [\n              {\n                required: surveyType !== 'Other',\n                message: $t('components.description.selectEquipment')\n              },\n            ],\n          },\n        ]"}],attrs:{"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Manual Measurement Tools"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.manualMeasurementTools'))+" ")]),_c('a-select-option',{attrs:{"disabled":_vm.isSupervisor,"value":"Mobile app for 3D measurements"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.mobileAppFor3DMeasurements'))+" ")])],1)],1),_c('a-form-item',{staticClass:"w270"},[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.gpsData'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.gpsData')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'allowGPS',
        {
          initialValue: true,
          rules: [
            {
              required: true,
              message: _vm.$t('components.description.selectEquipment'),
            } ],
        } ]),expression:"[\n        'allowGPS',\n        {\n          initialValue: true,\n          rules: [\n            {\n              required: true,\n              message: $t('components.description.selectEquipment'),\n            },\n          ],\n        },\n      ]"}],attrs:{"disabled":_vm.isSupervisor,"name":"radioGroup"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('values.yes'))+" ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('values.no'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }