<template>
  <div class="sidebar">
    <div class="current-survey-container" :class="{ 'current-survey-container-supervisor': currentUserRoleSystem === 'srvy-sup', 'current-survey-container-manager': currentUserRoleSystem === 'srvy-mgr' }">
      <p class="survey-name">{{ surveyName || '' }}</p>
      <p v-if="currentUserRoleSystem !== 'srvy-mgr' && surveyManager">
        <a-popover>
          <template slot="content">
            <p>{{ $t('components.labels.firstName') }}: {{ surveyManager.firstName }}</p>
            <p>{{ $t('components.labels.lastName') }}: {{ surveyManager.lastName }}</p>
            <p>Email: <a :href="`mailto:${surveyManager.email}`">{{ surveyManager.email }}</a></p>
          </template>
          <span class="managed-by-text">{{ $t('components.description.managedBy', { name: (surveyManager.firstName || '') + ' ' + (surveyManager.lastName || '') }) }}</span>
        </a-popover>
      </p>
      <p class="access-text">
        <embed v-if="getRoleIconOnSurvey(currentUserRoleSystem)" class="mr-4 vertical-middle" width="20px" height="20px" :src="getRoleIconOnSurvey(currentUserRoleSystem)">
        <span class="vertical-middle">{{ currentUserRoleLabel }}</span>
      </p>
    </div>
    <a-menu
      style="flex-grow: 1;"
      class="pt-8"
      :selected-keys="routeName"
      mode="inline">
      <a-menu-item key="/">
        <router-link id="navbar-projects-link" data-cy="menu-item-dashboard" to="/" exact>
          <embed width="20px" src="/nav-icons-dashboard.svg" class="mr-16">{{ $t('components.labels.dashboard') }}
        </router-link>
      </a-menu-item>

      <a-sub-menu key="sub2" :inline-collapsed="true">
        <span slot="title" data-cy="menu-item-survey-planning">
          <embed width="20px" src="/nav-icons-survey.svg" class="mr-16">
          <span :title="$t('components.labels.surveyPlanning')" class="survey-planning-title">{{ $t('components.labels.surveyPlanning') }}</span>
        </span>
        <a-timeline mode="left">
          <a-timeline-item>
            <a-icon
              v-if="isMetadataComplete"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="1">
              <router-link to="/survey-planning/metadata" data-cy="sub-menu-item-metadata">
                {{ $t('components.labels.metadata') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
          <a-timeline-item>
            <a-icon
              v-if="isClustersComplete"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="2">
              <router-link to="/survey-planning/cluster-sampling" data-cy="sub-menu-item-cluster-sampling">
                {{ $t('components.labels.clusterAndSampling') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
          <a-timeline-item>
            <a-icon
              v-if="isQuestionnareComplete"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="3">
              <router-link to="/survey-planning/questionnaire" data-cy="sub-menu-item-questionnaire">
                {{ $t('components.labels.questionnaire') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
          <a-timeline-item>
            <a-icon
              v-if="isProtocolValidated"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="4">
              <router-link to="/survey-planning/protocol" data-cy="sub-menu-item-protocol">
                {{ $t('components.labels.protocol') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
          <a-timeline-item>
            <a-icon
              v-if="isLocalEventComplete"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="5">
              <router-link to="/survey-planning/local-event-calendar" data-cy="sub-menu-item-local-event-calendar">
                {{ $t('components.labels.localEventCalendar') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
          <a-timeline-item>
            <a-icon
              v-if="isEnumeratorsComplete"
              slot="dot"
              theme="filled"
              type="check-circle"
              class="txt-green"
              style="font-size: 16px;"/>
            <a-menu-item key="6">
              <router-link to="/survey-planning/enumerators" data-cy="sub-menu-item-enumerators">
                {{ $t('components.labels.enumerators') }}
              </router-link>
            </a-menu-item>
          </a-timeline-item>
        </a-timeline>
      </a-sub-menu>

      <a-sub-menu key="sub3" :inline-collapsed="true">
        <span slot="title" data-cy="menu-item-results">
          <embed width="20px" src="/nav-icons-results.svg" class="mr-16">
          <span>{{ $t('components.labels.results') }}</span>
        </span>
        <a-menu-item key="1">
          <router-link key="results-anthropometry" data-cy="sub-menu-item-anthropometry" to="/results/Anthropometry" :disabled="disableAnthro">
            {{ $t('components.labels.anthropometry') }}
          </router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link key="results-mortality" data-cy="sub-menu-item-mortality" to="/results/Mortality" :disabled="disableMortality">
            {{ $t('components.labels.mortalityAndDemography') }}
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="report">
        <router-link to="/report" data-cy="menu-item-report" exact :disabled="disableReport">
          <embed width="20px" src="/nav-icons-report.svg" class="mr-16">{{ $t('components.labels.report') }}
        </router-link>
      </a-menu-item>

      <!-- <a-menu-item key="5" :disabled="true">
        <embed width="20px" src="/nav-icons-survey-archives.svg" class="mr-16">Survey Archive
      </a-menu-item> -->
    </a-menu>
    <localization-picker/>
  </div>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex';
import { getRoleIconOnSurvey, label } from '@/util/roles';
import request from '../mixins/request';
import routes from '../mixins/routes';


export default {
  name: 'MainMenu',
  components: {
    LocalizationPicker: () => import('./localization-picker.vue')
  },
  mixins: [request(), routes()],
  data() {
    return {
      anthroSelected: false,
      mortalitySelected: false,
      formIndicatorSelected: null
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      metadata: state => state.survey.metadata,
      selectedIndicators: state => state.indicators.selectedIndicators,
      surveyManager: state => state.survey.surveyManager,
      currentUserRoleSystem: state => state.survey.currentUserRoleSystem,
      surveyName: state => state.survey.name
    }),
    ...mapGetters([
      'isMetadataComplete',
      'isQuestionnareComplete',
      'isClustersComplete',
      'isEnumeratorsComplete',
      'isLocalEventComplete',
      'isProtocolValidated'
    ]),
    routeName() {
      return [this.$route.path];
    },
    surveyType() {
      if (this.metadata) {
        return this.metadata.surveyType;
      }

      return null;
    },
    disableAnthro() {
      const { anthroSelected } = this;
      return !anthroSelected && (this.surveyType !== 'SENS' && this.surveyType !== 'Other');
    },
    disableMortality() {
      const { mortalitySelected } = this;
      return !mortalitySelected && (this.surveyType !== 'SENS' && this.surveyType !== 'Other');
    },
    disableReport() {
      return !this.isQuestionnareComplete;
    },
    currentUserRoleLabel() {
      if (!this.currentUserRoleSystem) return '';
      const access = this.currentUserRoleSystem === 'srvy-sup' ? this.$t('components.description.viewAccess') : this.$t('components.description.editAccess');
      return `${this.$t(`components.labels.${label[this.currentUserRoleSystem]}`)}, ${access}`;
    }
  },
  watch: {
    selectedIndicatorsFormUpload: {
      handler(newValue) {
        this.formIndicatorSelected = newValue;
      }
    },
    selectedIndicators: {
      handler(newValue) {
        this.anthroSelected = newValue.findIndex(item => item.name === 'anthropometry') > -1;
        this.mortalitySelected = newValue.findIndex(item => item.name === 'mortality') > -1;
      }
    },
    surveyId: {
      deep: true,
      handler(surveyId) {
        if (surveyId) {
          this.loadSelectedIndicators({
            surveyId: this.surveyId
          });
        }
      }
    }
  },
  created() {
    if (this.surveyId) {
      this.loadSelectedIndicators({
        surveyId: this.surveyId
      });
    }
  },
  methods: {
    getRoleIconOnSurvey,
    ...mapActions([
      'loadSelectedIndicators'
    ])
  }
};
</script>

<style lang="scss">
@import '../assets/scss/variables';
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 250px;
  background: white;
  overflow-y: auto;

  > div {
    border-right: 1px solid #e8e8e8;
  }

  .current-survey-container {
    padding-left: 19px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .current-survey-container p {
    margin-bottom: 0.5rem;
  }

  .current-survey-container-supervisor {
    border-left: 5px solid $color-survey-supervisor-primary;
    background-color: $color-survey-supervisor-secondary;
  }
  .current-survey-container-manager {
    border-left: 5px solid $color-survey-manager-primary;
    background-color: $color-survey-manager-secondary;
  }

  .survey-name {
    font-family: 'DINNextLTPro-Medium';
    font-size: 16px;
    letter-spacing: -0.25px;
  }

  .managed-by-text {
    font-size: 14px;
    font-style: italic;
    letter-spacing: -0.25px;
    color: rgba(54, 53, 52, 0.5);
    font-family: 'DINNextLTPro-Regular';
  }

  .vertical-middle {
    vertical-align: middle;
  }

  .access-text {
    vertical-align: middle;
    display: inline-block;
  }

  .ant-menu-item a,
  .ant-menu-item.ant-menu-item-disabled {
    display: flex;
    align-items: center;
  }

  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-submenu,
  .ant-menu-submenu-title,
  .ant-menu-submenu a  {
    font-size: 18px;
    color: $color-link;
    letter-spacing: 0.02em;
    &:hover, &:active {
      text-decoration: none;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  .ant-timeline-item-content .ant-menu-item-selected,
  .ant-timeline-item-content .router-link-active,
  .router-link-active {
    color: $color-accent-primary !important;
    background-color: transparent;

    .router-link-active {
      color: $color-accent-primary !important;
    }

    &:after {
      display: none;
    }
  }

  .ant-menu-sub {
    .ant-menu-item a {
      font-size: 16px;
    }
  }

  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background-image: linear-gradient(to right, $color-link, $color-link);
  }

  .ant-menu-submenu-title {
    padding-left: 18px !important;
  }

  .ant-menu-sub .ant-menu-item {
    margin-bottom: 4px;
  }

  .ant-menu-item .anticon {
    min-width: 30px;
    font-size: 20px;
    margin-left: 2px;
  }

  .ant-menu-submenu-title > span {
    display: flex;
    margin-left: 6px;

    embed {
      margin-bottom: 5px;
    }
  }

  .ant-timeline {
    margin-left: 30px;
    margin-top: 8PX;
  }

  .ant-timeline-item-last {
    padding-bottom: 0;
  }

  .ant-timeline-item-content li {
    margin-left: 5px;
  }

  .survey-planning-title {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.ant-popover-inner-content p {
  margin-bottom: 0.25rem;
}
</style>
