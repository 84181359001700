import {
  ImageRun,
  File,
  HeadingLevel,
  AlignmentType,
  Paragraph,
  PageBreak,
  TextRun,
  PageNumber,
  PageNumberFormat,
  Footer,
  Packer,
  TableOfContents,
  SectionType,
  TabStopType,
  TabStopPosition,
  LeaderType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
  ShadingType,
  ExternalHyperlink,
  LevelFormat,
  convertInchesToTwip
} from 'docx';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { geoUnitsToTableData } from './clusters';
import { i18n } from '../localization';

const toDataURL = url => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => {
    const { width, height } = image;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    const base64 = canvas.toDataURL({ format: 'png' });
    resolve(base64);
  };
  image.onerror = reject;
  image.src = url;
});

const tableCellMargins = {
  top: convertInchesToTwip(0.01),
  bottom: convertInchesToTwip(0.02),
  left: convertInchesToTwip(0.08),
  right: convertInchesToTwip(0.08)
};

const buildSubheaderAndDescription = (title, description) => [
  new Paragraph({
    heading: HeadingLevel.HEADING_2,
    spacing: {
      before: 400,
      after: 200
    },
    children: [
      new TextRun({
        text: title,
        color: '000000',
        size: 26,
        underline: true,
        bold: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    spacing: {
      after: 200
    },
    heading: HeadingLevel.TITLE,
    children: [
      new TextRun({
        text: description,
        color: '8d8f8f',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  })
];

const buildHeader = title => new Paragraph({
  heading: HeadingLevel.HEADING_1,
  spacing: {
    after: 200,
    before: 400
  },
  children: [
    new TextRun({
      text: title,
      color: 'e18731',
      size: 32,
      font: 'Nexa Light',
      bold: true
    })
  ]
});

const buildClusterAssignmentTable = (geoUnits) => {
  const data = geoUnitsToTableData(geoUnits);
  return new Table({
    margins: {
      bottom: 300,
      top: 500
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.geographicalUnit'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.populationSize'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.tc('components.description.cluster', 1),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.location'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      ...data.map(([name, population, cluster, location]) => new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: name,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: population,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: cluster,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: location,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }))
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    columnWidths: [3000, 3000, 3000, 3000]
  });
};

const buildRegularBullet = (level, text) => new Paragraph({
  numbering: {
    reference: 'bullet-points',
    level
  },
  spacing: {
    after: 200,
    line: 300

  },
  children: [
    new TextRun({
      text,
      color: '000000',
      size: 24,
      font: 'Nexa Light'
    })
  ]
});

const buildImageRunFromUrl = async (url, width, height) => {
  const imageData = await toDataURL(url);
  const imageBase = imageData.split('base64,')[1];
  return new ImageRun({
    data: Buffer.from(imageBase, 'base64'),
    transformation: {
      width,
      height
    }
  });
};

const buildCoverageTable = (metadata) => new Table({
  margins: {
    bottom: 300
  },
  rows: [
    new TableRow({
      children: [
        new TableCell({
          shading: {
            fill: '363634',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          margins: tableCellMargins,
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.parameter'),
                  color: 'ffffff',
                  size: 22,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          shading: {
            fill: '363634',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          margins: tableCellMargins,
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.valueMinutes'),
                  color: 'ffffff',
                  size: 22,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.totalTimePerDayForFieldWork'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.fieldworkTime,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.travelTimeToClusterLocation'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.clusterTravelTime,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.durationForInitialIntroduction'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.duration,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.totalDurationOfBreaks'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.breaks,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.travelTimeFromOneHouseholdToAnother'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.householdTravelTime,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.averageTimeInTheHousehold'),
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.timeAtHousehold,
                  size: 20,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          shading: {
            fill: 'e5c28b',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.numberOfHhPlannedDayTeam'),
                  color: '000000',
                  size: 20,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          shading: {
            fill: 'e5c28b',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: metadata.householdPerDayTeam,
                  color: '000000',
                  size: 20,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    })
  ],
  width: {
    size: 100,
    type: WidthType.PERCENTAGE
  },
  columnWidths: [4000, 4000]
});

const buildSamplingSize = (metadata) => [
  new Paragraph({
    heading: HeadingLevel.HEADING_2,
    spacing: {
      before: 400,
      after: 200
    },
    children: [
      new TextRun({
        text: `6.2 ${i18n.t('components.titles.sampleSize')}`,
        color: '000000',
        size: 26,
        underline: true,
        bold: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.HEADING_3,
    indent: {
      left: 400
    },
    spacing: {
      after: 200
    },
    children: [
      new TextRun({
        text: `6.2.1 ${i18n.t('components.titles.correctionForSmallPopulationSize')}`,
        color: 'e18731',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.TITLE,
    spacing: {
      after: 200,
      line: 300
    },
    indent: {
      left: 700
    },
    children: [
      new TextRun({
        text: i18n.t('components.description.outlineWhetherACorrectionForSmallPopulation'),
        color: '8d8f8f',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.TITLE,
    spacing: {
      after: 500,
      line: 300
    },
    indent: {
      left: 700
    },
    children: [
      new TextRun({
        text: metadata.correction ? i18n.t('components.description.aCorrectionForSmallPopulation') : i18n.t('components.description.aCorrectionForSmallPopulationSizeWasNotUsed'),
        color: '000000',
        size: 24,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.HEADING_3,
    indent: {
      left: 400
    },
    spacing: {
      after: 200
    },
    children: [
      new TextRun({
        text: `6.2.2 ${i18n.t('components.titles.ageGroup')}`,
        color: 'e18731',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.TITLE,
    spacing: {
      after: 200,
      line: 300
    },
    indent: {
      left: 700
    },
    children: [
      new TextRun({
        text: i18n.t('components.description.identifyWhichAgeGroupIsBeingSurveyed'),
        color: '8d8f8f',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.TITLE,
    spacing: {
      after: 500,
      line: 300
    },
    indent: {
      left: 700
    },
    children: [
      new TextRun({
        text: i18n.t('components.description.theAgeGroupForThisSurvey', {
          includedAgeGroup: `${metadata.includedAgeGroup}`
        }),
        color: '000000',
        size: 24,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.HEADING_3,
    indent: {
      left: 400
    },
    spacing: {
      after: 200
    },
    children: [
      new TextRun({
        text: `6.2.3 ${i18n.t('components.titles.parameters')}`,
        color: 'e18731',
        size: 24,
        italics: true,
        font: 'Nexa Light'
      })
    ]
  }),
  new Paragraph({
    heading: HeadingLevel.TITLE,
    indent: {
      left: 400
    },
    spacing: {
      after: 300,
      line: 300
    },
    children: [
      new TextRun({
        text: i18n.t('components.description.theFollowingAssumptions'),
        color: '00000',
        size: 24,
        font: 'Nexa Light'
      })
    ]
  }),
  new Table({
    margins: {
      bottom: 300
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.parametersForAnthropometry'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.tc('components.description.value', 1),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                spacing: {
                  line: 300
                },
                children: [
                  new TextRun({
                    text: i18n.t('components.description.assumptionsJustificationsBasedOnContext'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.estimatedPrevalenceOfGam'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.anthroPrevalence,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.anthroPrevalenceJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.desiredPrecisionSigned'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroPercision,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroPercisionJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.designEffectIfApplicable'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroEffect,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroEffectJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.childrenToBeIncluded'),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: (parseFloat(metadata.antroSubjectsToInclude)).toFixed(0),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.averageHhSize'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.anthroHouseholdSize,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.anthroHouseholdSizeJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.childrenUnder5'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroUnderFive,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroUnderFiveJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.nonResponseHouseholds'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroNonresponse,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.antroNonresponseJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.householdsToBeIncluded'),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: (parseFloat(metadata.antroHouseholdsToInclude)).toFixed(0),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      })
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    columnWidths: [4000, 1200, 4000]
  }),
  new Table({
    margins: {
      bottom: 300
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.parametersForMortality'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.tc('components.description.value', 1),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: '363634',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.assumptionsJustificationsBasedOnContext'),
                    color: 'ffffff',
                    size: 22,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.estimatedDeathRate10000Day'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityRate,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityRateJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.desiredPrecision10000Day'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityPrecision,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityPrecisionJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.designEffectIfApplicable'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityEffect,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityEffectJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.recallPeriodInDays'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityRecall,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityRecallJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.populationToBeIncluded'),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: (parseFloat(metadata.mortalityPopulationIncluded)).toFixed(0),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.averageHhSize'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityHouseholdSize,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityHouseholdSizeJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.nonResponseHouseholds'),
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityNonresponse,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: metadata.mortalityNonresponseJustification,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: i18n.t('components.description.householdsToBeIncluded'),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: parseFloat(metadata.mortalityHouseholdIncluded).toFixed(0),
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            shading: {
              fill: 'e5c28b',
              type: ShadingType.SOLID,
              color: 'auto'
            },
            margins: tableCellMargins,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    color: '000000',
                    size: 20,
                    bold: true,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        ]
      })
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    columnWidths: [4000, 1200, 4000]
  })
];

const buildAnnexes = (metadata, geoUnits, calendar, organigram) => {
  const isRandomOrExhaustive = metadata.samplingMethod === 'Random' || metadata.samplingMethod === 'Exhaustive';
  const clusterAnnex = [
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.titles.annex')} 5 - ${i18n.t('components.titles.clusterControlForm')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.attachTheClusterControlFormBeingUsedForTheSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        before: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.noteThatThePlatformDashboard'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const clusterAssigmentAnnex = [
    ...buildSubheaderAndDescription(`${i18n.t('components.titles.annex')} 2 - ${i18n.t('components.titles.clusterAssignment')}`, i18n.t('components.description.attachTheClusterAssignment')),
    buildClusterAssignmentTable(geoUnits)
  ];

  const annexCount = (count) => {
    if (isRandomOrExhaustive) {
      return count - 1;
    }
    return count;
  };

  const caledarLinks = [];
  if (calendar) {
    caledarLinks.push(new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: `${i18n.t('components.description.youCanDownloadTheLocalEventCalendar')} `,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new ExternalHyperlink({
          child: new TextRun({
            text: i18n.t('components.description.here'),
            color: 'e18731',
            size: 24,
            underline: true,
            font: 'Nexa Light'
          }),
          link: calendar.url
        })
      ]
    }));
  }
  return [
    buildHeader('10. Annexes'),
    ...buildSubheaderAndDescription(`${i18n.t('components.titles.annex')} 1 - ${i18n.t('components.titles.questionnaire')}`, i18n.t('components.description.attachTheQuestionnaire')),
    ...isRandomOrExhaustive ? [] : clusterAssigmentAnnex,
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.annexLocalEventCalendar', {
            annexCount: `${annexCount(3)}`
          }),
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    ...caledarLinks,
    ...buildSubheaderAndDescription(i18n.t('components.description.annexSurveyTeamJobDescription', {
      annexCount: `${annexCount(4)}`
    }), i18n.t('components.description.editTheStandardized')),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.jobDescriptionForSurveyTeams'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.eachSurveyTeamShouldBeComposed')),
    buildRegularBullet(1, i18n.t('components.description.theCompositionOfTeamMembers')),

    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.allSurveyTeamMembers'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.theyShouldBeAbleToWrite')),
    buildRegularBullet(1, i18n.t('components.description.theyShouldHaveSufficientLevelOfEducation')),
    buildRegularBullet(1, i18n.t('components.description.theyShouldBePhysicallyFitToWalk')),

    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.itIsNotNecessaryForAllTeamMembers'),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.surveyManager1PerSurvey'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.skillsAndRequiredAbilities'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.haveAUniversityDegreeOrTheEquivalent')),
    buildRegularBullet(1, i18n.t('components.description.haveSignificantExperience')),
    buildRegularBullet(1, i18n.t('components.description.beFamiliarWithTheSmartSurveyMethodology')),
    buildRegularBullet(1, i18n.t('components.description.beFluentInEnglishWithExcellent')),
    buildRegularBullet(1, i18n.t('components.description.ifDataCollectionWillBeDoneMdcMethods')),
    buildRegularBullet(2, i18n.t('components.description.proficientInItAndAbleToAdapt')),
    buildRegularBullet(2, i18n.t('components.description.familiarWithTheUseOfTheSmartphones')),
    buildRegularBullet(2, i18n.t('components.description.forSurveyManagerWithNoPrior')),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.tasks'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.theManagerGuaranteesTheRespect')),
    buildRegularBullet(2, i18n.t('components.description.gatheringAvailableInformation')),
    buildRegularBullet(2, i18n.t('components.description.selectingTeamMembers')),
    buildRegularBullet(2, i18n.t('components.description.trainingTeamMembers')),
    buildRegularBullet(2, i18n.t('components.description.supervisionOfTheSurveyDataCollection')),
    buildRegularBullet(3, i18n.t('components.description.visitingTeamsInTheField')),
    buildRegularBullet(3, i18n.t('components.description.TheManagerShouldNoteTeams')),
    buildRegularBullet(3, i18n.t('components.description.ensuringThatHouseholdsAreSelected')),
    buildRegularBullet(3, i18n.t('components.description.decidingOnHowToOvercomeTheProblems')),
    buildRegularBullet(3, i18n.t('components.description.finalizeAllQuestionnaires')),
    buildRegularBullet(3, i18n.t('components.description.organizingAnEveningOrMorning')),
    buildRegularBullet(3, i18n.t('components.description.ensuringThatTheTeamsHaveEnoughTime')),
    buildRegularBullet(2, i18n.t('components.description.analyseTheDataUsingTheStandard')),
    buildRegularBullet(2, i18n.t('components.description.presentThePreliminarySmartResults')),
    buildRegularBullet(2, i18n.t('components.description.writeThePreliminaryAndFinalReports')),

    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.surveySupervisor'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.skillsAndRequiredAbilities'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.toBeAbleToRead')),
    buildRegularBullet(1, i18n.t('components.description.ifDataCollectionWillBeDone')),
    buildRegularBullet(2, i18n.t('components.description.familiarWithTheUseOfTheSmartphones')),

    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.tasks'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSupervisorAssistsTheManagerAndGuarantees'),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.assistSupervisionOfTheSurvey')),
    buildRegularBullet(1, i18n.t('components.description.visitingTeamsInTheField')),
    buildRegularBullet(1, i18n.t('components.description.itIsParticularlyImportant')),
    buildRegularBullet(1, i18n.t('components.description.ensuringThatHouseholdsAreSelected')),
    buildRegularBullet(1, i18n.t('components.description.decidingOnHowToOvercomeTheProblems')),
    buildRegularBullet(1, i18n.t('components.description.checkingAnySuspectDataEveryEvening')),
    buildRegularBullet(1, i18n.t('components.description.organizingAnEveningOrMorning')),
    buildRegularBullet(1, i18n.t('components.description.ensuringThatTheTeamsHaveEnoughTime')),
    buildRegularBullet(1, i18n.t('components.description.inSomeContexts')),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.teamLeader1PerTeam'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.skillsAndRequiredAbilities'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.toBeAbleToRead')),
    buildRegularBullet(1, i18n.t('components.description.ifDataCollectionWillBeDone')),
    buildRegularBullet(1, i18n.t('components.description.capableOfUsingASmartphone')),
    buildRegularBullet(1, i18n.t('components.description.ableToMultitaskAndManage')),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.tasks'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.ensuresAllFormsAndQuestionnaires')),
    buildRegularBullet(1, i18n.t('components.description.ensuresAllEquipmentIsReady')),
    buildRegularBullet(1, i18n.t('components.description.calibratesAnthropometric')),
    buildRegularBullet(1, i18n.t('components.description.participatesToBriefingMeetingWithHis')),
    buildRegularBullet(1, i18n.t('components.description.speaksWithRepresentatives')),
    buildRegularBullet(1, i18n.t('components.description.managesTheHousehold')),
    buildRegularBullet(1, i18n.t('components.description.usesALocalEventsCalendar')),
    buildRegularBullet(1, i18n.t('components.description.checksIfTheChildIsMalnourished')),
    buildRegularBullet(1, i18n.t('components.description.fillsTheChildrenAndWomenQuestionnaires')),
    buildRegularBullet(1, i18n.t('components.description.atAllTimesChecksAndSupervises')),
    buildRegularBullet(1, i18n.t('components.description.supervisesTheTeamMemberFillingOut')),
    buildRegularBullet(1, i18n.t('components.description.ensuresThatHousesWithMissingData')),
    buildRegularBullet(1, i18n.t('components.description.checksThatAllQuestionnaires')),
    buildRegularBullet(1, i18n.t('components.description.ensuresThatAllTheEquipment')),
    buildRegularBullet(1, i18n.t('components.description.managesTimeAllocatedToInterviews')),
    buildRegularBullet(1, i18n.t('components.description.ensuresTheSecurityOfTeamMembers')),
    buildRegularBullet(1, i18n.t('components.description.noteAndReportTheProblemsEncountered')),

    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.interviewer'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.skillsAndRequiredAbilities'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.toBeAbleToRead')),
    buildRegularBullet(1, i18n.t('components.description.ifDataCollectionWillBeWithSmartphone')),
    buildRegularBullet(1, i18n.t('components.description.capableOfUsingASmartphone')),

    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.tasks'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.isResponsibleForFillingOut')),
    buildRegularBullet(1, i18n.t('components.description.conductsTheInterviewsOnWash')),
    buildRegularBullet(1, i18n.t('components.description.usesALocalEventsCalendar')),
    buildRegularBullet(1, i18n.t('components.description.respectsTheTimeRequiredForInterviews')),
    buildRegularBullet(1, i18n.t('components.description.followsSecurityMeasures')),

    new Paragraph({
      spacing: {
        before: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theInterviewerMustAcquireSomeSpecialSkills'),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.knowHowToSelectHouseholds'),),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.measurerAnthropometry'),
          color: 'e18731',
          size: 24,
          font: 'Nexa Light',
          bold: true
        })
      ]
    }),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.skillsAndRequiredAbilities'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.toBeAbleToRead')),
    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.tasks'),
          color: '000000',
          size: 24,
          underline: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.measuresTheHeight')),
    buildRegularBullet(1, i18n.t('components.description.assessesThePresenceOfBilateral')),
    buildRegularBullet(1, i18n.t('components.description.usesALocalEventsCalendar')),
    buildRegularBullet(1, i18n.t('components.description.respectsTheTimeRequiredForMeasurements')),
    buildRegularBullet(1, i18n.t('components.description.takesCareOfTheEquipment')),
    buildRegularBullet(1, i18n.t('components.description.followsSecurityMeasures')),

    new Paragraph({
      spacing: {
        before: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theAnthropometricMeasurer'),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    buildRegularBullet(1, i18n.t('components.description.knowHowToSelectHouseholds')),
    buildRegularBullet(1, i18n.t('components.description.knowHowToCheckIfAChildIsMalnourished')),
    buildRegularBullet(1, i18n.t('components.description.knowHowToCheckTheHealthCard')),
    buildRegularBullet(1, i18n.t('components.description.learnHowToMakeAReference')),

    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.titles.annex')} 4 - ${i18n.t('components.titles.surveyTeamOrganigram')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
      children: [
        organigram
      ],
      spacing: {
        before: 1000
      }
    }),
    ...(metadata.samplingMethod === 'Cluster' ? clusterAnnex : [])
  ];
};

const bulletSectionConfig = [];

const bulletSection = (reference, delimiter = ')', color = '000000') => {
  if (bulletSectionConfig.every(item => item.reference !== reference)) {
    bulletSectionConfig.push({
      reference,
      levels: [
        {
          level: 2,
          format: LevelFormat.LOWER_LETTER,
          text: i18n.t('components.description.3_Delimiter', {
            delimiter: `${delimiter}`
          }),
          alignment: AlignmentType.LEFT,
          style: {
            run: {
              color,
              size: 24,
              font: 'Nexa Light'
            },
            paragraph: {
              indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
            }
          }
        }
      ]
    });
  }
  return reference;
};

let indicatorTableList = [];

const generateIndicators = (metadata, indicators) => {
  const householdIndicator = new TableRow({
    children: [
      new TableCell({
        columnSpan: 2,
        shading: {
          fill: 'ECC182',
          type: ShadingType.SOLID,
          color: 'auto'
        },
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.householdIndicators'),
                color: '000000',
                size: 24,
                bold: true,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const mortalityIndicator = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.mortality'),
                color: '000000',
                size: 24,
                bold: true,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const foodSecurity = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.foodSecurity'),
                color: '000000',
                bold: true,
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const FCS = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.foodConsumptionScoreFcs'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const HDDS = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.householdDietaryDiversityScore'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const rCSI = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.reducedCopingStrategyIndex'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const HHS = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.householdHungerScale'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const FIES = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.foodInsecureExperienceScale'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const WASH = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.wash'),
                color: '000000',
                size: 24,
                bold: true,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const accessSafe = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.improvedWaterForDrinkingAndCooking'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const waterTreatment = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.householdWaterTreatment'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const facilities = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.accessToImprovedSanitationFacilities'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const handwashing = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.accessToAHandwashingSystem'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const waterQuantity = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.accessToSufficientQuantityOfWater'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.totalPopulation'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const child = new TableRow({
    children: [
      new TableCell({
        columnSpan: 2,
        shading: {
          fill: 'ECC182',
          type: ShadingType.SOLID,
          color: 'auto'
        },
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.childIndicators'),
                color: '000000',
                size: 24,
                bold: true,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const anthropometry = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.anthropometry'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.includedageGroupMonths', {
                  includedAgeGroup: `${metadata.includedAgeGroup}`
                }),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const iycf = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.iycf'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.0_24Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const anaemia = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.anaemia'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.6_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const mnps = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.mnpsSupplementationCoverage'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.6_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const bsfp = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.bsfpCoverage'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.6_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const vitaminA = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.vitaminASupplementationCoverage'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.6_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const deworming = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.dewormingCoverage'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.12_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const measles = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.measlesVaccinationCoverage'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.9_59Months'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const diarrhoea = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.episodeOfDiarrhoeaCareSeeking'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.includedageGroupMonths', {
                  includedAgeGroup: `${metadata.includedAgeGroup}`
                }),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const fever = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.episodeOfFeverAndCareSeekingForChildrenWithFever'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.includedageGroupMonths', {
                  includedAgeGroup: `${metadata.includedAgeGroup}`
                }),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const ari = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.episodeOfAriAndCareSeekingForChildrenWithAri'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.includedageGroupMonths', {
                  includedAgeGroup: `${metadata.includedAgeGroup}`
                }),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const women = new TableRow({
    children: [
      new TableCell({
        columnSpan: 2,
        shading: {
          fill: 'ECC182',
          type: ShadingType.SOLID,
          color: 'auto'
        },
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.womenIndicators'),
                color: '000000',
                size: 24,
                bold: true,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const muac = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.anthropometryMuac'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.15_49Years'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const health = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.healthSkilledAttendantDelivery'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.15_49Years'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });
  const womenAnemia = new TableRow({
    children: [
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.anemia'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      }),
      new TableCell({
        margins: tableCellMargins,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: i18n.t('components.description.15_49Years'),
                color: '000000',
                size: 24,
                font: 'Nexa Light'
              })
            ]
          })
        ]
      })
    ]
  });

  const indicatorTable = {
    household: { display: false, value: householdIndicator },
    mortality: { display: false, value: mortalityIndicator },
    'Food Security': { display: false, value: foodSecurity },
    food_consumption_score: { display: false, value: FCS },
    household_dietary_diversity_score: { display: false, value: HDDS },
    reduced_coping_strategy_index: { display: false, value: rCSI },
    household_hunger_scale: { display: false, value: HHS },
    food_insecure_experience_scale: { display: false, value: FIES },
    WASH: { display: false, value: WASH },
    access_to_safe_water_for_drinking_cooking: { display: false, value: accessSafe },
    household_water_treatment: { display: false, value: waterTreatment },
    access_to_improved_sanitation: { display: false, value: facilities },
    access_to_handwashing: { display: false, value: handwashing },
    access_to_sufficient_quantity_water: { display: false, value: waterQuantity },
    child: { display: false, value: child },
    anthropometry: { display: false, value: anthropometry },
    iycf: { display: false, value: iycf },
    child_anaemia: { display: false, value: anaemia },
    mnps_supplementation_coverage: { display: false, value: mnps },
    bsfp_coverage: { display: false, value: bsfp },
    vitamin_a_supplementation_coverage: { display: false, value: vitaminA },
    deworming_coverage: { display: false, value: deworming },
    measles_vaccination_coverage: { display: false, value: measles },
    episode_of_diarrhoea: { display: false, value: diarrhoea },
    episode_of_fever: { display: false, value: fever },
    episode_of_ari: { display: false, value: ari },
    women: { display: false, value: women },
    women_anthropometry: { display: false, value: muac },
    women_health: { display: false, value: health },
    women_anaemia: { display: false, value: womenAnemia }
  };
  for (const indicator of indicators) {
    if (indicatorTable[indicator.name]) {
      indicatorTable[indicator.name].display = true;
    }
    if (indicatorTable[indicator.category]) {
      indicatorTable[indicator.category].display = true;
    }
    if (indicatorTable[indicator.subcategory]) {
      indicatorTable[indicator.subcategory].display = true;
    }
    if (indicatorTable[indicator.grouping]) {
      indicatorTable[indicator.grouping].display = true;
    }
  }
  indicatorTableList = [
    new TableRow({
      children: [
        new TableCell({
          shading: {
            fill: '363534',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          width: {
            size: 5505,
            type: WidthType.DXA
          },
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.indicator'),
                  color: 'ffffff',
                  size: 24,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        }),
        new TableCell({
          shading: {
            fill: '363534',
            type: ShadingType.SOLID,
            color: 'auto'
          },
          width: {
            size: 3505,
            type: WidthType.DXA
          },
          margins: tableCellMargins,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: i18n.t('components.description.targetPopulation'),
                  color: 'ffffff',
                  size: 24,
                  bold: true,
                  font: 'Nexa Light'
                })
              ]
            })
          ]
        })
      ]
    }),
    ...Object.values(indicatorTable).filter(item => item.display).map(item => item.value)
  ];
};

// eslint-disable-next-line import/prefer-default-export
export const generateProtocol = async (
  metadata, survey, calendar, organization, geoUnits, indicators
) => {
  generateIndicators(metadata, indicators);
  const logo = await buildImageRunFromUrl('/logo.svg', 163, 62);
  const organigram = await buildImageRunFromUrl('/organigram.png', 600, 270);

  const firstPage = {
    properties: {
      type: SectionType.NEXT_PAGE
    },
    children: [
      new Paragraph({
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        spacing: {
          after: 2500
        },
        children: [new TextRun('')]
      }),
      new Paragraph({
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          logo
        ],
        spacing: {
          after: 200
        }
      }),
      new Paragraph({
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: i18n.t('components.description.smartPlusSurveyProtocolTemplate'),
            size: 40,
            color: '000000',
            font: 'Nexa Light',
            bold: true
          })
        ],
        spacing: {
          after: 1000
        }
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: survey.name,
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: `${metadata.geographicAreaName}`,
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: i18n.t('components.description.surveymonthSurveyyear', {
              surveyMonth: `${moment().month(metadata.surveyMonth - 1).format('MMMM')}`,
              surveyYear: `${moment().year(metadata.surveyYear).format('YYYY')}`
            }),
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: i18n.t('components.description.surveytypeSurvey', {
              surveyType: `${metadata.surveyType}`
            }),
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true,
            text: `${i18n.t('components.description.fundedBy')} `
          }),
          new TextRun({
            text: i18n.t('components.description.agencyName'),
            size: 28,
            font: 'Nexa Light',
            bold: true,
            color: '000000'
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true,
            text: `${i18n.t('components.description.assessmentCoordinatedBy')} `
          }),
          new TextRun({
            text: i18n.t('components.description.namePosition'),
            size: 28,
            font: 'Nexa Light',
            bold: true,
            color: '000000'
          })
        ]
      }),
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            color: 'e18731',
            size: 28,
            font: 'Nexa Light',
            bold: true,
            text: `${i18n.t('components.description.technicallySupportedBy')} `
          }),
          new TextRun({
            text: i18n.t('components.description.agencyName'),
            size: 28,
            font: 'Nexa Light',
            bold: true,
            color: '000000'
          })
        ]
      })
    ]
  };

  const tableOfContents = {
    properties: {
      type: SectionType.NEXT_PAGE
    },
    children: [
      new Paragraph({
        spacing: {
          after: 200
        },
        heading: HeadingLevel.TITLE,
        children: [
          new TextRun({
            color: 'e18731',
            size: 32,
            font: 'Nexa Light',
            bold: true,
            text: i18n.t('components.description.tableOfContents')
          })
        ]
      }),
      new TableOfContents(i18n.t('components.description.tableOfContents'), {
        hyperlink: true,
        headingStyleRange: '1-5'
      })
    ]
  };

  const acknowledgements = [
    buildHeader('1. Acknowledgements'),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineAnyAcknowledgementsFromThisSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new PageBreak()
      ]
    })
  ];

  const acronyms = [
    buildHeader(`2. ${i18n.t('components.titles.acronyms')}`),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.listAllAcronymsUsedInThisSurveyProtocol'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new PageBreak()
      ]
    })
  ];

  const executiveSummary = [
    buildHeader(`3. ${i18n.t('components.titles.executiveSummary')}`),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.includeAnExecutiveSummaryOfTheSurveyHere'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const introduction = [
    buildHeader(`4. ${i18n.t('components.titles.introduction')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.1 ${i18n.t('components.titles.organization')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.whatOrganizationIsConductingTheSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.theSurveyIsConductedByName', {
            name: `${organization.name}`,
            country: `${metadata.country}`,
            type: `${organization.type}`,
            setting: `${organization.setting}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.2 ${i18n.t('components.titles.backgroundInformation')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeAnyRelevantBackgroundInformation'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: metadata.background,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.3 ${i18n.t('components.titles.surveyJustification')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheReasonsForWhichThisSurveyIsRequired'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.4 ${i18n.t('components.titles.surveyType')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeWhatTypeOfSurveyIsBeingConducted'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.theSurveyTypeUsedInThisSurvey', {
            surveyType: `${metadata.surveyType}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.5 ${i18n.t('components.titles.surveyTiming')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheFollowingAreasOfSurveyTiming'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-four-five', '.'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSurveyWillBeConductedIn', {
            surveyMonth: `${moment().month(metadata.surveyMonth - 1).format('MMMM')}`,
            surveyYear: `${moment().year(metadata.surveyYear).format('YYYY')}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-four-five', '.'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSurveyWillLastForCollectiondays', {
            collectionDays: `${metadata.collectionDays}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-four-five', '.'),
        level: 2
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.thisSurveyWillBeConducted', {
            seasonality: `${metadata.seasonality}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.6 ${i18n.t('components.titles.typeOfPopulation')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeThePopulationWhichIsBeingSurveyed'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.theTargetPopulationForThisSurvey', {
            populationType: `${metadata.populationType}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.7 ${i18n.t('components.titles.typeOfSetting')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheSettingInWhichTheSurveyIsTakingPlace'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.thisSurveyIsTakingPlace', {
            surveySetting: `${metadata.surveySetting}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.8 ${i18n.t('components.titles.surveyLocation')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeWhereTheSurveyIsTakingPlace'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.theSurveyIsTakingPlaceIn', {
            geographicAreaName: `${metadata.geographicAreaName}`,
            latitude: `${metadata.latitude}`,
            longitude: `${metadata.longitude}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `4.9 ${i18n.t('components.titles.excludedAreasIfApplicable')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.listAndDescribeAnyAreas'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.withinTheSurveyLocation', {
            excludedAreas: `${metadata?.excludedAreas?.join(', ')}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const surveyGoals = [
    buildHeader(`5. ${i18n.t('components.titles.surveyGoalAndObjectives')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `5.1 ${i18n.t('components.titles.surveyGoal')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheOverallGoalOfTheSurvey'),
          color: '000000',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: metadata.surveyGoal,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `5.2 ${i18n.t('components.titles.specificSurveyObjectives')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheSpecificObjectivesOfYourSurvey'),
          color: '000000',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: metadata.surveyObjective,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `5.3 ${i18n.t('components.titles.indicatorsIncluded')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 400
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheIndicators'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Table({
      columnWidths: [3505, 5505],
      rows: [...indicatorTableList],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      }
    })
  ];

  const clusterMethodology = [
    buildHeader(`6. ${i18n.t('components.titles.methodology')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1 ${i18n.t('components.titles.surveyDesign')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.1 ${i18n.t('components.titles.samplingMethod')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200,
        line: 300
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheSamplingMethodBeingUsedInTheSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSamplingMethodBeingUsed', {
            samplingMethod: `${metadata.samplingMethod}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.2 ${i18n.t('components.titles.secondStageSamplingMethod')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      indent: {
        left: 400
      },
      spacing: {
        after: 200,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheSecondStageSamplingMethodBeingUsed'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500,
        line: 300
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: metadata.secondStageSampling ? i18n.t('components.description.theSecondStageSamplingMethodBeingUsed', {
            secondStageSampling: `${metadata.secondStageSampling}`
          }) : '',
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.3 ${i18n.t('components.titles.householdDefinition')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.includeTheHouseholdDefinition'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: metadata.householdDefinition,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.4 ${i18n.t('components.titles.householdListing')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheHouseholdListingProcess'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    ...buildSamplingSize(metadata),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.3 ${i18n.t('components.titles.clusterSelection')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 300
      },
      children: [
        new TextRun({
          text: `6.3.1 ${i18n.t('components.titles.calculationOfHouseholdCoverage')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildCoverageTable(metadata),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        before: 250,
        after: 300
      },
      children: [
        new TextRun({
          text: `6.3.2 ${i18n.t('components.titles.clusterCalculation')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.clustersAreToBeIncluded', {
            numberOfClusters: `${metadata.numberOfClusters}`
          }),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const randomMethodology = [
    buildHeader(`6. ${i18n.t('components.titles.methodology')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1 ${i18n.t('components.titles.surveyDesign')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.1 ${i18n.t('components.titles.samplingMethod')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheSamplingMethodBeingUsedInTheSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSamplingMethodBeingUsed', {
            samplingMethod: `${metadata.samplingMethod}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      children: [
        new TextRun({
          text: `6.1.3 ${i18n.t('components.titles.householdDefinition')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.includeTheHouseholdDefinition'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: metadata.householdDefinition,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      children: [
        new TextRun({
          text: `6.1.4 ${i18n.t('components.titles.householdListing')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheHouseholdListingProcess'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    ...buildSamplingSize(metadata),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.3 ${i18n.t('components.titles.calculationOfHouseholdCoverage')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildCoverageTable(metadata)
  ];

  const exhaustiveMethodology = [
    buildHeader(`6. ${i18n.t('components.titles.methodology')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1 ${i18n.t('components.titles.surveyDesign')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: `6.1.1 ${i18n.t('components.titles.samplingMethod')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 200
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheSamplingMethodBeingUsedInTheSurvey'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.theSamplingMethodBeingUsed', {
            samplingMethod: `${metadata.samplingMethod}`
          }),
          color: '000000',
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      children: [
        new TextRun({
          text: `6.1.2 ${i18n.t('components.titles.householdDefinition')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: metadata.householdDefinition,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_3,
      indent: {
        left: 400
      },
      children: [
        new TextRun({
          text: `6.1.3 ${i18n.t('components.titles.householdListing')}`,
          color: 'e18731',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        after: 500
      },
      indent: {
        left: 700
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.describeTheHouseholdListingProcess'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `6.3 ${i18n.t('components.titles.calculationOfHouseholdCoverage')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    buildCoverageTable(metadata)
  ];

  const methodology = {
    Exhaustive: exhaustiveMethodology,
    Random: randomMethodology,
    Cluster: clusterMethodology
  };

  const caseDefinitions = [
    buildHeader(`7. ${i18n.t('components.titles.caseDefinitionsAndThresholds')}`),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.age')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.thisWillBeRecordedAsADateOfBirth'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.weightInKg')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.childrenWillBeWeighedRemoving'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.heightLengthInCm')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.aMeasuringBoardWillBeUsedToMeasure'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.midUpperArmCircumferenceMuac')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.willBeMeasuredUsingAFlexibleNonElasticTapeMidway'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.bilateralPittingOedema')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.willBeAssessedByApplyingAModerateThumb'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      footnotes: 'Testing',
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.crudeMortalityRate')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.numberOfDeathsFromAllCausesPer10000People'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.underFiveMortalityRate')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.numberOfDeathsAmongChildrenUnderFive'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.cutOffsForMalnutritionStatus')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.outlineTheCutOffsUsedForThisSurvey'),
          size: 24,
          color: 'e18731',
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: 'letter-bullets-two',
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.whoUnicefClassification'),
          bold: true,
          size: 24,
          font: 'Nexa Light'
        })
      ]
    }),
    new Table({
      margins: {
        bottom: 300
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: '363634',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.indicators'),
                      color: 'ffffff',
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: '363634',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              columnSpan: 5,
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.prevalenceThresholdsLevel'),
                      color: 'ffffff',
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: 'eb3223',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.veryHigh'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'f6c142',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.high'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'fffd54',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.medium'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'a0ce62',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.low'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: '4fad5b',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.veryLow'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.wasting'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.gte15'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.10_15'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.5l10'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.2_5l5'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.l2_5'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.overweightWhz'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.gte15'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.10_15'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.5l10'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.2_5l5'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.l2_5'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.stunting'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.gte30'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.220to30'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.10l20'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.2_5l10'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.l2_5'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '',
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'eb3223',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              columnSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.critical'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'f6c142',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.serious'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: 'a0ce62',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.poor'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              shading: {
                fill: '4fad5b',
                type: ShadingType.SOLID,
                color: 'auto'
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.acceptable'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.underweightWaz'),
                      size: 22,
                      bold: true,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.gte30'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.20to30'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.10l20'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              margins: tableCellMargins,
              children: [
                new Paragraph({
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: i18n.t('components.description.l10'),
                      size: 22,
                      font: 'Nexa Light'
                    })
                  ]
                })
              ]
            })
          ]
        })
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      columnWidths: [2000, 1500, 1500, 1500, 1500, 1500]
    })
  ];

  const organizationOfSurvey = [
    buildHeader(`8. ${i18n.t('components.titles.organizationOfTheSurvey')}`),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.1 ${i18n.t('components.titles.coordinationCollaboration')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.provideABriefOutlineOfTheStakeholder'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.2 ${i18n.t('components.titles.logistics')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: metadata.surveyEquipment,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.3 ${i18n.t('components.titles.trainingSupervisionAndTeamComposition')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheFollowingAspectsOfSupervision'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        before: 200,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.recruitmentOfSurveyTeams'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.thereWillBeNumberofteams', {
            numberOfTeams: `${metadata.numberOfTeams}`
          }),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.trainingOfSurveyTeams'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.detailsOfFieldSupervision'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.dataEntryProcedures'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.backtranslationIfApplicable'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-three', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.fieldTestPilot'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.4 ${i18n.t('components.titles.specialCases')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.noChildrenInTheHousehold')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.allQuestionsTargetingSurveySubjects'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.abandonedHousehold')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.generallyAbandonedHouseholds'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.absentHousehold')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.aHouseholdWillBeConsideredAbsent'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.excludedHousehold'),
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.ifApplicable'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.aHouseholdWillBeExcludedFromTheSurvey'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.absentChildren')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.ifAChildIsAbsentAtTheTimeOfTheSurvey'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.childrenWithDisabilityHandicap')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.ifAPhysicalDeformityPreventsTheEnumerators'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.polygamousFamilies')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.polygamousFamiliesCanBeLivingTogether'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.compounds')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.followTheSameSamplingMethodology'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.notPossibleToVisitHousehold')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.ifTheEnumeratorsAreUnableToVisitTheHousehold'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-four'),
        level: 2
      },
      spacing: {
        after: 400,
        line: 300
      },
      children: [
        new TextRun({
          text: `${i18n.t('components.description.childInCenterInstitution')} `,
          bold: true,
          size: 24,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: i18n.t('components.description.aChildInACenterOrInstitution'),
          size: 24,
          italics: true,
          font: 'Nexa Light'
        }),
        new TextRun({
          text: ` ${i18n.t('components.description.editAsRequired')}`,
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.5 ${i18n.t('components.titles.referral')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheReferralCriteria'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.6 ${i18n.t('components.titles.ethicalConsiderations')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineAnyEthicalConsideration'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.7 ${i18n.t('components.titles.electronicDataCollectionDataManagement')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineTheVariousTools'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-seven', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        before: 200,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.mobileDataCollectionTools'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-seven', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        before: 200,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.digitalInfrastructureUsed'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-seven', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        before: 200,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.dataQualityControl'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      numbering: {
        reference: bulletSection('letter-bullets-eight-seven', ')', '8d8f8f'),
        level: 2
      },
      spacing: {
        before: 200,
        after: 200
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.dataAnalysisAndTools'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.8 ${i18n.t('components.titles.surveyReportDisseminationPlan')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineThePlanForTheSurveyReport'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.9 ${i18n.t('components.titles.limitations')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineAnyAnticipatedLimitations'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 400,
        after: 200
      },
      children: [
        new TextRun({
          text: `8.10 ${i18n.t('components.titles.healthAndSafetyMeasuresDuringFieldwork')}`,
          color: '000000',
          size: 26,
          underline: true,
          bold: true,
          font: 'Nexa Light'
        })
      ]
    }),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.outlineAnyHealthAndSafetyMeasures'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const summmary = [
    buildHeader(`9. ${i18n.t('components.titles.summaryTimelineOfWorkplan')}`),
    new Paragraph({
      heading: HeadingLevel.TITLE,
      spacing: {
        line: 300
      },
      children: [
        new TextRun({
          text: i18n.t('components.description.attachAndOutlineTheTimeline'),
          color: '8d8f8f',
          size: 24,
          italics: true,
          font: 'Nexa Light'
        })
      ]
    })
  ];

  const doc = new File({
    features: {
      updateFields: true
    },
    styles: {
      paragraphStyles: [
        {
          id: 'TOC1',
          name: 'toc 1',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            spacing: {
              after: 200
            },
            tabStops: [
              {
                type: TabStopType.RIGHT,
                leader: LeaderType.DOT,
                position: (TabStopPosition.MAX - 100)
              }
            ]
          },
          run: {
            italics: true,
            bold: true,
            size: 24,
            color: '8f908f',
            font: 'Arial'
          }
        },
        {
          id: 'TOC2',
          name: 'toc 2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            indent: {
              left: 250
            },
            spacing: {
              after: 200
            },
            tabStops: [
              {
                type: TabStopType.RIGHT,
                leader: LeaderType.DOT,
                position: (TabStopPosition.MAX - 100)
              }
            ]
          },
          run: {
            bold: true,
            size: 22,
            color: '8f908f',
            font: 'Arial'
          }
        },
        {
          id: 'TOC3',
          name: 'toc 3',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            indent: {
              left: 250
            },
            spacing: {
              after: 200
            },
            tabStops: [
              {
                type: TabStopType.RIGHT,
                leader: LeaderType.DOT,
                position: (TabStopPosition.MAX - 100)
              }
            ]
          },
          run: {
            bold: true,
            size: 22,
            color: '8f908f',
            font: 'Arial'
          }
        }
      ]
    },
    numbering: {
      config: [
        {
          reference: 'letter-bullets',
          levels: [
            {
              level: 1,
              format: LevelFormat.LOWER_LETTER,
              text: '%3)',
              alignment: AlignmentType.LEFT,
              style: {
                run: {
                  color: '8d8f8f',
                  size: 24,
                  font: 'Nexa Light'
                },
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            }
          ]
        },
        ...bulletSectionConfig,
        {
          reference: 'letter-bullets-two',
          levels: [
            {
              level: 2,
              format: LevelFormat.LOWER_LETTER,
              text: '%3)',
              alignment: AlignmentType.LEFT,
              style: {
                run: {
                  color: '000000',
                  size: 24,
                  font: 'Nexa Light'
                },
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            }
          ]
        },
        {
          reference: 'letter-bullets-three',
          levels: [
            {
              level: 4,
              format: LevelFormat.LOWER_LETTER,
              text: '%3.',
              alignment: AlignmentType.LEFT,
              style: {
                run: {
                  color: '8d8f8f',
                  size: 24,
                  font: 'Nexa Light'
                },
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            }
          ]
        },
        {
          reference: 'bullet-points',
          levels: [
            {
              level: 1,
              format: LevelFormat.BULLET,
              text: '•',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            },
            {
              level: 2,
              format: LevelFormat.BULLET,
              text: '◦',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25) }
                }
              }
            },
            {
              level: 3,
              format: LevelFormat.BULLET,
              text: '▪',
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(0.25) }
                }
              }
            }
          ]
        }
      ]
    },
    sections: [
      firstPage,
      tableOfContents,
      {
        properties: {
          page: {
            pageNumbers: {
              start: 2,
              formatType: PageNumberFormat.DECIMAL
            }
          }
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                heading: HeadingLevel.TITLE,
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [PageNumber.CURRENT],
                    color: '000000',
                    bold: true,
                    size: 20,
                    font: 'Nexa Light'
                  })
                ]
              })
            ]
          })
        },
        children: [
          ...acknowledgements,
          ...acronyms,
          ...executiveSummary,
          ...introduction,
          ...surveyGoals,
          ...methodology[metadata.samplingMethod],
          ...caseDefinitions,
          ...organizationOfSurvey,
          ...summmary,
          ...buildAnnexes(metadata, geoUnits, calendar, organigram)
        ]
      }
    ]
  });

  Packer.toBlob(doc).then((blob) => saveAs(blob, `${i18n.t('components.titles.protocol')}.docx`));
};
