<template>
  <a-modal
    v-if="survey"
    width="90%"
    class="survey-manager-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="visible && !showAddSupervisorModal"
    :closable="true"
    @cancel="onExit">
    <h1 class="flex align-center justify-left txt-30 txt-bold txt-font-din-medium">
      {{ $t('components.titles.manageSurveySupervisors') }}
    </h1>
    <a-tag
      style="display: flex; flex-direction: row; padding: 10px 20px; width: 100%; color: black;"
      color="blue">
      <span style="display: flex; flex-direction: column; padding-right: 30px">
        <span style=" font-weight: bold;">{{ $t('components.labels.surveyName') }}</span>
        <span>{{ survey.name }}</span>
      </span>
      <span style="display: flex; flex-direction: column;">
        <span style=" font-weight: bold;">{{ $t('components.labels.dateCreated') }}</span>
        <span>{{ formatDate(survey.createdAt) }}</span>
      </span>
    </a-tag>
    <div class="flex justify-space-between align-center mt-20 mb-20">
      <span style="display: flex; cursor: pointer; color: #e98300;" @click="showAddSupervisor">
        <embed class="mr-4" src="/icon-add-user.svg">
        <span style="padding-left: 5px; font-size: medium;">{{ $t('components.description.addNewSupervisor') }}</span>
      </span>
    </div>
    <div class="mt-20">
      <a-table
      class="manage-supervisors-table"
      :columns="columns"
      size="small"
      :data-source="invitations"
      row-key="id"
      :pagination="false">
        <template #invitationStatus="invitationStatus, { expiresAt }">
          <span>
            <a-tag
              style="padding: 5px"
              :color="getColourForStatus(invitationStatus, expiresAt)">
              <span style="display: flex; padding: 0px 5px;">
                <a-icon v-if="invitationStatus==='Accepted'" type="check-circle" theme="filled" style="color: #66c300; font-size: 18px; padding-right: 5px;"/>
                <span>{{ translateInvitationStatus(invitationStatus, expiresAt) }}</span>
              </span>
            </a-tag>
          </span>
        </template>
        <template #resendInvite="invitationStatus, { id, resendCoolDown }">
          <div v-if="invitationStatus === 'pending'">
            <CountdownTimer :resend-cooldown="resendCoolDown" :resend-invite="resendInvite" :invitation-id="id"/>
          </div>
        </template>
        <template #cancelInvite="id, { invitationStatus }">
          <span v-if="invitationStatus === 'pending'" style="display: flex; cursor: pointer; justify-content: center;" @click="showDeleteSupervisor(id)">
            <embed data-cy="delete-invitation" class="mr-4" src="/icon-trash.svg">
          </span>
        </template>
      </a-table>
    </div>
    <div class="mt-40 buttons-container">
      <a-button
        class="w170"
        type="primary"
        size="large"
        @click="onExit">
        {{ $t('values.done') }}
      </a-button>
    </div>
    <AddSupervisorModal :survey-id="survey.id" :visible="showAddSupervisorModal" :on-exit="exitAddSupervisor" :on-complete="completeAddSupervisor"/>
    <DeleteSupervisorModal :visible="deleteSupervisorid != null" :on-exit="exitDeleteSupervisor" :on-complete="completeDeleteSupervisor"/>
  </a-modal>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import moment from 'moment';
import { requestData } from '../../../../store/modules/request';
import { configForPossibleBackendRequest } from '../../../../util/request';
import AddSupervisorModal from '../add-supervisor-modal/index.vue';
import DeleteSupervisorModal from '../delete-supervisor-modal/index.vue';
import CountdownTimer from './countdownTimer.vue';

export default {
  name: 'ManageSupervisorsModal',
  components: {
    AddSupervisorModal,
    DeleteSupervisorModal,
    CountdownTimer
  },
  props: {
    onExit: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    survey: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showAddSupervisorModal: false,
      deleteSupervisorid: null
    };
  },
  computed: {
    ...requestData(['invitations']),
    columns() {
      return [
        {
          title: this.$t('components.labels.fullName'),
          dataIndex: 'supervisorFullName',
          key: 'name',
          width: '20%'
        },
        {
          title: this.$t('components.titles.emailAddress'),
          dataIndex: 'supervisorEmail',
          key: 'email',
          width: '30%'
        },
        {
          title: this.$t('components.labels.status'),
          dataIndex: 'invitationStatus',
          key: 'status',
          scopedSlots: { customRender: 'invitationStatus' },
          width: '20%'
        },
        {
          title: this.$t('components.titles.actions').toLocaleUpperCase(),
          dataIndex: 'invitationStatus',
          key: 'resendInvite',
          scopedSlots: { customRender: 'resendInvite' },
          width: '20%'
        },
        {
          dataIndex: 'id',
          key: 'cancelInvite',
          scopedSlots: { customRender: 'cancelInvite' }
        }
      ];
    }
  },
  watch: {
    survey: {
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    fetchData() {
      if (this.survey.id != null) {
        this.setLoading(true);
        this.$store.dispatch('get', [{
          key: 'invitations',
          url: `/surveys/${this.survey.id}/invitations`,
          extended: true
        }])
          .finally(() => this.setLoading(false));
      }
    },
    getColourForStatus(invitationStatus, expiresAt) {
      switch (invitationStatus) {
        case 'pending':
          if (moment() >= moment(expiresAt)) {
            return 'orange';
          }
          return undefined;
        case 'accepted':
          return 'green';
        default:
          return 'black';
      }
    },
    translateInvitationStatus(invitationStatus, expiresAt) {
      switch (invitationStatus) {
        case 'pending':
          if (moment() >= moment(expiresAt)) {
            return this.$t('values.inviteExpired');
          }
          return this.$t('values.pending');
        case 'accepted':
          return this.$t('values.accepted');
        default:
          return 'ERROR: Unrecognized Status';
      }
    },
    resendInvite(invitationId) {
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = {
        method: 'POST',
        url: `/surveys/${this.survey.id}/invitations/${invitationId}/resend`
      };
      Vue.prototype.$http.request(
        configForPossibleBackendRequest(axiosConfig, token),
      ).then(() => {
        this.fetchData();
        this.$message.success({
          content: this.$t('components.notifications.inviteSent'),
          duration: 5
        });
      }).catch(error => {
        this.$alert().danger(error.message);
        return false;
      });
    },
    showAddSupervisor() {
      this.showAddSupervisorModal = true;
    },
    exitAddSupervisor() {
      this.showAddSupervisorModal = false;
    },
    completeAddSupervisor() {
      this.fetchData();
      this.showAddSupervisorModal = false;
    },
    showDeleteSupervisor(invitationId) {
      this.deleteSupervisorid = invitationId;
    },
    exitDeleteSupervisor() {
      this.deleteSupervisorid = null;
    },
    completeDeleteSupervisor() {
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = {
        method: 'DELETE',
        url: `/surveys/${this.survey.id}/invitations/${this.deleteSupervisorid}`
      };
      Vue.prototype.$http.request(
        configForPossibleBackendRequest(axiosConfig, token),
      ).then(() => {
        this.fetchData();
        this.$message.success({
          content: this.$t('components.notifications.inviteDeleted'),
          duration: 5
        });
      }).catch(error => {
        this.$alert().danger(error.message);
      });
      this.deleteSupervisorid = null;
    }
  }
};
</script>

<style lang="scss">
.survey-manager-modal {
  .ant-modal-body {
    padding: 40px;
  }

  .manage-supervisors-table {
    .ant-table-small {
      border: none;
      table tr > th,td {
        font-weight: bold;
      }
      .ant-table-thead > tr > th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
      .ant-table-content .ant-table-row:last-child td {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: end;
  }
}
</style>
