import kebabCase from 'kebab-case';

export function capitalizeTheFirstLetterOfEachWord(words) {
  const separateWord = words.toLowerCase().split(' ');
  for (let i = 0; i < separateWord.length; i += 1) {
    if (i > 0) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
    }
  }
  return separateWord.join(' ');
}

export function capitalizeStringArray(stringArray) {
  return stringArray.map(word => {
    const [first, ...rest] = word;
    const firstLetter = first.toUpperCase();
    return [firstLetter, ...rest].join('');
  });
}

export function toClassName(value) {
  return kebabCase(capitalizeTheFirstLetterOfEachWord(value)
    .replace(/\s/g, ''));
}
