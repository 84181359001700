import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';
import isNull from 'lodash/isNull';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import axios from 'axios';
import { configForPossibleBackendRequest, queryString } from '../../util/request';
import { getUserSurvey, setUserProject, setUserSurvey } from '../../util/util';

export default function(Vue) {
  const getSurveyDataById = async (projectId, surveyId, isCurrentUserAdmin, token) => {
    let baseConfig = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}` };
    if (!isCurrentUserAdmin) {
      baseConfig = { ...baseConfig, url: `/auth-surveys/${surveyId}` };
    }
    const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
    try {
      const response = await Vue.prototype.$http.request(axiosConfig);
      if (response.status === 200 && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const getLastSurveyFromAllSurveys = async (projectId, isCurrentUserAdmin, token, commit) => {
    let surveysBaseConfig = { method: 'GET', url: `/projects/${projectId}/surveys` };
    if (!isCurrentUserAdmin) {
      surveysBaseConfig = { ...surveysBaseConfig, url: '/auth-surveys' };
    }
    const surveysAxiosConfig = configForPossibleBackendRequest(surveysBaseConfig, token);
    try {
      const surveysResponse = await Vue.prototype.$http.request(surveysAxiosConfig);
      if (surveysResponse.status === 200 && surveysResponse.data) {
        const surveys = surveysResponse.data;
        const payload = (surveys && Array.isArray(surveys) && orderBy(surveys, 'createdAt', 'desc')[0]) || undefined;
        if (payload) {
          return payload;
        }
      }
      return null;
    } catch (error) {
      commit('setError', error);
      return null;
    }
  };
  const getSurvey = async ({ state, getters, commit, rootState }) => {
    const currentUser = rootState?.request?.data?.currentUser ?? null;
    const isCurrentUserAdmin = currentUser?.verbs?.includes('project.delete');
    const projectId = state?.project?.id;
    const selectedSurveyId = getUserSurvey(rootState?.request?.data?.currentUser?.id);
    const token = getters.loggedIn ? getters.token : null;

    let surveyData = null;
    if (token && selectedSurveyId) {
      surveyData = await getSurveyDataById(projectId, selectedSurveyId, isCurrentUserAdmin, token);
    }
    if (!surveyData) {
      surveyData = await getLastSurveyFromAllSurveys(projectId, isCurrentUserAdmin, token, commit);
      if (surveyData) {
        setUserSurvey(currentUser.id, surveyData.id);
      }
    }

    if (surveyData != null) {
      setUserProject(currentUser.id, surveyData.projectId);
      commit('setDirty');
      // If metadata doesn't exist yet, set to undefined
      const metadata = surveyData.metadata ? {
        ...surveyData.metadata
      } : undefined;
      let currentUserRoleSystem = surveyData?.surveyManagers?.find((item) => item.isCurrentUser)?.roleSystem;
      if (!currentUserRoleSystem) {
        const currentUserAssignment = surveyData?.userAssignments?.find((item) => item.isCurrentUser);
        if (currentUserAssignment) {
          currentUserRoleSystem = currentUserAssignment.roleSystem;
        }
      }
      if (!currentUserRoleSystem) {
        currentUserRoleSystem = isCurrentUserAdmin ? 'admin' : 'srvy-sup';
      }
      let surveyManager = surveyData?.surveyManagers?.length > 0 ? surveyData.surveyManagers[0] : null;
      if (!surveyManager) {
        const surveyManagers = surveyData?.userAssignments?.length > 0 ? surveyData.userAssignments.filter((item) => item?.roleSystem === 'srvy-mgr') : null;
        surveyManager = surveyManagers?.length > 0 ? surveyManagers[0] : null;
      }
      commit('setState', {
        ...state,
        form: {
          ...state.form,
          type: surveyData.type,
          language: surveyData.language ? parseInt(surveyData.language, 0) : undefined
        },
        metadata,
        hasOdkbuild: surveyData.hasOdkbuild,
        odkbuild: surveyData.odkbuild,
        xml: surveyData.xml,
        surveyId: surveyData.id,
        pilot: surveyData.pilot,
        name: surveyData.name,
        standardizationComplete: surveyData.standardizationComplete,
        standardizationStarted: surveyData.standardizationStarted,
        standardizationFinished: surveyData.standardizationFinished,
        validation: surveyData.validation || '_unset_',
        reasonForNotValidated: surveyData.reasonForNotValidated,
        status: surveyData.status,
        surveyManager,
        currentUserRoleSystem
      });
    }
  };

  const getCalendar = async ({ state, getters, commit, rootState }, shouldUpdateSurvey = true) => {
    if (state.metadata) {
      const { surveyId } = state;
      if (surveyId) {
        if (shouldUpdateSurvey) getSurvey({ state, getters, commit, rootState });
        const baseConfig = { method: 'GET', url: `/projects/${state.project.id}/survey/${surveyId}/calendar` };
        const token = getters.loggedIn ? getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        try {
          const { data } = await Vue.prototype.$http.request(axiosConfig);
          if (data && !data.noResults) {
            commit('setCalendar', data);
          } else {
            commit('setCalendar', undefined);
          }
        } catch (error) {
          commit('setCalendar', undefined);
          commit('setError', error);
        }
      }
    }
  };

  const getProtocols = async ({ state, getters, commit, rootState }, shouldUpdateSurvey = true) => {
    if (state.metadata) {
      const { surveyId } = state;
      if (surveyId) {
        if (shouldUpdateSurvey) getSurvey({ state, getters, commit, rootState });
        const baseConfig = { method: 'GET', url: `/projects/${state.project.id}/survey/${surveyId}/protocols` };
        const token = getters.loggedIn ? getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        try {
          const { data } = await Vue.prototype.$http.request(axiosConfig);
          if (data && !data.noResults) {
            commit('setProtocols', data);
          } else {
            commit('setProtocols', []);
          }
        } catch (error) {
          commit('setError', error);
        }
      }
    }
  };

  const getSurveyReports = async ({ state, getters, commit }, { params } = {}, shouldUpdateSurvey = true) => {
    const { surveyId = null } = state;
    if (surveyId) {
      if (shouldUpdateSurvey) getSurvey({ state, getters, commit });
      const baseConfig = {
        method: 'GET',
        url: `/projects/${state.project.id}/survey/${surveyId}/survey-report${queryString(params)}`
      };
      const token = getters.loggedIn ? getters.token : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

      const { data } = await Vue.prototype.$http.request(axiosConfig);

      state.surveyReports = data;

      await commit('setSurveyReports', data);
    }
  };

  async function updateSurveyReport({ rootState, state, getters }, opts) {
    const { params, id, name, size, url, validated } = opts;
    const { surveyId } = state;
    const baseConfig = {
      method: 'PUT',
      url: `/projects/${state.project.id}/survey/${surveyId}/survey-report/${id}${queryString(params)}`,
      data: {
        name,
        size,
        url,
        validated
      }
    };

    const token = getters.loggedIn ? getters.token : null;
    const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

    const { data } = await Vue.prototype.$http.request(axiosConfig);

    return data;
  }

  const deleteSurveyReport = async ({ rootState, state }, { reportId }) => {
    const { surveyId } = state;
    const baseConfig = {
      method: 'DELETE',
      url: `/projects/${state.project.id}/survey/${surveyId}/survey-report/${reportId}`
    };
    const { token } = rootState.session;
    const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

    const { data } = await Vue.prototype.$http.request(axiosConfig);

    return data;
  };

  const saveStateToDB = async ({ state, getters, commit }, callback) => {
    const projectId = state.project.id;
    const { surveyId } = state;
    const { organizations, dirty, loading, loadingCount, saving, geoUnits, clusters, error, validation, ...rest } = state;
    const localState = {
      validation: validation === '_unset_' ? undefined : validation,
      ...rest
    };

    const result = omit(
      localState,
      ['hasOdkbuild', 'odkbuild', 'xml']
    );
    const baseConfig = {
      method: 'POST',
      url: `/projects/${projectId}/survey/${surveyId}`,
      data: result
    };


    if (state.saveStateToDBRequestAbortController?.signal && !state.saveStateToDBRequestAbortController?.signal?.aborted) {
      state.saveStateToDBRequestAbortController.abort();
    }
    const newController = window && window.AbortController ? new window.AbortController() : null;
    commit('setSaveStateToDBRequestAbortController', newController);

    const token = getters.loggedIn ? getters.token : null;
    const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

    try {
      const response = await Vue.prototype.$http.request({ ...axiosConfig, signal: newController.signal });
      // If lat/lng changes from localState, fetch local calendars
      if (localState.metadata.latitude !== response.data.metadata.latitude ||
          localState.metadata.longitude !== response.data.metadata.longitude) {
        getCalendar({ state, getters, commit });
      }

      commit('setState', {
        ...omit(
          state,
          [
            'odkbuild',
            'xml',
            'hasOdkbuild'
          ]
        ),
        status:
          (response.data && response.data.status) || state.status,
        ...pick(response.data, [
          'metadata',
          'odkbuild',
          'xml',
          'hasOdkbuild'
        ])
      });
      callback(true);
    } catch (er) {
      if (!axios.isCancel(er)) {
        commit('setError', er.message);
        commit('setAlert', { type: 'danger', message: er.message });
        callback(false);
      }
    }
  };

  const saveODKStateToDB = async ({ state, getters, commit }, callback) => {
    const projectId = state.project.id;
    const { surveyId } = state;
    const { organizations, dirty, loading, loadingCount, saving, geoUnits, clusters, error, validation, ...rest } = state;
    const localState = {
      validation: validation === '_unset_' ? undefined : validation,
      ...rest
    };

    const result = omit(
      rest,
      ['hasOdkbuild']
    );
    const baseConfig = {
      method: 'POST',
      url: `/projects/${projectId}/survey/${surveyId}`,
      data: result
    };
    const token = getters.loggedIn ? getters.token : null;
    const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

    try {
      const response = await Vue.prototype.$http.request(axiosConfig);
      // If lat/lng changes from localState, fetch local calendars
      if (localState.metadata.latitude !== response.data.metadata.latitude ||
          localState.metadata.longitude !== response.data.metadata.longitude) {
        getCalendar({ state, getters, commit });
      }

      commit('setState', {
        ...state,
        status:
          (response.data && response.data.status) || state.status,
        metadata:
          (response.data && response.data.metadata) || undefined
      });
    } catch (er) {
      commit('setError', er);
    } finally {
      callback();
    }
  };

  const getGeoUnitClusters = async ({ state, getters, commit }) => {
    if (state.surveyId) {
      const projectId = state.project.id;
      const token = getters.loggedIn ? getters.token : null;
      const axiosConfig = { method: 'GET', url: `/projects/${projectId}/survey/${state.surveyId}/geounitclusters` };
      try {
        const { data } = await Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token)
        );
        commit('setGeoUnits', data.map(geoUnit => ({ ...geoUnit, clusters: geoUnit.clusters.sort((a, b) => (a.id > b.id ? 1 : -1)).map(cluster => cluster.name).join(', ') })));
        commit('setClusters', data.flatMap(geoUnit => geoUnit.clusters));
      } catch (error) {
        commit('setError', error);
      }
    }
  };

  const getClusterAssignments = async ({ state, getters, commit }) => {
    if (state.surveyId) {
      const projectId = state.project.id;
      const token = getters.loggedIn ? getters.token : null;
      const axiosConfig = { method: 'GET', url: `/projects/${projectId}/survey/${state.surveyId}/assignments` };
      try {
        const { data } = await Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token)
        );
        commit('setClusterAssignments', data);
      } catch (error) {
        commit('setError', error);
      }
    }
  };

  const defaultState = {
    name: null,
    project: undefined,
    metadata: undefined,
    pilot: null,
    form: undefined,
    odkbuild: undefined,
    xml: undefined,
    hasOdkbuild: null,
    saving: false,
    error: undefined,
    dirty: false,
    organizations: undefined,
    geoUnits: [],
    clusters: [],
    clusterAssignments: [],
    protocols: [],
    // TODO: Tembias should be removed from survey - reference standardization-group bias
    temBias: [],
    standardization: [],
    standardizationReport: undefined,
    standardizationComplete: false,
    standardizationStarted: false,
    standardizationFinished: false,
    validation: undefined,
    reasonForNotValidated: undefined,
    status: {
      name: 'Survey Planning',
      progress: 0,
      metadataComplete: false,
      questionnaireComplete: false,
      clustersComplete: false,
      protocolValidated: false,
      calendarComplete: false,
      enumeratorsComplete: false,
      surveyResultsValidated: false,
      surveyReportValidated: false
    },
    enumerators: [],
    surveyId: undefined,
    calendar: undefined,
    skipJustification: undefined,
    loading: null,
    // this tracks the number of times loading has been set/unset,
    // used to prevent loading calls from overriding eachother
    loadingCount: 0,

    // TODO: Move all survey reports to be handled by its own store
    surveyReports: [],

    dashboardSettings: null,
    setDashboardSettingsError: null,
    dashboardSettingsLoaded: false,

    saveStateToDBRequestAbortController: null,
    checkNameAvailabilityForSurveysAbortController: null,

    surveyManager: null,
    currentUserRoleSystem: null
  };
  return {
    state: {
      ...defaultState
    },
    mutations: {
      /* eslint-disable no-param-reassign */
      setForm(state, form) {
        state.form = { ...form };
        state.dirty = true;
      },
      setProject(state, project) {
        state.project = { ...project };
      },
      setMetadata(state, metadata) {
        state.metadata = { ...metadata };
        state.dirty = true;
      },
      setFormFormats(state, { odkbuild, xml }) {
        state.odkbuild = odkbuild;
        state.xml = xml;
        state.dirty = true;
      },
      setSaving(state, saving) {
        state.saving = saving;
      },
      setError(state, err) {
        state.error = err;
      },
      setState(state, dbState) {
        state.form = dbState.form;
        state.hasOdkbuild = dbState.hasOdkbuild;
        state.odkbuild = dbState.odkbuild;
        state.xml = dbState.xml;
        state.metadata = dbState.metadata;
        state.name = dbState.name;
        state.surveyId = dbState.surveyId;
        state.pilot = dbState.pilot;
        state.standardizationComplete = dbState.standardizationComplete;
        state.standardizationStarted = dbState.standardizationStarted;
        state.standardizationFinished = dbState.standardizationFinished;
        state.validation = dbState.validation;
        state.reasonForNotValidated = dbState.reasonForNotValidated;
        state.status = dbState.status;
        state.surveyManager = dbState.surveyManager;
        state.currentUserRoleSystem = dbState.currentUserRoleSystem;
      },
      setSurveyName(state, name) {
        state.name = name;
      },
      setOrganizations(state, organizations) {
        state.organizations = sortBy(organizations, 'name');
      },
      resetSurvey(state) {
        Object.keys(defaultState).forEach(key => {
          state[key] = clone(defaultState[key]);
        });
      },
      resetSurveyMetadata(state, metadata) {
        state.metadata = metadata;
      },
      setDirty(state) {
        state.dirty = true;
      },
      setGeoUnits(state, geoUnits) {
        state.geoUnits = geoUnits;
      },
      setClusters(state, clusters) {
        state.clusters = clusters;
      },
      setClusterAssignments(state, assignments) {
        state.clusterAssignments = assignments;
      },
      setCalendar(state, calendar) {
        state.calendar = calendar;
      },
      setProtocols(state, protocols) {
        state.protocols = protocols;
      },
      setSurveyReports(state, surveyReports) {
        state.surveyReports = surveyReports;
      },
      setLoading(state, loading) {
        if (loading === true) {
          state.loadingCount += 1;
        } else if (loading === false) {
          state.loadingCount -= 1;
        }
        if (state.loadingCount > 0) {
          state.loading = true;
        } else {
          // prevent count from being negative
          state.loadingCount = 0;
          state.loading = false;
        }
      },
      setPilot(state, { pilot, skipJustification }) {
        state.pilot = pilot;
        state.skipJustification = skipJustification;
      },
      setStandardizationComplete(state, status) {
        state.standardizationComplete = status;
      },
      setTemBias(state, temBias) {
        state.temBias = temBias;
      },
      updateStandardization(state, updates) {
        updates.forEach(updateVal => {
          const foundChildItemIndex = state.standardization.findIndex(
            item => item.childId === updateVal.childId && item.enumeratorId === updateVal.enumeratorId
          );

          // We just need to add the updates back to our stored standardization value
          const { weight1, weight2, height1, height2, muac1, muac2 } = updateVal;
          if (![weight1, weight2, height1, height2, muac1, muac2].every(isNull)) {
            if (foundChildItemIndex > -1) {
              state.standardization.splice(foundChildItemIndex, 1, updateVal);
            } else {
              state.standardization.push(updateVal);
            }
          } else if (foundChildItemIndex > -1) {
            Vue.set(state.standardization, foundChildItemIndex, {
              ...state.standardization[foundChildItemIndex],
              weight1,
              weight2,
              height1,
              height2,
              muac1,
              muac2
            });
          }
        });
      },
      setStandardization(state, standardization) {
        state.standardization = standardization;
      },
      setEnumerators(state, enumerators) {
        state.enumerators = enumerators;
      },
      setStandardizationReport(state, report) {
        state.standardizationReport = report;
      },
      setStandardizationStarted(state, status) {
        state.standardizationStarted = status;
      },
      setStandardizationFinished(state, status) {
        state.standardizationFinished = status;
      },
      setDashboardSettings(state, settings) {
        state.dashboardSettings = settings;
      },
      setDashboardSettingsLoaded(state, loaded) {
        state.dashboardSettingsLoaded = loaded;
      },
      setDashboardSettingsError(state, error) {
        state.dashboardSettingsError = error;
      },
      setValidation(state, status) {
        state.validation = status || '_unset_';
      },
      setReasonForNotValidated(state, status) {
        state.reasonForNotValidated = status;
      },
      setStatus(state, status) {
        state.status = status;
      },
      setSaveStateToDBRequestAbortController(state, controller) {
        state.saveStateToDBRequestAbortController = controller;
      },
      setCheckNameAvailabilityForSurveysAbortController(state, controller) {
        state.checkNameAvailabilityForSurveysAbortController = controller;
      }
      /* eslint-enable no-param-reassign */
    },
    getters: {
      isSENS: state => state.metadata && state.metadata.surveyType === 'SENS',
      isRapidSmart: state => state.metadata && state.metadata.surveyType === 'Rapid Smart',
      isExhaustive: state => state.metadata && state.metadata.samplingMethod === 'Exhaustive',
      isRandom: state => state.metadata && state.metadata.samplingMethod === 'Random',
      isRandomOrExhaustive: state => state.metadata && (state.metadata.samplingMethod === 'Random' || state.metadata.samplingMethod === 'Exhaustive'),
      surveyCompletePercent: (state) => Math.floor(state.status.progress * 100),
      isMetadataComplete: (state) => state.status.metadataComplete,
      isQuestionnareComplete: (state) => state.status.questionnaireComplete,
      isClustersComplete: (state) => state.status.clustersComplete,
      hasHouseholdDataForEnumerator: (state) => {
        if (state.metadata) {
          const clusterRequiredProperties = [
            'householdDayTeam'
          ];
          const isRequiredFieldsComplete = clusterRequiredProperties
            .map(property => state.metadata[property] !== null)
            .filter(value => !value).length === 0;
          return isRequiredFieldsComplete;
        }

        return false;
      },
      hasQuestionnaireForEnumerator: (state) => {
        if (state.metadata) {
          const clusterRequiredProperties = [
            'householdDayTeam'
          ];
          const isRequiredFieldsComplete = clusterRequiredProperties
            .map(property => state.metadata[property] !== null)
            .filter(value => !value).length === 0;
          return isRequiredFieldsComplete;
        }

        return false;
      },
      isLocalEventComplete: (state) => state.status.calendarComplete,
      isEnumeratorsComplete: (state) => state.status.enumeratorsComplete,
      meetsLocalEventRequirements: (state) => {
        if (state.metadata) {
          const metadataPageRequiredProperties = [
            'geographicAreaName',
            'surveyMonth',
            'surveyYear'
          ];
          return metadataPageRequiredProperties
            .map(property => state.metadata[property])
            .filter(value => !value).length === 0;
        }
        return false;
      },
      isSurveyActive(state) {
        // If pilot is complete
        return state.pilot === false;
      },
      surveyOrganization(state) {
        if (state.organizations !== undefined) {
          return state.organizations
            .find(organization => organization.id === state.metadata?.organizationId);
        }
        return undefined;
      },
      isProtocolValidated(state) {
        return state.status.protocolValidated;
      },
      isSurveyResultsValidated(state) {
        return state.status.surveyResultsValidated;
      },
      surveyReportsByCreatedAt(state) {
        const { surveyReports } = state;
        return surveyReports.sort((report) => report.createdAt);
      },
      isSurveyReportValidated(state) {
        return state.status.surveyReportValidated;
      },
      clusterCumulativePopulation(state) {
        const assignments = state.geoUnits
          .filter(assignment => !Number.isNaN(assignment.population));
        const sorted = sortBy(assignments, 'name');
        const populationTotal = sorted.reduce((accum, current) => accum + current.population, 0);
        return populationTotal;
      },
      clustersHaveAssignments(state) {
        const clustersWithAssignments = state.clusters.filter(cluster => cluster.name);
        return clustersWithAssignments.length > 0;
      }
    },
    actions: {
      async saveDashboardSettings({ getters, commit }, { projectId, surveyId, settings }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/settings/dashboard`,
            data: settings
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            if (data && data.length && data.length > 0) {
              commit('setDashboardSettings', data[0].config);
            }
          } catch (error) {
            commit('setDashboardSettingsError', error);
          }
        }
      },
      async getDashboardSettings({ getters, commit }, { projectId, surveyId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const axiosConfig = {
            method: 'GET',
            url: `/projects/${projectId}/survey/${surveyId}/settings/dashboard`
          };

          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(axiosConfig, token)
            );
            commit('setDashboardSettings', data.config);
          } catch (error) {
            commit('setDashboardSettingsError', error);
          } finally {
            commit('setDashboardSettingsLoaded', true);
          }
        }
      },
      setLoading(vuex, loading) {
        vuex.commit('setLoading', loading);
      },
      async setProject(vuex, project) {
        const isNew = project.id !== vuex.state.project?.id;
        vuex.commit('setLoading', true);
        vuex.commit('setProject', project);
        await getSurvey(vuex);
        if (isNew) {
          await Promise.all([
            getGeoUnitClusters(vuex),
            getClusterAssignments(vuex),
            getCalendar(vuex, false),
            getProtocols(vuex, false),
            getSurveyReports(vuex, {}, false)
          ]);
        }
        vuex.commit('setLoading', false);
      },
      async setForm(vuex, form) {
        vuex.commit('setForm', form);
        vuex.commit('setSaving', true);
        await saveStateToDB(vuex, (isSuccessful) => {
          vuex.commit('setSaving', isSuccessful ? false : null);
        });
      },
      async setMetadata(vuex, metadata) {
        if (!isEqual({ ...vuex.state.metadata }, metadata)) {
          vuex.commit('setMetadata', metadata);
          vuex.commit('setSaving', true);
          await saveStateToDB(vuex, (isSuccessful) => {
            vuex.commit('setSaving', isSuccessful ? false : null);
          });
        }
      },
      async setFormFormats(vuex, { odkbuild, xml }) {
        vuex.commit('setFormFormats', { odkbuild, xml });
        vuex.commit('setSaving', true);
        await saveODKStateToDB(vuex, () => {
          vuex.commit('setSaving', false);
        });
      },
      async setOrganizations({ commit }, organizations) {
        await commit('setOrganizations', organizations);
      },
      setDirty({ commit }) {
        commit('setDirty');
      },
      async getGeoUnitClusters(vuex) {
        await getGeoUnitClusters(vuex);
      },
      async setClusterAssignments({ commit }, clusterAssignments) {
        await commit('setClusterAssignments', clusterAssignments);
      },
      async getClusterAssignments(vuex) {
        await getClusterAssignments(vuex);
      },
      async getCalendar(vuex) {
        await getCalendar(vuex);
      },
      async getProtocols(vuex) {
        await getProtocols(vuex);
      },
      async setSurveyReports({ commit }, { surveyReports }) {
        commit('setSurveyReports', surveyReports);
      },
      getSurveyReports,
      updateSurveyReport,
      deleteSurveyReport,
      async setPilot(vuex, payload) {
        vuex.commit('setPilot', payload);
        await saveStateToDB(vuex, (isSuccessful) => {
          vuex.commit('setSaving', isSuccessful ? false : null);
        });
      },
      async setStandardizationComplete(vuex, { status, shouldSaveToDB = true }) {
        vuex.commit('setStandardizationComplete', status);
        if (shouldSaveToDB) {
          await saveStateToDB(vuex, (isSuccessful) => {
            vuex.commit('setSaving', isSuccessful ? false : null);
          });
        }
      },
      async setGeoUnitClusters({ getters, state, commit, rootState }, { geoUnits, justification, updateClusters = false }) {
        const { surveyId } = state;
        const projectId = state.project.id;
        if (projectId && surveyId) {
          commit('setSaving', true);
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/geounitclusters?updateClusters=${!!updateClusters}`, data: { geoUnits, justification } };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setGeoUnits', response.data.map(geoUnit => ({ ...geoUnit, clusters: geoUnit.clusters.sort((a, b) => (a.id > b.id ? 1 : -1)).map(cluster => cluster.name).join(', ') })));
            commit('setClusters', response.data.flatMap(geoUnit => geoUnit.clusters));
            await getSurvey({ state, getters, commit, rootState });
          } catch (error) {
            commit('setError', error);
          } finally {
            commit('setSaving', false);
          }
        }
      },
      async createProjectWithName({ getters, commit }, { name }) {
        if (name) {
          const token = getters.loggedIn ? getters.token : null;
          const config = {
            method: 'POST',
            url: '/projects',
            data: { name }
          };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );

            return response;
          } catch (error) {
            const err = error;
            if (err.response) {
              commit('setError', error.response.data);
            } else {
              commit('setError', error);
            }
          }
        }
        return null;
      },
      async updateSurveyName({ getters, commit }, { surveyId, projectId, name }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = {
            method: 'PATCH',
            url: `/projects/${projectId}/survey/${surveyId}`,
            data: { name }
          };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setSurveyName', data.name);
            return data.name;
          } catch (error) {
            commit('setError', error);
          }
        }
        return null;
      },
      async createSurveyWithName({ getters, commit }, { projectId, data }) {
        if (projectId) {
          const token = getters.loggedIn ? getters.token : null;

          const config = {
            method: 'POST',
            url: `/projects/${projectId}/survey`,
            params: {
              nameOnly: true
            },
            data
          };
          try {
            const { data: value } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            return value;
          } catch (error) {
            commit('setError', error);
          }
        }
        return null;
      },
      async checkNameAvailabilityForSurveys({ getters, state, commit }, { name }) {
        if (state.checkNameAvailabilityForSurveysAbortController && !state.checkNameAvailabilityForSurveysAbortController.signal.aborted) {
          state.checkNameAvailabilityForSurveysAbortController.abort();
        }
        if (name) {
          const token = getters.loggedIn ? getters.token : null;
          const newController = new AbortController();
          commit('setCheckNameAvailabilityForSurveysAbortController', newController);

          const config = {
            method: 'POST',
            url: '/surveys/name-checks',
            data: { name },
            signal: newController.signal
          };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            return data;
          } catch (error) {
            if (axios.isCancel(error)) {
              // Request canceled
              return null; // so we don't show the error message.
            }
            commit('setError', error);
          }
        }
        return false;
      },
      async getTemBias({ getters, commit }, { surveyId, projectId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}/standardization/tembias` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setTemBias', response.data?.tembias);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getStandardizationReport({ getters, commit, state }) {
        if (state.project.id && state.surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'GET', url: `/projects/${state.project.id}/survey/${state.surveyId}/standardization/report` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setStandardizationReport', response.data?.report);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async getStandardization({ getters, commit }, { surveyId, projectId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'GET', url: `/projects/${projectId}/survey/${surveyId}/standardization` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );

            commit('setStandardization', response.data);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async updateStandardization({ getters, commit }, { surveyId, projectId, updates }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/standardization`,
            data: updates
          };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('updateStandardization', data);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async resetStandardization({ getters, commit }, { surveyId, projectId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/standardization/reset` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setStandardization', response.data);
            commit('setTemBias', []);
            commit('setStandardizationReport', undefined);
            commit('setStandardizationStarted', false);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async startStandardization({ getters, commit }, { surveyId, projectId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = {
            method: 'POST',
            url: `/projects/${projectId}/survey/${surveyId}/standardization/start`
          };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setStandardizationStarted', response.data.success);
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      async finishStandardization({ state, getters, commit, rootState }, { surveyId, projectId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = { method: 'POST', url: `/projects/${projectId}/survey/${surveyId}/standardization/finish` };
          try {
            const response = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );
            commit('setStandardizationFinished', response.data.success);
            await getSurvey({ state, getters, commit, rootState });
          } catch (error) {
            commit('setError', error);
          }
        }
      },
      setEnumerators({ commit }, enumerators) {
        commit('setEnumerators', enumerators);
      },
      async setValidation(vuex, { validation, reasonForNotValidated = null }) {
        const projectId = vuex.state.project.id;
        const { surveyId } = vuex.state;
        const result = {
          validation: validation === '_unset_' ? undefined : validation
        };

        const baseConfig = {
          method: 'POST',
          url: `/projects/${projectId}/survey/${surveyId}`,
          data: result
        };

        const token = vuex.getters.loggedIn ? vuex.getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

        try {
          const response = await Vue.prototype.$http.request(axiosConfig);
          if (response.status === 200 && response.data) {
            vuex.commit('setValidation', validation);
            vuex.commit('setReasonForNotValidated', reasonForNotValidated);
            vuex.commit('setStatus', response.data && response.data.status);
          }
        } catch (error) {
          vuex.commit('setError', error);
          // re-throw the error so it can be caught and displayed
          throw error;
        }
      },
      emptyStoredSurvey({ commit }) {
        commit('resetSurvey');
      },
      async resetTargetSurvey({ getters, commit }, { projectId, surveyId }) {
        if (projectId && surveyId) {
          const token = getters.loggedIn ? getters.token : null;
          const config = {
            method: 'POST',
            url: `/projects/${projectId}/surveys/${surveyId}/reset`
          };
          try {
            const { data } = await Vue.prototype.$http.request(
              configForPossibleBackendRequest(config, token)
            );

            commit('resetSurveyMetadata', data.metadata);
            return data;
          } catch (error) {
            commit('setError', error);
          }
        }
        return null;
      }
    }
  };
}
