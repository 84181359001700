<template>
  <div class="w100 h100">
    <l-map
      ref="myMap"
      class="map"
      :zoom="zoom"
      :center="center"
      :options="{attributionControl: false}"
      @ready="ready"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate">
      <v-tilelayer-googlemutant
        :apikey="GOOGLE_MAPS_API_KEY"
        :options="{
          styles: DEFAULT_MAP_STYLES,
        }"
        lang="en"
        region="en"/>
      <l-marker
        v-for="marker of mapMarkers"
        :key="marker.key"
        :draggable="false" :lat-lng="marker.latLng">
        <l-tooltip>
          {{ $t('components.toolTips.geoUnitMarker', { marker: marker.unitName }) }}
          <br>
          {{ $t('components.toolTips.clustersMarker', { marker: marker.clusters.join(', ') }) }}
          <br>
          {{ $t('components.toolTips.assignedToTeams', { marker: marker.teamIds.join(', ') }) }}
        </l-tooltip>
        <l-icon>
          <div
            class="cluster-icon"
            :style="{ '--circleColor': getRandomColor(marker.teamIds[0], 1) }"></div>
        </l-icon>
      </l-marker>
      <l-marker
        v-for="teamLocation of teamLocations"
        :key="teamLocation.key"
        :draggable="false" :lat-lng="teamLocation.latLng">
        <l-tooltip>
          {{ $t('components.toolTips.clusterMarker', { name: teamLocation.cluster }) }}
          <br>
          {{ $t('components.toolTips.teamLocation', { name: teamLocation.team }) }}
          <br>
          {{ teamLocation.statistics.households ? $t('components.toolTips.householdsStats', { households:teamLocation.statistics.households.join(', ') }) : null }}
        </l-tooltip>
        <l-icon>
          <div
            class="submissions-icon"
            :style="{ '--circleColor': getRandomColor(teamLocation.team, 1) }"></div>
        </l-icon>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import L, { latLng } from 'leaflet';
import { LMap, LMarker, LIcon, LTooltip } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import { uniq, isEqual, minBy, maxBy, groupBy, keys } from 'lodash';
import 'leaflet/dist/leaflet.css';
import { DEFAULT_MAP_STYLES, GOOGLE_MAPS_API_KEY } from '../../../util/map';
import { getRandomColor } from '../../../util/util';
import { getRCNumber } from '../../../util/clusters';

export default {
  name: 'ProjectDashboardClusterMap',
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    clusters: {
      type: Array,
      required: true
    },
    clusterAssignments: {
      type: Array,
      required: true
    },
    initialLat: {
      type: Number,
      required: true
    },
    initialLng: {
      type: Number,
      required: true
    },
    clusterTeamLocations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      DEFAULT_MAP_STYLES,
      GOOGLE_MAPS_API_KEY,
      icon: L.icon({
        iconUrl: '/marker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 20]
      }),
      zoom: 10,
      center: latLng(this.initialLat, this.initialLng),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
    };
  },
  computed: {
    teamLocations() {
      return this.clusterTeamLocations.filter((item) => item?.gps?.latitude != null && item?.gps?.longitude != null).map((item) => ({
        ...item,
        key: `submission-${item.cluster}-${item.team}`,
        latLng: latLng({ lat: item.gps.latitude, lng: item.gps.longitude, alt: item.gps.altitude })
      }));
    },
    mapMarkers() {
      const clustersGroupByGeoUnits = groupBy(this.clusterAssignments
        .filter(cluster => cluster.location), 'unitName');
      const geoUnitLocations = keys(clustersGroupByGeoUnits).map((geoUnit) => {
        const clusters = clustersGroupByGeoUnits[geoUnit];
        const teamIds = uniq(clusters.map((item) => item.teamId)).sort();
        const clusterNames = uniq(clusters.map((item) => (item.name !== 'RC' ? item.name : `${item.name}-${item.unitName}${getRCNumber(item, this.clusters, '#')}`))).sort();
        const [latitude, longitude] = clusters[0].location && clusters[0].location.split(',').map((loc) => loc.trim());
        return {
          key: `clusters-${clusterNames.join(',')}-teams-${teamIds.join(',')}`,
          clusters: clusterNames,
          unitName: geoUnit,
          teamIds,
          latLng: latLng(latitude, longitude)
        };
      });
      return geoUnitLocations;
    }
  },
  watch: {
    teamLocations(newValues, oldValues) {
      if (!isEqual(newValues, oldValues)) {
        this.updateCenter();
      }
    },
    mapMarkers(newValues, oldValues) {
      if (!isEqual(newValues, oldValues)) {
        this.updateCenter();
      }
    }
  },
  methods: {
    getRandomColor,
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.$refs.myMap.mapObject._onResize();
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    ready() {
      this.$refs.myMap.mapObject._onResize();
    },
    updateCenter() {
      const locations = [...this.mapMarkers, ...this.teamLocations].map((item) => ({ latitude: item.latLng.lat, longitude: item.latLng.lng }));
      const minLat = minBy(locations, 'latitude').latitude;
      const minLng = minBy(locations, 'longitude').longitude;
      const maxLat = maxBy(locations, 'latitude').latitude;
      const maxLng = maxBy(locations, 'longitude').longitude;
      this.$refs.myMap.mapObject.flyToBounds([
        latLng(maxLat, minLng),
        latLng(minLat, maxLng)
      ]);
    }
  }
};
</script>

<style lang="scss">
.cluster-icon {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: relative;
    background: var(--circleColor);
}
.submissions-icon {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: relative;
    background: var(--circleColor);
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      left: -11px;
      top: -11px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: var(--circleColor);
      opacity: 0.4;
    }
}
</style>
