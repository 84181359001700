var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hide-required-mark":true}},[_c('h4',{staticClass:"mt-24 mb-22 txt-bold txt-font-din-medium"},[_vm._v(_vm._s(_vm.$t('components.titles.changePassword')))]),_c('a-card',{attrs:{"bordered":true}},[_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.oldPassword'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'oldPassword',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('components.description.pleaseEnterYourCurrentPassword'),
                    } ]
              }]),expression:"[\n                'oldPassword',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('components.description.pleaseEnterYourCurrentPassword'),\n                    },\n                  ]\n              }]"}],attrs:{"type":"password","placeholder":_vm.$t('components.labels.oldPassword'),"size":"large"}})],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.newPassword'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'newPassword',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('components.description.pleaseEnterYourNewPassword'),
                    } ]
              }]),expression:"[\n                'newPassword',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('components.description.pleaseEnterYourNewPassword'),\n                    },\n                  ]\n              }]"}],attrs:{"type":"password","placeholder":_vm.$t('components.labels.newPassword'),"size":"large"}})],1),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.confirmPassword'))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirm',
                {
                  rules: [
                    {
                      validator: _vm.newPasswordMismatched,
                      trigger: 'change',
                      required: true,
                      message: _vm.$t('components.description.passwordsDoNotMatch'),
                    } ]
              }]),expression:"[\n                'confirm',\n                {\n                  rules: [\n                    {\n                      validator: newPasswordMismatched,\n                      trigger: 'change',\n                      required: true,\n                      message: $t('components.description.passwordsDoNotMatch'),\n                    },\n                  ]\n              }]"}],attrs:{"type":"password","placeholder":_vm.$t('components.labels.confirmPassword'),"size":"large"}})],1),_c('a-button',{staticClass:"mt-10 mb-12 w170 flex align-center justify-center min-width-fit-content",attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('components.description.changePassword'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }