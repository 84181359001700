<!--
Copyright 2020 SMART Methodology
Copyright 2019 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

NOTICE: THIS FILE HAS BEEN MODIFIED BY SMART Methodology UNDER COMPLIANCE WITH THE
APACHE 2.0 LICENCE FROM THE ORIGINAL WORKOF THE COMPANY ODK Central. THE FOLLOWING
IS THE COPYRIGHT OF THE ORIGINAL DOCUMENT:

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->

<template>
  <div id="set-password-container" class="row">
    <div class="col-xl-lg-md-sm-xs-12 set-password-panel">
      <a href="/">
        <img src="/logo.svg" width="250px">
      </a>
      <div class="mt-20">
        <router-link id="login-link" to="/login">
          {{ $t('components.titles.backToLogin') }}
        </router-link>
      </div>
      <div class="heading mt-20">
        <h3>{{ $t('components.titles.setPassword') }}</h3>
        <router-link id="login-link" :to="loginLocation"
          :disabled="disabled">
          {{ $t('components.titles.backToLogin') }}
        </router-link>
      </div>

      <div class="form">
        <div class="panel-body">
          <form @submit.prevent="submit">
            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.password') }}</legend>
              <div class="input-group">
                <input
                  v-model="form.password"
                  data-cy="password"
                  :type="passwordFieldType"
                  class="form-control"
                  :placeholder="$t('components.titles.passwordPlaceholder')"
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  autocomplete="off">
                  <span id="basic-addon1"
                    class="input-group-addon eye-styling"
                    data-cy="see-password-button"
                    @click="togglePasswordFieldType">
                    <embed src="/eye.svg" :class="showPassword">
                    <embed src="/eye-blocked.svg" :class="hidePassword">
                  </span>
              </div>
              <div>
                <ul>
                  <li :class="containsLetter">{{ $t('components.description.mustContainAtLeastOneLetter') }}</li>
                  <li :class="containsCapital">{{ $t('components.description.mustContainAtLeastOneUppercase') }}</li>
                  <li :class="containsLowercase">{{ $t('components.description.mustContainAtLeastOneLowercase') }}</li>
                  <li :class="containsNumber">{{ $t('components.description.mustContainAtLeastOneNumber') }}</li>
                  <li :class="containsSpecialCharacter">
                    {{ $t('components.description.mustContainSpecialCharacter') }}
                  </li>
                  <li :class="longEnough">{{ $t('components.description.mustBeAtLeast8Characters') }}</li>
                  </ul>
              </div>
            </fieldset>

            <fieldset class="legend-border">
              <legend class="legend-border">{{ $t('components.titles.confirmPassword') }}</legend>
              <div class="input-group">
                <input
                  v-model="form.confirmPassword"
                  data-cy="confirmPassword"
                  :type="passwordFieldType"
                  class="form-control"
                  :placeholder="$t('components.titles.confirmPasswordPlaceholder')"
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  autocomplete="off">
                  <span id="basic-addon1"
                    class="input-group-addon eye-styling"
                    data-cy="see-password-button"
                    @click="togglePasswordFieldType">
                    <embed src="/eye.svg" :class="showPassword">
                    <embed src="/eye-blocked.svg" :class="hidePassword">
                  </span>
              </div>
              <div class="error" :hidden="passwordFieldsMatch">{{ $t('components.description.passwordFieldsMatch') }}</div>
            </fieldset>

            <div class="footer">
              <button
                id="set-password-button"
                :disabled="disabled"
                type="submit" class="btn btn-primary orange">
                {{ $t('components.titles.setPassword') }} <spinner :state="disabled"/>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Spinner from '../spinner.vue';
import request from '../../mixins/request';

export default {
  name: 'AccountClaim',
  components: { Spinner },
  mixins: [request()],
  data() {
    return {
      passwordFieldType: 'password',
      passwordVisibilityIconPath: '/eye-blocked.svg',
      awaitingResponse: false,
      showPassword: 'hide',
      hidePassword: 'show',
      form: {
        password: '',
        confirmPassword: ''
      }
    };
  },
  computed: {
    formValid() {
      return this.containsNumber === 'success' &&
      this.containsLetter === 'success' &&
      this.containsSpecialCharacter === 'success' &&
      this.containsCapital === 'success' &&
      this.containsLowercase === 'success' &&
      this.longEnough === 'success' &&
      this.passwordFieldsMatch;
    },
    containsCapital() {
      return this.form.password.match(/[A-Z]/g) ? 'success' : 'error';
    },
    containsLowercase() {
      return this.form.password.match(/[a-z]/g) ? 'success' : 'error';
    },
    containsNumber() {
      return this.form.password.match(/[0-9]/g) ? 'success' : 'error';
    },
    containsLetter() {
      return this.form.password.match(/[a-zA-Z]/g) ? 'success' : 'error';
    },
    containsSpecialCharacter() {
      // eslint-disable-next-line no-useless-escape
      return this.form.password.match(/[\_\*\-?]/g) ? 'success' : 'error';
    },
    longEnough() {
      return this.form.password.length >= 8 ? 'success' : 'error';
    },
    passwordFieldsMatch() {
      return this.form.password === this.form.confirmPassword;
    }
  },
  mounted() {
    this.$refs.password.focus();
  },
  methods: {
    togglePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.passwordVisibilityIconPath = this.passwordVisibilityIconPath === '/eye.svg' ? 'eye-blocked.svg' : '/eye.svg';
      this.showPassword = this.showPassword === 'show' ? 'hide' : 'show';
      this.hidePassword = this.hidePassword === 'hide' ? 'show' : 'hide';
    },
    submit() {
      if (!this.formValid) return;

      const headers = {};
      const { token } = this.$route.query;
      if (token != null) headers.Authorization = `Bearer ${token}`;
      this.request({
        method: 'POST',
        url: '/users/reset/verify',
        headers,
        data: { new: this.form.password },
        problemToAlert: ({ code }) => (code === 401.2
          ? this.$t('components.notifications.expireCredentials')
          : null)
      })
        .then(() => {
          this.$router.push('/login', () => {
            this.$alert().success(this.$t('components.notifications.passwordReset'));
          });
        })
        .catch(() => {
          this.$router.push('/reset-password');
        });
    }
  }
};
</script>


<style scoped>

.set-password-panel {
  font-family: 'DINNextLTPro-Regular';
  padding: 30px 30px;
  color: #363534;
  background-color:#fff;
}
.heading {
  text-align: left;
  margin-bottom : 1em;
}
.header h4 {
  font-family : 'DINNextLTPro-Regular';
  color : #363534;
}
.heading img {
  min-width: 120px;
  width: 50%;
  padding-bottom: 2.5em;
}

.heading p {
  font-size: 1.25em;
  color: #9a9a99;
}
legend {
  padding : 0 1em 0 1em;
}
input {
  border: none;
}
.panel-body {
  padding : 0 0 0 0;
}
.panel-footer {
  background-color: #fff;
}
#set-password-container {
  margin-top: 1.75em;
  margin-bottom : 1.75em;
  box-shadow: 0 0 1em #333;
  background-color:#fff;
  box-sizing: border-box;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 840px;
  font-size:1em;
}
button.orange {
  background-color: #e98301 !important;
  font-size: 1em;
  font-family: 'SourceSansPro-SemiBold';
  border: none;
}

fieldset.legend-border {
  border: 1px solid #ddd !important;
  padding: 0 1em 1em 1em !important;
  margin: 0 0 1em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.legend-border {
  font-size: 0.75em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;

  border-bottom: none;
}
.legend-border .input-group {

}

span.icon-eye {
  font-size: 20px;
}

embed {
  pointer-events: none;
  height : 20px;
  width : 20px;
  padding : 0 0 0 0;
}

.row {
  margin-left:0;
  margin-right:0;
  padding: 0 0 0 0;
}

#forgot-password-col {
  text-align: right;
  padding-right:0;
}

#set-password-button {
  font-family: 'DINNextLTPro-Regular';
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  height : 3.5em;
  width : 50%;
}
.eye-styling {
  background-color:white;
  border-color:white;
}
.footer p {
  font-family : 'DINNextLTPro-Medium';
  margin-bottom : 0;
}

#login-link:hover {
  color :#e98301;
}
#login-link:active {
  color :#d3b58e;
}
#login-link:link {
  color :#e98301;
}
#login-link:visited {
  color :#e98301;
}



.left-cell {
  padding: 0 5px 0 0;
}
.right-cell {
  padding: 0 0 0 5px;
}

input[type=text] {
  border : 0;
  box-shadow : none;
}
input[type=password] {
  border : 0;
  box-shadow : none;
}
input[type=email] {
  border : 0;
  box-shadow : none;
}
ul {
  background-color : #efefef;
}
.error {
  color : #f00;
  background-color : #efefef;

}
.success {
  display: none;
}


</style>
