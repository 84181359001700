<template>
  <div class="mt-40" :class="{ 'disabled' : disabled }">
    <a-spin :spinning="loading !== false" size="large">
      <CalendarActions/>
      <CalendarUpload/>
    </a-spin>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CalendarUpload from './calendar-upload/calendar-upload.vue';
import project from '../../../mixins/project';
import isSurveyActive from '../../../mixins/isSurveyActive';

export default {
  name: 'LocalEventCalendar',
  components: {
    CalendarUpload,
    CalendarActions: () => import('./calendar-actions/calendar-actions.vue')
  },
  mixins: [project(), isSurveyActive()],
  computed: {
    ...mapState({
      loading: state => state.survey.loading
    })
  }
};
</script>
