import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import sortBy from 'lodash/sortBy';
import { configForPossibleBackendRequest, apiPaths } from '../util/request';
import { noop, getUserProject } from '../util/util';
import { requestData } from '../store/modules/request';

export default () => {
  const mixin = {
    computed: {
      ...requestData(['projects', 'currentUser']),
      ...mapState({
        organizations: state => state.survey.organizations,
        project: state => state.survey.project,
        surveyId: state => state.survey.surveyId
      }),
      currentProject() {
        const storedProjectId = getUserProject(this.currentUser.id);
        if (storedProjectId) {
          return { _data: { id: storedProjectId } };
        }
        const projects = sortBy((this.projects || [])
          .filter(project => !project._data.archived), 'id')
          .reverse();
        return ((projects && Array.isArray(projects) && sortBy(projects, 'createdAt')[0]) || undefined);
      },
      projectAndSurveyIds() {
        return { projectId: this.project?.id, surveyId: this.surveyId };
      }
    },
    watch: {
      projectAndSurveyIds: {
        deep: true,
        handler(newVal) {
          const { projectId, surveyId } = newVal;
          if (projectId != null && surveyId != null) {
            this.$store.dispatch('get', [{
              key: 'forms',
              url: apiPaths.surveyForms(projectId, surveyId),
              extended: true
            }]).catch(noop);
            this.$store.dispatch('get', [{
              key: 'surveyEnumerators',
              url: apiPaths.enumerators(projectId, surveyId),
              extended: true,
              clear: false,
              resend: true
            }]).catch(noop);
          }
        }
      },
      projects: {
        deep: true,
        handler() {
          if (this.$store.state.request.requests.projects.last.state === 'success' && this.currentProject) {
            this.setProject(this.currentProject._data);
          }

          if (this.$store.state.request.requests.projects.last.state === 'success' && !this.currentProject) {
            this.$router.push('/');
          }
        }
      }
    },
    async created() {
      this.refreshProject();
      const baseConfig = { method: 'get', url: '/organizations' };
      const token = this.$store.getters.token ? this.$store.getters.token : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);

      try {
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        await this.setOrganizations(data);
      } catch (error) {
        this.error = error.message;
      }
    },
    methods: {
      ...mapActions([
        'setProject',
        'setOrganizations',
        'setLoading'
      ]),
      refreshProject() {
        this.setLoading(true);
        this.$store.dispatch('get', [{
          key: 'projects',
          url: '/projects',
          extended: true
        }])
          .then(() => this.setLoading(false))
          .catch(() => this.setLoading(false));
      }
    }
  };

  return mixin;
};
