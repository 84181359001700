<template>
  <Modal
      width="530px"
      class="validation-modal"
      :dialog-style="{ display: 'none' }"
      :footer="null"
      :visible="true"
      :closable="true"
      @cancel="onExit">
    <h1 id="modal-wizard-title" class="flex align-center txt-30 txt-bold txt-font-din-medium">
      {{ title }}
    </h1>

    <div v-for="(prompt, ix) in currentPrompt" :key="ix">
      <br>
      <p :id="prompt.key + '-prompt'" class="txt-16">
        {{ $t(`components.description.${prompt.prompt}`) }}
      </p>

      <Select
          v-if="prompt.type.name === 'Array'"
          key="ix"
          v-model="results[prompt.key]"
          class="w100"
          :get-popup-container="(triggerNode) => triggerNode.parentNode">
        <a-icon slot="suffixIcon" type="caret-down"/>

        <a-select-option v-for="(option, _ix) in prompt.options" :key="_ix" :value="option">
          <div :id="toClassName(option)">{{ (typeof option === 'string' || option instanceof String) !== null && $t(`components.labels.${option.toLowerCase().replaceAll(' ', '_')}`) }}</div>
        </a-select-option>
      </Select>

      <a-textarea
          v-if="prompt.type.name === 'String'"
          :id="prompt.key"
          v-model="results[prompt.key]"
          :placeholder="$t('components.description.justificationPlaceholder')"
          :required="true"
          size="large"/>

      <a-checkbox
          v-if="prompt.type.name === 'Boolean'"
          :id="prompt.key"
          v-model="results[prompt.key]">
        {{ $t('values.accept') }}
      </a-checkbox>
    </div>

    <div class="mt-24 flex justify-space-between">
      <Button
          v-if="currentPromptIndex > 0"
          id="back"
          class="w101"
          type="primary"
          size="large"
          @click="previousPrompt()">
        {{ $t('values.back') }}
      </Button>

      <a-button
          id="next"
          class="w101"
          type="primary"
          size="large"
          @click="nextPrompt()">
        {{ currentPrompt[0].promptConfirmText ? currentPrompt[0].promptConfirmText : $t('values.next') }}
      </a-button>
    </div>
  </Modal>
</template>

<script>

import { Button, Modal, Select } from 'ant-design-vue';
import Prompts from '../prompts/prompts';
import { toClassName } from '../../stringUtilities';


export default {
  name: 'ModalWizard',
  components: {
    Button,
    Modal,
    Select
  },
  props: {
    promptOpts: {
      type: Object,
      required: false,
      default: () => ({})
    },
    title: {
      type: String,
      required: true
    },
    prompts: {
      type: Array,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      lastPrompt: {},
      currentPromptIndex: 0,
      results: {
        ...this.genPrompts(this.results).defaultResult,
        ...this.promptOpts
      }
    };
  },
  computed: {
    currentPrompt() {
      const prompt = this._prompts.prompts[this.currentPromptIndex];

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lastPrompt = prompt;

      if (Array.isArray(prompt)) {
        return prompt;
      }

      return [prompt];
    },
    _prompts() {
      return this.genPrompts(this.results);
    }
  },
  watch: {
  },
  methods: {
    toClassName,
    previousPrompt() {
      this.currentPromptIndex -= 1;
    },
    nextPrompt() {
      const nextIndex = this.currentPromptIndex + 1;

      if (nextIndex > this._prompts.prompts.length - 1) {
        if (this._prompts.prompts.length - nextIndex < -1) {
          this.currentPromptIndex = this._prompts.prompts.length - 1;
        }
        this.onComplete(this.results);
      } else {
        this.currentPromptIndex = nextIndex;
      }
    },
    genPrompts() {
      const prompts = new Prompts({
        data: this.results,
        lastPrompt: this.lastPrompt,
        prompts: this.prompts
      });
      return prompts;
    }
  }
};
</script>

<style lang="scss">
.ant-modal-body > h1 {
  margin-bottom: 24px;
}
</style>
