import VueI18n from 'vue-i18n';

export const i18n = new VueI18n();

const loadedLanguages = []; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // babel eslint does not support dynamic import with template string https://github.com/babel/babel-eslint/issues/681
  // eslint-disable-next-line
  return import(/* webpackChunkName: 'lang-[request]' */ './locale/' + lang + '/index.js').then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    }
  );
}
