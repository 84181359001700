import { mapActions, mapState } from 'vuex';
import { apiPaths } from '../util/request';
import { noop } from '../util/util';
import { requestData } from '../store/modules/request';
import { grantFormAccessToEnumeratorsForProject } from '../util/enumerators';

export default () => {
  const mixin = {
    computed: {
      ...requestData(['surveyEnumerators']),
      ...mapState({
        enumerators: state => state.survey.enumerators
      })
    },
    watch: {
      surveyEnumerators: {
        deep: true,
        handler() {
          if (this.surveyEnumerators?.length) {
            this.setEnumerators(this.surveyEnumerators);
          }
        }
      }
    },
    methods: {
      ...mapActions(['setEnumerators']),
      fetchEmumerators(projectId, surveyId) {
        this.$store.dispatch('get', [{
          key: 'surveyEnumerators',
          url: apiPaths.enumerators(projectId, surveyId),
          extended: true,
          clear: false,
          resend: true,
          success: (surveyEnumerators) => {
            this.setEnumerators(surveyEnumerators);
          }
        }]).catch(noop);
      },
      grantFormAccessToEnumeratorsForProject
    }
  };

  return mixin;
};
