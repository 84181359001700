<template>
  <span data-cy="geographicalInformation">
    <h4 class="mt-40 mb-22 txt-bold txt-font-din-medium">
      {{ $t('components.titles.geographicalInformation') }}
    </h4>
    <a-card :bordered="true" class="mb-22">
      <a-alert
        v-if="calendar"
        :message="$t('components.notifications.warning')"
        :description="$t('components.notifications.uploadedLocalEventCalendar')"
        type="warning"
        class="mb-20"
        show-icon/>
      <p>{{ $t('components.description.searchGeographicArea') }}</p>
      <div class="geographic-container" :class="{ isSelected: selected }">
        <div class="search">
          <a-form-item
            :validate-status="error ? 'error' : 'success'"
            :help="error">
            <div slot="label">
              {{ $t('components.labels.geographicArea') }}
              <a-tooltip
                :title="$t('components.toolTips.geographicArea')"
                class="ml-8">
                <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
              </a-tooltip>
            </div>
            <a-input v-model="searchText" data-cy="geographic-area-input" :disabled="isSupervisor" allow-clear :placeholder="$t('components.description.searchForArea')" @change="search"/>
          </a-form-item>
        </div>
        <div v-if="results && results.length > 0 || selected" class="results">
          <div
            v-for="result in results"
            :key="result.place_id"
            class="result"
            @click="selectPrediction(result)">
            <div class="flex align-center">
              <a-tooltip
                v-if="result.isManualGeographicArea"
                :title="$t('components.toolTips.manualLocation')">
                <a-icon type="pushpin" class="mr-8"/>
              </a-tooltip>
              <a-icon
                v-if="!result.isManualGeographicArea"
                type="environment" class="mr-8"/>
              {{ result.description }}
              <a-tooltip v-if="result.isManualGeographicArea" :title="$t('components.toolTips.editLocationName')">
                <a-button type="link mt-4" @click="edit($event, result)">
                  <embed width="20px" height="20px" src="/pencil-circle.svg">
                </a-button>
              </a-tooltip>
            </div>
            <div
              v-if="selected && (selected.place_id === result.place_id ||
                selected.description === result.description || selected.isFromDatabase)"
              class="active-result-icon">
              <a-icon type="check-square" :style="{ color: '#6ad26a', fontSize: '25px' }"/>
            </div>
          </div>
          <div class="result" @click="setManual">
            <div
              v-if="!selected || selected && selected.place_id !== 'manual'"
              class="active-result-icon">
              <a-icon type="plus-square" class="mr-8"/>{{ $t('components.description.manuallyEnter') }}
            </div>
            <div v-if="selected && selected.place_id === 'manual'" class="active-result-icon">
              <a-icon type="environment" class="mr-8"/>{{ selected.description }}
            </div>
            <div v-if="selected && selected.place_id === 'manual'" class="active-result-icon">
              <a-icon type="check-square" :style="{ color: '#6ad26a', fontSize: '25px' }"/>
            </div>
          </div>
        </div>
        <div v-show="selected" class="map">
          <a-spin v-if="!selected"/>
          <div id="map"></div>
          <l-map
            v-if="selected"
            ref="myMap"
            class="myMap"
            :zoom="zoom"
            :center="center"
            @ready="ready"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate">
            <v-tilelayer-googlemutant
              :apikey="GOOGLE_MAPS_API_KEY"
              lang="en"
              region="en"/>
            <l-marker :draggable="false" :lat-lng="marker" :icon="icon"/>
          </l-map>
        </div>
      </div>
      <ExcludedGeographicArea/>
      <ManualGeographicAreaModal
        v-if="manualLat && manualLng"
        :get-nearby-places="getNearbyPlaces"
        :on-exit="setManual"
        :active="manual"
        :initial-lat="manualLat"
        :initial-lng="manualLng"
        :initial-name="manualName"
        :on-complete="onManualComplete"/>
      <EditGeographicAreaModal
        :result="editModalResult"
        :on-exit="() => editModalResult = undefined"
        :on-complete="onEditComplete"/>
    </a-card>
  </span>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';
import L, { latLng } from 'leaflet';
import { LMap, LMarker } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import 'leaflet/dist/leaflet.css';
import { configForPossibleBackendRequest } from '../../../../util/request';
import { GOOGLE_MAPS_API_KEY } from '../../../../util/map';

export default {
  name: 'MetadataGeographicInformation',
  components: {
    LMap,
    LMarker,
    ManualGeographicAreaModal: () => import('./manual-geographic-area-modal/manual-geographic-area-modal'),
    EditGeographicAreaModal: () => import('./edit-geographic-area-modal/edit-geographic-area-modal'),
    ExcludedGeographicArea: () => import('./excluded-area/excluded-area'),
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isFirstLoad: true,
      searchText: '',
      error: undefined,
      editModalResult: undefined,
      manual: false,
      manualLat: undefined,
      manualLng: undefined,
      manualName: undefined,
      results: [],
      selected: undefined,
      sessionToken: undefined,
      map: undefined,
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      icon: L.icon({
        iconUrl: '/marker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 20]
      }),
      marker: undefined,
      center: undefined,
      GOOGLE_MAPS_API_KEY
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      calendar: state => state.survey.calendar,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    metadata: {
      deep: true,
      handler() {
        if (!this.metadata || this.selected || !this.isFirstLoad) {
          return;
        }

        const { latitude, longitude, geographicAreaName } = this.metadata;
        if (latitude && longitude && geographicAreaName) {
          const selected = {
            lat: latitude,
            lng: longitude,
            description: geographicAreaName,
            place_id: 'manual',
            isFromDatabase: true
          };
          this.setSelected(selected);
          this.isFirstLoad = false;
        }
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(document.getElementById('map'));
  },
  methods: {
    ...mapActions(['setDirty']),
    async selectPrediction(prediction) {
      if (this.selected && (this.selected.place_id === prediction.place_id ||
        this.selected.description === prediction.description)) {
        this.selected = undefined;
        this.$emit('input', undefined);
        return;
      }

      if (prediction.isManualGeographicArea) {
        this.searchText = prediction.description;
        this.setSelected({
          ...prediction
        });
        return;
      }

      // eslint-disable-next-line no-undef
      const service = new google.maps.places.PlacesService(this.map);
      service.getDetails({
        placeId: prediction.place_id,
        fields: ['name', 'geometry', 'address_components']
      }, (result) => {
        const geographicCountry = result.address_components
          .find(component => component.types.includes('country')).long_name;
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        this.searchText = result.formatted_address;
        this.$emit('update-geographic-information', result);
        this.setSelected({
          ...prediction,
          lat,
          lng,
          geographicCountry,
          isManualGeographicArea: false
        });
      });
    },
    async getLocation(prediction) {
      // eslint-disable-next-line no-undef
      const service = new google.maps.places.PlacesService(this.map);
      return new Promise((resolve, reject) => {
        service.getDetails({
          placeId: prediction,
          fields: ['name', 'geometry', 'address_components']
        },
        (result) => resolve(result), (err) => reject(err));
      });
    },
    async setResults(predictions) {
      this.setDirty();
      if (this.searchText) {
        this.manualName = this.searchText;
      }
      if (!predictions || (predictions && Array.isArray(predictions) && predictions.length === 0)) {
        return;
      }
      // eslint-disable-next-line no-undef
      const predictionLocations = await Promise.all(
        predictions.map(prediction => this.getLocation(prediction.place_id))
      );
      const results = predictions.map((prediction, index) => ({
        ...prediction,
        address_components: predictionLocations[index].address_components,
        lat: predictionLocations[index].geometry.location.lat(),
        lng: predictionLocations[index].geometry.location.lng()
      }));
      // Default manual entry to first result found from search
      const firstPrediction = results[0];
      const geographicCountry = firstPrediction.address_components
        .find(component => component.types.includes('country')).long_name;
      this.manualLat = firstPrediction.lat;
      this.manualLng = firstPrediction.lng;
      const payload = {
        ...firstPrediction,
        geographicCountry,
        lat: firstPrediction.lat,
        lng: firstPrediction.lng
      };
      const nearbyResults = await this.getNearbyPlaces({
        ...payload,
        search: this.searchText
      });
      // filter out duplicates
      this.results = [...results, ...nearbyResults]
        .reduce((accumulator, current) => {
          function checkIfAlreadyExist(currentVal) {
            return accumulator.some(({ lat, lng }) => {
              const currentLat = parseFloat(currentVal.lat.toFixed(4));
              const currentLng = parseFloat(currentVal.lng.toFixed(4));
              const accumLat = parseFloat(lat.toFixed(4));
              const accumLng = parseFloat(lng.toFixed(4));
              return (accumLat === currentLat && accumLng === currentLng);
            });
          }
          if (checkIfAlreadyExist(current)) {
            return accumulator;
          }
          return [...accumulator, current];
        }, []);
      this.setSelected(undefined);
    },
    reset() {
      this.setSelected(undefined);
    },
    // eslint-disable-next-line func-names
    search: debounce(function(event) {
      if (this.searchText === '') {
        this.reset();
      }

      // eslint-disable-next-line no-undef
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({
        input: event.target.value,
        sessionToken: this.sessionToken
      }, this.setResults);
    }, 500),
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    ready() {
      this.$refs.myMap.mapObject._onResize();
    },
    setManual() {
      this.manual = !this.manual;
    },
    onManualComplete(payload) {
      if (this.isSupervisor) {
        return;
      }
      this.setManual();
      this.setSelected({
        description: payload.name,
        ...payload,
        place_id: 'manual'
      });
      this.results = [];
      this.searchText = '';
    },
    setSelected(payload) {
      this.error = undefined;
      this.selected = payload;

      if (payload) {
        this.center = latLng(payload.lat, payload.lng);
        this.marker = latLng(payload.lat, payload.lng);
        this.manualLat = payload.lat;
        this.manualLng = payload.lng;
        this.manualName = payload.description;
        const geographicArea = {
          latitude: payload.lat,
          longitude: payload.lng,
          geographicAreaName: payload.description,
          isManualGeographicArea: payload.place_id === 'manual' || payload.isManualGeographicArea || null,
          geographicCountry: payload.geographicCountry
        };
        this.$emit('input', geographicArea);
      }
    },
    setError() {
      this.error = this.$t('components.notifications.errorEnterGeographicArea');
    },
    async getNearbyPlaces(payload) {
      const baseConfig = { method: 'POST', url: '/nearbyGeographicAreas', data: payload };
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
      try {
        const response = await Vue.prototype.$http.request(axiosConfig);
        if (response && response.data && response.data.length > 0) {
          return [...response.data];
        }
        return [];
      } catch (error) {
        // do nothing
        return [];
      }
    },
    edit(event, result) {
      event.preventDefault();
      this.editModalResult = result;
    },
    onEditComplete(response) {
      this.results = this.results
        .map(result => (response.place_id === result.place_id ? response : result));
      this.setSelected({ ...response });
      this.editModalResult = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.geographic-container {
  display: grid;
  grid-template-columns: 100% 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "search search"
    "results map";
  row-gap: 20px;
  &.isSelected {
    grid-template-columns: 40% 1fr;
  }
}
.search {
  grid-area: search;
}

.results {
  grid-area: results;
  border: 1px solid #ccc;
}

.result {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;

  .active-result-icon {
    height: 20px;
  }

  &:hover {
    background: #f7f6f6;
  }

  &:last-child {
    border-bottom: none;
  }
}

#map {
  display: none;
}

.myMap {
  overflow: auto;
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: map;
  min-height: 300px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
 }
</style>
