var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.survey)?_c('a-modal',{staticClass:"survey-manager-modal",attrs:{"width":"90%","dialog-style":{ display: 'none' },"footer":null,"visible":_vm.visible && !_vm.showAddSupervisorModal,"closable":true},on:{"cancel":_vm.onExit}},[_c('h1',{staticClass:"flex align-center justify-left txt-30 txt-bold txt-font-din-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.titles.manageSurveySupervisors'))+" ")]),_c('a-tag',{staticStyle:{"display":"flex","flex-direction":"row","padding":"10px 20px","width":"100%","color":"black"},attrs:{"color":"blue"}},[_c('span',{staticStyle:{"display":"flex","flex-direction":"column","padding-right":"30px"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('components.labels.surveyName')))]),_c('span',[_vm._v(_vm._s(_vm.survey.name))])]),_c('span',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t('components.labels.dateCreated')))]),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.survey.createdAt)))])])]),_c('div',{staticClass:"flex justify-space-between align-center mt-20 mb-20"},[_c('span',{staticStyle:{"display":"flex","cursor":"pointer","color":"#e98300"},on:{"click":_vm.showAddSupervisor}},[_c('embed',{staticClass:"mr-4",attrs:{"src":"/icon-add-user.svg"}}),_c('span',{staticStyle:{"padding-left":"5px","font-size":"medium"}},[_vm._v(_vm._s(_vm.$t('components.description.addNewSupervisor')))])])]),_c('div',{staticClass:"mt-20"},[_c('a-table',{staticClass:"manage-supervisors-table",attrs:{"columns":_vm.columns,"size":"small","data-source":_vm.invitations,"row-key":"id","pagination":false},scopedSlots:_vm._u([{key:"invitationStatus",fn:function(invitationStatus, ref){
var expiresAt = ref.expiresAt;
return [_c('span',[_c('a-tag',{staticStyle:{"padding":"5px"},attrs:{"color":_vm.getColourForStatus(invitationStatus, expiresAt)}},[_c('span',{staticStyle:{"display":"flex","padding":"0px 5px"}},[(invitationStatus==='Accepted')?_c('a-icon',{staticStyle:{"color":"#66c300","font-size":"18px","padding-right":"5px"},attrs:{"type":"check-circle","theme":"filled"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.translateInvitationStatus(invitationStatus, expiresAt)))])],1)])],1)]}},{key:"resendInvite",fn:function(invitationStatus, ref){
var id = ref.id;
var resendCoolDown = ref.resendCoolDown;
return [(invitationStatus === 'pending')?_c('div',[_c('CountdownTimer',{attrs:{"resend-cooldown":resendCoolDown,"resend-invite":_vm.resendInvite,"invitation-id":id}})],1):_vm._e()]}},{key:"cancelInvite",fn:function(id, ref){
var invitationStatus = ref.invitationStatus;
return [(invitationStatus === 'pending')?_c('span',{staticStyle:{"display":"flex","cursor":"pointer","justify-content":"center"},on:{"click":function($event){return _vm.showDeleteSupervisor(id)}}},[_c('embed',{staticClass:"mr-4",attrs:{"data-cy":"delete-invitation","src":"/icon-trash.svg"}})]):_vm._e()]}}],null,false,55734684)})],1),_c('div',{staticClass:"mt-40 buttons-container"},[_c('a-button',{staticClass:"w170",attrs:{"type":"primary","size":"large"},on:{"click":_vm.onExit}},[_vm._v(" "+_vm._s(_vm.$t('values.done'))+" ")])],1),_c('AddSupervisorModal',{attrs:{"survey-id":_vm.survey.id,"visible":_vm.showAddSupervisorModal,"on-exit":_vm.exitAddSupervisor,"on-complete":_vm.completeAddSupervisor}}),_c('DeleteSupervisorModal',{attrs:{"visible":_vm.deleteSupervisorid != null,"on-exit":_vm.exitDeleteSupervisor,"on-complete":_vm.completeDeleteSupervisor}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }