function shouldNotBeEmpty(key, object) {
  if (object[key] === null) {
    throw new Error(`${key} cannot be null.`);
  }
  if (object[key] === undefined) {
    throw new Error(`${key} cannot be undefined.`);
  }
}

class Prompt {
  constructor({
    key,
    title,
    prompt,
    defaultValue,
    type = Boolean,
    visibleConditions = {},
    options = [],
    promptConfirmText = 'Next'
  }) {
    this.key = key;
    this.title = title;
    this.type = type;
    this.prompt = prompt;
    this.defaultValue = defaultValue;
    this.promptConfirmText = promptConfirmText;
    this.options = options;
    this.visibleConditions = visibleConditions;


    shouldNotBeEmpty('key', this);
    shouldNotBeEmpty('title', this);
    shouldNotBeEmpty('type', this);
    shouldNotBeEmpty('prompt', this);
  }
}

export default Prompt;
